var MAX_WIDTH = 1280;
var MAX_HEIGHT = 1280;

/**
 * Esta funcion escala la imagen a un tamaño fijo 
 * antes de subirla al servidor 
 * @param {File} image Imagen a recortar
 * @param {Function} callback Acción luego de recortar la imagen
 */
export default function resize(image, callback) {
    // creamos img que sera nuestra nueva imagen
    const img = new Image();

    // creamos un lector que leerá nuestra imagen
    const reader = new FileReader();

    // Función de devolución de llamada cuando el lienzo se convierte en blob
    const createFileFromBlob = blob => {
        // creamos un archivo con el blob pasando el nombre de la imagen, tipo y última modificación
        const imageResized = new File([blob], image.name, {
            type: image.type,
            lastModified: Date.now()
        });

        // función de devolución de llamada que será su función de carga al servidor
        callback(imageResized);
    };

    // escribamos el método onload del lector
    // porque se llama cuando la imagen termina de cargarse
    reader.onload = e => {
        img.src = e.target.result;

        // cuando se cargó la imagen después de recibir la línea superior
        img.onload = () => {
            // creamos un lienzo (canvas)
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            // dibujamos la imagen en la posición (0,0)
            ctx.drawImage(img, 0, 0);

            // hacemos cálculos para saber qué lado es más grande y reducir en la proporción correcta
            let width = img.width;
            let height = img.height;

            if (width > height) {
                if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                }
            } else {
                if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                }
            }
            // configuramos el tamaño del lienzo al mismo tamaño que la imagen ya reducida
            canvas.width = width;
            canvas.height = height;
            // obtener el contexto del lienzo
            // const ctx = canvas.getContext("2d");

            // dibujamos la nueva imagen pasando la imagen, la posición inicial y el tamaño
            ctx.drawImage(img, 0, 0, width, height);

            // aquí es donde sucede la magia,

            // primero convertimos la imagen dibujada por canvas a formato blob
            // el primer parámetro es la función de devolución de llamada que llamará después de la conversión,
            // el segundo es el tipo de imagen
            // y el tercero es la calidad que va de 0 a 1
            ctx.canvas.toBlob(createFileFromBlob, image.type, 0.8);
        };
    };

    reader.readAsDataURL(image);
}