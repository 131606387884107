import * as React from "react";
import CloseIcon from "mdi-react/CloseCircleOutlineIcon";
import funciones from "services/funciones";
import FileUpload from "shared/FileUpload";
import { Input } from "shared/Input";

/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const EditContainer = ({ item, update, remove }) => {
    const [descripcion, setDescripcion] = React.useState(item.descripcion);
    const [pieces, setPieces] = React.useState(item.pieces);
    const [length, setLength] = React.useState(item.length);
    const [height, setHeight] = React.useState(item.height);
    const [width, setWidth] = React.useState(item.width);
    const [weight, setWeight] = React.useState(item.weight);
    const [weightTotal, setWeightTotal] = React.useState(0);
    const [volume, setVolume] = React.useState(item.volume);
    const [volumeTotal, setVolumeTotal] = React.useState(0);
    const [precio, setPrecio] = React.useState(item.price);
    const [type, setType] = React.useState(item.type);
    const [delivery, setDelivery] = React.useState(item.delivery);
    const [attachments, setAttachments] = React.useState(item.attachments);
    const [addresseeId, setAddresseeId] = React.useState(item.addressee);
    const [typePackages] = React.useState(["SOBRE", "CAJA", "BOLSA", "ESTIBA"]);



    const updatePackage = (param, value) => {
        let _weight_total = weightTotal;
        let _volume_total = volumeTotal;

        switch (param) {
            case "weight":
                _weight_total = calculaWeight(value, pieces);
                break;
            case "pieces":
                _weight_total = calculaWeight(weight, value);
                _volume_total = calculaTotal(length, width, height, value);
                break;
            case "length":
                _volume_total = calculaTotal(value, width, height, pieces);
                break;
            case "width":
                _volume_total = calculaTotal(length, value, height, pieces);
                break;
            case "height":
                _volume_total = calculaTotal(length, width, value, pieces);
                break;
            default:
                _weight_total = weightTotal;
                break;
        }

        let _pack = {
            ...item,
            "precio": param === "precio" ? value.replaceAll(".", "") : precio.replaceAll(".", ""),
            "descripcion": param === "descripcion" ? value : descripcion,
            "pieces": param === "pieces" ? value : pieces,
            "length": param === "length" ? value : length,
            "height": param === "height" ? value : height,
            "width": param === "width" ? value : width,
            "weight": param === "weight" ? value : weight,
            "weight_total": _weight_total,
            "volume": param === "volume" ? value : volume,
            "volume_total": _volume_total,
            "type": param === "type" ? value : type,
            "addressee": param === "addressee" ? value : addresseeId,
            "delivery": param === "delivery" ? value : delivery,
            "attachments": param === "image" ? value : attachments,
            "estado": item.estado,
        };

        param === "addressee" && setAddresseeId(value);
        param === "precio" && setPrecio(value);
        param === "descripcion" && setDescripcion(value);
        param === "pieces" && setPieces(value);
        param === "length" && setLength(value);
        param === "height" && setHeight(value);
        param === "width" && setWidth(value);
        param === "weight" && setWeight(value);
        param === "volume" && setVolume(value);
        param === "type" && setType(value);
        param === "delivery" && setDelivery(value);
        param === "image" && setAttachments(value);

        update(_pack, item.index);
    }

    const calculaTotal = (_length, _width, _height, _pieces) => {
        let _vol = ((_length * _width * _height) / 1728).toFixed(2);
        let _vol_total = (_vol * _pieces).toFixed(2);

        updatePackage("volume", _vol);

        setVolumeTotal(_vol_total);
        return _vol_total;
    }

    const calculaWeight = (_weight, _pieces) => {
        let _total = (_weight * _pieces).toFixed(2);
        setWeightTotal(_total);
        return _total;
    }

    React.useEffect(() => {

        setDescripcion(item.descripcion);
        setLength(item.length);
        setHeight(item.height);
        setWidth(item.width);
        setWeight(item.weight);
        setVolume(item.volume);
        setType(item.type);


        setTimeout(() => {
            updatePackage("pieces", item.pieces);
        }, 1000);
    }, [item]);


    return (
        <div className="new_container">

            <fieldset className="new_features">
                <legend>Paquete {item.index}</legend>
                <h5>
                    <CloseIcon onClick={() => remove(item.index, item.id)} />
                    {funciones.getStateSpan(item)}
                </h5>

                <div className="f_row">

                    <div className="features_options _number">
                        <label>Piezas *</label>
                        <input type="number" name="pieces" value={pieces}
                            onChange={({ target: { value } }) => updatePackage("pieces", value)} />
                    </div>

                    <div className="features_options">
                        <label>Tipo de paquete *</label>
                        <Input type="text" name="type" list={typePackages} placeholder="Tipo de paquete"
                            value={type} onChange={(value) => updatePackage("type", value)} />
                    </div>

                    <div className="features_options _static">
                        <label>Descripción</label>
                        <textarea name="descripcion" rows="2" value={descripcion} placeholder="Ingresa una Descripción"
                            onChange={({ target: { value } }) => updatePackage("descripcion", value)} style={{ marginBottom: -8 }} />
                    </div>

                    <div className="features_options _static">
                        <label>Adjuntar archivos</label>
                        <FileUpload
                            onChange={(value) => updatePackage("image", value)} />
                    </div>

                </div>

            </fieldset>

        </div>
    );
}

export default EditContainer;