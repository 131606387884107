import * as React from "react";
import Information from 'mdi-react/InformationOutlineIcon';
import { MovePopUp } from "shared/MovePopUp";
import * as Icon from "images";
import PropTypes from 'prop-types';

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const FilterConcept = (params) => {
    const { task, parent, close, call } = params;
    const [showLoading, setShowLoading] = React.useState(false);
    const [criterio, setCriterio] = React.useState("");
    const [error, setError] = React.useState("");
    const form_ref = React.useRef();


    const handleClose = (e) => {
        e.preventDefault();
        document.body.style.overflow = "auto";
        close(false);
    }

    const validateFields = (e) => {
        e.preventDefault();

        setError("");

        if (criterio.length < 1) {
            setError("¡Debes ingresar un criterio de búsqueda, puede ser el nombre de un mensajero o método de pago!");
            return;
        }
        setShowLoading(true);

        document.body.style.overflow = "auto";
        call({
            task,
            criterio: criterio.trim()
        });
    }

    React.useEffect(() => {
        document.body.style.overflow = "hidden";
    }, []);

    return (
        <MovePopUp
            parent={parent}
            close={handleClose}
            className="win_modal _overflow"
            title="Filtro por concepto">

            <form ref={form_ref}>

                <div className="_in_column">

                    <div className="_in_column">
                        <label className="__titulo">Concepto</label>
                        <input type="text" name="criterio" placeholder="Ej: bold o nombre mensajero"
                            value={criterio} onChange={e => { setError(""); setCriterio(e.target.value); }} />
                    </div>

                </div>

            </form>
            {error.length > 1 && <h4 className="_error"><Information />{error}</h4>}

            {showLoading ?
                <button className="btn btn-common width-static"><Icon.Loading /></button>
                :
                <button className="btn btn-common width-static" onClick={validateFields}>Filtrar</button>
            }
        </MovePopUp>
    )
}

FilterConcept.propTypes = {
    task: PropTypes.string.isRequired,
    parent: PropTypes.shape({
        top: PropTypes.number.isRequired,
        left: PropTypes.number.isRequired,
        width: PropTypes.number.isRequired,
    }).isRequired,
    close: PropTypes.func.isRequired,
    call: PropTypes.func.isRequired
}


export default FilterConcept;