import * as React from "react";
import Information from 'mdi-react/InformationOutlineIcon';
import { useNavigate } from 'react-router-dom';
import { MovePopUp } from "shared/MovePopUp";
import * as Icon from "images";
import { Alert } from "shared/Alert";
import { Input } from "shared/Input";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const CourierList = (params) => {
    const { data, close, reload } = params;
    const [showLoading, setShowLoading] = React.useState(true);
    const [courierId, setCourierId] = React.useState("");
    const [userList, setUserList] = React.useState([]);
    const [courier, setCourier] = React.useState("");
    const [error, setError] = React.useState("");
    const navigate = useNavigate();


    const getAllCourier = async () => {
        setShowLoading(true);
        let _data = await api.fetchJson({
            url: "get-only-carriers",
            data: {}
        });
        let _users = [];


        if (_data.response == 1) {
            setUserList(_data.data);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
        return _users;
    }

    const changeCourier = (val) => {
        setCourier(val);
        setError("");

        let item = userList.filter(({ nombre, apellido }) => nombre + " " + apellido === val);
        item = item[0];

        if (typeof item === "object") {
            setCourierId(item.id_user);
        } else {
            setCourierId("");
        }
    }

    const handleClose = (e) => {
        e.preventDefault();
        document.body.style.overflow = "auto";
        close(false);
    }

    const validateFields = (e) => {
        e.preventDefault();

        setError("");

        if (courierId.length < 1) {
            setError("Debes agregar un mensajero!");
            return;
        }

        saveCourier();
    }

    const saveCourier = async () => {
        setShowLoading(true);
        let _data = await api.fetchJson({
            url: data.url,
            data: {
                ...data,
                id_carrier: courierId
            }
        });

        if (_data.response == 1) {
            Alert(_data.msg, "ok", () => reload());
            close(false);
        } else if (_data.response === "-3") {
            Alert(_data.msg, "warning", () => reload());
            close(false);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    React.useEffect(() => {
        getAllCourier();
    }, []);

    return (
        <MovePopUp
            parent={data.parent}
            close={handleClose}
            className="win_modal _small"
            title="Lista de mensajeros">

            <div className="_in_column">
                <p className="_info">¡Se mostrarán sugerencias de los mensajeros a medida que escribas el nombre!</p>
                <Input
                    type="text"
                    list={userList.map(({ nombre, apellido }) => (nombre + " " + apellido))}
                    name="courier"
                    placeholder="Nombre del mensajero"
                    value={courier}
                    onChange={changeCourier} />
            </div>

            {error.length > 1 && <h4 className="_error"><Information />{error}</h4>}


            {showLoading ?
                <button className="btn btn-common width-static"><Icon.Loading /></button>
                :
                <button className="btn btn-common width-static" onClick={validateFields}>Asignar</button>
            }

        </MovePopUp>
    )
}

export default CourierList;