import * as React from "react";
import Information from "mdi-react/InformationOutlineIcon";
import { useNavigate } from "react-router-dom";
import funciones from "services/funciones";
import { MovePopUp } from "shared/MovePopUp";
import * as Icon from "images";
import { Alert } from "shared/Alert";
import { Input } from "shared/Input";
import api from "services/api";
import log from "services/log";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const CambiaEfectivo = ({ saldo, parent, close, reload }) => {
    const [showLoading, setShowLoading] = React.useState(false);
    const [monto, setMonto] = React.useState("");
    const [notes, setNotes] = React.useState("");
    const [error, setError] = React.useState("");
    const navigate = useNavigate();

    const handleClose = (e) => {
        e.preventDefault();
        document.body.style.overflow = "auto";
        close(false);
    }

    const validateFields = (e) => {
        e.preventDefault();
        let _error = false;

        setError("");

        if (monto.length < 1) {
            setError("¡Debes ingresar un monto!");
            return;
        }

        if (isNaN(monto)) {
            setError("¡Debes ingresar solo numeros!");
            return;
        }

        if (parseInt(monto) < 1) {
            setError("¡El monto no puede ser cero o menor!");
            return;
        }

        if (notes.length < 1) {
            setError(`¡Debes ingresar una descripción!`);
            return;
        }


        if (_error) {
            log.e("Tiene errores");
        } else {
            guardaCaja();
        }
    }

    const guardaCaja = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "save-caja",
            data: {
                task: "cambia_efectivo",
                monto: funciones.formatPrice(monto).int,
                nota: notes
            }
        });

        if (_data.response == 1) {
            reload();
            document.body.style.overflow = "auto";
            close(false);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    React.useEffect(() => {
        document.body.style.overflow = "hidden";
    }, []);

    return (
        <MovePopUp
            parent={parent}
            close={handleClose}
            className="win_modal _overflow _delivery"
            title="Cambia a efectivo">

            <div className="_in_column">

                <h5>Valor en transferencias <br />
                    ${funciones.formatPrice(saldo).format}
                </h5>

                <div>
                    <label className="__titulo">Monto</label>
                    <Input type="tel" name="monto" placeholder="ingresa un valor"
                        value={funciones.formatPrice(monto).format} onChange={val => { setError(""); setMonto(val); }} />
                </div>

                <div>
                    <p className="__titulo">Descripción</p>
                    <textarea name="comment" rows="3" placeholder="ingresa una descripción"
                        value={notes} onChange={e => { setError(""); setNotes(e.target.value); }} />
                </div>

            </div>

            {error.length > 1 && <h4 className="_error"><Information />{error}</h4>}

            {showLoading ?
                <button className="btn btn-common width-static"><Icon.Loading /></button>
                :
                <button className="btn btn-common width-static" onClick={validateFields}>Guardar</button>
            }
        </MovePopUp>
    )
}

export default CambiaEfectivo;