import * as React from "react";
import ListStatusIcon from "mdi-react/ListStatusIcon";
import AutorenewIcon from "mdi-react/AutorenewIcon";
import { Card, CardBody, Col } from "reactstrap";
import funciones from "services/funciones";
import { useNavigate } from "react-router";
import * as Icon from "images";
import { Alert } from "shared/Alert";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const meses = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

const Pendientes = ({ parent, tipo_caja }) => {
    const [previousQuery, setPreviousQuery] = React.useState({ task: "pendientes_bodega", offset: 0 });
    const [showLoading, setShowLoading] = React.useState(true);
    const [showLazy, setShowLazy] = React.useState(true);
    const [dataList, setDataList] = React.useState([]);
    const navigate = useNavigate();

    const handlerRefresh = (e) => {
        e.preventDefault();
        getList();
        parent.update();
    }

    const getListLazy = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "info-caja",
            data: {
                task: previousQuery.task,
                caja: tipo_caja,
                offset: dataList.length
            }
        });

        if (_data.response == 1) {
            let _packs = [];

            dataList.map(item => {
                _packs.push(item);
            });

            _data.data.map(item => {
                _packs.push(item);
            });

            // setPreviousQuery({ task: previousQuery.task, offset: dataList.length });
            setShowLazy(_data.data.length < 50 ? false : true);
            setDataList(_packs);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const getList = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "info-caja",
            data: {
                task: "pendientes_bodega",
                caja: tipo_caja,
                offset: 0
            }
        });

        if (_data.response == 1) {
            setPreviousQuery({ task: "pendientes_bodega", offset: 0 });
            setShowLazy(_data.data.length < 50 ? false : true);
            setDataList(_data.data);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const History = () => {
        return <div className="dashboard__sales-table">
            {dataList.length < 1 ?
                <h4>¡No hay pendientes!</h4>
                :
                <>
                    <table className="table">
                        <tbody>
                            <tr className="table_title">
                                <th>Fecha</th>
                                <th>Monto</th>
                                <th>Concepto</th>
                            </tr>

                            {dataList.map((item, index) => {
                                let fecha_creado = new Date(item.fecha);

                                switch (parseInt(item.movimiento)) {
                                    case 14:
                                    case 15:
                                        item.nota = "Abono a caja principal" + item.concepto.split("\" abono desde caja de bodega")[1];
                                        break;
                                    case 21:
                                    case 22:
                                        item.nota = "Entrega a caja principal" + item.concepto.split("\" entrega caja de bodega")[1];
                                        break;
                                    default:
                                        item.nota = "...";
                                        break;
                                }

                                return <tr key={index}>
                                    <td>{format(fecha_creado.getDate())}/{meses[fecha_creado.getMonth()]}/{fecha_creado.getFullYear()} {format(fecha_creado.getHours())}:{format(fecha_creado.getMinutes())}</td>
                                    <td style={{ textAlign: "right" }} className="con_icon">{funciones.formatPrice(item.monto).format}</td>
                                    <td style={{ whiteSpace: "pre" }}>{item.nota}</td>
                                </tr>;
                            })}

                            <tr className="_no_margin">
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>

                    {showLoading ?
                        <button className="btn_lazy_load"><Icon.Loading /></button>
                        :
                        <>
                            {showLazy ?
                                <button className="btn_lazy_load" onClick={() => getListLazy()}>Cargar más</button>
                                :
                                <h4 className="h4_lazy_load">¡No hay más pendientes!</h4>
                            }
                        </>
                    }
                </>
            }
        </div>;
    }

    React.useEffect(() => {
        if (parent.inload) {
            getList();
        }
    }, [parent]);

    return (
        <div className="content_reportes">
            <Col className="col_table">
                <Card style={{ overflow: "hidden" }}>
                    <CardBody className="dashboard__card-widget">
                        <div className="card__title">
                            <h5 className="bold-text"><ListStatusIcon className="dashboard__money-icon" /> Confirmaciones Pendientes</h5>
                        </div>

                        <div className="panel__btns">

                            <button
                                className="panel__btn"
                                aria-label="panel__btn"
                                type="button"
                                onClick={handlerRefresh}>
                                <AutorenewIcon className={showLoading ? "on_refresh" : ""} />
                            </button>

                        </div>

                        <History />
                    </CardBody>
                </Card>
            </Col>
        </div>
    )
}

export default Pendientes;