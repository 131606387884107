import * as React from "react";
import { RequirePermissions } from "shared/AlertMessages";
import { TitleSticky } from "shared/Components";
import withRouter from "services/withRouter";
import funciones from "services/funciones";
import MenuRow from "./components/MenuRow";
import ToolBar from "shared/tool_bar";
import { Alert } from "shared/Alert";
import { Api } from "services/api";
import { useMedia } from "hooks";
import * as Icon from "images";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const meses = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

const MyPickUps = (router) => {
    const { navigate } = router;
    const { media_movil, setTitulo, setDataUser, currentQuery, setCurrentQuery } = useMedia();
    const [loadResult, setLoadResult] = React.useState("Cargando recogidas...");
    const api = React.useRef(new Api({ navigate, setDataUser }));
    const [showLoading, setShowLoading] = React.useState(false);
    const [selected, setSelected] = React.useState({ id: 0 });
    const [showLazy, setShowLazy] = React.useState(true);
    const [dataList, setDataList] = React.useState([]);


    const Row = ({ data, blue }) => {
        let status = funciones.getStateTd(data);
        let date_pickup = new Date(data.recogida.fecha);

        return (
            <MenuRow
                data={data}
                blue={blue}
                navigate={navigate}
                current={selected.id}
                onSel={setSelected}
                reload={() => getList(currentQuery.recogidas)}>

                {status}
                <td>{data.consecutivo}</td>
                {!media_movil && <td>{data.pickup_name}</td>}
                <td>{data.pickup_address}</td>
                {!media_movil && <td>{format(date_pickup.getDate())}/{meses[date_pickup.getMonth()]}/{date_pickup.getFullYear()}</td>}
            </MenuRow>
        );
    }

    const AllRow = () => {
        let _data = [];

        dataList.map((item, index) => {
            _data.push(<Row key={item.id} blue={index % 2} data={item} />);
        });

        if (_data.length < 1) {
            return <h4 className="_titulo">{loadResult}</h4>;
        } else {
            return <>
                <table className="table">
                    <tbody>

                        <tr className="table_title">
                            <th>Estado</th>
                            <th>Número</th>
                            {!media_movil && <th>Cliente final</th>}
                            <th>Lugar de recogida</th>
                            {!media_movil && <th>Fecha de recogida</th>}
                        </tr>
                        {_data}
                    </tbody>
                </table>

                {showLoading ?
                    <button className="btn_lazy_load"><Icon.Loading /></button>
                    :
                    <>
                        {showLazy ?
                            <button className="btn_lazy_load" onClick={() => getListLazy(currentQuery.recogidas)}>Cargar más</button>
                            :
                            <h4 className="h4_lazy_load">{loadResult}</h4>
                        }
                    </>
                }
            </>;
        }
    }

    const getListLazy = async (_filter) => {
        setLoadResult("Cargando recogidas...");
        setShowLoading(true);

        let _data = await api.current.fetchJson({
            url: "pickup-list",
            data: { ..._filter, type: 2, offset: dataList.length }
        });

        if (_data.response == 1) {
            let _packs = [];

            dataList.map(item => {
                _packs.push(item);
            });

            _data.data.map(item => {
                item.recogida = JSON.parse(item.recogida);
                _packs.push(item);
            });

            setShowLazy(_data.data.length < 50 ? false : true);
            setDataList(_packs);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        if (_filter?.search == 1) {
            let _msg = "";
            _msg += (_filter.consignee?.length > 0 && _filter.consigneeName) ? "\ncliente \"" + _filter.consigneeName + "\"" : "";
            _msg += (_filter.carrier?.length > 0 && _filter.carrierName) ? "\nmensajero \"" + _filter.carrierName + "\"" : "";
            _msg += (_filter.estado?.length > 0 && _filter.estadoName) ? "\nestado \"" + _filter.estadoName + "\"" : "";

            setLoadResult((!_data?.data || _data?.data.length < 1) ? "No hay recogidas con: " + _msg : "No hay más recogidas con: " + _msg);
        } else {
            setLoadResult((!_data?.data || _data?.data.length < 1) ? "¡No hay recogidas!" : "¡No hay más recogidas!");
        }
        setShowLoading(false);
    }

    const getList = async (_filter) => {
        setLoadResult("Cargando recogidas...");
        setShowLoading(true);

        let _data = await api.current.fetchJson({
            url: "pickup-list",
            data: { ..._filter, type: 2, offset: 0 }
        });

        if (_data.response == 1) {
            setShowLazy(_data.data.length < 50 ? false : true);
            setDataList(_data.data.map(item => {
                item.recogida = JSON.parse(item.recogida);
                return item;
            }));
            setCurrentQuery(v => ({ ...v, recogidas: { ..._filter } }));
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        if (_filter?.search == 1) {
            let _msg = "";
            _msg += (_filter.consignee?.length > 0 && _filter.consigneeName) ? "\ncliente \"" + _filter.consigneeName + "\"" : "";
            _msg += (_filter.carrier?.length > 0 && _filter.carrierName) ? "\nmensajero \"" + _filter.carrierName + "\"" : "";
            _msg += (_filter.estado?.length > 0 && _filter.estadoName) ? "\nestado \"" + _filter.estadoName + "\"" : "";

            setLoadResult((!_data?.data || _data?.data.length < 1) ? "No hay recogidas con: " + _msg : "No hay más recogidas con: " + _msg);
        } else {
            setLoadResult((!_data?.data || _data?.data.length < 1) ? "¡No hay recogidas!" : "¡No hay más recogidas!");
        }
        setShowLoading(false);
    }

    // Ordenes de recogida del cliente
    React.useEffect(() => {
        setTitulo("Ordenes de recogida");
        document.body.style.overflow = "auto";
    }, []);

    return (
        <RequirePermissions navigate={navigate}
            permission={funciones.validaPermisos("company_recogidas")}>

            <div className="cont_principal">
                <TitleSticky className="_in_filters">Ordenes de recogida</TitleSticky>
                <ToolBar {...router}
                    type="mis_recogidas"
                    showLoading={showLoading}
                    reload={getList}
                    currentFilterOptions={currentQuery.recogidas}
                    filter={{
                        showStatus: true,
                        statusList: [
                            "Todos",
                            "Recogido",
                            "Por recoger",
                            "Sin asignar",
                        ]
                    }}
                    crear={() => navigate("/dashboard/nueva-orden-de-recogida")} />

                <AllRow />
            </div>
        </RequirePermissions>
    )
}

export default withRouter(MyPickUps);
