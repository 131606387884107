import common_en from './en/common.json';
import common_es from './es/common.json';

const dictionary = {
  en: {
    common: common_en,
  },
  es: {
    common: common_es,
  },
};

export default dictionary;