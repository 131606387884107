import * as React from "react";
import { RequirePermissions } from "shared/AlertMessages";
import { TitleSticky } from "shared/Components";
import withRouter from "services/withRouter";
import funciones from "services/funciones";
import ToolBar from "shared/tool_bar";
import { Alert } from "shared/Alert";
import Menu from "./components/Menu";
import { Api } from "services/api";
import { useMedia } from "hooks";
import * as Icon from "images";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const meses = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

const CommodityList = (router) => {
    const { navigate } = router;
    const { media_movil, data_user, setTitulo, setDataUser, currentQuery, setCurrentQuery } = useMedia();
    const [loadResult, setLoadResult] = React.useState("Cargando paquetes...");
    const api = React.useRef(new Api({ navigate, setDataUser }));
    const [showLoading, setShowLoading] = React.useState(false);
    const [selected, setSelected] = React.useState({ id: 0 });
    const [showLazy, setShowLazy] = React.useState(true);
    const [dataList, setDataList] = React.useState([]);
    const [conteo, setConteo] = React.useState(0);


    const Row = ({ data, blue }) => {
        let fecha_creado = new Date(data.date_create + "T05:00:00");

        return (
            <Menu
                inRow={true}
                data={data}
                blue={blue}
                navigate={navigate}
                current={selected.id}
                onSel={setSelected}
                reload={() => getList(currentQuery.distribucion)}>

                {media_movil ?
                    <td style={{ maxWidth: "calc(100vw - 32px)", paddingBottom: 0 }}>
                        <div className="_states">
                            {funciones.getStateSpan(data)}
                            <b>{data.codigo}</b>
                        </div>

                        <div className="_states">
                            {data_user.nivel != 6 && <strong><i style={{ float: "left" }}>Cliente</i><br />{data.client_name ?? "Sin especificar"}</strong>}
                            {data_user.nivel < 4 && <strong><i>Mensajero</i><br />{data.carrier_name ?? "Sin asignar"}</strong>}
                        </div>

                        <p className="_states">{data.descripcion}</p>
                        <i className="_states">Creado: {format(fecha_creado.getDate())}/{meses[fecha_creado.getMonth()]}/{fecha_creado.getFullYear()}</i>
                    </td>
                    :
                    <>
                        {funciones.getStateTd(data)}
                        <td>{data.codigo}</td>
                        {data_user.nivel != 6 && <td>{data.client_name ?? "Sin especificar"}</td>}
                        {data_user.nivel < 4 && <td>{data.carrier_name ?? "Sin asignar"}</td>}
                        <td>{data.descripcion}</td>
                        <td>{format(fecha_creado.getDate())}/{meses[fecha_creado.getMonth()]}/{fecha_creado.getFullYear()}</td>
                    </>}
            </Menu>
        );
    }

    const AllRow = () => {
        let _data = [];

        dataList.map((item, index) => {
            _data.push(<Row key={item.id} blue={index % 2} data={item} />);
        });

        if (_data.length < 1) {
            return <h4 className="_titulo">{loadResult}</h4>;
        } else {
            return <>
                <table className="table">
                    <tbody>

                        {media_movil ?
                            <tr className="table_title">
                            </tr>
                            :
                            <tr className="table_title">
                                <th>Estado</th>
                                <th>Código</th>
                                {data_user.nivel != 6 && <th>Cliente</th>}
                                {data_user.nivel < 4 && <th>Mensajero</th>}
                                <th>Descripción</th>
                                <th>Fecha Creado</th>
                            </tr>
                        }

                        {_data}
                    </tbody>
                </table>

                {showLoading ?
                    <button className="btn_lazy_load"><Icon.Loading /></button>
                    :
                    <>
                        {showLazy ?
                            <button className="btn_lazy_load" onClick={() => getListLazy(currentQuery.distribucion)}>Cargar más</button>
                            :
                            <h4 className="h4_lazy_load">{loadResult}</h4>
                        }
                    </>
                }
            </>;
        }
    }

    const getListLazy = async (_filter) => {
        setLoadResult("Cargando paquetes...");
        setShowLoading(true);

        let _data = await api.current.fetchJson({
            url: "get-all-packages",
            data: { ..._filter, offset: dataList.length }
        });

        if (_data.response == 1) {
            let _packs = [];

            dataList.map(item => {
                _packs.push(item);
            });

            _data.data.map(item => {
                _packs.push(item);
            });

            setConteo(parseInt(_data.conteo));
            setShowLazy(_data.data.length < 50 ? false : true);
            setDataList(_packs);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        if (_filter?.search == 1) {
            let _msg = "";
            _msg += (_filter.consignee?.length > 0 && _filter.consigneeName) ? "\ncliente \"" + _filter.consigneeName + "\"" : "";
            _msg += (_filter.carrier?.length > 0 && _filter.carrierName) ? "\nmensajero \"" + _filter.carrierName + "\"" : "";
            _msg += (_filter.estado?.length > 0 && _filter.estadoName) ? "\nestado \"" + _filter.estadoName + "\"" : "";

            setLoadResult((!_data?.data || _data?.data.length < 1) ? "No hay paquetes con: " + _msg : "No hay más paquetes con: " + _msg);
        } else {
            setLoadResult((!_data?.data || _data?.data.length < 1) ? "¡No hay paquetes!" : "¡No hay más paquetes!");
        }
        setShowLoading(false);
    }

    const getList = async (_filter) => {
        setLoadResult("Cargando paquetes...");
        setShowLoading(true);

        let _data = await api.current.fetchJson({
            url: "get-all-packages",
            data: { ..._filter, offset: 0 }
        });

        if (_data.response == 1) {
            setConteo(parseInt(_data.conteo));
            setShowLazy(_data.data.length < 50 ? false : true);
            setDataList(_data.data);
            setCurrentQuery(v => ({ ...v, distribucion: { ..._filter } }));
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        if (_filter?.search == 1) {
            let _msg = "";
            _msg += (_filter.consignee?.length > 0 && _filter.consigneeName) ? "\ncliente \"" + _filter.consigneeName + "\"" : "";
            _msg += (_filter.carrier?.length > 0 && _filter.carrierName) ? "\nmensajero \"" + _filter.carrierName + "\"" : "";
            _msg += (_filter.estado?.length > 0 && _filter.estadoName) ? "\nestado \"" + _filter.estadoName + "\"" : "";

            setLoadResult((!_data?.data || _data?.data.length < 1) ? "No hay paquetes con: " + _msg : "No hay más paquetes con: " + _msg);
        } else {
            setLoadResult((!_data?.data || _data?.data.length < 1) ? "¡No hay paquetes!" : "¡No hay más paquetes!");
        }
        setShowLoading(false);
    }

    // Lista de paquetes de administración y de los mensajeros
    React.useEffect(() => {
        setTitulo("Distribución de paquetes");
        document.body.style.overflow = "auto";
    }, []);

    return (
        <RequirePermissions navigate={navigate}
            permission={funciones.validaPermisos("pack_entregas") && ![6, 7].includes(parseInt(data_user.nivel))}>

            <div className="cont_principal">
                <TitleSticky className="_in_filters">{data_user.nivel < 4 ? "Distribución de paquetes" : `${conteo} paquetes`}</TitleSticky>
                <ToolBar {...router}
                    type="distribucion"
                    showLoading={showLoading}
                    reload={getList}
                    currentFilterOptions={currentQuery.distribucion}
                    filter={{
                        loadUsersList: true,
                        showClient: true,
                        showCarrier: true,
                        showStatus: true
                    }} />

                <AllRow />
            </div>
        </RequirePermissions>
    )
}

export default withRouter(CommodityList);