import * as React from "react";
import MovimientosTodos from "./components/MovimientosTodos";
import ChartTimelineIcon from "mdi-react/ChartTimelineIcon";
import { RequirePermissions } from "shared/AlertMessages";
import { TitleSticky } from "shared/Components";
import { useNavigate } from "react-router-dom";
import { Container, Row } from "reactstrap";
import funciones from "services/funciones";
import * as Iconos from "images";
import { useMedia } from "hooks";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint no-extend-native: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const ListaMovimientos = () => {
    const [lista, setLista] = React.useState("lista");
    const { setTitulo } = useMedia();
    const navigate = useNavigate();


    React.useEffect(() => {
        setTitulo("Todos los movimientos de caja de mensajeros");
    }, []);

    return (
        <RequirePermissions navigate={navigate}
            permission={funciones.validaPermisos("caja_bodega")}>

            <TitleSticky className="_in_page">Todos los movimientos de caja de mensajeros</TitleSticky>

            <Container className="dashboard _caja">
                <Row>

                    <div className="cont_btns_caja">
                        <button className="cash-button" onClick={e => setLista(l => l === "lista" ? "history" : "lista")}>
                            {lista === "history" ?
                                <ChartTimelineIcon />
                                :
                                <Iconos.BoxHistory />
                            }
                            {lista === "history" ? "Todos los movimientos" : "Hitórico de entregas"}
                        </button>
                    </div>

                    <MovimientosTodos lista={lista} />
                </Row>
            </Container>
        </RequirePermissions>
    )
}

export default ListaMovimientos;
