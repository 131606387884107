import * as React from "react";
import Topbar from "./topbar/Topbar";
import Sidebar from "./sidebar/Sidebar";

const Layout = (params) => {
  const [isSidebarShown, setIsSidebarShown] = React.useState(false);

  const changeMobileSidebarVisibility = () => {
    setIsSidebarShown(!isSidebarShown);
  };

  return (
    <div className="layout">
      <Topbar
        {...params}
        changeMobileSidebarVisibility={changeMobileSidebarVisibility}
      />
      <Sidebar
        sidebarShow={isSidebarShown}
        changeMobileSidebarVisibility={changeMobileSidebarVisibility}
      />
    </div>
  );
}

export default Layout;
