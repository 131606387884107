import * as React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export default function withRouter(Component) {
  return (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return (<Component {...props}
      navigate={navigate}
      location={location}
      params={params} />);
  }
}
