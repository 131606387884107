import ArchiveIcon from "mdi-react/ArchiveIcon";
import log from "services/log";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint no-useless-escape: 0 */
/* eslint array-callback-return: 0 */
/* eslint import/no-anonymous-default-export: 0 */

const estados = {
    default: { color: "#153A61", name: "" },
    0: { color: "#172ba0", name: "sin.asignar" },
    1: { color: "#fafa5a", name: "por.recoger" },
    2: { color: "#faf050", name: "recogido" },
    3: { color: "#ccff25", name: "por.recolectar" },
    31: { color: "#ffe100", name: "en.recolecta" },
    32: { color: "#f73319", name: "error.dirección" },
    33: { color: "#959595", name: "recolecta.cancelada" },
    4: { color: "#172bdc", name: "para.salir" },
    5: { color: "#fadc3c", name: "por.entregar" },
    6: { color: "#328200", name: "OK" },
    7: { color: "#f5320a", name: "KO.CANCELADO" },
    8: { color: "#f5500a", name: "KO.DOM" },
    9: { color: "#329600", name: "OK.DV" },
    10: { color: "#f55a0a", name: "pen.dv.bodega" },
    11: { color: "#ff280a", name: "borrado" },
    12: { color: "#f5460a", name: "KO.DOM.PAGO" },
    13: { color: "#f53c0a", name: "KO.REPROGRAMADO" },
    14: { color: "#172bb4", name: "en.bodega" },
    15: { color: "#172bc8", name: "pen.dv." },
    16: { color: "#32b400", name: "devuelto" },
    17: { color: "#32c800", name: "pagado" },
    18: { color: "#172bb4", name: "en.validación" },
};

class Funciones {

    getPerfil(_nivel) {
        let _perfil;

        /**
         * Niveles de los usuarios 
         * 
         * 1 Super Administrador
         * 2 Administrador
         * 3 Coordinador de zona
         * 4 Mensajero
         * 5 Recepcionista
         * 6 Cliente bts
         * 7 Cliente final
         * 
         */

        switch (parseInt(_nivel)) {
            case 7:
                _perfil = "Cliente final";
                break;
            case 6:
                _perfil = "Cliente bts";
                break;
            case 5:
                _perfil = "Recepcionista";
                break;
            case 4:
                _perfil = "Mensajero";
                break;
            case 3:
                _perfil = "Coordinador";
                break;
            case 2:
                _perfil = "Administrador";
                break;
            case 1:
                _perfil = "Super Administrador";
                break;
            default:
                _perfil = "";
                break;
        }

        return _perfil;
    }

    /**
     * Combierte el estado de numero a texto
     * 
     * @param {Number} _estado El estado en formato numerico
     * 
     * @returns {String} El estado en formato de texto
     * 
     *   0 sin.asignar          Estado inicia
     *   1 por.recoger          Pendiente de recoger
     *   2 recogido             Ya esta en manos del mensajero
     *   3 por.recolectar       A la espera de de recogida
     *  31 en.recolecta         Ya esta en manos del recolector
     *  32 error.dirección      Hay un error en la dirección
     *  33 recolecta.cancelada  El cliente cancela la recolección
     *  11 borrado              
     *  
     *   4 para.salir           En bodega preparado para ser tomado por el mensajero 
     *   5 por.entregar         Tomado por el mensajero
     *  
     *   6 OK                   Entregado
     *   9 OK.DV                Entregado con devolición 
     *  
     *   8 KO.DOM               Cancelado con desplazamiento 
     *  12 KO.DOM.PAGO          Cancelado con desplazamiento más pago
     *   7 KO.CANCELADO         Cancelado sin desplazamiento 
     *  13 KO.REPROGRAMADO      Cancelado y reprogramado 
     *  
     *  14 en.bodega            Devuelto a bodega
     *  10 pen.dv.bodega        Pendiente de volver a bodega 
     *  15 dev.pendiente        Pendiente a devolver al cliente  
     *  16 devuelto             Devuleto al cliente  
     *  17 pagado               Pagado al cliente
     *  18 en.validación
     */
    parseState(data) {
        let _state;

        switch (parseInt(data.val)) {
            case 18:
            case 17:
            case 16:
            case 14:
            case 13:
            case 12:
            case 11:
            case 10:
            case 9:
            case 8:
            case 7:
            case 6:
            case 5:
            case 4:
            case 33:
            case 32:
            case 31:
            case 3:
            case 2:
            case 1:
            case 0:
                _state = estados[data.val].name;
                break;
            case 15:
                _state = estados[data.val].name + data.dev;
                break;
            default:
                _state = `(${data.val}).invalido`;
                break;
        }

        return _state;
    }

    unirEstados(data) {
        let _all_states = "";

        if (data.all_states) {
            let _array = data.all_states.toString().split(",");
            let _new_array = [];
            // _array = [...new Set(_array)];

            for (let i = (_array.length - 1); i >= 0; i--) {
                _new_array.unshift(_array[i]);
                if ([6, 7, 8, 9, 12, 13].includes(parseInt(_array[i]))) {
                    break;
                }
            }

            _new_array.map(value => {
                if (value === "paid & returned") {
                    log.w(value);
                } else {
                    _all_states += this.parseState({ val: value, dev: data.devolucion + ".cli" || "cliente" }) + "+";
                }
            });

            _all_states = _all_states.slice(0, -1);

            let _first = _all_states.indexOf('+');

            if (_first > -1) {
                _all_states = _all_states.substring(0, _first) + " (" + _all_states.substring(_first + 1);
                _all_states += ")";
            }

            return _all_states;
        } else {
            return this.parseState({ val: data.estado, dev: data.devolucion + ".cli" || "cliente" });
        }
    }

    /**
     * Entrega el estado del paquete en un elemeto span
     * @param {JSON} data El objeto con la información del paquete
     * @returns {HTMLSpanElement} Elemento span
     */
    getStateSpan(data) {
        let _estado = data.estado;
        let _state;

        switch (parseInt(_estado)) {
            case 17:
                _state = <span className="_states" style={{ backgroundColor: estados['6'].color }}>{this.unirEstados(data)}</span>;
                break;
            case 16:
                _state = <span className="_states" style={{ backgroundColor: estados['6'].color }}>{this.unirEstados(data)}</span>;
                break;
            case 15:
                _state = <span className="_states" style={{ backgroundColor: estados['15'].color }}>{this.unirEstados(data)}</span>;
                break;
            case 14:
                _state = <span className="_states" style={{ backgroundColor: estados['14'].color }}>{this.unirEstados(data)}</span>;
                break;
            case 13:
                _state = <span className="_states" style={{ backgroundColor: estados['13'].color }}>{this.unirEstados(data)}</span>;
                break;
            case 12:
                _state = <span className="_states" style={{ backgroundColor: estados['12'].color }}>{this.unirEstados(data)}</span>;
                break;
            case 11:
                delete data.all_states;
                _state = <span className="_states" style={{ backgroundColor: estados['11'].color }}>{this.unirEstados(data)}</span>;
                break;
            case 10:
                _state = <span className="_states" style={{ backgroundColor: estados['10'].color }}>{this.unirEstados(data)}</span>;
                break;
            case 9:
                _state = <span className="_states" style={{ backgroundColor: estados['9'].color }}>{this.unirEstados(data)}</span>;
                break;
            case 8:
                _state = <span className="_states" style={{ backgroundColor: estados['8'].color }}>{this.unirEstados(data)}</span>;
                break;
            case 7:
                _state = <span className="_states" style={{ backgroundColor: estados['7'].color }}>{this.unirEstados(data)}</span>;
                break;
            case 6:
                _state = <span className="_states" style={{ backgroundColor: estados['6'].color }}>{this.unirEstados(data)}</span>;
                break;
            case 5:
                delete data.all_states;
                _state = <span className="_states" style={{ backgroundColor: estados['5'].color }}>{this.unirEstados(data)}</span>;
                break;
            case 4:
                delete data.all_states;
                _state = <span className="_states" style={{ backgroundColor: estados['4'].color }}>{this.unirEstados(data)}</span>;
                break;
            case 33:
                delete data.all_states;
                _state = <span className="_states" style={{ backgroundColor: estados['33'].color }}>{this.unirEstados(data)}</span>;
                break;
            case 32:
                delete data.all_states;
                _state = <span className="_states" style={{ backgroundColor: estados['32'].color }}>{this.unirEstados(data)}</span>;
                break;
            case 31:
                delete data.all_states;
                _state = <span className="_states" style={{ backgroundColor: estados['31'].color }}>{this.unirEstados(data)}</span>;
                break;
            case 3:
                delete data.all_states;
                _state = <span className="_states" style={{ backgroundColor: estados['3'].color }}>{this.unirEstados(data)}</span>;
                break;
            case 2:
                delete data.all_states;
                _state = <span className="_states" style={{ backgroundColor: estados['2'].color }}>{this.unirEstados(data)}</span>;
                break;
            case 1:
                delete data.all_states;
                _state = <span className="_states" style={{ backgroundColor: estados['1'].color }}>{this.unirEstados(data)}</span>;
                break;
            case 0:
                delete data.all_states;
                _state = <span className="_states" style={{ backgroundColor: estados['0'].color }}>{this.unirEstados(data)}</span>;
                break;
            default:
                delete data.all_states;
                _state = <span className="_states" style={{ backgroundColor: estados['default'].color }}>{this.unirEstados(data)}</span>;
                break;
        }

        return _state;
    }

    /**
     * 
     * Entrega el estado del paquete en un elemento para tabla
     * 
     * @param {JSON} data El objeto con la información del paquete
     * 
     * @returns {HTMLTableDataCellElement} Elemento td para una tabla
     * 
     */
    getStateTd(data) {
        let _estado = data.estado;
        let status;

        switch (parseInt(_estado)) {
            case 17:
                status = <td style={{ maxWidth: "max-content" }}><ArchiveIcon color={estados['6'].color} />{data.icono} {this.unirEstados(data)}{data.state_detail}</td>;
                break;
            case 16:
                status = <td style={{ maxWidth: "max-content" }}><ArchiveIcon color={estados['6'].color} />{data.icono} {this.unirEstados(data)}{data.state_detail}</td>;
                break;
            case 15:
                status = <td style={{ maxWidth: "max-content" }}><ArchiveIcon color={estados['15'].color} />{data.icono} {this.unirEstados(data)}{data.state_detail}</td>;
                break;
            case 14:
                status = <td style={{ maxWidth: "max-content" }}><ArchiveIcon color={estados['14'].color} />{data.icono} {this.unirEstados(data)}{data.state_detail}</td>;
                break;
            case 13:
                status = <td style={{ maxWidth: "max-content" }}><ArchiveIcon color={estados['13'].color} />{data.icono} {this.unirEstados(data)}{data.state_detail}</td>;
                break;
            case 12:
                status = <td style={{ maxWidth: "max-content" }}><ArchiveIcon color={estados['12'].color} />{data.icono} {this.unirEstados(data)}{data.state_detail}</td>;
                break;
            case 11:
                delete data.all_states;
                status = <td style={{ maxWidth: "max-content" }}><ArchiveIcon color={estados['11'].color} />{data.icono} {this.unirEstados(data)}{data.state_detail}</td>;
                break;
            case 10:
                status = <td style={{ maxWidth: "max-content" }}><ArchiveIcon color={estados['10'].color} />{data.icono} {this.unirEstados(data)}{data.state_detail}</td>;
                break;
            case 9:
                status = <td style={{ maxWidth: "max-content" }}><ArchiveIcon color={estados['9'].color} />{data.icono} {this.unirEstados(data)}{data.state_detail}</td>;
                break;
            case 8:
                status = <td style={{ maxWidth: "max-content" }}><ArchiveIcon color={estados['8'].color} />{data.icono} {this.unirEstados(data)}{data.state_detail}</td>;
                break;
            case 7:
                status = <td style={{ maxWidth: "max-content" }}><ArchiveIcon color={estados['7'].color} />{data.icono} {this.unirEstados(data)}{data.state_detail}</td>;
                break;
            case 6:
                status = <td style={{ maxWidth: "max-content" }}><ArchiveIcon color={estados['6'].color} />{data.icono} {this.unirEstados(data)}{data.state_detail}</td>;
                break;
            case 5:
                delete data.all_states;
                status = <td style={{ maxWidth: "max-content" }}><ArchiveIcon color={estados['5'].color} />{data.icono} {this.unirEstados(data)}{data.state_detail}</td>;
                break;
            case 4:
                delete data.all_states;
                status = <td style={{ maxWidth: "max-content" }}><ArchiveIcon color={estados['4'].color} />{data.icono} {this.unirEstados(data)}{data.state_detail}</td>;
                break;
            case 33:
                delete data.all_states;
                status = <td style={{ maxWidth: "max-content" }}><ArchiveIcon color={estados['33'].color} />{data.icono} {this.unirEstados(data)}{data.state_detail}</td>;
                break;
            case 32:
                delete data.all_states;
                status = <td style={{ maxWidth: "max-content" }}><ArchiveIcon color={estados['32'].color} />{data.icono} {this.unirEstados(data)}{data.state_detail}</td>;
                break;
            case 31:
                delete data.all_states;
                status = <td style={{ maxWidth: "max-content" }}><ArchiveIcon color={estados['31'].color} />{data.icono} {this.unirEstados(data)}{data.state_detail}</td>;
                break;
            case 3:
                delete data.all_states;
                status = <td style={{ maxWidth: "max-content" }}><ArchiveIcon color={estados['3'].color} />{data.icono} {this.unirEstados(data)}{data.state_detail}</td>;
                break;
            case 2:
                delete data.all_states;
                status = <td style={{ maxWidth: "max-content" }}><ArchiveIcon color={estados['2'].color} />{data.icono} {this.unirEstados(data)}{data.state_detail}</td>;
                break;
            case 1:
                delete data.all_states;
                status = <td style={{ maxWidth: "max-content" }}><ArchiveIcon color={estados['1'].color} />{data.icono} {this.unirEstados(data)}{data.state_detail}</td>;
                break;
            case 0:
                delete data.all_states;
                status = <td style={{ maxWidth: "max-content" }}><ArchiveIcon color={estados['0'].color} />{data.icono} {this.unirEstados(data)}{data.state_detail}</td>;
                break;
            default:
                delete data.all_states;
                status = <td style={{ maxWidth: "max-content" }}><ArchiveIcon color={estados['default'].color} />{data.icono} {this.unirEstados(data)}{data.state_detail}</td>;
                break;
        }

        return status;
    }

    getMeasure() {
        let units = JSON.parse(localStorage.getItem('measure'));
        let _default = { longitud: "in", masa: "lb", volumen: "ft³" };

        units = units == null ? _default : units;

        return units;
    }

    /**
     * 
     * @param {JSON} _pack todos los prametros del paquete en formato json
     * 
     * @returns {String} string con la dirección, ciudad, zona
     */
    getZone(_pack) {
        let _zone = "";
        let _direccion = this.formateaDireccion(_pack.address.toLowerCase());

        switch (_pack.city.toLowerCase()) {

            case "zipaquira":
            case "san jose":
            case "la neuta":
            case "el canelon":
            case "el tejar":
            case "briceño":
            case "tocancipa":
            case "guasca":
            case "cajica":
            case "chia":
            case "macadamia":
            case "la calera":
            case "patios":
            case "san luis":
                _zone = "NorOriente";
                break;
            case "cota":
            case "el corzo":
            case "la isla":
            case "buena vista":
            case "guadalajara":
            case "villa mery":
                _zone = "NorOccidente";
                break;
            case "batallon 13":
            case "chipaque":
            case "suate":
            case "amorosa":
            case "boqueron":
            case "olarte":
            case "usme pueblo":
                _zone = "SurOriente";
                break;
            case "facatativa":
            case "zipacon":
            case "bojaca":
            case "cartagenita":
            case "madrid":
            case "mosquera":
            case "funza":
                _zone = "Oriente";
                break;
            case "soacha":
            case "fusagasuga":
            case "san antonio":
            case "mesitas":
            case "el charquito":
            case "silvania":
            case "el tambo":
            case "subia":
            case "san raimundo":
            case "granada":
            case "sibate":
            case "la arboleda":
                _zone = "SurOccidente";
                break;
            case "bogotá":
            case "bogota":
                _zone = this.zonificaDireccion(_direccion);
                break;
        }

        return <>{_direccion}, {_pack.city}  <b className="_zone"> {_zone}</b></>;
    }

    validaDireccion(_address) {
        let _dir = /^([a-zA-Z]+\s)+([0-9]{1,})+([a-zA-Z]{0,}\s#\s)+([0-9]{1,})+([a-zA-Z]{0,}\s-\s)+([0-9]{1,})+([a-zA-Z]{0,})|([a-zA-Z]+\s)+([0-9]{1,})+([a-zA-Z]{0,}\s#\s)+([0-9]{1,})+([a-zA-Z]{0,}\s-\s)+([0-9]{1,})+([a-zA-Z]{0,}\s)+([a-zA-Z]{3,})|([a-zA-Z]+\s)+([0-9]{1,})+([a-zA-Z]{0,}\s)+([a-zA-Z]{1,}\s#\s)+([0-9]{1,})+([a-zA-Z]{0,}\s-\s)+([0-9]{1,})+([a-zA-Z]{0,}\s)+(.*)$/;
        _address = _address.trim();

        if (!/^cll/ig.test(_address.toLowerCase()) &
            !/^calle/ig.test(_address.toLowerCase()) &
            !/^cra/ig.test(_address.toLowerCase()) &
            !/^carrera/ig.test(_address.toLowerCase()) &
            !/^av/ig.test(_address.toLowerCase()) &
            !/^avenida/ig.test(_address.toLowerCase()) &
            !/^tv/ig.test(_address.toLowerCase()) &
            !/^transversal/ig.test(_address.toLowerCase()) &
            !/^dg/ig.test(_address.toLowerCase()) &
            !/^diagonal/ig.test(_address.toLowerCase())) {
            return false;
        }

        return _dir.test(_address);
    }

    formateaDireccion(_address) {
        let _cra, _calle, _separa;


        _calle = /cl./ig;
        _address = _address.replaceAll(_calle, "calle");
        _calle = /cl/ig;
        _address = _address.replaceAll(_calle, "calle");

        _calle = /cll./ig;
        _address = _address.replaceAll(_calle, "calle");
        _calle = /cll/ig;
        _address = _address.replaceAll(_calle, "calle");


        _cra = /crra./ig;
        _address = _address.replaceAll(_cra, "carrera");
        _cra = /crr./ig;
        _address = _address.replaceAll(_cra, "carrera");
        _cra = /cra./ig;
        _address = _address.replaceAll(_cra, "carrera");
        _cra = /cr./ig;
        _address = _address.replaceAll(_cra, "carrera");


        _separa = /Nº:/ig;
        _address = _address.replaceAll(_separa, "#");
        _separa = /Nº/ig;
        _address = _address.replaceAll(_separa, "#");

        _separa = /No:/ig;
        _address = _address.replaceAll(_separa, "#");
        _separa = /No /ig;
        _address = _address.replaceAll(_separa, "#");


        return _address;
    }

    zonificaDireccion(_address) {
        let _to_return = "¡?";
        let _re, _dir;
        let _direccion = _address.split("#");


        if (_direccion[0].includes("calle")) {
            _re = /[a-z]/ig;
            _dir = _direccion[0].replaceAll(_re, "");

            if (_address.includes("sur")) {
                _to_return = "Sur";
            } else {
                _to_return = parseInt(_dir) > 13 ? "Nor" : "Sur";
            }
        }


        if (_direccion[0].includes("carrera")) {
            _re = /[a-z]/ig;
            _dir = _direccion[0].replaceAll(_re, "");

            if (_address.includes("sur")) {
                _to_return = "Sur";
            } else {
                _to_return = parseInt(_direccion[1]) > 13 ? "Nor" : "Sur";
            }

            _to_return += parseInt(_dir) > 68 ? "Occidente" : "Oriente";
        } else {
            _to_return += parseInt(_direccion[1]) > 68 ? "Occidente" : "Oriente";
        }


        return _to_return;
    }

    /**
     * Obtiene la zona limpiando la dirección
     * 
     * @param {String} city ciudad de la dirección
     * @param {String} address dirección a limpiar
     * 
     * @returns {JSON} {`zone`, `address`} objeto con la zona y la dirección
     */
    formatZone(city, address) {
        let _zone = "";
        let _direccion = this.formateaDireccion(address.toLowerCase());

        switch (city.toLowerCase()) {

            case "zipaquira":
            case "san jose":
            case "la neuta":
            case "el canelon":
            case "el tejar":
            case "briceño":
            case "tocancipa":
            case "guasca":
            case "cajica":
            case "chia":
            case "macadamia":
            case "la calera":
            case "patios":
            case "san luis":
                _zone = "NorOriente";
                break;
            case "cota":
            case "el corzo":
            case "la isla":
            case "buena vista":
            case "guadalajara":
            case "villa mery":
                _zone = "NorOccidente";
                break;
            case "batallon 13":
            case "chipaque":
            case "suate":
            case "amorosa":
            case "boqueron":
            case "olarte":
            case "usme pueblo":
                _zone = "SurOriente";
                break;
            case "facatativa":
            case "zipacon":
            case "bojaca":
            case "cartagenita":
            case "madrid":
            case "mosquera":
            case "funza":
                _zone = "Oriente";
                break;
            case "soacha":
            case "fusagasuga":
            case "san antonio":
            case "mesitas":
            case "el charquito":
            case "silvania":
            case "el tambo":
            case "subia":
            case "san raimundo":
            case "granada":
            case "sibate":
            case "la arboleda":
                _zone = "SurOccidente";
                break;
            case "bogotá":
            case "bogota":
                _zone = this.zonificaDireccion(_direccion);
                break;
        }

        return { address: _direccion, zone: _zone };
    }

    formatCeros(item) {
        let { code, aux } = item;
        let _codigo = code.toString();
        let _ceros = "";

        if (aux != null) {
            _ceros = aux;
        }

        for (let i = 0; i < (8 - _codigo.length); i++) {
            _ceros += "0";
        }


        return _ceros + _codigo;
    }

    setMeasure(_data) {
        localStorage.setItem('measure', JSON.stringify(_data));
        return _data;
    }

    /**
     * otros
     */
    cleanAccents(str) {
        let toReturn = str.replace(/Á/g, "A")
            .replace(/É/g, "E")
            .replace(/Í/g, "I")
            .replace(/Ó/g, "O")
            .replace(/Ú/g, "U")
            .replace(/á/g, "a")
            .replace(/é/g, "e")
            .replace(/í/g, "i")
            .replace(/ó/g, "o")
            .replace(/ú/g, "u");

        return toReturn.toLowerCase();
    }

    formateaPrecio(amount, decimals = 0) {
        amount += ''; // por si pasan un numero en vez de un string
        amount = parseFloat(amount.replace(/[^0-9\.]/g, '')); // elimino cualquier cosa que no sea numero o punto


        // si no es un numero o es igual a cero retorno el mismo cero
        if (isNaN(amount) || amount === 0)
            return parseFloat(0).toFixed(decimals);

        // si es mayor o menor que cero retorno el valor formateado como numero
        amount = '' + amount.toFixed(decimals);

        var amount_parts = amount.split('.'),
            regexp = /(\d+)(\d{3})/;

        while (regexp.test(amount_parts[0]))
            amount_parts[0] = amount_parts[0].replace(regexp, '$1.$2');

        return amount_parts.join('.');
    }

    /**
     * 
     * @param num Number to format 
     * @returns {JSON} obj {`int`, `format`}
     */
    formatPrice(num) {
        // num = num || 0;

        if (isNaN(num)) {
            return {
                int: num,
                format: num
            };
        } else {
            let _clean = num.toString().replaceAll(".", "");
            let _minus = _clean.substring(0, 1);
            _clean = _minus == "-" ? _clean.substring(1) : _clean;
            _minus = _minus == "-" ? _minus : "";
            let _cant = parseInt(_clean.length / 3);
            let _to_return = "";
            let _rest = _clean;
            let _parts = [];

            for (let i = 0; i < _cant; i++) {
                _parts.push(_rest.slice(-3));
                _rest = _rest.slice(0, -3);
            }

            for (let e = _parts.length - 1; e >= 0; e--) {
                _to_return += "." + _parts[e];
            }

            _to_return = _rest + _to_return;
            _to_return = _to_return.slice(0, 1) == "." ? _to_return.slice(1, _to_return.length) : _to_return;

            return {
                int: _clean,
                format: _minus + _to_return
            };
        }
    }

    getTiempo(unixCreado, unixActual) {
        let timeActual = new Date();
        unixActual = unixActual || Math.round((timeActual.getTime() + ((timeActual.getTimezoneOffset() / 60) * 3600)) / 1000);
        let tiempo = Math.round((unixActual - unixCreado) / 60);
        let toReturn;

        if (tiempo < 2) {
            toReturn = `Hace instantes`;
        } else if (tiempo >= 2 && tiempo < 60) {
            toReturn = `Hace ${Math.round(tiempo)} minutos`;
        } else if (tiempo >= 60 && tiempo < 120) {
            toReturn = `Hace 1 hora`;
        } else if (tiempo >= 120 && tiempo < 1440) {
            toReturn = `Hace ${Math.round(tiempo / 60)} horas`;
        } else if (tiempo >= 1440 && tiempo < 2880) {
            toReturn = `Hace 1 día`;
        } else if (tiempo >= 2880 && tiempo < 10080) {
            toReturn = `Hace ${Math.round(tiempo / 1440)} días`;
        } else if (tiempo >= 10080 && tiempo < 20160) {
            toReturn = `Hace 1 semana`;
        } else if (tiempo >= 20160 && tiempo < 43200) {
            toReturn = `Hace ${Math.round(tiempo / 10080)} semanas`;
        } else if (tiempo >= 43200 && tiempo < 86400) {
            toReturn = `Hace 1 mes`;
        } else if (tiempo >= 86400 && tiempo < 525960) {
            toReturn = `Hace ${Math.round(tiempo / 43200)} meses`;
        } else if (tiempo >= 525960 && tiempo < 1051920) {
            toReturn = `Hace 1 año`;
        } else if (tiempo >= 1051920) {
            toReturn = `Más de dos años`;
        }

        return toReturn;
    }

    getTimeAgo(time_msg) {
        let ago_time = "hace instantes";
        let time_current = new Date().getTime() / 1000;
        let tiempoPasado = (time_current - time_msg);

        const round = (num) => {
            return Math.round(num);
        }
        const cleanDot = (num) => {
            return num.toFixed(2).split('.')[0];
        }

        let seconds = 60;
        let mins = seconds * 60;

        if (time_msg !== null) {
            if (tiempoPasado >= seconds && tiempoPasado < mins) {
                ago_time = round(tiempoPasado / seconds) > 1 ? "hace " + round(tiempoPasado / seconds) + " minutos" : "hace " + round(tiempoPasado / seconds) + " minuto";
            } else if (tiempoPasado >= 3600 && tiempoPasado < 86400) {
                ago_time = round(tiempoPasado / 3600) > 1 ? "hace " + cleanDot(tiempoPasado / 3600) + " horas" : "hace " + cleanDot(tiempoPasado / 3600) + " hora";
            } else if (tiempoPasado >= 86400 && tiempoPasado < 604800) {
                ago_time = round(tiempoPasado / 86400) > 1 ? "hace " + round(tiempoPasado / 86400) + " días" : "hace " + round(tiempoPasado / 86400) + " día";
            } else if (tiempoPasado >= 604800 && tiempoPasado < 31536000) {
                ago_time = round(tiempoPasado / 604800) > 1 ? "hace " + round(tiempoPasado / 604800) + " semanas" : "hace " + round(tiempoPasado / 604800) + " semana";
            } else if (tiempoPasado > 31536000) {
                ago_time = "más de un año";
            }
        } else {
            ago_time = null;
        }

        return ago_time;
    }

    getDayMonth(time_msg) {
        let month = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
        let day_month = null;

        if (time_msg !== null) {
            let fecha_msg = new Date(time_msg * 1000);
            day_month = <div key={time_msg} className="_date"><h5>{fecha_msg.getDate()} {month[fecha_msg.getMonth()]}</h5></div>;
        }

        return day_month;
    }

    getCompareDates(lista, i) {
        if (i == 0) {
            return lista[i].date;
        } else {
            let fecha_1 = new Date(lista[i].date * 1000);
            fecha_1 = fecha_1.getDate() + " " + fecha_1.getMonth();

            let fecha_2 = new Date(lista[i - 1].date * 1000);
            fecha_2 = fecha_2.getDate() + " " + fecha_2.getMonth();

            if (fecha_1 !== fecha_2) {
                return lista[i].date;
            } else {
                return null;
            }
        }
    }

    /**
     * 
     * Valida los permisos del usuario
     * @param {String} permission El permiso a validar
     * @param {Array} nivel Array con los niveles a validar
     * 
     * @returns {Boolean}
     */
    validaPermisos(permission, nivel) {
        const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7, permisos: {} };

        if (!data_user.permisos[permission]) {
            return false;
        }

        if (nivel) {
            if (nivel.includes(parseInt(data_user.nivel))) {
                return true;
            } else {
                return false;
            }
        } else {
            return data_user.permisos[permission] !== undefined;
        }
    }

    validaGrupo(permissions) {
        const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7, permisos: {} };
        let _have = false;

        permissions.forEach(permission => {

            if (data_user.permisos[permission] !== undefined) {
                // log.d("tiene permiso " + permission);
                _have = true;
            }
        });

        return _have
    }

    /**
     * 
     * @param {MouseEvent} e Evento del mouse
     * @param {RefObject} _popup la referencia del popup
     * @returns {JSON} Objeto con la posicion de la ventana emergente
     */
    getPositonDisplay(e, _popup) {
        const _top_bar = 70;
        const _popup_width = _popup.offsetWidth;
        // const _popup_height = _popup.offsetHeight;
        const _popup_height = (_popup.firstChild.getBoundingClientRect().height + 24);

        let _left = e.pageX - 16;
        let _top = e.target.getBoundingClientRect().top;
        let _bottom = e.target.getBoundingClientRect().bottom;
        let _width = document.getElementById("root").offsetWidth;
        let _height = (window.innerHeight - _bottom);

        _left = (_left + _popup_width) > _width ? (_width - (_popup_width + 5)) : _left;
        _top = _height < _popup_height ? (_bottom - (_popup_height + 5)) : _top;
        _top = _top < _top_bar ? _top_bar : _top;
        return {
            top: _top,
            left: _left,
            width: _popup_width,
            height: _popup_height,
        };
    }


    /**
     * 
     * @param {JSON} parent los datos de posicion del elemeto padre
     * @param {RefObject} _popup la referencia del popup
     * @returns {JSON} datos de la posicion en formato json
     */
    getPositonPopUp(parent, _popup) {
        const media_movil = window.matchMedia('(max-width: 576px)').matches;
        const _top_bar = 70;
        const _corre_left = media_movil ? 8 : 16;
        const _popup_width = parent.width ?? _popup.offsetWidth;
        const _popup_height = _popup.offsetHeight;

        let _top = parent.top;
        let _left = parent.left;
        let _bottom = (_top + _popup_height);
        let _width_container = document.getElementById("root").offsetWidth;
        let _height_window = window.innerHeight;

        _left = (_left + _popup_width) > _width_container ? (_width_container - (_popup_width + _corre_left)) : _left;
        _top = _bottom > _height_window ? (_height_window - (_popup_height + 5)) : _top;
        _top = _top < _top_bar ? _top_bar : _top;

        return {
            top: _top,
            left: _left,
            width: parent.width,
        };
    }

    lastRelevant(_states) {
        _states = _states ? _states.toString().split(',') : [];
        let _estado = 0;

        for (let i = (_states.length - 1); i >= 0; i--) {
            _estado = parseInt(_states[i]);

            if ([6, 7, 8, 9, 12, 13].includes(_estado)) {
                break;
            }
        }
        return _estado;
    }

    ordenaValoresExcel(packs) {
        let _subtotal = 0;

        let _list = packs.map(item => {
            item.price = parseInt(item.price);
            item.v_mensajero = parseInt(item.v_mensajero);
            item.v_domicilio = parseInt(item.v_domicilio);
            item.efectivo = item.efectivo == null ? "" : parseInt(item.efectivo);
            item.efectivo = item.efectivo == 0 ? "" : item.efectivo;
            item.bold = item.bold == null ? "" : parseInt(item.bold);
            item.bold = item.bold == 0 ? "" : item.bold;
            item.carrier_name = parseInt(item.carrier) >= 1 ? item.carrier_name : "Sin asignar";

            let _state = this.lastRelevant(item.all_states);

            // =SI(D54="OK";E54-F54;SI(D54="KO";0;SI(D54="KO.DOM";F54*-1)))
            // "OK"              = valor - mensajero
            // "OK.DV"           = valor - mensajero
            //
            // "KO.CANCELADO"    = 0
            // "KO.REPROGRAMADO" = 0
            //
            // "KO.DOM"          = mensajero * -1
            // "KO.DOM.PAGO"     = domicilio_ko - mensajero
            //
            // =SI(D54="OK";E54-G54;SI(D54="KO";0;SI(D54="KO.DOM";G54*-1)))
            // "OK"              = valor - domicilio
            // "OK.DV"           = valor - domicilio
            //
            // "KO.CANCELADO"    = 0
            // "KO.REPROGRAMADO" = 0
            //
            // "KO.DOM"          = domicilio * -1
            // "KO.DOM.PAGO"     = domicilio_ko - domicilio

            switch (_state) {
                case 6:
                case 9:
                    item.subtotal_mensajero = item.price - item.v_mensajero;
                    item.subtotal = item.price - item.v_domicilio;
                    break;
                case 8:
                    item.price = 0;
                    item.subtotal_mensajero = item.v_mensajero * -1;
                    item.subtotal = item.v_domicilio * -1;
                    break;
                case 12:
                    // item.price = 0;
                    let _ko_obj = JSON.parse(item.domicilio_ko)[0];
                    let _ko_dom_value = parseInt(_ko_obj.monto ?? "0");
                    let _ko_dom_name = _ko_obj.metodo ?? "";

                    switch (_ko_dom_name) {
                        case "Efectivo":
                            item.efectivo = _ko_dom_value;
                            break;
                        case "Bold":
                            item.bold = _ko_dom_value;
                            break;
                    }

                    item.subtotal_mensajero = _ko_dom_value - item.v_mensajero;
                    item.subtotal = _ko_dom_value - item.v_domicilio;
                    break;
                case 100:
                    item.other = "en una recogida";
                    break;
                default:
                    item.price = 0;
                    item.v_mensajero = "";
                    item.v_domicilio = "";
                    item.subtotal_mensajero = "";
                    item.subtotal = "";
                    break;
            }

            _subtotal += parseInt(item.subtotal || 0);

            return item;
        });

        return { lista: _list, subtotal: _subtotal }
    }

    ordenaValores(_lista, userToClose) {

        let _new_lista = _lista.map(item => {
            item.price = parseInt(item.price || 0);
            item.v_mensajero = parseInt(item.v_mensajero || 0);
            item.v_domicilio = parseInt(item.v_domicilio || 0);
            item.efectivo = parseInt(item.efectivo || 0);
            item.bold = parseInt(item.bold || 0);
            item.carrier_name = parseInt(item.carrier) >= 1 ? item.carrier_name : "Sin asignar";
            let _state = this.lastRelevant(item.all_states);

            /**
             * 
             *  0 Sin asignar    
             *  4 En almacén     
             * 14 En bodega      
             * 15 dev.pendiente     
             * 
             *  1 Por recoger    
             *  2 Recogido       
             *  3 Por recolectar
             * 31 En recolecta
             * 32 Error dirección
             * 33 Recolecta cancelada
             *  5 Por entregar   
             * 
             *  6 OK             
             *  9 OK.DV          
             * 
             * 11 Borrado        
             *  7 KO.CANCELADO   
             *  8 KO.DOM          
             * 12 KO.DOM.PAGO    
             * 13 KO.REPROGRAMADO
             * 
             */

            if (parseInt(userToClose.nivel) == 4) {
                /**
                 * =SI(D54="OK";E54-F54;SI(D54="KO";0;SI(D54="KO.DOM";F54*-1)))
                 * "OK"              = valor - mensajero
                 * "OK.DV"           = valor - mensajero
                 *
                 * "KO.CANCELADO"    = 0
                 * "KO.REPROGRAMADO" = 0
                 *
                 * "KO.DOM"          = mensajero * -1
                 * "KO.DOM.PAGO"     = domicilio_ko - mensajero
                 * 
                 */
                //switch (parseInt(item.estado)) {
                switch (_state) {
                    case 6:
                    case 9:
                        item.subtotal_mensajero = item.price - item.v_mensajero;
                        break;
                    case 8:
                        item.price = 0;
                        item.subtotal_mensajero = item.v_mensajero * -1;
                        break;
                    case 12:
                        // item.price = 0;
                        let _ko_obj = JSON.parse(item.domicilio_ko)[0];
                        let _ko_dom_value = parseInt(_ko_obj.monto ?? "0");
                        let _ko_dom_name = _ko_obj.metodo ?? "";

                        switch (_ko_dom_name) {
                            case "Efectivo":
                                item.efectivo = _ko_dom_value;
                                break;
                            case "Bold":
                                item.bold = _ko_dom_value;
                                break;
                        }

                        item.subtotal_mensajero = _ko_dom_value - item.v_mensajero;
                        break;
                    default:
                        item.price = 0;
                        item.v_mensajero = 0;
                        item.subtotal_mensajero = "No aplica";
                        break;
                }
            }

            if (parseInt(userToClose.nivel) == 6) {
                /**
                 * =SI(D54="OK";E54-G54;SI(D54="KO";0;SI(D54="KO.DOM";G54*-1)))
                 * "OK"              = valor - domicilio
                 * "OK.DV"           = valor - domicilio
                 *
                 * "KO.CANCELADO"    = 0
                 * "KO.REPROGRAMADO" = 0
                 *
                 * "KO.DOM"          = domicilio * -1
                 * "KO.DOM.PAGO"     = domicilio_ko - domicilio
                 */
                switch (_state) {
                    case 6:
                    case 9:
                        item.v_domicilio = (item.valor_domicilio * -1);
                        break;
                    case 8:
                        item.price = 0;
                        item.v_domicilio = (item.valor_domicilio * -1);
                        break;
                    case 12:
                        // item.price = 0;
                        item.efectivo += item.pago_domicilio_ko;
                        item.v_domicilio = (item.valor_domicilio * -1);
                        break;
                    default:
                        item.price = 0;
                        item.v_domicilio = 0;
                        break;
                }
            }

            return item;
        });

        return _new_lista;
    }
}

export default new Funciones();