import * as React from "react";
import { useMedia } from "hooks";

/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const TerminosCondiciones = () => {
    const { setTitulo } = useMedia();


    React.useEffect(() => {
        setTitulo("Términos y Condiciones");
        document.body.style.overflow = "auto";
    }, []);

    return (
        <div className="comun _terminos">

            <h2><strong>Términos y Condiciones</strong></h2>
            <p>&nbsp;</p>

            <p className="last-updated">Última actualización: 01 de Julio del 2024</p>

            <p>Bienvenido a la plataforma de <strong>Best Transport Service BTS SAS</strong>. Al acceder o utilizar nuestra aplicación de entrega de paquetes, usted acepta estar sujeto a estos Términos y Condiciones de Uso. Por favor, lea detenidamente este documento.</p>

            <h3><strong>1.1. Descripción del Servicio</strong></h3>
            <p><strong>BTS Mensajería</strong> es una plataforma de entrega de paquetes tanto dentro de la ciudad como entre ciudades.</p>

            <h3><strong>1.2. Registro de Cuenta</strong></h3>
            <p>Para utilizar nuestros servicios, debe registrarse y crear una cuenta. Usted es responsable de mantener la confidencialidad de su cuenta y contraseña, y de restringir el acceso a su dispositivo.</p>

            <h3><strong>1.3. Uso del Servicio</strong></h3>
            <p>Como usuario, usted puede solicitar la entrega de paquetes a través de la aplicación. Como repartidor, puede aceptar solicitudes de entrega. Ambas partes se comprometen a proporcionar información precisa y a cumplir con las entregas acordadas.</p>

            <h3><strong>1.4. Responsabilidades del Usuario</strong></h3>
            <p>Los usuarios son responsables de empaquetar adecuadamente sus envíos y proporcionar información precisa sobre el contenido, peso y dimensiones del paquete. Está prohibido enviar artículos ilegales o peligrosos.</p>

            <h3><strong>1.5. Responsabilidades del Repartidor</strong></h3>
            <p>Los repartidores son responsables de realizar las entregas de manera oportuna y segura, siguiendo todas las leyes de tránsito y regulaciones aplicables. Deben mantener un seguro adecuado y las licencias necesarias para operar.</p>

            <h3><strong>1.6. Terminación</strong></h3>
            <p>Podemos suspender o terminar su acceso a la aplicación por violación de estos términos o por cualquier otra razón a nuestra discreción.</p>

            <h3><strong>1.7. Ley Aplicable y Resolución de Disputas</strong></h3>
            <p>Estos términos se regirán por las leyes de Colombia. Cualquier disputa se resolverá primero mediante negociación de buena fe, y si es necesario, a través de arbitraje vinculante.</p>


            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>

            <h2 id="privacidad"><strong>Política de Privacidad</strong></h2>

            <p>En <strong>Best Transport Service BTS SAS</strong>, respetamos su privacidad y nos comprometemos a proteger sus datos personales. Esta Política de Privacidad explica cómo recopilamos, usamos y protegemos su información cuando utiliza nuestra aplicación de entrega de paquetes, <strong>BTS Mensajería</strong>.</p>

            <h3><strong>2.1. Información que Recopilamos</strong></h3>
            <p>Podemos recopilar los siguientes tipos de información:</p>
            <ul>
                <li>Información de registro: nombre, dirección de correo electrónico, número de teléfono.</li>
                <li>Información de ubicación: para facilitar las entregas y recogidas.</li>
                <li>Información de transacciones: detalles de pagos, historial de envíos.</li>
            </ul>

            <h3><strong>2.2. Cómo Utilizamos su Información</strong></h3>
            <p>Utilizamos su información para:</p>
            <ul>
                <li>Facilitar y mejorar nuestros servicios de entrega.</li>
                <li>Comunicarnos con usted sobre su cuenta y nuestros servicios.</li>
                <li>Proporcionar soporte al cliente.</li>
                <li>Cumplir con obligaciones legales y resolver disputas.</li>
            </ul>

            <h3><strong>2.3. Compartir Información</strong></h3>
            <p>Podemos compartir su información con:</p>
            <ul>
                <li>Repartidores: para facilitar las entregas.</li>
                <li>Proveedores de servicios: para procesar pagos y mejorar nuestros servicios.</li>
                <li>Autoridades legales: cuando sea requerido por ley.</li>
            </ul>

            <h3><strong>2.4. Seguridad de los Datos</strong></h3>
            <p>Implementamos medidas de seguridad técnicas y organizativas para proteger sus datos personales contra acceso no autorizado, pérdida o alteración.</p>

            <h3><strong>2.5. Sus Derechos</strong></h3>
            <p>Usted tiene derecho a:</p>
            <ul>
                <li>Acceder a sus datos personales.</li>
                <li>Corregir datos inexactos.</li>
                <li>Solicitar la eliminación de sus datos.</li>
                <li>Oponerse al procesamiento de sus datos.</li>
                {/* <li>Solicitar la portabilidad de sus datos.</li> */}
            </ul>

            <h3><strong>2.6. Retención de Datos</strong></h3>
            <p>Conservamos sus datos personales solo durante el tiempo necesario para los fines establecidos en esta política, a menos que se requiera un período de retención más largo por ley.</p>

            <h3><strong>2.7. Menores</strong></h3>
            <p>Nuestros servicios no están dirigidos a menores de 18 años. No recopilamos intencionalmente información de menores.</p>

            <h3><strong>2.8. Modificaciones del Servicio, Términos o Política</strong></h3>
            <p>Nos reservamos el derecho de modificar o discontinuar el servicio en cualquier momento. También podemos actualizar estos términos o Política de Privacidad, y le notificaremos de cualquier cambio significativo.</p>
            <p>&nbsp;</p>
            <p>Si tiene alguna pregunta sobre estos Términos o Política de Privacidad, por favor contáctenos en: <a href="mailto:contacto@btsmensajeria.com">contacto@btsmensajeria.com</a>.</p>
            <p>&nbsp;</p>
            <p>Al utilizar <strong>BTS Mensajería</strong>, usted reconoce que ha leído, entendido y acepta estar sujeto a estos Términos y Condiciones, así como a las prácticas descritas en nuestra Política de Privacidad.</p>

        </div>
    );
}

export default TerminosCondiciones;