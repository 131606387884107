import * as React from "react";
import funciones from "services/funciones";
import * as Iconos from "images";
import { useMedia } from "hooks";

import devuelto from "../../audios/devuelto.m4a";
import en_bodega from "../../audios/en_bodega.m4a";
import ko_cancelado from "../../audios/ko_cancelado.m4a";
import ko_dom_pago from "../../audios/ko_dom_pago.m4a";
import ko_dom from "../../audios/ko_dom.m4a";
import ko_rep from "../../audios/ko_rep.m4a";
import ok_dv from "../../audios/ok_dv.m4a";
import ok from "../../audios/ok.m4a";
import pagado from "../../audios/pagado.m4a";
import para_salir from "../../audios/para_salir.m4a";
import pen_dv_cambio_cli from "../../audios/pen_dv_cambio_cli.m4a";
import pen_dv_sobrante_cli from "../../audios/pen_dv_sobrante_cli.m4a";
import pend_dv_bodega from "../../audios/pend_dv_bodega.m4a";
import por_entregar from "../../audios/por_entregar.m4a";
import por_recoger from "../../audios/por_recoger.m4a";
import recogido from "../../audios/recogido.m4a";
import { TitleSticky } from "shared/Components";


/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const Help = () => {
    const { setTitulo } = useMedia();
    const [sonido, setSonido] = React.useState();
    const [current, setCurrent] = React.useState();


    const handlePlay = (e, fuente) => {
        let _icon_audio = document.getElementsByClassName('audio')[0].innerHTML;
        let _icon_pause = document.getElementsByClassName('pause')[0].innerHTML;
        let _icon_play = document.getElementsByClassName('play')[0].innerHTML;
        let _icono = e.target.getElementsByClassName('audio')[0];


        if (sonido.src.toString().split('/').pop() === fuente.toString().split('/').pop()) {
            if (sonido.paused) {
                sonido.play();
                _icono.innerHTML = _icon_pause;
            } else {
                sonido.pause();
                _icono.innerHTML = _icon_play;
            }
        } else {
            if (current) {
                current.innerHTML = _icon_audio;
            }
            sonido.src = fuente;
            sonido.play();
            _icono.innerHTML = _icon_pause;
            setCurrent(_icono);
        }

        sonido.onended = () => {
            _icono.innerHTML = _icon_audio;
        }
    }

    React.useEffect(() => {
        setTitulo("Ayuda - BTS");
        document.body.style.overflow = "auto";

        let _icono = document.getElementsByClassName("logo_help")[0];
        let _audio = document.createElement("audio");
        _audio.setAttribute("preload", "auto");
        _audio.setAttribute("controls", "none");
        _audio.style.display = "none";
        document.body.appendChild(_audio);

        setSonido(_audio);

        _icono.style.display = "none";

        return () => _icono.style.display = "block";
    }, []);

    return (
        <div className="comun">
            <TitleSticky>Ayuda</TitleSticky>

            <div className="comun_content">
                <div style={{ display: "none" }}>
                    <Iconos.Audio className="audio" />
                    <Iconos.Play className="audio play" />
                    <Iconos.Pause className="audio pause" />
                </div>

                <p className="_sub_titulo">En esta sección encontrarás la descripción de cada uno de los estados por los que ha pasado o pasará tu paquete, desde su recogida hasta su entrega.</p>

                <table>
                    <tbody>
                        <tr className="row_help" onClick={e => handlePlay(e, ok)}>
                            {funciones.getStateTd({
                                estado: 6,
                                icono: <Iconos.Audio className="audio" />,
                                state_detail: <>: <p>Tu pedido ha sido entregado con exito.</p></>
                            })}
                        </tr>
                        <tr className="row_help" onClick={e => handlePlay(e, ok_dv)}>
                            {funciones.getStateTd({
                                estado: 9,
                                icono: <Iconos.Audio className="audio" />,
                                state_detail: <>: <p>Tu pedido ha sido entregado con éxito, recuerda que tienes un cambio o sobrante por retirar cuando el mensajero lo haya entregado en el punto BTS establecido.</p></>
                            })}
                        </tr>
                        <tr className="row_help" onClick={e => handlePlay(e, ko_rep)}>
                            {funciones.getStateTd({
                                estado: 13,
                                icono: <Iconos.Audio className="audio" />,
                                state_detail: <>: <p>Tu pedido no ha sido entregado, se reprogramó para intentar hacer nuevamente la entrega.</p></>
                            })}
                        </tr>
                        <tr className="row_help" onClick={e => handlePlay(e, ko_cancelado)}>
                            {funciones.getStateTd({
                                estado: 7,
                                icono: <Iconos.Audio className="audio" />,
                                state_detail: <>: <p>Tu pedido ha sido cancelado, recuerda retirar tu devolución cuando el mensajero la haya entregado en el punto BTS establecido.</p></>
                            })}
                        </tr>
                        <tr className="row_help" onClick={e => handlePlay(e, ko_dom)}>
                            {funciones.getStateTd({
                                estado: 8,
                                icono: <Iconos.Audio className="audio" />,
                                state_detail: <>: <p>Tu pedido no ha sido entregado, debes asumir el valor del domicilio, recuerda retirar tu devolución cuando el mensajero la haya entregado en el punto BTS establecido.</p></>
                            })}
                        </tr>
                        <tr className="row_help" onClick={e => handlePlay(e, ko_dom_pago)}>
                            {funciones.getStateTd({
                                estado: 12,
                                icono: <Iconos.Audio className="audio" />,
                                state_detail: <>: <p>Tu pedido no ha sido entregado, tu cliente asumió el valor total o parcial del domicilio, recuerda retirar tu devolución cuando el mensajero la haya entregado en el punto BTS establecido.</p></>
                            })}
                        </tr>
                        <tr className="row_help" onClick={e => handlePlay(e, en_bodega)}>
                            {funciones.getStateTd({
                                estado: 14,
                                icono: <Iconos.Audio className="audio" />,
                                state_detail: <>: <p>Tu pedido se encuentra en bodega, recuerda retirar tu devolución en el punto BTS establecido.</p></>
                            })}
                        </tr>
                        <tr className="row_help" onClick={e => handlePlay(e, pend_dv_bodega)}>
                            {funciones.getStateTd({
                                estado: 10,
                                icono: <Iconos.Audio className="audio" />,
                                state_detail: <>: <p>El mensajero no ha entregado tu devolucion en bodega, estamos pendientes para recibirla lo más pronto en el punto BTS establecido.</p></>
                            })}
                        </tr>
                        <tr className="row_help" onClick={e => handlePlay(e, pen_dv_sobrante_cli)}>
                            {funciones.getStateTd({
                                estado: 15,
                                devolucion: "sobrante",
                                icono: <Iconos.Audio className="audio" />,
                                state_detail: <>: <p>El sobrante de tu pedido se encuentra en bodega, recuerda retirarlo en el punto BTS establecido.</p></>
                            })}
                        </tr>
                        <tr className="row_help" onClick={e => handlePlay(e, pen_dv_cambio_cli)}>
                            {funciones.getStateTd({
                                estado: 15,
                                devolucion: "cambio",
                                icono: <Iconos.Audio className="audio" />,
                                state_detail: <>: <p>El cambio de tu producto se encuentra en bodega, recuerda retirarlo en el punto BTS establecido.</p></>
                            })}
                        </tr>
                        <tr className="row_help" onClick={e => handlePlay(e, devuelto)}>
                            {funciones.getStateTd({
                                estado: 16,
                                icono: <Iconos.Audio className="audio" />,
                                state_detail: <>: <p>Tu producto se te a devuelto, para verificar puedes consultar con el código en detalle del paquete las evidencias correspondientes.</p></>
                            })}
                        </tr>
                        <tr className="row_help" onClick={e => handlePlay(e, pagado)}>
                            {funciones.getStateTd({
                                estado: 17,
                                icono: <Iconos.Audio className="audio" />,
                                state_detail: <>: <p>Tu pedido se te a pagado, para verificar puedes consultar con el código en tu historial de cortes.</p></>
                            })}
                        </tr>
                        <tr className="row_help" onClick={e => handlePlay(e, para_salir)}>
                            {funciones.getStateTd({
                                estado: 4,
                                icono: <Iconos.Audio className="audio" />,
                                state_detail: <>: <p>Tu pedido se encuentra en bodega listo para salir a ruta.</p></>
                            })}
                        </tr>
                        <tr className="row_help" onClick={e => handlePlay(e, por_entregar)}>
                            {funciones.getStateTd({
                                estado: 5,
                                icono: <Iconos.Audio className="audio" />,
                                state_detail: <>: <p>Tu pedido está en ruta, pronto se hará la entrega.</p></>
                            })}
                        </tr>
                        <tr className="row_help" onClick={e => handlePlay(e, por_recoger)}>
                            {funciones.getStateTd({
                                estado: 1,
                                icono: <Iconos.Audio className="audio" />,
                                state_detail: <>: <p>Uno de nuestros mensajeros pronto recogerá tus pedidos.</p></>
                            })}
                        </tr>
                        <tr className="row_help" onClick={e => handlePlay(e, recogido)}>
                            {funciones.getStateTd({
                                estado: 2,
                                icono: <Iconos.Audio className="audio" />,
                                state_detail: <>: <p>Tus pedidos están en camino hacia la bodega, pronto estaran listos para salir a ruta.</p></>
                            })}
                        </tr>

                    </tbody>
                </table>
            </div>

        </div>
    );
}

export default Help;