import * as React from "react";
import ReportsExcelHistory from "./components/ReportsExcelHistory";
import { RequirePermissions } from "shared/AlertMessages";
import { TitleSticky } from "shared/Components";
import withRouter from "services/withRouter";
import { Container, Row } from "reactstrap";
import { useMedia } from "hooks";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint no-extend-native: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

Date.prototype.getWeekNumber = function () {
    var d = new Date(+this);
    d.setHours(0, 0, 0, 0);
    d.setDate(d.getDate() + 4 - (d.getDay() || 7));

    return Math.ceil((((d - new Date(d.getFullYear(), 0, 1)) / 8.64e7) + 1) / 7);
};

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

const ReportHistory = (router) => {
    const { navigate, location } = router;
    const { setTitulo } = useMedia();
    const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7 };
    const [userToClose, setUserToClose] = React.useState();
    const fecha_creado = new Date(location.state.propietario.fecha);
    const fecha = `${format(fecha_creado.getDate())} de ${meses[fecha_creado.getMonth()]} del ${fecha_creado.getFullYear()} a las ${format(fecha_creado.getHours())}:${format(fecha_creado.getMinutes())}`;


    React.useEffect(() => {
        setTitulo("Historico de corte");
        document.body.style.overflow = "auto";
    }, []);

    React.useEffect(() => {
        if ([1, 2, 3, 4, 6, 7, 8].includes(parseInt(data_user.nivel))) {
            if (location.state && location.state.propietario) {
                setUserToClose(location.state.propietario);
            }
        }
    }, [location.state]);

    return (
        <RequirePermissions navigate={navigate}
            permission={[1, 2, 3, 4, 6, 7, 8].includes(parseInt(data_user.nivel))}>

            <TitleSticky className="_in_page">
                {data_user.nivel < 4 ?
                    <>Historico de corte<br /><i>{location.state.propietario.nombre}</i> <span>{fecha}</span></>
                    :
                    <>Corte del {fecha}</>}
            </TitleSticky>

            <Container className="dashboard">
                <Row>

                    <ReportsExcelHistory
                        userToClose={userToClose}
                        fileName={[1, 2, 3, 5].includes(parseInt(data_user.nivel)) ? location.state.propietario.nombre + " " + fecha : "Corte del " + fecha} />

                </Row>

            </Container>
        </RequirePermissions>
    );
};

export default withRouter(ReportHistory);
