import * as React from "react";
import Information from "mdi-react/InformationOutlineIcon";
import { company_permissions } from "shared/permissions";
import { useNavigate } from "react-router-dom";
import { Input, InputNum } from "shared/Input";
import EyeOffIcon from "mdi-react/EyeOffIcon";
import { MovePopUp } from "shared/MovePopUp";
import funciones from "services/funciones";
import EyeIcon from "mdi-react/EyeIcon";
import { Alert } from "shared/Alert";
import PropTypes from 'prop-types';
import CryptoJS from "crypto-js";
import { useMedia } from "hooks";
import * as Icon from "images";
import api from "services/api";
import log from "services/log";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint  jsx-a11y/anchor-is-valid: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const DEFAULT_DOMICILIO_CLIENTE = "8000";
const DEFAULT_DOMICILIO_MENSAJERO = "5000";

export const EditAgent = (params) => {
    const { parent, close, reload, data } = params;
    const [nombre, setNombre] = React.useState("");
    const [lastname, setLastname] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [phone2, setPhone2] = React.useState("");
    const [idtipe, setIdtipe] = React.useState("Cédula de Ciudadanía");
    const [identification, setIdentification] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [password, setPass] = React.useState("");
    const [city, setCity] = React.useState("");
    const [address, setAddress] = React.useState("");
    const [error, setError] = React.useState("");
    const [zona, setZona] = React.useState(data.zona || 0);
    const [role, setRole] = React.useState(data.nivel);
    const [cityList, setCityList] = React.useState([]);
    const [showPass, setShowPass] = React.useState(false);
    const [task, setTask] = React.useState();
    const [pagos, setPagos] = React.useState([]);
    const [domicilioCliente, setDomicilioCliente] = React.useState(DEFAULT_DOMICILIO_CLIENTE);
    const [domicilioMensajero, setDomicilioMensajero] = React.useState(DEFAULT_DOMICILIO_MENSAJERO);
    const [showLoading, setShowLoading] = React.useState(false);
    const [showPermission, setShowPermission] = React.useState(false);
    const [permission, setPermission] = React.useState({});
    const [permOld, setPermOld] = React.useState({});
    const { media_movil, data_user } = useMedia();

    const ref_name = React.useRef();
    const ref_lastname = React.useRef();
    const ref_identification = React.useRef();
    const ref_phone = React.useRef();
    const ref_email = React.useRef();
    const ref_address = React.useRef();
    const ref_password = React.useRef();
    const navigate = useNavigate();


    const handleError = (_msg) => {
        setError(_msg);

        if (_msg.length > 0) {
            try {
                navigator.vibrate(250);
            } catch (e) {
                log.e('No se puede ejecutar el vibrador', e);
            }
        }
    }

    const handleAcept = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let _error = false;

        Object.keys(permission).reduce((obj, key) => {
            log.wm(key, permission[key]);
            if (key === "caja_cli_aux") {
                if (permission[key] == "" || permission[key] == 0) {
                    Alert("Debes asignar una caja de clientes principal", "warning");
                    _error = true;
                }
            }
            if (key === "caja_msj_aux") {
                if (permission[key] == "" || permission[key] == 0) {
                    Alert("Debes asignar una caja de mensajeros principal", "warning");
                    _error = true;
                }
            }
            if (key === "caja_msj") {
                if (permission[key] == 1 || permission[key] == 0) {
                    Alert("Debes ingresar un nombre para la caja de mensajeros principal", "warning");
                    _error = true;
                }
            }
            if (key === "caja_cli_principal") {
                if (permission[key] == 1 || permission[key] == 0) {
                    Alert("Debes ingresar un nombre para la caja de clientes principal", "warning");
                    _error = true;
                }
            }
        }, {});

        if (!_error) {
            setShowPermission(false);
        }
    }

    const handleCancel = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setPermission(permOld);
        setShowPermission(false);
    }

    const handleClose = (e) => {
        e.preventDefault();
        e.stopPropagation();

        document.body.style.overflow = "auto";
        close();
    }

    /**
     * Niveles de los usuarios 
     * 
     * 6  Administrador de la tienda
     * 61 Usuario de la tienda
     * 
     */
    const changeTask = (value) => {
        let toCreate;

        switch (parseInt(value)) {
            case 6:
                toCreate = "admin. de tienda";
                break;
            case 61:
            default:
                toCreate = "usuario de tienda";
                break;
        }

        setRole(parseInt(value));
        setTask(toCreate);
    }

    const getCityList = async () => {
        let _data = await api.getUsers("city");

        if (_data.response == 1) {

            setCityList(_data.data.map(data => (data.city)));
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            handleError(_data.msg);
        }
    }

    const saveData = async () => {
        setShowLoading(true);

        handleError("");
        let _pass = password.length > 1 ? CryptoJS.SHA256(password).toString() : data.password;
        let _info = {
            id: parseInt(data.id_user),
            nombre: nombre,
            lastname: lastname,
            idtipe: idtipe,
            identification: identification,
            phone: phone,
            phone_family: phone2,
            email: email,
            city: city,
            address: address,
            password: _pass,
            reset_pass: password.length > 1 ? "reset" : "",
            zona: zona,
            rol: role,
            permisos: permission,
            domicilio_cliente: funciones.formatPrice(domicilioCliente).int,
            domicilio_mensajero: funciones.formatPrice(domicilioMensajero).int,
            tienda: data_user.info.company.id
        };

        let _data = await api.saveAgent(_info);

        if (_data.response == 1) {
            document.body.style.overflow = "auto";
            if (params.onChange) {
                params.onChange(nombre + " " + lastname);
            }
            reload();
            close();
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            handleError(_data.msg);
        }

        setShowLoading(false);
    }

    const validateFields = (e) => {
        e.preventDefault();
        let _form = {
            name: ref_name.current,
            lastname: ref_lastname.current,
            identification: ref_identification.current,
            phone: ref_phone.current,
            email: ref_email.current,
            address: ref_address.current,
            password: ref_password.current,
        };

        handleError("");

        if (role == "0") {
            handleError("Debes asignar un rol");
            return;
        }

        if (nombre.length < 1) {
            handleError("El nombre no puede estar vacío");
            _form.name.focus();
            return;
        }

        if (role !== 7 && lastname.length < 1) {
            handleError("El apellido no puede estar vacío");
            _form.lastname.focus();
            return;
        }

        if (role !== 7) {
            if (identification.length < 1) {
                handleError("El numero del documento no puede estar vacío");
                _form.identification.focus();
                return;
            }
        }

        if (phone.length < 1) {
            handleError("El teléfono no puede estar vacío");
            _form.phone.focus();
            return;
        }

        if (role !== 7) {
            if (email.length < 1) {
                handleError("El correo no puede estar vacío");
                _form.email.focus();
                return;
            }
        }

        if (city.length < 1) {
            handleError("La ciudad no puede estar vacía");
            _form.city.focus();
            return;
        }

        if (address.length < 1) {
            handleError("La direccón no puede estar vacía");
            _form.address.focus();
            return;
        }

        if (!funciones.validaDireccion(address)) {
            handleError("El formato de la direccón no es válido \nEjemplos: \nCra 94 # 69a - 42 \nCarrera 94a # 69 - 42 sur");
            _form.address.focus();
            return;
        }

        if (Object.entries(permission).length < 1) {
            handleError("Debes conceder permisos al " + task);
            _form.name.focus();
            return;
        }

        if (role === 6) {
            let _empty = false;
            pagos.map(data => {
                if (data.entity.length < 1 || data.number.length < 1) {
                    _empty = true;
                }
            });

            if (_empty) {
                handleError("La entidad y el número son requeridos en la transacción.");
                return;
            }
        }

        saveData();
    }

    const BtnGuarda = () => {
        if (showLoading) {
            return <button className="btn btn-common width-static"><Icon.Loading /></button>;
        } else {
            return <button className="btn btn-common width-static" onClick={validateFields}>Guardar</button>;
        }
    }

    const BtnCheck = ({ object }) => {
        // eslint-disable-next-line
        const [listaCajas, setListaCajas] = React.useState([]);
        const [height, setHeight] = React.useState(100);
        const [c_prim, set_c_prim] = React.useState("");
        const [c_aux, set_c_aux] = React.useState("");

        const cPrimRef = React.useRef();
        const contRef = React.useRef();
        const cAuxRef = React.useRef();
        const z_i = (99 - object.index);


        const handleCancel = (e) => {
            e.preventDefault();
            set_c_prim(permission[object.key]);
            cPrimRef.current.classList.remove("_marco_resalta");
        }

        const handleAcept = (e) => {
            e.preventDefault();
            if (c_prim === undefined || c_prim.length < 1) {
                cPrimRef.current.classList.add("_marco_resalta");
                cPrimRef.current.focus();
            } else {
                setPermission(p => { return { ...p, [object.key]: c_prim } });
            }
        }

        const handleSelectCancel = (e) => {
            e.preventDefault();
            set_c_aux(permission[object.key]);
            cAuxRef.current.classList.remove("_marco_resalta");
        }

        const handleSelectAcept = (e) => {
            e.preventDefault();
            if (c_aux === undefined || c_aux < 1) {
                cAuxRef.current.classList.add("_marco_resalta");
                cAuxRef.current.focus();
            } else {
                setPermission(p => { return { ...p, [object.key]: c_aux } });
            }
        }

        React.useEffect(() => {
            set_c_prim((permission[object.key] !== undefined && isNaN(permission[object.key])) ? permission[object.key] : "");

            if (contRef.current !== undefined) {
                setHeight(contRef.current.parentNode.scrollHeight);
            }
        }, []);

        return <>
            {((object.key == "caja_msj_aux" && permission["caja_msj"] !== undefined) ||
                (object.key == "caja_msj" && permission["caja_msj_aux"] !== undefined) ||
                (object.key == "caja_cli_aux" && permission["caja_cli_principal"] !== undefined) ||
                (object.key == "caja_cli_principal" && permission["caja_cli_aux"] !== undefined)) ?
                <></>
                :
                <div key={object.index} ref={contRef}>
                    <label
                        className="cont_check"
                        onClick={() => setPermission(p => {
                            let _new = Object.keys(p).reduce((obj, key) => {
                                if (object.key !== key) {
                                    obj[key] = p[key];
                                }
                                return obj;
                            }, {});

                            if (p[object.key] === undefined) {
                                switch (object.key) {
                                    case "caja_cli_aux":
                                    case "caja_msj_aux":
                                        _new[object.key] = "";
                                        break;
                                    case "caja_msj":
                                    case "caja_cli_principal":
                                        _new[object.key] = 0;
                                        break;
                                    default:
                                        _new[object.key] = 1;
                                        break;
                                }
                            }

                            setHeight(contRef.current.parentNode.scrollHeight);
                            return _new;
                        })}>
                        <input
                            type="checkbox"
                            checked={permission[object.key] !== undefined}
                            readOnly />
                        {object.value}
                    </label>

                    {((object.key == "caja_cli_principal" && permission[object.key] !== undefined) ||
                        (object.key == "caja_msj" && permission[object.key] !== undefined)) && <>
                            {permission[object.key] !== c_prim && <div className="_bkg_black" style={{ height: height, zIndex: z_i }}></div>}
                            <input type="text"
                                value={c_prim}
                                ref={cPrimRef}
                                className="_input_in_permission"
                                style={{ zIndex: permission[object.key] !== c_prim ? z_i : 1 }}
                                placeholder="Nombre de caja"
                                onChange={e => {
                                    set_c_prim(e.target.value);
                                    cPrimRef.current.classList.remove("_marco_resalta");
                                }} />
                            {permission[object.key] !== c_prim && <div className="_btns_in_row" style={{ zIndex: z_i }}>
                                <button className="btn" onClick={handleCancel}>Cancelar</button>
                                <button className="btn" onClick={handleAcept}>Aceptar</button>
                            </div>}
                        </>}

                    {((object.key == "caja_cli_aux" && permission[object.key] !== undefined) ||
                        (object.key == "caja_msj_aux" && permission[object.key] !== undefined)) && <>
                            {(permission[object.key] !== c_aux || c_aux == "0") && <div className="_bkg_black" style={{ height: height, zIndex: z_i }}></div>}
                            <select
                                value={c_aux}
                                ref={cAuxRef}
                                className="_input_in_permission"
                                style={{ zIndex: (permission[object.key] !== c_aux || c_aux == "0") ? z_i : 1 }}
                                onChange={e => {
                                    set_c_aux(e.target.value);
                                    cAuxRef.current.classList.remove("_marco_resalta");
                                }}>
                                <option value="0">Selecciona...</option>
                                {listaCajas.map(i => <option key={i.id_user} value={i.id_user}>{i.nombre_caja}</option>)}
                            </select>
                            {(permission[object.key] !== c_aux || c_aux == "0") && <div className="_btns_in_row" style={{ zIndex: z_i }}>
                                <button className="btn" onClick={handleSelectCancel}>Cancelar</button>
                                <button className="btn" onClick={handleSelectAcept}>Aceptar</button>
                            </div>}
                        </>}
                </div>
            }
        </>;
    }

    React.useEffect(() => {
        document.body.style.overflow = "hidden";
        getCityList();
    }, []);

    React.useEffect(() => {
        changeTask(data.nivel);

        setPermOld(JSON.parse(data.permisos));
        setPermission(JSON.parse(data.permisos));

        setNombre(data.nombre || "");
        setLastname(data.apellido || "");
        setPhone(data.telefono || "");
        setPhone2(data.telefono_familia || "");
        setIdtipe(data.typo_doc || "Cédula de Ciudadanía");
        setIdentification(data.documento || "");
        setEmail(data.correo || "");
        setCity(data.city || "");
        setAddress(data.address || "");
        setZona(data.zona || 0);
        let _info = JSON.parse(data.info);

        setDomicilioCliente(_info?.company?.domicilio?.cliente ?? DEFAULT_DOMICILIO_CLIENTE);
        setDomicilioMensajero(_info?.company?.domicilio?.mensajero ?? DEFAULT_DOMICILIO_MENSAJERO);
    }, [data]);

    return (
        <MovePopUp
            parent={parent}
            close={handleClose}
            className="new_agent"
            title={showPermission ? "Permisos del " + task : "Editar " + task}>

            {showPermission ?
                <>
                    <div className="_row_permission">
                        {company_permissions.map((perm, i) => {
                            const [key, value] = Object.entries(perm)[0];

                            if (key == "breack_line") {
                                return <h4 key={i} className="_hr"><span>{value}</span></h4>;
                            } else {
                                return <BtnCheck key={i} object={{ index: i, key, value }} />;
                            }
                        })}

                        <hr className="_hr" style={{ marginBottom: "1.5rem" }} />

                        {error.length > 1 && <h4 className="_error"><Information />{error}</h4>}
                    </div>

                    <div className="_in_row">
                        <button className="btn btn-common width-static" onClick={handleAcept}>Aceptar</button>
                        <button className="btn btn-common width-static" onClick={handleCancel}>Cancelar</button>
                    </div>
                </>
                :
                <>
                    <div className="_to_scroll">
                        <div className="_in_row _to_left">
                            <a className="_btn_a" onClick={() => setShowPermission(s => !s)}>Permisos del {task}</a>
                        </div>

                        <input type="email" name="fake_email" style={{ display: "none" }} />
                        {media_movil ?
                            <div className="_in_column">

                                <label>Nombre</label>
                                <input type="text" name="name" value={nombre} ref={ref_name}
                                    onChange={({ target: { value } }) => setNombre(value)} />

                                <label>Apellidos</label>
                                <input type="text" name="lastname" value={lastname} ref={ref_lastname}
                                    onChange={({ target: { value } }) => setLastname(value)} />

                                {role !== 7 && <>
                                    <label>Tipo de identificación</label>
                                    <select name="role" value={idtipe} onChange={({ target: { value } }) => setIdtipe(value)}>
                                        <option>Cédula de Ciudadanía</option>
                                        <option>Cédula de extranjería</option>
                                        <option>Pasapote</option>
                                        <option>NIT</option>
                                    </select>

                                    <label>Número de identificación</label>
                                    <input type="tel" name="identification" value={identification} maxLength="12" ref={ref_identification}
                                        onChange={({ target: { value } }) => setIdentification(value)} />
                                </>}

                                <label>Teléfono</label>
                                <input type="text" name="phone" value={phone} maxLength="15" ref={ref_phone}
                                    onChange={({ target: { value } }) => setPhone(value)} />

                                {role === 4 && <>
                                    <label>Teléfono de contacto</label>
                                    <input type="text" name="phone2" value={phone2} maxLength="15"
                                        onChange={({ target: { value } }) => setPhone2(value)} />
                                </>}

                                {role !== 7 && <>
                                    <label>Correo</label>
                                    <input type="email" name="email" value={email} ref={ref_email}
                                        onChange={({ target: { value } }) => setEmail(value)} />

                                    <label>Contraseña</label>
                                    <div className="_toglle_pass">
                                        <input type={showPass ? "text" : "password"} name="password" value={password}
                                            onChange={({ target: { value } }) => setPass(value)} />
                                        {showPass ? <EyeOffIcon onClick={() => setShowPass(false)} /> : <EyeIcon onClick={() => setShowPass(true)} />}
                                    </div>
                                </>}

                                <label>Ciudad</label>
                                <Input type="text" name="city" value={city} list={cityList}
                                    onChange={setCity} />

                                <label>Dirección</label>
                                <textarea name="address" rows="2" value={address} placeholder="Ingresa Dirección" ref={ref_address}
                                    onChange={({ target: { value } }) => setAddress(value)} />

                                {role === 3 && <>
                                    <label>Zonificación</label>
                                    <select name="zona" value={zona} onChange={({ target: { value } }) => setZona(value)}>
                                        <option value="0">Sellecciona...</option>
                                        <option value="1">Norte</option>
                                        <option value="2">Sur</option>
                                        <option value="3">Oriente</option>
                                        <option value="4">Occidente</option>
                                        <option value="5">NorOriente</option>
                                        <option value="6">NorOccidente</option>
                                        <option value="7">SurOriente</option>
                                        <option value="8">SurOccidente</option>
                                    </select>
                                </>}


                                {role === 6 &&
                                    <>
                                        <label>Comicilio Cliente</label>
                                        <InputNum name="domicilio_cliente"
                                            value={funciones.formatPrice(domicilioCliente).format} onChange={setDomicilioCliente} />

                                        <label>Comicilio Mensajero</label>
                                        <InputNum name="domicilio_mensajero"
                                            value={funciones.formatPrice(domicilioMensajero).format} onChange={setDomicilioMensajero} />

                                        {pagos.map((data, index, array) => {
                                            return <fieldset key={index} className="_metodos_pago">
                                                <legend>Transacción</legend>

                                                <label>Entidad</label>
                                                <input type="text" value={data.entity} onChange={({ target: { value } }) => {
                                                    array[index]['entity'] = value;
                                                    setPagos([...array])
                                                }} />

                                                <label>Número</label>
                                                <input type="tel" value={data.number} onChange={({ target: { value } }) => {
                                                    array[index]['number'] = value;
                                                    setPagos([...array])
                                                }} />
                                            </fieldset>
                                        })}

                                        <h4 className="_button" onClick={() => {
                                            setPagos([...pagos, { entity: "", number: "" }]);
                                        }}>Agregar transacción<Icon.CashPlus /></h4>

                                    </>
                                }

                            </div>
                            :
                            <div className="_in_row">

                                <div className="_in_column">

                                    <label>Nombre</label>
                                    <input type="text" name="name" value={nombre} ref={ref_name}
                                        onChange={({ target: { value } }) => setNombre(value)} />

                                    <label>Apellidos</label>
                                    <input type="text" name="lastname" value={lastname} ref={ref_lastname}
                                        onChange={({ target: { value } }) => setLastname(value)} />

                                    <label>Teléfono</label>
                                    <input type="text" name="phone" value={phone} maxLength="15" ref={ref_phone}
                                        onChange={({ target: { value } }) => setPhone(value)} />

                                    {role === 4 && <>
                                        <label>Teléfono de contacto</label>
                                        <input type="text" name="phone2" value={phone2} maxLength="15"
                                            onChange={({ target: { value } }) => setPhone2(value)} />
                                    </>}

                                    {role !== 7 && <>
                                        <label>Correo</label>
                                        <input type="email" name="email" value={email} ref={ref_email}
                                            onChange={({ target: { value } }) => setEmail(value)} />

                                        <label>Contraseña</label>
                                        <div className="_toglle_pass">
                                            <input type={showPass ? "text" : "password"} name="password" value={password}
                                                onChange={({ target: { value } }) => setPass(value)} />
                                            {showPass ? <EyeOffIcon onClick={() => setShowPass(false)} /> : <EyeIcon onClick={() => setShowPass(true)} />}
                                        </div>
                                    </>}


                                    {role === 6 &&
                                        <>
                                            {pagos.map((data, index, array) => {
                                                return <fieldset key={index} className="_metodos_pago">
                                                    <legend>Transacción</legend>

                                                    <label>Entidad</label>
                                                    <input type="text" value={data.entity} onChange={({ target: { value } }) => {
                                                        array[index]['entity'] = value;
                                                        setPagos([...array])
                                                    }} />

                                                    <label>Número</label>
                                                    <input type="tel" value={data.number} onChange={({ target: { value } }) => {
                                                        array[index]['number'] = value;
                                                        setPagos([...array])
                                                    }} />
                                                </fieldset>
                                            })}

                                            <h4 className="_button" onClick={() => {
                                                setPagos([...pagos, { entity: "", number: "" }]);
                                            }}>Agregar transacción<Icon.CashPlus /></h4>

                                        </>
                                    }
                                </div>

                                <div className="_in_column">

                                    {role !== 7 && <>
                                        <label>Tipo de identificación</label>
                                        <select name="role" value={idtipe} onChange={({ target: { value } }) => setIdtipe(value)}>
                                            <option>Cédula de Ciudadanía</option>
                                            <option>Cédula de extranjería</option>
                                            <option>Pasapote</option>
                                            <option>NIT</option>
                                        </select>

                                        <label>Número de identificación</label>
                                        <input type="tel" name="identification" value={identification} maxLength="12" ref={ref_identification}
                                            onChange={({ target: { value } }) => setIdentification(value)} />
                                    </>}

                                    <label>Ciudad</label>
                                    <Input type="text" name="city" value={city} list={cityList}
                                        onChange={setCity} />

                                    <label>Dirección</label>
                                    <textarea name="address" rows="2" value={address} placeholder="Ingresa Dirección" ref={ref_address}
                                        onChange={({ target: { value } }) => setAddress(value)} />

                                    {role === 6 &&
                                        <>
                                            <label>Domicilio Cliente</label>
                                            <InputNum name="domicilio_cliente"
                                                value={funciones.formatPrice(domicilioCliente).format} onChange={setDomicilioCliente} />

                                            <label>Domicilio Mensajero</label>
                                            <InputNum name="domicilio_mensajero"
                                                value={funciones.formatPrice(domicilioMensajero).format} onChange={setDomicilioMensajero} />
                                        </>}

                                    {role === 3 && <>
                                        <label>Zonificación</label>
                                        <select name="zona" value={zona} onChange={({ target: { value } }) => setZona(value)}>
                                            <option value="0">Sellecciona...</option>
                                            <option value="1">Norte</option>
                                            <option value="2">Sur</option>
                                            <option value="3">Oriente</option>
                                            <option value="4">Occidente</option>
                                            <option value="5">NorOriente</option>
                                            <option value="6">NorOccidente</option>
                                            <option value="7">SurOriente</option>
                                            <option value="8">SurOccidente</option>
                                        </select>
                                    </>}
                                </div>

                            </div>
                        }

                        {error.length > 1 && <h4 className="_error"><Information />{error}</h4>}
                    </div>

                    <div className="_in_row">
                        <BtnGuarda />
                        <button className="btn btn-common width-static" onClick={handleClose}>Cancelar</button>
                    </div>
                </>
            }
        </MovePopUp>
    )
}

export const NewAgent = (params) => {
    const { parent, close, reload, rol = params.data.rol } = params;
    const [nombre, setNombre] = React.useState("");
    const [lastname, setLastname] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [phone2, setPhone2] = React.useState("");
    const [idtipe, setIdtipe] = React.useState("Cédula de Ciudadanía");
    const [identification, setIdentification] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [password, setPass] = React.useState("");
    const [city, setCity] = React.useState("");
    const [address, setAddress] = React.useState("");
    const [error, setError] = React.useState("");
    const [zona, setZona] = React.useState(0);
    const [role, setRole] = React.useState(rol);
    const [cityList, setCityList] = React.useState([]);
    const [showPass, setShowPass] = React.useState(false);
    const [task, setTask] = React.useState();
    const [pagos, setPagos] = React.useState([]);
    const [domicilioCliente, setDomicilioCliente] = React.useState(DEFAULT_DOMICILIO_CLIENTE);
    const [domicilioMensajero, setDomicilioMensajero] = React.useState(DEFAULT_DOMICILIO_MENSAJERO);
    const [showLoading, setShowLoading] = React.useState(false);
    const [showPermission, setShowPermission] = React.useState(false);
    const [permission, setPermission] = React.useState({});
    const [permOld, setPermOld] = React.useState({});
    const { media_movil, data_user } = useMedia();

    const ref_name = React.useRef();
    const ref_lastname = React.useRef();
    const ref_identification = React.useRef();
    const ref_phone = React.useRef();
    const ref_email = React.useRef();
    const ref_address = React.useRef();
    const ref_password = React.useRef();
    const navigate = useNavigate();


    const handleError = (_msg) => {
        setError(_msg);

        if (_msg.length > 0) {
            try {
                navigator.vibrate(250);
            } catch (e) {
                log.e('No se puede ejecutar el vibrador', e);
            }
        }
    }

    const handleAcept = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let _error = false;

        Object.keys(permission).reduce((obj, key) => {
            log.wm(key, permission[key]);
            if (key === "caja_cli_aux") {
                if (permission[key] == "" || permission[key] == 0) {
                    Alert("Debes asignar una caja de clientes principal", "warning");
                    _error = true;
                }
            }
            if (key === "caja_msj_aux") {
                if (permission[key] == "" || permission[key] == 0) {
                    Alert("Debes asignar una caja de mensajeros principal", "warning");
                    _error = true;
                }
            }
            if (key === "caja_msj") {
                if (permission[key] == 1 || permission[key] == 0) {
                    Alert("Debes ingresar un nombre para la caja de mensajeros principal", "warning");
                    _error = true;
                }
            }
            if (key === "caja_cli_principal") {
                if (permission[key] == 1 || permission[key] == 0) {
                    Alert("Debes ingresar un nombre para la caja de clientes principal", "warning");
                    _error = true;
                }
            }
        }, {});

        if (!_error) {
            setShowPermission(false);
        }
    }

    const handleCancel = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setPermission(permOld);
        setShowPermission(false);
    }

    const handleClose = (e) => {
        e.preventDefault();
        e.stopPropagation();

        document.body.style.overflow = "auto";
        close();
    }

    /**
     * Niveles de los usuarios 
     * 
     * 6  Administrador de la tienda
     * 61 Usuario de la tienda
     * 
     */
    const changeTask = (value) => {
        let toCreate;
        let _perm = {};

        switch (parseInt(value)) {
            case 6:
                toCreate = "admin. de tienda";
                _perm = {
                    company_users: 1,
                    company_reporte: 1,
                    company_gestiones: 1,
                    company_recogidas: 1,
                    company_entregas: 1,
                    company_dev_pendientes: 1,
                    company_dev_entregadas: 1,
                    company_crear_recogidas: 1,
                };
                break;
            case 61:
            default:
                toCreate = "usuario de tienda";
                break;
        }

        setPermOld(_perm);
        setPermission(_perm);
        setRole(parseInt(value));
        setTask(toCreate);
    }

    const getCityList = async () => {
        let _data = await api.getUsers("city");

        if (_data.response == 1) {

            setCityList(_data.data.map(data => (data.city)));
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            handleError(_data.msg);
        }
    }

    const saveData = async () => {
        setShowLoading(true);

        handleError("");
        let _pass = password.length > 1 ? CryptoJS.SHA256(password).toString() : null;
        let _info = {
            id: 0,
            nombre: nombre,
            lastname: lastname,
            idtipe: idtipe,
            identification: identification,
            phone: phone,
            phone_family: phone2,
            email: email,
            city: city,
            address: address,
            password: _pass,
            zona: zona,
            rol: role,
            permisos: permission,
            domicilio_cliente: funciones.formatPrice(domicilioCliente).int,
            domicilio_mensajero: funciones.formatPrice(domicilioMensajero).int,
            tienda: data_user.info.company.id
        };

        let _data = await api.saveAgent(_info);

        if (_data.response == 1) {
            document.body.style.overflow = "auto";
            if (params.onChange) {
                params.onChange(nombre + " " + lastname);
            }
            reload();
            close();
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            handleError(_data.msg);
        }

        setShowLoading(false);
    }

    const validateFields = (e) => {
        e.preventDefault();
        let _form = {
            name: ref_name.current,
            lastname: ref_lastname.current,
            identification: ref_identification.current,
            phone: ref_phone.current,
            email: ref_email.current,
            address: ref_address.current,
            password: ref_password.current,
        };

        handleError("");

        if (role == "0") {
            handleError("Debes asignar un rol");
            return;
        }

        if (nombre.length < 1) {
            handleError("El nombre no puede estar vacío");
            _form.name.focus();
            return;
        }

        if (role !== 7 && lastname.length < 1) {
            handleError("El apellido no puede estar vacío");
            _form.lastname.focus();
            return;
        }

        if (role !== 7) {
            if (identification.length < 1) {
                handleError("El numero del documento no puede estar vacío");
                _form.identification.focus();
                return;
            }
        }

        if (phone.length < 1) {
            handleError("El teléfono no puede estar vacío");
            _form.phone.focus();
            return;
        }

        if (role !== 7) {
            if (email.length < 1) {
                handleError("El correo no puede estar vacío");
                _form.email.focus();
                return;
            }
        }

        if (city.length < 1) {
            handleError("La ciudad no puede estar vacía");
            return;
        }

        if (address.length < 1) {
            handleError("La direccón no puede estar vacía");
            _form.address.focus();
            return;
        }

        if (!funciones.validaDireccion(address)) {
            handleError("El formato de la direccón no es válido \nEjemplos: \nCra 94 # 69a - 42 \nCarrera 94a # 69 - 42 sur");
            _form.address.focus();
            return;
        }

        if (Object.entries(permission).length < 1) {
            handleError("Debes conceder permisos al " + task);
            _form.name.focus();
            return;
        }

        if (role === 6) {
            let _empty = false;
            pagos.map(data => {
                if (data.entity.length < 1 || data.number.length < 1) {
                    _empty = true;
                }
            });

            if (_empty) {
                handleError("La entidad y el número son requeridos en la transacción.");
                return;
            }
        }

        saveData();
    }

    const BtnGuarda = () => {
        if (showLoading) {
            return <button className="btn btn-common width-static"><Icon.Loading /></button>;
        } else {
            return <button className="btn btn-common width-static" onClick={validateFields}>Guardar</button>;
        }
    }

    const BtnCheck = ({ object }) => {
        // eslint-disable-next-line
        const [listaCajas, setListaCajas] = React.useState([]);
        const [height, setHeight] = React.useState(100);
        const [c_prim, set_c_prim] = React.useState("");
        const [c_aux, set_c_aux] = React.useState("");

        const cPrimRef = React.useRef();
        const contRef = React.useRef();
        const cAuxRef = React.useRef();
        const z_i = (99 - object.index);


        const handleCancel = (e) => {
            e.preventDefault();
            set_c_prim(permission[object.key]);
            cPrimRef.current.classList.remove("_marco_resalta");
        }

        const handleAcept = (e) => {
            e.preventDefault();
            if (c_prim === undefined || c_prim.length < 1) {
                cPrimRef.current.classList.add("_marco_resalta");
                cPrimRef.current.focus();
            } else {
                setPermission(p => { return { ...p, [object.key]: c_prim } });
            }
        }

        const handleSelectCancel = (e) => {
            e.preventDefault();
            set_c_aux(permission[object.key]);
            cAuxRef.current.classList.remove("_marco_resalta");
        }

        const handleSelectAcept = (e) => {
            e.preventDefault();
            if (c_aux === undefined || c_aux < 1) {
                cAuxRef.current.classList.add("_marco_resalta");
                cAuxRef.current.focus();
            } else {
                setPermission(p => { return { ...p, [object.key]: c_aux } });
            }
        }

        React.useEffect(() => {
            set_c_prim((permission[object.key] !== undefined && isNaN(permission[object.key])) ? permission[object.key] : "");

            if (contRef.current !== undefined) {
                setHeight(contRef.current.parentNode.scrollHeight);
            }
        }, []);

        return <>
            {((object.key == "caja_msj_aux" && permission["caja_msj"] !== undefined) ||
                (object.key == "caja_msj" && permission["caja_msj_aux"] !== undefined) ||
                (object.key == "caja_cli_aux" && permission["caja_cli_principal"] !== undefined) ||
                (object.key == "caja_cli_principal" && permission["caja_cli_aux"] !== undefined)) ?
                <></>
                :
                <div key={object.index} ref={contRef}>
                    <label
                        className="cont_check"
                        onClick={() => setPermission(p => {
                            let _new = Object.keys(p).reduce((obj, key) => {
                                if (object.key !== key) {
                                    obj[key] = p[key];
                                }
                                return obj;
                            }, {});

                            if (p[object.key] === undefined) {
                                switch (object.key) {
                                    case "caja_cli_aux":
                                    case "caja_msj_aux":
                                        _new[object.key] = "";
                                        break;
                                    case "caja_msj":
                                    case "caja_cli_principal":
                                        _new[object.key] = 0;
                                        break;
                                    default:
                                        _new[object.key] = 1;
                                        break;
                                }
                            }

                            setHeight(contRef.current.parentNode.scrollHeight);
                            return _new;
                        })}>
                        <input
                            type="checkbox"
                            checked={permission[object.key] !== undefined}
                            readOnly />
                        {object.value}
                    </label>

                    {((object.key == "caja_cli_principal" && permission[object.key] !== undefined) ||
                        (object.key == "caja_msj" && permission[object.key] !== undefined)) && <>
                            {permission[object.key] !== c_prim && <div className="_bkg_black" style={{ height: height, zIndex: z_i }}></div>}
                            <input type="text"
                                value={c_prim}
                                ref={cPrimRef}
                                className="_input_in_permission"
                                style={{ zIndex: permission[object.key] !== c_prim ? z_i : 1 }}
                                placeholder="Nombre de caja"
                                onChange={e => {
                                    set_c_prim(e.target.value);
                                    cPrimRef.current.classList.remove("_marco_resalta");
                                }} />
                            {permission[object.key] !== c_prim && <div className="_btns_in_row" style={{ zIndex: z_i }}>
                                <button className="btn" onClick={handleCancel}>Cancelar</button>
                                <button className="btn" onClick={handleAcept}>Aceptar</button>
                            </div>}
                        </>}

                    {((object.key == "caja_cli_aux" && permission[object.key] !== undefined) ||
                        (object.key == "caja_msj_aux" && permission[object.key] !== undefined)) && <>
                            {(permission[object.key] !== c_aux || c_aux == "0") && <div className="_bkg_black" style={{ height: height, zIndex: z_i }}></div>}
                            <select
                                value={c_aux}
                                ref={cAuxRef}
                                className="_input_in_permission"
                                style={{ zIndex: (permission[object.key] !== c_aux || c_aux == "0") ? z_i : 1 }}
                                onChange={e => {
                                    set_c_aux(e.target.value);
                                    cAuxRef.current.classList.remove("_marco_resalta");
                                }}>
                                <option value="0">Selecciona...</option>
                                {listaCajas.map(i => <option key={i.id_user} value={i.id_user}>{i.nombre_caja}</option>)}
                            </select>
                            {(permission[object.key] !== c_aux || c_aux == "0") && <div className="_btns_in_row" style={{ zIndex: z_i }}>
                                <button className="btn" onClick={handleSelectCancel}>Cancelar</button>
                                <button className="btn" onClick={handleSelectAcept}>Aceptar</button>
                            </div>}
                        </>}
                </div>
            }
        </>;
    }

    React.useEffect(() => {
        document.body.style.overflow = "hidden";
        getCityList();
    }, []);

    React.useEffect(() => {
        changeTask(rol);
    }, [rol]);

    return (
        <MovePopUp
            parent={parent}
            close={handleClose}
            className="new_agent"
            title={showPermission ? "Permisos del " + task : "Nuevo " + task}>

            {showPermission ?
                <>
                    <div className="_row_permission">
                        {company_permissions.map((perm, i) => {
                            const [key, value] = Object.entries(perm)[0];

                            if (key == "breack_line") {
                                return <h4 key={i} className="_hr"><span>{value}</span></h4>;
                            } else {
                                return <BtnCheck key={i} object={{ index: i, key, value }} />;
                            }
                        })}

                        <hr className="_hr" style={{ marginBottom: "1.5rem" }} />

                        {error.length > 1 && <h4 className="_error"><Information />{error}</h4>}
                    </div>

                    <div className="_in_row">
                        <button className="btn btn-common width-static" onClick={handleAcept}>Aceptar</button>
                        <button className="btn btn-common width-static" onClick={handleCancel}>Cancelar</button>
                    </div>
                </>
                :
                <>
                    <div className="_to_scroll">
                        <div className="_in_row _to_left">
                            <a className="_btn_a" onClick={() => setShowPermission(s => !s)}>Permisos del {task}</a>
                        </div>

                        <input type="email" name="fake_email" style={{ display: "none" }} />
                        {media_movil ?
                            <div className="_in_column">

                                <label>Nombre</label>
                                <input type="text" name="name" value={nombre} ref={ref_name}
                                    onChange={({ target: { value } }) => setNombre(value)} />

                                <label>Apellidos</label>
                                <input type="text" name="lastname" value={lastname} ref={ref_lastname}
                                    onChange={({ target: { value } }) => setLastname(value)} />

                                {role !== 7 && <>
                                    <label>Tipo de identificación</label>
                                    <select name="role" value={idtipe} onChange={({ target: { value } }) => setIdtipe(value)}>
                                        <option>Cédula de Ciudadanía</option>
                                        <option>Cédula de extranjería</option>
                                        <option>Pasapote</option>
                                        <option>NIT</option>
                                    </select>

                                    <label>Número de identificación</label>
                                    <input type="tel" name="identification" value={identification} maxLength="12" ref={ref_identification}
                                        onChange={({ target: { value } }) => setIdentification(value)} />
                                </>}

                                <label>Teléfono</label>
                                <input type="text" name="phone" value={phone} maxLength="15" ref={ref_phone}
                                    onChange={({ target: { value } }) => setPhone(value)} />

                                {role === 4 && <>
                                    <label>Teléfono de contacto</label>
                                    <input type="text" name="phone2" value={phone2} maxLength="15"
                                        onChange={({ target: { value } }) => setPhone2(value)} />
                                </>}

                                {role !== 7 && <>
                                    <label>Correo</label>
                                    <input type="email" name="email" value={email} ref={ref_email}
                                        onChange={({ target: { value } }) => setEmail(value)} />

                                    <label>Contraseña</label>
                                    <div className="_toglle_pass">
                                        <input type={showPass ? "text" : "password"} name="password" value={password}
                                            onChange={({ target: { value } }) => setPass(value)} />
                                        {showPass ? <EyeOffIcon onClick={() => setShowPass(false)} /> : <EyeIcon onClick={() => setShowPass(true)} />}
                                    </div>
                                </>}

                                <label>Ciudad</label>
                                <Input type="text" name="city" value={city} list={cityList}
                                    onChange={setCity} />

                                <label>Dirección</label>
                                <textarea name="address" rows="2" value={address} placeholder="Ingresa Dirección" ref={ref_address}
                                    onChange={({ target: { value } }) => setAddress(value)} />

                                {role === 3 && <>
                                    <label>Zonificación</label>
                                    <select name="zona" value={zona} onChange={({ target: { value } }) => setZona(value)}>
                                        <option value="0">Sellecciona...</option>
                                        <option value="1">Norte</option>
                                        <option value="2">Sur</option>
                                        <option value="3">Oriente</option>
                                        <option value="4">Occidente</option>
                                        <option value="5">NorOriente</option>
                                        <option value="6">NorOccidente</option>
                                        <option value="7">SurOriente</option>
                                        <option value="8">SurOccidente</option>
                                    </select>
                                </>}

                                {role === 6 &&
                                    <>
                                        <label>Domicilio Cliente</label>
                                        <InputNum name="domicilio__cliente"
                                            value={funciones.formatPrice(domicilioCliente).format} onChange={setDomicilioCliente} />

                                        <label>Domicilio Mensajero</label>
                                        <InputNum name="domicilio_mensajero"
                                            value={funciones.formatPrice(domicilioMensajero).format} onChange={setDomicilioMensajero} />

                                        {pagos.map((data, index, array) => {
                                            return <fieldset key={index} className="_metodos_pago">
                                                <legend>Transacción</legend>

                                                <label>Entidad</label>
                                                <input type="text" value={data.entity} onChange={({ target: { value } }) => {
                                                    array[index]['entity'] = value;
                                                    setPagos([...array])
                                                }} />

                                                <label>Número</label>
                                                <input type="tel" value={data.number} onChange={({ target: { value } }) => {
                                                    array[index]['number'] = value;
                                                    setPagos([...array])
                                                }} />
                                            </fieldset>
                                        })}

                                        <h4 className="_button" onClick={() => {
                                            setPagos([...pagos, { entity: "", number: "" }]);
                                        }}>Agregar transacción<Icon.CashPlus /></h4>

                                    </>
                                }

                            </div>
                            :
                            <div className="_in_row">

                                <div className="_in_column">

                                    <label>Nombre</label>
                                    <input type="text" name="name" value={nombre} ref={ref_name}
                                        onChange={({ target: { value } }) => setNombre(value)} />

                                    <label>Apellidos</label>
                                    <input type="text" name="lastname" value={lastname} ref={ref_lastname}
                                        onChange={({ target: { value } }) => setLastname(value)} />

                                    <label>Teléfono</label>
                                    <input type="text" name="phone" value={phone} maxLength="15" ref={ref_phone}
                                        onChange={({ target: { value } }) => setPhone(value)} />

                                    {role === 4 && <>
                                        <label>Teléfono de contacto</label>
                                        <input type="text" name="phone2" value={phone2} maxLength="15"
                                            onChange={({ target: { value } }) => setPhone2(value)} />
                                    </>}

                                    {role !== 7 && <>
                                        <label>Correo</label>
                                        <input type="email" name="email" value={email} ref={ref_email}
                                            onChange={({ target: { value } }) => setEmail(value)} />

                                        <label>Contraseña</label>
                                        <div className="_toglle_pass">
                                            <input type={showPass ? "text" : "password"} name="password" value={password}
                                                onChange={({ target: { value } }) => setPass(value)} />
                                            {showPass ? <EyeOffIcon onClick={() => setShowPass(false)} /> : <EyeIcon onClick={() => setShowPass(true)} />}
                                        </div>
                                    </>}


                                    {role === 6 &&
                                        <>
                                            {pagos.map((data, index, array) => {
                                                return <fieldset key={index} className="_metodos_pago">
                                                    <legend>Transacción</legend>

                                                    <label>Entidad</label>
                                                    <input type="text" value={data.entity} onChange={({ target: { value } }) => {
                                                        array[index]['entity'] = value;
                                                        setPagos([...array])
                                                    }} />

                                                    <label>Número</label>
                                                    <input type="tel" value={data.number} onChange={({ target: { value } }) => {
                                                        array[index]['number'] = value;
                                                        setPagos([...array])
                                                    }} />
                                                </fieldset>
                                            })}

                                            <h4 className="_button" onClick={() => {
                                                setPagos([...pagos, { entity: "", number: "" }]);
                                            }}>Agregar transacción<Icon.CashPlus /></h4>

                                        </>
                                    }
                                </div>

                                <div className="_in_column">

                                    {role !== 7 && <>
                                        <label>Tipo de identificación</label>
                                        <select name="role" value={idtipe} onChange={({ target: { value } }) => setIdtipe(value)}>
                                            <option>Cédula de Ciudadanía</option>
                                            <option>Cédula de extranjería</option>
                                            <option>Pasapote</option>
                                            <option>NIT</option>
                                        </select>

                                        <label>Número de identificación</label>
                                        <input type="tel" name="identification" value={identification} maxLength="12" ref={ref_identification}
                                            onChange={({ target: { value } }) => setIdentification(value)} />
                                    </>}

                                    <label>Ciudad</label>
                                    <Input type="text" name="city" value={city} list={cityList}
                                        onChange={setCity} />

                                    <label>Dirección</label>
                                    <textarea name="address" rows="2" value={address} placeholder="Ingresa Dirección" ref={ref_address}
                                        onChange={({ target: { value } }) => setAddress(value)} />

                                    {role === 6 &&
                                        <>
                                            <label>Domicilio Cliente</label>
                                            <InputNum name="domicilio_cliente"
                                                value={funciones.formatPrice(domicilioCliente).format} onChange={setDomicilioCliente} />

                                            <label>Domicilio Mensajero</label>
                                            <InputNum name="domicilio_mensajero"
                                                value={funciones.formatPrice(domicilioMensajero).format} onChange={setDomicilioMensajero} />
                                        </>}

                                    {role === 3 && <>
                                        <label>Zonificación</label>
                                        <select name="zona" value={zona} onChange={({ target: { value } }) => setZona(value)}>
                                            <option value="0">Sellecciona...</option>
                                            <option value="1">Norte</option>
                                            <option value="2">Sur</option>
                                            <option value="3">Oriente</option>
                                            <option value="4">Occidente</option>
                                            <option value="5">NorOriente</option>
                                            <option value="6">NorOccidente</option>
                                            <option value="7">SurOriente</option>
                                            <option value="8">SurOccidente</option>
                                        </select>
                                    </>}

                                </div>

                            </div>
                        }

                        {error.length > 1 && <h4 className="_error"><Information />{error}</h4>}
                    </div>

                    <div className="_in_row">
                        <BtnGuarda />
                        <button className="btn btn-common width-static" onClick={handleClose}>Cancelar</button>
                    </div>
                </>
            }
        </MovePopUp>
    )
}

EditAgent.propType = {
    parent: PropTypes.shape({
        top: PropTypes.number.isRequired,
        left: PropTypes.number.isRequired,
        width: PropTypes.number.isRequired,
    }).isRequired,
    close: PropTypes.func.isRequired,
    reload: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
}

NewAgent.propType = {
    parent: PropTypes.shape({
        top: PropTypes.number.isRequired,
        left: PropTypes.number.isRequired,
        width: PropTypes.number.isRequired,
    }).isRequired,
    close: PropTypes.func.isRequired,
    reload: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
}
