import * as React from 'react';


export const RequirePermissions = ({ navigate, permission, children }) => {
    return (
        <>
            {permission ? children
                :
                <div className="main_container">
                    <div className="cont_messages">
                        <h2>No cuentas con los permisos necesarios para ver este contenido</h2>
                        <button className="btn btn-common" onClick={() => navigate("/dashboard")}>Ir al inicio</button>
                        <button className="btn btn-common" onClick={() => navigate("/sign-in")}>Ir a iniciar sesión</button>
                    </div>
                </div>
            }
        </>
    )
}
