import * as React from "react";
import CalendarMonthIcon from "mdi-react/CalendarMonthIcon";
import Information from 'mdi-react/InformationOutlineIcon';
import DatePicker from "react-datepicker";
import { MovePopUp } from "shared/MovePopUp";
import * as Icon from "images";
import PropTypes from 'prop-types';

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const FilterDate = (params) => {
    const { task, parent, close, call } = params;
    const [showLoading, setShowLoading] = React.useState(false);
    const [fechaFil, setFechaFil] = React.useState(new Date());
    const [error, setError] = React.useState("");
    const form_ref = React.useRef();

    const format = (_num) => {
        return _num < 10 ? "0" + _num : _num;
    }

    const handleClose = (e) => {
        e.preventDefault();
        document.body.style.overflow = "auto";
        close(false);
    }

    const validateFields = (e) => {
        e.preventDefault();

        setError("");

        if (fechaFil.length < 1) {
            setError("¡Debes agregar una fecha!");
            return;
        }
        setShowLoading(true);

        document.body.style.overflow = "auto";
        call({
            task,
            criterio: fechaFil.getFullYear() + "-" + format(fechaFil.getMonth() + 1) + "-" + format(fechaFil.getDate()),
        });
    }

    React.useEffect(() => {
        document.body.style.overflow = "hidden";
    }, []);

    return (
        <MovePopUp
            parent={parent}
            close={handleClose}
            className="win_modal _overflow"
            title="Filtro por fecha">

            <form ref={form_ref}>

                <div className="_in_column">

                    <div className="calendar">
                        <DatePicker dateFormat="yyyy/MM/dd" name="desde" selected={fechaFil} onChange={(date) => setFechaFil(date)} />
                        <CalendarMonthIcon />
                    </div>

                </div>

            </form>
            {error.length > 1 && <h4 className="_error"><Information />{error}</h4>}

            {showLoading ?
                <button className="btn btn-common width-static"><Icon.Loading /></button>
                :
                <button className="btn btn-common width-static" onClick={validateFields}>Filtrar</button>
            }
        </MovePopUp>
    )
}

FilterDate.propTypes = {
    task: PropTypes.string.isRequired,
    parent: PropTypes.shape({
        top: PropTypes.number.isRequired,
        left: PropTypes.number.isRequired,
        width: PropTypes.number.isRequired,
    }).isRequired,
    close: PropTypes.func.isRequired,
    call: PropTypes.func.isRequired
}

export default FilterDate;