import * as React from "react";
import { RequirePermissions } from "shared/AlertMessages";
import Pendientes from "./components/Pendientes";
import { TitleSticky } from "shared/Components";
import Historico from "./components/Historico";
import { useNavigate } from "react-router-dom";
import MsjsList from "./components/MsjsList";
import { Container, Row } from "reactstrap";
import funciones from "services/funciones";
import { useMedia } from "hooks";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint no-extend-native: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const Validador = () => {
    const { setTitulo } = useMedia();
    const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7 };
    const [isLoading, setIsLoading] = React.useState(false);
    const navigate = useNavigate();

    React.useEffect(() => {
        setTitulo("Validador de pagos al vendedor");
        document.body.style.overflow = "auto";
    }, []);

    return (
        <RequirePermissions navigate={navigate}
            permission={funciones.validaPermisos("validador") && ![6, 7].includes(parseInt(data_user.nivel))}>

            <TitleSticky className="_in_page">Validador de pagos al vendedor</TitleSticky>

            <Container className="dashboard _caja">
                <Row className="_en_caja">

                    <MsjsList inLoad={isLoading} onChange={setIsLoading} />

                    <Pendientes inLoad={isLoading} onChange={setIsLoading} />

                    <Historico inLoad={isLoading} onChange={setIsLoading} />
                </Row>
            </Container>
        </RequirePermissions>
    )
}

export default Validador;
