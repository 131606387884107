import * as React from 'react';
import LoadingWare from "images/loading.svg";

const Loading = ({ className = "" }) => {
    return (
        <div className={"load_common " + className}>
            <div className="load__icon-wrap">
                <img src={LoadingWare} alt="Loading..." />
            </div>
        </div>
    );
}

export default Loading;