import * as React from "react";

export const TitleSticky = ({ className = "", children }) => {
    const [showBkg, setShowBkg] = React.useState(false);
    const titleRef = React.useRef();


    const handleScroll = () => {
        if (titleRef.current) {
            const rect = titleRef.current.getBoundingClientRect();
            setShowBkg(rect.top <= 50);
        }
    }

    React.useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (<h3 className={showBkg ? "_title " + className + " _with_shadow" : "_title " + className} ref={titleRef}>{children}</h3>)
}