import * as React from "react";
import AccountGroupIcon from "mdi-react/AccountGroupIcon";
import ReporIcon from "mdi-react/ChartAreasplineIcon";
import CajaIcon from "mdi-react/CashRegisterIcon";
import SidebarCategory from "./SidebarCategory";
import ArchiveIcon from "mdi-react/ArchiveIcon";
import funciones from "services/funciones";
import HomeIcon from "mdi-react/HomeIcon";
import ChatIcon from "mdi-react/ChatIcon";
import SidebarLink from "./SidebarLink";
import PropTypes from "prop-types";
import * as Iconos from "images";

/* eslint eqeqeq: 0 */

const SidebarContent = ({ onClick }) => {
    const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7 };

    const handleHideSidebar = () => {
        onClick();
    };

    return (
        <div className="sidebar__content">

            <ul className="sidebar__block">
                <SidebarLink title="Inicio" icon={<HomeIcon />} route="/dashboard" onClick={handleHideSidebar} />
            </ul>

            <ul className="sidebar__block">

                {[6, 61].includes(parseInt(data_user.nivel)) ?
                    <>
                        {funciones.validaPermisos("company_recogidas") && <SidebarLink title="Recogidas" icon={<Iconos.BoxMarker />} route="/dashboard/mis-recogidas" onClick={handleHideSidebar} />}
                        {funciones.validaPermisos("company_recolectas") && <SidebarLink title="Recolectas" icon={<Iconos.BoxColect />} route="/dashboard/mis-recolectas" onClick={handleHideSidebar} />}
                        {funciones.validaPermisos("company_entregas") && <SidebarLink title="Entregas" icon={<ArchiveIcon />} route="/dashboard/mis-entregas" onClick={handleHideSidebar} />}
                        {funciones.validaPermisos("company_dev_pendientes") && <SidebarLink title="Dev. Pendientes" icon={<Iconos.BoxReturnPending />} route="/dashboard/mis-devoluciones-pendientes" onClick={handleHideSidebar} />}
                        {funciones.validaPermisos("company_dev_entregadas") && <SidebarLink title="Dev. Entregadas" icon={<Iconos.BoxReturnOk />} route="/dashboard/mis-devoluciones-entregadas" onClick={handleHideSidebar} />}
                        <hr />
                        {funciones.validaPermisos("company_users") && <SidebarLink title="Usuarios" icon={<AccountGroupIcon />} route="/dashboard/tienda/usuarios" state={{ rol: 61 }} onClick={handleHideSidebar} />}
                        {funciones.validaPermisos("company_reporte") && <SidebarLink title="Caja y reportes" icon={<Iconos.Caja />} route="/dashboard/tienda/reportes" onClick={handleHideSidebar} />}
                        {funciones.validaPermisos("company_gestiones") && <SidebarLink title="Gestiones" icon={<ChatIcon />} route="/dashboard/chat" onClick={handleHideSidebar} />}
                    </>
                    :
                    <>
                        {data_user.nivel < 4 ?
                            <>
                                {funciones.validaGrupo(["pack_recogidas", "recolectas_gestion", "pack_edicion", "pack_entregas"]) &&
                                    <SidebarCategory title="Paquetes" icon={<Iconos.Box />}>
                                        {funciones.validaPermisos("pack_recogidas") && <SidebarLink title="Recogidas" route="/dashboard/ordenes-de-recogida" onClick={handleHideSidebar} />}
                                        {funciones.validaPermisos("recolectas_gestion") && <SidebarLink title="Recolectas" route="/dashboard/todas-las-recolectas" onClick={handleHideSidebar} />}
                                        {funciones.validaPermisos("pack_edicion") && <SidebarLink title="Recepción" route="/dashboard/ordenes-de-recepcion" onClick={handleHideSidebar} />}
                                        {funciones.validaPermisos("pack_entregas") && <SidebarLink title="Entregas" route="/dashboard/todos-los-paquetes" onClick={handleHideSidebar} />}
                                    </SidebarCategory>}

                                {funciones.validaGrupo(["genera_codigos", "reporte_cli", "reporte_msj", "caja_msj", "caja_msj_aux", "caja_cli", "caja_bodega", "caja_bts", "validador", "descargar_informe"]) &&
                                    <SidebarCategory title="Administración" icon={<Iconos.StoreCog />}>
                                        {funciones.validaPermisos("genera_codigos") && <SidebarLink title="Generar Códigos" route="/dashboard/generar-codigos" onClick={handleHideSidebar} />}
                                        {funciones.validaPermisos("reporte_cli") && <SidebarLink title="Reporte Clientes" route="/dashboard/reporte-clientes" onClick={handleHideSidebar} />}
                                        {funciones.validaPermisos("reporte_msj") && <SidebarLink title="Reporte Mensajeros" route="/dashboard/reporte-mensajeros" onClick={handleHideSidebar} />}
                                        {funciones.validaPermisos("caja_msj") && <SidebarLink title="Caja mensajeros" route="/dashboard/caja-mensajeros" onClick={handleHideSidebar} />}
                                        {funciones.validaPermisos("caja_msj_aux") && <SidebarLink title="Caja mensajeros" route="/dashboard/caja-mensajeros-aux" onClick={handleHideSidebar} />}
                                        {[1, 2].includes(parseInt(data_user.nivel)) && <SidebarLink title="Caja mensajeros (movimientos)" route="/dashboard/movimientos-caja-mensajeros" onClick={handleHideSidebar} />}
                                        {funciones.validaPermisos("caja_cli") && <SidebarLink title="Caja clientes" route={funciones.validaPermisos("caja_cli_aux") ? "/caja-clientes-aux" : "/caja-clientes"} onClick={handleHideSidebar} />}
                                        {funciones.validaPermisos("caja_bodega") && <SidebarLink title="Caja bodega" route="/dashboard/caja-bodega" onClick={handleHideSidebar} />}
                                        {[1, 2].includes(parseInt(data_user.nivel)) && <SidebarLink title="Caja bodega (movimientos)" route="/dashboard/movimientos-caja-bodega" onClick={handleHideSidebar} />}
                                        {funciones.validaPermisos("caja_bts") && <SidebarLink title="Caja bts" route="/dashboard/caja-bts" onClick={handleHideSidebar} />}
                                        {funciones.validaPermisos("validador") && <SidebarLink title="Validador" route="/dashboard/administrar-validador" onClick={handleHideSidebar} />}
                                        {[1, 2].includes(parseInt(data_user.nivel)) && <SidebarLink title="Validaciones pendientes" route="/dashboard/validaciones-pendientes" onClick={handleHideSidebar} />}
                                        {funciones.validaPermisos("descargar_informe") && <SidebarLink title="Informes y cierre" route="/dashboard/informes-cierre" onClick={handleHideSidebar} />}
                                    </SidebarCategory>}

                                {funciones.validaGrupo(["user_admin", "user_coordi", "user_mensajero", "user_recepcionista", "user_cajero", "user_cliente"]) &&
                                    <SidebarCategory title="Usuarios" icon={<AccountGroupIcon />}>
                                        {(data_user.auth && window.atob(data_user.auth) === "1") && <SidebarLink title="Super Admin" route="/dashboard/administradores" state={{ rol: 1 }} onClick={handleHideSidebar} />}
                                        {funciones.validaPermisos("user_admin") && <SidebarLink title="Administradores" route="/dashboard/administradores" state={{ rol: 2 }} onClick={handleHideSidebar} />}
                                        {funciones.validaPermisos("user_coordi") && <SidebarLink title="Coordinadores" route="/dashboard/coordinadores" state={{ rol: 3 }} onClick={handleHideSidebar} />}
                                        {funciones.validaPermisos("user_mensajero") && <SidebarLink title="Mensajeros" route="/dashboard/mensajeros" onClick={handleHideSidebar} />}
                                        {funciones.validaPermisos("user_recepcionista") && <SidebarLink title="Recepcionistas" route="/dashboard/recepcionistas" state={{ rol: 5 }} onClick={handleHideSidebar} />}
                                        {funciones.validaPermisos("user_cajero") && <SidebarLink title="Cajeros" route="/dashboard/cajeros" state={{ rol: 8 }} onClick={handleHideSidebar} />}
                                        {funciones.validaPermisos("user_cliente") && <SidebarLink title="Tiendas" route="/dashboard/tiendas" state={{ rol: 6 }} onClick={handleHideSidebar} />}
                                    </SidebarCategory>}
                            </>
                            :
                            <>
                                {funciones.validaPermisos("pack_recogidas") && <SidebarLink title="Recogidas" icon={<Iconos.BoxMarker />} route="/dashboard/ordenes-de-recogida" onClick={handleHideSidebar} />}
                                {funciones.validaPermisos("recolectas_gestion") && <SidebarLink title="Recolectas" icon={<Iconos.BoxColect />} route="/dashboard/todas-las-recolectas" onClick={handleHideSidebar} />}
                                {funciones.validaPermisos("pack_edicion") && <SidebarLink title="Recepción" icon={<Iconos.BoxReception />} route="/dashboard/ordenes-de-recepcion" onClick={handleHideSidebar} />}
                                {funciones.validaPermisos("pack_entregas") && <SidebarLink title="Entregas" icon={<ArchiveIcon />} route="/dashboard/todos-los-paquetes" onClick={handleHideSidebar} />}
                                <hr />
                                {funciones.validaPermisos("reporte_msj") && <SidebarLink title="Reporte Mensajeros" icon={<ReporIcon />} route="/dashboard/reporte-mensajeros" onClick={handleHideSidebar} />}
                                {funciones.validaPermisos("reporte_cli") && <SidebarLink title="Reporte Clientes" icon={<ReporIcon />} route="/dashboard/reporte-clientes" onClick={handleHideSidebar} />}

                                {(funciones.validaPermisos("ver_mi_caja") && data_user.nivel == 4) && <SidebarLink title="Mi Caja" icon={<CajaIcon />} route="/dashboard/reporte-mi-caja" onClick={handleHideSidebar} />}
                                {funciones.validaPermisos("caja_msj") && <SidebarLink title="Caja mensajeros" icon={<CajaIcon />} route="/dashboard/caja-mensajeros" onClick={handleHideSidebar} />}
                                {funciones.validaPermisos("caja_msj_aux") && <SidebarLink title="Caja mensajeros" icon={<CajaIcon />} route="/dashboard/caja-mensajeros-aux" onClick={handleHideSidebar} />}
                                {funciones.validaPermisos("caja_cli") && <SidebarLink title="Caja clientes" icon={<CajaIcon />} route={funciones.validaPermisos("caja_cli_aux") ? "/caja-clientes-aux" : "/caja-clientes"} onClick={handleHideSidebar} />}
                                {funciones.validaPermisos("caja_bodega") && <SidebarLink title="Caja bodega" icon={<CajaIcon />} route="/dashboard/caja-bodega" onClick={handleHideSidebar} />}

                                {funciones.validaPermisos("validador") && <SidebarLink title="Validador" route="/dashboard/validador" onClick={handleHideSidebar} />}
                            </>}
                        {funciones.validaPermisos("chat") && <SidebarLink title="Chat" icon={<ChatIcon />} route="/dashboard/chat" onClick={handleHideSidebar} />}
                    </>}
            </ul>
        </div>
    );
};

SidebarContent.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default SidebarContent;
