import * as React from "react";
import Information from "mdi-react/InformationOutlineIcon";
import { useNavigate } from "react-router-dom";
import funciones from "services/funciones";
import { MovePopUp } from "shared/MovePopUp";
import * as Icon from "images";
import { Input } from "shared/Input";
import { Alert } from "shared/Alert";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const AbonoCaja = ({ parent, close, reload }) => {
    const navigate = useNavigate();
    const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7 };
    const [showLoading, setShowLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [monto, setMonto] = React.useState("");
    const [notes, setNotes] = React.useState("");


    const handleClose = () => {
        reload();
        document.body.style.overflow = "auto";
        close(false);
    }

    const abonarCaja = async () => {
        setError("");

        if (monto.length < 1 || parseInt(monto) < 1 || isNaN(monto)) {
            setError("Debes ingerar un monto valido");
            return false;
        }

        if (notes.length < 1) {
            setError(`¡Debes ingresar una nota!`);
            return;
        }

        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "save-caja",
            data: {
                task: "abono_desde_caja_bdg",
                monto: monto.trim().replaceAll(".", ""),
                concepto: `"${data_user.nombre}" abono desde caja de bodega. \nNota: ${notes}`
            }
        });

        if (_data.response == 1) {
            Alert(_data.msg, "ok", () => handleClose());
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            setError(_data.msg);
        }

        setShowLoading(false);
    }

    const BtnAceptar = () => {
        if (showLoading) {
            return <button className="btn btn-common width-static"><Icon.Loading /></button>;
        } else {
            return <button className="btn btn-common width-static" onClick={abonarCaja}>Abonar</button>;
        }
    }

    React.useEffect(() => {
        document.body.style.overflow = "hidden";
    }, []);

    return (
        <MovePopUp
            parent={parent}
            close={handleClose}
            className="win_modal _overflow _delivery"
            title="Abon a caja">

            <div className="_in_column">

                <div>
                    <label className="__titulo">Monto</label>
                    <Input type="tel" name="monto" placeholder="ingresa un valor"
                        value={funciones.formatPrice(monto).format} onChange={val => { setError(""); setMonto(val); }} />
                </div>

                <div>
                    <p className="__titulo">Nota</p>
                    <textarea name="comment" rows="3" placeholder="ingresa una nota"
                        value={notes} onChange={e => { setError(""); setNotes(e.target.value); }} />
                </div>

            </div>

            {error.length > 1 && <h4 className="_error"><Information />{error}</h4>}

            <BtnAceptar />
        </MovePopUp>
    )
}

export default AbonoCaja;