import * as React from "react";
import funciones from "services/funciones";
import PropTypes from "prop-types";
import { useMedia } from "hooks";
import * as Iconos from "images";


export const MovePopUp = (params) => {
    const { children, className, close, title } = params;
    const [pos, set_pos] = React.useState({ top: 80, left: 0 });
    const [dif, setDif] = React.useState({ top: 0, left: 0 });
    const [inDrag, setInDrag] = React.useState(false);
    const { media_movil } = useMedia();
    const popupRef = React.useRef();


    const onMouseMove = (e) => {
        if (inDrag) {
            set_pos(p => {
                return { ...p, top: (e.screenY - dif.top), left: (e.screenX - dif.left) };
            });
        }
    }

    const onTouchMove = (e) => {
        if (inDrag) {
            set_pos(p => {
                return { ...p, top: (e.touches[0].screenY - dif.top), left: (e.touches[0].screenX - dif.left) };
            });
        }
    }

    const onMouseDown = (e) => {
        let _dif_y = e.screenY - popupRef.current.getBoundingClientRect().top;
        let _dif_x = e.screenX - popupRef.current.getBoundingClientRect().left;
        setDif({ top: _dif_y, left: _dif_x });
        setInDrag(true);
    }

    const onTouchStart = (e) => {
        let _dif_y = e.touches[0].screenY - popupRef.current.getBoundingClientRect().top;
        let _dif_x = e.touches[0].screenX - popupRef.current.getBoundingClientRect().left;
        setDif({ top: _dif_y, left: _dif_x });
        setInDrag(true);
    }

    React.useEffect(() => {
        set_pos(funciones.getPositonPopUp(params.parent, popupRef.current));
    }, [params]);

    return (
        <>
            {media_movil ?
                <div className="back_form _bkg_black" onTouchMove={onTouchMove}>
                    <div className={className + " _movil"} style={{ ...pos, left: 12 }} ref={popupRef}>
                        <h3 className="_title_win"
                            onTouchStart={onTouchStart}
                            onTouchEnd={() => setInDrag(false)}>
                            <Iconos.CloseCircle onClick={close} />{title} </h3>
                        {children}
                    </div>
                </div>
                :
                <div className="back_form _bkg_black" onMouseMove={onMouseMove}>
                    <div className={className} style={pos} ref={popupRef}>
                        <h3 className="_title_win"
                            onMouseDown={onMouseDown}
                            onMouseUp={() => setInDrag(false)}>
                            <Iconos.CloseCircle onClick={close} />{title} </h3>
                        {children}
                    </div>
                </div>}
        </>
    )
}

export const MovePopUpStatic = (params) => {
    const { children, className, close, title } = params;
    const [pos, set_pos] = React.useState({ top: 80, left: 0 });
    const [dif, setDif] = React.useState({ top: 0, left: 0 });
    const [inDrag, setInDrag] = React.useState(false);
    const { media_movil } = useMedia();
    const popupRef = React.useRef();


    const onMouseMove = (e) => {
        if (inDrag) {
            set_pos(p => {
                return { ...p, top: (e.screenY - dif.top), left: (e.screenX - dif.left) };
            });
        }
    }

    const onTouchMove = (e) => {
        if (inDrag) {
            set_pos(p => {
                return { ...p, top: (e.touches[0].screenY - dif.top), left: (e.touches[0].screenX - dif.left) };
            });
        }
    }

    const onMouseDown = (e) => {
        let _dif_y = e.screenY - popupRef.current.getBoundingClientRect().top;
        let _dif_x = e.screenX - popupRef.current.getBoundingClientRect().left;
        setDif({ top: _dif_y, left: _dif_x });
        setInDrag(true);
    }

    const onTouchStart = (e) => {
        let _dif_y = e.touches[0].screenY - popupRef.current.getBoundingClientRect().top;
        let _dif_x = e.touches[0].screenX - popupRef.current.getBoundingClientRect().left;
        setDif({ top: _dif_y, left: _dif_x });
        setInDrag(true);
    }

    React.useEffect(() => {
        set_pos(funciones.getPositonPopUp(params.parent, popupRef.current));
    }, [params]);

    return (
        <>
            {media_movil ?
                <div className="back_form _bkg_black" onTouchMove={onTouchMove}>
                    <div className={className} style={pos} ref={popupRef}>
                        <h3 className="_title_win"
                            onTouchStart={onTouchStart}
                            onTouchEnd={() => setInDrag(false)}>
                            <Iconos.CloseCircle className="correction_right" onClick={close} />{title} </h3>
                        {children}
                    </div>
                </div>
                :
                <div className="back_form _bkg_black" onMouseMove={onMouseMove}>
                    <div className={className} style={pos} ref={popupRef}>
                        <h3 className="_title_win"
                            onMouseDown={onMouseDown}
                            onMouseUp={() => setInDrag(false)}>
                            <Iconos.CloseCircle className="correction_right" onClick={close} />{title} </h3>
                        {children}
                    </div>
                </div>}
        </>
    )
}

MovePopUp.propTypes = {
    parent: PropTypes.shape({
        top: PropTypes.number.isRequired,
        left: PropTypes.number.isRequired,
        width: PropTypes.number.isRequired,
    }).isRequired,
    close: PropTypes.func.isRequired,
    className: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
}

MovePopUpStatic.propTypes = {
    parent: PropTypes.shape({
        top: PropTypes.number.isRequired,
        left: PropTypes.number.isRequired,
        width: PropTypes.number.isRequired,
    }).isRequired,
    close: PropTypes.func.isRequired,
    className: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
}
