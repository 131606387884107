import * as React from "react";
import { Alert, alerta, alertaRemove, alertDown, Confirm } from "shared/Alert";
import PlusCircleOutlineIcon from "mdi-react/PlusCircleOutlineIcon";
import { RequirePermissions } from "shared/AlertMessages";
import { TitleSticky } from "shared/Components";
import { useNavigate } from "react-router-dom";
import withRouter from "services/withRouter";
import EditContainer from "./EditContainer";
import funciones from "services/funciones";
import NewContainer from "./NewContainer";
import Loading from "shared/Loading";
import { Field } from "shared/Input";
import { useMedia } from "hooks";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const DEFAULT_DOMICILIO_CLIENTE = "8000";
const DEFAULT_DOMICILIO_MENSAJERO = "5000";

const EditReception = ({ location }) => {
    const [data, setData] = React.useState([]);
    const [userList, setUserList] = React.useState([{ id_user: 0, nombre: "Cargando...", apellido: "" }]);
    const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7 };
    const [fechaIngreso, setFechaIngreso] = React.useState(new Date());
    const [addressConsignee, setAddressConsignee] = React.useState("");
    const [collectPrice, setCollectPrice] = React.useState("");
    const [showLoading, setShowLoading] = React.useState(true);
    const [allPaquages, setAllPaquages] = React.useState([]);
    const [consigneeId, setConsigneeId] = React.useState("");
    const [consignee, setConsignee] = React.useState("");
    const [domicilioCliente, setDomicilioCliente] = React.useState(DEFAULT_DOMICILIO_CLIENTE);
    const [domicilioMensajero, setDomicilioMensajero] = React.useState(DEFAULT_DOMICILIO_MENSAJERO);
    const [collect, setCollect] = React.useState(false);
    const [packages, setPackages] = React.useState({});
    const [origin] = React.useState(1);
    const [notes, setNotes] = React.useState("");
    const { setTitulo } = useMedia();
    const ref_form = React.useRef();
    const navigate = useNavigate();


    const getPackagesList = async (_id) => {
        let _data = await api.getPackages(_id);
        let _lista = await getAllList();

        if (_data.response == 1) {
            configPackages(_data.data, _lista);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const getAllList = async () => {
        let _data = await api.getUsers("duo");
        let _users = [];


        if (_data.response == 1) {

            _data.data.map(item => {
                item.nivel > "2" && _users.push(item);
            });

            setUserList(_users);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        return _users;
    }

    const configPackages = (_data, _lista) => {
        let _pack = {};
        let _allPack = [];
        let _index;

        _data.map((item, index) => {
            _index = index + 1;
            item.index = _index;
            item.estado_real = item.estado;
            item.addressee = item.addressee ?? "";
            item.recibiendo = data.comentario !== null;
            item.estado = data.comentario == null ? item.estado : 4;
            item.valorp = item.price;
            item.valord = item.v_domicilio;
            item.valorm = item.v_mensajero;

            _pack = { ..._pack, [item.index]: item };
            _allPack.push(<ContainerEdit key={item.index} pack={item} lista={_lista} />);
        });

        setPackages(_pack);
        setAllPaquages(_allPack);
    }

    const updatePackage = (pack, index) => {
        setPackages(p => ({ ...p, [index]: pack }))
    }

    const format = (_num) => {
        return _num < 10 ? "0" + _num : _num;
    }

    const saveOrder = async () => {
        setShowLoading(true);

        let _info = {
            order: data.id,
            estado: data.comentario == null ? data.estado : 4,
            collect_state: data.comentario == null ? data.collect_state : 1,
            date_ingress: fechaIngreso.getFullYear() + "-" + format(fechaIngreso.getMonth() + 1) + "-" + format(fechaIngreso.getDate()),
            origin: origin,
            consignee: consigneeId,
            comentario: data.comentario ?? "Se modifica orden de recepción",
            notes: notes,
            packages: packages
        };

        let _data = await api.editReceptionOrder(_info);

        if (_data.response == 1) {
            Alert(data.comentario == null ? _data.msg : "Recibido correctamente", "ok", () => navigate(-1));
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const validateFields = (e) => {
        e.preventDefault();
        let _is_empty = false;
        let _message = "";

        alertaRemove(ref_form.current.origen);
        alertaRemove(ref_form.current.consignee);
        alertaRemove(ref_form.current.other);

        if (origin == 0) {
            alerta(ref_form.current.origen, "Debes ingresar el origen del paquete!");
            ref_form.current.origen.focus();
            window.scrollTo({
                top: 60,
                behavior: 'smooth'
            });
            return;
        }

        if (consignee.length < 1) {
            alerta(ref_form.current.consignee, "Debes ingresar un Cliente bts!");
            ref_form.current.consignee.focus();
            window.scrollTo({
                top: 190,
                behavior: 'smooth'
            });
            return;
        }

        if (consigneeId.length < 1) {
            alerta(ref_form.current.consignee, "Este usuario no se encuentra registrado! \nAgregalo desde el icono ( + )");
            ref_form.current.consignee.focus();
            window.scrollTo({
                top: 250,
                behavior: 'smooth'
            });
            return;
        }

        if (typeof packages !== "object" || Object.entries(packages).length < 1) {
            alertDown(ref_form.current.other, "Debes agregar al menos un paquete!");
            return;
        }

        for (let i in packages) {

            if (packages[i].codigo.length < 1) {
                _is_empty = true;
                _message = `Debes ingresar un "código" para cada paquete`;
                break;
            }

            let codigo = Object.entries(packages).filter(item => item[1].codigo === packages[i].codigo);

            if (codigo.length > 1) {
                _is_empty = true;
                _message = `¡El código "${codigo[0][1].codigo}" se encuentra repetido en esta orden!`;
                break;
            }

            if (packages[i].tiene_dev) {
                if (packages[i].devolucion.length < 1) {
                    _is_empty = true;
                    _message = `Debes indicar el tipo de devolución`;
                    break;
                }
            }

            if (!packages[i].tiene_dev) {
                packages[i].devolucion = "";
            }

            if (packages[i]["valorp"].length < 1 || parseInt(packages[i]["valorp"]) < 0) {
                _is_empty = true;
                _message = `Debes ingresar el "Valor del producto" para cada paquete`;
                break;
            }

            if (packages[i]["valord"].length < 1 || parseInt(packages[i]["valord"]) < 1) {
                _is_empty = true;
                _message = `Debes ingresar el "Valor del domicilio" para cada paquete`;
                break;
            }

            if (packages[i]["valorm"].length < 1 || parseInt(packages[i]["valorm"]) < 1) {
                _is_empty = true;
                _message = `Debes ingresar el "Valor del mensajero" para cada paquete`;
                break;
            }

            if (packages[i]["attachments"].length < 1) {
                _is_empty = true;
                _message = `Debes ingresar al menos una imagen para cada paquete`;
                break;
            }
        }

        if (_is_empty) {
            Alert(_message);
            return;
        }

        saveOrder();
    }

    const addPackage = (e) => {
        e.preventDefault();

        if (consigneeId !== "") {
            let _index = allPaquages.length + 1;
            let _pack = {
                "index": _index,
                "id": 0,
                "codigo": "",
                "valorp": "0",
                "valord": domicilioCliente,
                "valorm": domicilioMensajero,
                "devolucion": "",
                "tiene_dev": false,
                "descripcion": "",
                "attachments": process.env.REACT_APP_IMAGE_PACK,
                "estado": 4,
            };

            setPackages({ ...packages, [_index]: _pack });

            alertaRemove(ref_form.current.other);

            setAllPaquages([...allPaquages, <Container key={_index} pack={_pack} />]);
        }
    }

    const removePackage = (_index, _id) => {
        let _pack_list = [];
        let _action = () => {
            setPackages(p => {
                let _new_list;

                for (let i in p) {
                    if (p[i].index !== _index) {
                        _new_list = { ..._new_list, [i]: p[i] };
                        _pack_list.push(<ContainerEdit key={p[i].index} pack={p[i]} />);
                    }
                }

                return _new_list;
            });

            setAllPaquages(_pack_list);
        }

        if (_id != 0) {
            Confirm("Seguro de borrar este paquete", (response) => {
                if (response) {
                    _action();
                }
            });
        } else {
            _action();
        }
    }

    const Container = ({ pack }) => {

        return (
            <NewContainer
                item={pack}
                list={userList}
                remove={removePackage}
                update={item => setPackages(p => ({ ...p, [pack.index]: item }))} />
        );
    }

    const ContainerEdit = ({ pack, lista }) => {

        return (
            <EditContainer
                item={pack}
                list={lista}
                remove={removePackage}
                update={(p, i) => updatePackage(p, i)} />
        );
    }

    const addConsignee = async (val) => {
        setConsignee(val);
        alertaRemove(ref_form.current.consignee);

        let lista = await getAllList();

        let item = lista.filter(({ nombre, apellido }) => nombre + " " + apellido === val);
        item = item[0];


        if (typeof item === "object") {
            let _info = JSON.parse(item.info);
            setAddressConsignee(item.address);
            setConsigneeId(item.id_user);
            setDomicilioCliente(_info?.company?.domicilio?.cliente ?? DEFAULT_DOMICILIO_CLIENTE);
            setDomicilioMensajero(_info?.company?.domicilio?.mensajero ?? DEFAULT_DOMICILIO_MENSAJERO);
        } else {
            setAddressConsignee("");
            setConsigneeId("");
            setDomicilioCliente(DEFAULT_DOMICILIO_CLIENTE);
            setDomicilioMensajero(DEFAULT_DOMICILIO_MENSAJERO);
        }
    }

    const changeConsignee = (val) => {
        setConsignee(val);
        alertaRemove(ref_form.current.consignee);

        let item = userList.filter(({ nombre, apellido }) => nombre + " " + apellido === val);
        item = item[0];

        if (typeof item === "object") {
            let _info = JSON.parse(item.info);
            setAddressConsignee(item.address);
            setConsigneeId(item.id_user);
            setDomicilioCliente(_info?.company?.domicilio?.cliente ?? DEFAULT_DOMICILIO_CLIENTE);
            setDomicilioMensajero(_info?.company?.domicilio?.mensajero ?? DEFAULT_DOMICILIO_MENSAJERO);
        } else {
            setAddressConsignee("");
            setConsigneeId("");
            setDomicilioCliente(DEFAULT_DOMICILIO_CLIENTE);
            setDomicilioMensajero(DEFAULT_DOMICILIO_MENSAJERO);
        }
    }

    React.useEffect(() => {
        if (data.length > 0) {
            getPackagesList(data.id);

            setDomicilioCliente(data.consignee_domicilio ?? DEFAULT_DOMICILIO_CLIENTE);
            setDomicilioMensajero(data.carrier_domicilio ?? DEFAULT_DOMICILIO_MENSAJERO);

            setConsignee(data.consignee_name == null ? "" : data.consignee_name);
            setConsigneeId(data.consignee == null ? "" : data.consignee);
            setAddressConsignee(data.consignee_address == null ? "" : data.consignee_address);

            setNotes(data.nota == null ? "" : data.nota);

            setFechaIngreso(new Date(data.date_received));

            if (data.collect > 1) {
                setCollect(true);
                setCollectPrice(funciones.formatPrice(data.collect).format);
            }
        }
    }, [data]);

    React.useEffect(() => {
        document.body.style.overflow = "auto";

        if (data_user.nivel == 1 ||
            data_user.nivel == 2 ||
            data_user.nivel == 3 ||
            data_user.nivel == 5) {
            if (location.state) {
                let _data = location.state.data;
                _data.length = 1;

                setTitulo(_data.comentario == null ? "Editar orden de recepción " + _data.consecutivo : "Recibir en almacén orden " + _data.consecutivo);

                setData(_data);
                setFechaIngreso(new Date(_data.date_received));
            }
        } else {
            Alert("No cuentas con los permisos necesarios para ver este contenido",
                "warning",
                () => navigate("/dashboard"));
            setShowLoading(false);
        }
    }, [location.state]);

    return (
        <RequirePermissions navigate={navigate}
            permission={funciones.validaPermisos("pack_edicion")}>

            <form className="pickup" ref={ref_form} onSubmit={e => e.preventDefault()}>
                <TitleSticky>{data.comentario == null ? "Editar orden de recepción" : "Recibir orden en almacén"}</TitleSticky>

                <div className="pickup_container" style={{ marginBottom: 28 }}>
                    <h5>Número: {data.consecutivo}</h5>

                    <div className="pickup_row">

                        <div className="pickup_column static">

                            <div className="pickup_column m_less" style={{ height: 54 }}>

                            </div>

                            <div className="pickup_column m_less">
                                <label style={{ marginTop: 12 }}>Nota</label>
                                <textarea name="notes" rows="2" placeholder="Ingresa una observación"
                                    value={notes} onChange={({ target: { value } }) => setNotes(value)} />
                            </div>

                        </div>

                        <div className="pickup_column static">
                            <Field
                                rol="6"
                                name="consignee"
                                titulo="Cliente bts"
                                list={userList}
                                placeholder="Ingresa Cliente bts"
                                value={consignee}
                                onChange={changeConsignee}
                                onAdd={addConsignee}
                            />

                            <div className="pickup_column m_less">

                                <label style={{ marginTop: 12 }}>Dirección</label>
                                <textarea name="address_delivery_location" rows="2" placeholder="Selecciona un Cliente bts para agregar su dirección."
                                    value={addressConsignee} onChange={({ target: { value } }) => setAddressConsignee(value)} readOnly={true} />
                            </div>
                        </div>

                    </div>

                    {collect && <h5 className="_collect">Esta orden tiene un cobro de recolección de ${collectPrice}</h5>}
                    {allPaquages}
                </div>

                <div className="boton_agregar">
                    <div className="for_alert">
                        <button className={consigneeId !== "" ? "btn btn-common" : "btn btn-common _deshabilitado"}
                            type="button" name="other"
                            onClick={addPackage}>
                            <PlusCircleOutlineIcon /> Agregar paquete
                        </button>
                    </div>


                    <button type="button" className="btn btn-common width-static" onClick={validateFields}>Guardar</button>
                    <button type="button" className="btn btn-common width-static" onClick={() => navigate(-1)}>Cancelar</button>

                </div>

                {showLoading && <Loading />}
            </form >
        </RequirePermissions>
    )
}

export default withRouter(EditReception);