import * as React from "react";
import CloseIcon from "mdi-react/CloseCircleOutlineIcon";
import FileUpload from "shared/FileUpload";
import { useMedia } from "hooks";
import log from "services/log";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */

const NewContainer = ({ item, update, remove }) => {
    const { media_movil } = useMedia();
    const [codigo, setCodigo] = React.useState(item.codigo);
    const [valorp, setValorp] = React.useState(item.valorp);
    const [valord, setValord] = React.useState(item.valord || 8000);
    const [valorm, setValorm] = React.useState(item.valorm || 5000);
    const [attachments, setAttachments] = React.useState(item.attachments);
    const [descripcion, setDescripcion] = React.useState(item.descripcion || "");


    const updatePackage = (param, value) => {

        switch (param) {
            case "codigo":
                // if (isNaN(value)) {
                //     return;
                // }
                break;
            case "attachments":
                log.i("attachments", value);
                break;
        }

        let _pack = {
            ...item,
            "codigo": param === "codigo" ? value : codigo,
            "valorp": param === "valorp" ? value.replaceAll(".", "") : valorp.replaceAll(".", ""),
            "valord": param === "valord" ? value.replaceAll(".", "") : valord.replaceAll(".", ""),
            "valorm": param === "valorm" ? value.replaceAll(".", "") : valorm.replaceAll(".", ""),
            "descripcion": param === "descripcion" ? value : descripcion,
            "attachments": param === "attachments" ? value : attachments,
        };

        param === "codigo" && setCodigo(value);
        param === "valorp" && setValorp(value);
        param === "valord" && setValord(value);
        param === "valorm" && setValorm(value);
        param === "descripcion" && setDescripcion(value);
        param === "attachments" && setAttachments(value);

        update(_pack);
    }

    return (
        <div className="new_container">

            <fieldset className="new_features">
                <legend>Paquete {item.index}</legend>
                <h5>
                    <CloseIcon onClick={() => remove(item.index, item.id)} />
                </h5>

                <div className="f_row" style={{ maxWidth: 600, paddingTop: 0 }}>

                    <div className="f_row" style={{ width: "100%", alignItems: "self-start", justifyContent: "center" }}>

                        <div className="features_options _static" style={{ marginLeft: 10, marginRight: 10 }}>
                            <legend>Descripción</legend>
                            <textarea name="descripcion" rows={media_movil ? "2" : "5"} value={descripcion} placeholder="Ingresa una Descripción"
                                onChange={({ target: { value } }) => updatePackage("descripcion", value)} style={{ marginTop: 6, paddingTop: 12, paddingBottom: 16 }} />
                        </div>

                        <div className="features_options _static" style={{ marginLeft: media_movil ? 10 : 0 }}>
                            <label>Adjuntar archivos</label>
                            <FileUpload
                                codigo={codigo}
                                onChange={(value) => updatePackage("attachments", value)} />
                        </div>

                    </div>

                </div>

            </fieldset>

        </div>
    );
}

export default NewContainer;