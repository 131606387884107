import * as React from 'react';
import FortgotForm from './components/FortgotForm';
import { useTranslation } from 'react-i18next';


const ForgotPassword = () => {
  const { t } = useTranslation('common');

  React.useEffect(() => {
    document.title = t('form_login.recover_password');
  }, [t]);

  return (
    <div className="account">
      <div className="account_background">
        <div className="account__wrapper">

          <div className="account__card">

            <div className="account__head">
              <div className="logo"></div>
              <h4 className="account__subhead subhead">{t("form_login.greetings")}</h4>
            </div>

            <FortgotForm />

            <p>Copyright © 2021 PressEx all rights reserved.</p>

          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;