import * as React from "react";
import AccountGroupIcon from "mdi-react/AccountGroupIcon";
import AccountOffIcon from "mdi-react/AccountOffIcon";
import AutorenewIcon from "mdi-react/AutorenewIcon";
import { useNavigate } from "react-router-dom";
import { SearchCarrier } from "shared/Input";
import { MovePopUp } from "shared/MovePopUp";
import * as Icon from "images";
import { Alert, Confirm } from "shared/Alert";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const MsjsListAdmin = (params) => {
    const [parent, setParent] = React.useState({ top: "0", left: "0" });
    const [showValidadorList, setShowValidadorList] = React.useState(false);
    const [showCarrierList, setShowCarrierList] = React.useState(false);
    const [listaValidadores, setListaValidadores] = React.useState([]);
    const [txtMensaje, setTxtMensaje] = React.useState("Cargando...");
    const [listaMensajeros, setListaMensajeros] = React.useState([]);
    const [validadorActual, setValidadorActual] = React.useState("");
    const [showLoading, setShowLoading] = React.useState(true);
    const [listAssigned, setListAssigned] = React.useState(0);
    const [showLazy, setShowLazy] = React.useState(true);
    const [showValidadoresLazy, setShowValidadoresLazy] = React.useState(true);
    const [criterioValidador, setCriterioValidador] = React.useState("");
    const [criterioMensajero, setCriterioMensajero] = React.useState("");
    const [tarea, setTarea] = React.useState("");
    const navigate = useNavigate();


    const handleCloseCarrierList = (e) => {
        e.preventDefault();
        document.body.style.overflow = "auto";
        setShowCarrierList(false);
    }

    const handleCloseValidadorList = (e) => {
        e.preventDefault();
        document.body.style.overflow = "auto";
        setShowValidadorList(false);
        setTarea("");
    }

    const handleShowValidadorList = (e, _task) => {
        e.preventDefault();
        document.body.style.overflow = "hidden";
        setShowValidadorList(true);
        setParent({ ...e.target.getBoundingClientRect().toJSON(), width: 250 });
        getValidadores();

        setTarea(_task);
    }

    const sendRelease = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "validador-cierre",
            data: {
                task: "liberar_mensajeros",
                validador: validadorActual
            }
        });

        if (_data.response == 1) {
            setListaValidadores([]);
            setListaMensajeros([]);
            Alert(_data.msg, "ok", () => {
                setTarea("");
            });
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const searchValidador = async (_val) => {
        _val = _val.toLowerCase();

        if (_val.length >= 1) {
            setCriterioValidador(_val);

            setShowLoading(true);
            setTxtMensaje(`Buscando validador con "${_val}"...`);

            setListaValidadores([]);
            let _data = await api.fetchJson({
                url: "get-users",
                data: {
                    roll: "busca_validadores",
                    criterio: _val,
                    limit: 50,
                    offset: 0
                }
            });

            if (_data.response == 1) {
                setShowValidadoresLazy(_data.data.length < 50 ? false : true);
                setListaValidadores(_data.data);
            } else if (_data.response === -2) {
                Alert(_data.msg, "warning", () => {
                    document.body.style.overflow = "auto";
                    navigate("/sign-in");
                });
            } else {
                Alert(_data.msg, "warning");
            }

            setTxtMensaje(`¡No se encuentran validadores con "${_val}"!`);
            setShowLoading(false);
        } else {
            setCriterioValidador("");
            getValidadores();
        }
    }

    const searchCarrier = async (_val) => {
        _val = _val.toLowerCase();

        if (_val.length >= 1) {
            setCriterioMensajero(_val);

            setShowLoading(true);
            setTxtMensaje(`Buscando mensajeros con "${_val}"...`);

            setListaMensajeros([]);
            let _data = await api.fetchJson({
                url: "get-users",
                data: {
                    roll: "busca_mensajeros_asignados",
                    validador: validadorActual,
                    criterio: _val,
                    limit: 50,
                    offset: 0
                }
            });

            if (_data.response == 1) {
                let _mensajeros = _data.data.map(item => {
                    if (item.validador === "si") {
                        item.select = true;
                    } else {
                        item.select = false;
                    }

                    return item;
                });

                _mensajeros = _mensajeros.reduce(
                    (prev, curr) => prev.find(i => i.id_user === curr.id_user)
                        ? prev
                        : [...prev, curr]
                    , []);

                setShowLazy(_data.data.length < 50 ? false : true);
                setListaMensajeros(_mensajeros);
            } else if (_data.response === -2) {
                Alert(_data.msg, "warning", () => {
                    document.body.style.overflow = "auto";
                    navigate("/sign-in");
                });
            } else {
                Alert(_data.msg, "warning");
            }

            setTxtMensaje(`¡No se encuentran mensajeros con "${_val}"!`);
            setShowLoading(false);
        } else {
            setCriterioMensajero("");
            getCarriers();
        }
    }

    const getValidadoresLazy = async () => {
        setShowLoading(true);
        let _data = await api.fetchJson({
            url: "get-users",
            data: {
                roll: "validadores_list",
                limit: 50,
                offset: listaValidadores.length
            }
        });

        if (_data.response == 1) {
            let _validadores = [];

            listaValidadores.map(item => {
                _validadores.push(item);
            });

            _data.data.map(item => {
                _validadores.push(item);
            });

            setShowValidadoresLazy(_data.data.length < 50 ? false : true);
            setListaValidadores(_validadores);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setTxtMensaje("¡No hay validadores!");
        setShowLoading(false);
    }

    const getValidadores = async () => {
        setShowLoading(true);
        setListaValidadores([]);
        setTxtMensaje("Buscando validadores...");
        let _data = await api.fetchJson({
            url: "get-users",
            data: {
                roll: "validadores_list",
                limit: 50,
                offset: 0
            }
        });

        if (_data.response == 1) {
            setShowValidadoresLazy(_data.data.length < 50 ? false : true);
            setListaValidadores(_data.data);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setTxtMensaje("¡No hay validadores!");
        setShowLoading(false);
    }

    const getCarriersLazy = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "get-users",
            data: {
                roll: "mensajeros_asignados",
                validador: validadorActual,
                limit: 50,
                offset: listaMensajeros.length
            }
        });

        if (_data.response == 1) {
            let _mensajeros = [];

            listaMensajeros.map(item => {
                _mensajeros.push(item);
            });

            _data.data.map(item => {
                if (item.validador === "si") {
                    item.select = true;
                } else {
                    item.select = false;
                }

                _mensajeros.push(item);
            });

            setShowLazy(_data.data.length < 50 ? false : true);
            setListaMensajeros(_mensajeros);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setTxtMensaje("¡No hay mensajeros!");
        setShowLoading(false);
    }

    const getCarriers = async () => {
        setShowLoading(true);
        setListaMensajeros([]);
        setTxtMensaje("Buscando mensajeros...");
        let _data = await api.fetchJson({
            url: "get-users",
            data: {
                roll: "mensajeros_asignados",
                validador: validadorActual,
                limit: 50,
                offset: 0
            }
        });

        if (_data.response == 1) {
            let _lista = _data.data.map(item => {
                if (item.validador === "si") {
                    item.select = true;
                } else {
                    item.select = false;
                }

                return item;
            });

            setShowLazy(_data.data.length < 50 ? false : true);
            setListaMensajeros(_lista);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setTxtMensaje("¡No hay mensajeros!");
        setShowLoading(false);
    }

    const countAssign = async () => {
        setShowLoading(true);
        let _data = await api.fetchJson({
            url: "lista-validador",
            data: {
                task: "mis_asignaciones",
                limit: 50,
                offset: 0
            }
        });

        if (_data.response == 1) {
            setListAssigned(_data.data[0].cantidad);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const validaAssignAll = (e) => {
        e.preventDefault();

        Confirm("Al asignarle todos los mensajeros liberarás la lista de los demás validadores.\n\n ¿Estas seguro de continuar?", (response) => {
            if (response) {
                saveAssignAll();
            }
        });
    }

    const saveAssignAll = async (_to_assign) => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "validador-asigna",
            data: {
                task: "asigna_todos",
                validador: validadorActual
            }
        });

        if (_data.response == 1) {
            Alert(_data.msg, "ok", () => {
                setShowCarrierList(false);
                setShowLoading(true);
            });
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }
    
    const saveAssign = async (_to_assign) => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "validador-asigna",
            data: {
                task: "asigna_admin",
                validador: validadorActual,
                list_assign: _to_assign.toString()
            }
        });

        if (_data.response == 1) {
            Alert(_data.msg, "ok", () => {
                document.body.style.overflow = "auto";
                setShowCarrierList(false);
                setShowLoading(true);
            });
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const verificaValidador = (e) => {
        e.preventDefault();

        if (validadorActual.length < 1) {
            Alert("Debes seleccionar un validador", "warning");
            return;
        }

        setShowValidadorList(false);

        if (tarea === "assign") {
            setListaMensajeros([]);
            setShowCarrierList(true);
            getCarriers();
        }

        if (tarea === "release") {
            Confirm("¿Estas seguro de liberar los mensajeros asignados?", (response) => {
                if (response) {
                    sendRelease();
                }
            });
        }
    }

    const cancelCarrier = (e) => {
        e.preventDefault();
        setShowCarrierList(false);
        setShowValidadorList(true);
    }

    const verificaCarrier = (e) => {
        e.preventDefault();
        let _lista = [];

        listaMensajeros.map(u => {
            if (u.select) {
                _lista.push(u.id_user);
            }
        });

        if (_lista.length < 1) {
            Alert("Debes seleccionar al menos un mensajero", "warning");
            return;
        }

        saveAssign(_lista);
    }

    const RowsValidadores = () => {
        if (listaValidadores.length < 1) {
            return <h3 key="01">{txtMensaje}</h3>;
        } else {
            return <>
                {listaValidadores.map((item, index) => {
                    return <div key={item.id_user}>
                        <p className="_nowrap" onClick={() => setValidadorActual(item.id_user)}>
                            <input type="radio" name="validador" checked={validadorActual === item.id_user} readOnly />
                            <b>{item.nombre} ({item.cantidad})</b>
                        </p>
                        {item.validador_name && <i>{item.validador_name}</i>}
                    </div>;
                })}

                {showLoading ?
                    <button className="btn_lazy_load"><Icon.Loading /></button>
                    :
                    <>
                        {showValidadoresLazy ?
                            <button className="btn_lazy_load" onClick={() => getValidadoresLazy()}>Cargar más</button>
                            :
                            <h4 className="h4_lazy_load">¡No hay más validadores!</h4>
                        }
                    </>
                }
            </>;
        }
    }

    const RowsCarrier = () => {
        if (listaMensajeros.length < 1) {
            return <h3 key="01">{txtMensaje}</h3>;
        } else {
            return <>
                <div key="01">
                    <p className="_btn" onClick={validaAssignAll}>Asignar todos los mensajeros</p>
                </div>

                {listaMensajeros.map((item, index) => {
                    return <div key={index}>
                        <p className="_nowrap" onClick={() => setListaMensajeros(m => {
                            return m.filter(val => {
                                if (val.id_user === m[index].id_user) {
                                    val.select = !m[index].select;
                                }

                                return val;
                            });
                        })}>
                            <input type="checkbox" checked={item.select} readOnly />
                            <b>{item.nombre}</b>
                        </p>
                        {item.validador_name && <i>{item.validador_name}</i>}
                    </div>;
                })}

                {showLoading ?
                    <button className="btn_lazy_load"><Icon.Loading /></button>
                    :
                    <>
                        {showLazy ?
                            <button className="btn_lazy_load" onClick={() => getCarriersLazy()}>Cargar más</button>
                            :
                            <h4 className="h4_lazy_load">¡No hay más mensajeros!</h4>
                        }
                    </>
                }
            </>;
        }
    }

    React.useEffect(() => {
        params.onChange(showLoading);
    }, [showLoading]);

    React.useEffect(() => {
        if (params.inLoad) {
            setCriterioValidador("");
            setCriterioMensajero("");
            countAssign();
        }
    }, [params.inLoad]);

    return (
        <>
            <div className="panel__btns _en_caja">

                <button
                    className="panel__btn"
                    type="button"
                    onClick={() => setShowLoading(true)}>
                    <AutorenewIcon className={showLoading ? "on_refresh" : ""} />
                </button>

            </div>

            <h4 className="_precio">
                <span>Mis mensajeros asignados</span> {listAssigned}
            </h4>

            <div className="cont_btns_caja">
                {showLoading ?
                    <>
                        <button type="button" className="cash-button"><Icon.Loading />Libera mensajeros</button>
                        <button type="button" className="cash-button"><Icon.Loading />Asignar mensajeros</button>
                    </>
                    :
                    <>
                        <button type="button" className="cash-button" onClick={e => handleShowValidadorList(e, "release")}><AccountOffIcon />Libera mensajeros</button>
                        <button type="button" className="cash-button" onClick={e => handleShowValidadorList(e, "assign")}><AccountGroupIcon />Asignar mensajeros</button>
                    </>
                }
            </div>

            {showValidadorList && <MovePopUp
                parent={parent}
                close={handleCloseValidadorList}
                className="win_modal"
                title="Lista de validadores">

                <SearchCarrier
                    name="buscaValidador"
                    value={criterioValidador}
                    placeholder="Buscar validador"
                    onChange={searchValidador} />

                <div className="_scroll">
                    <RowsValidadores />
                </div>

                <hr className="_line" />

                <div className="_in_row">
                    {showLoading ?
                        <button type="button" className="btn btn-common width-static"><Icon.Loading /></button>
                        :
                        <button type="button" className="btn btn-common width-static" onClick={verificaValidador}>Continuar</button>
                    }
                </div>
            </MovePopUp>}

            {showCarrierList && <MovePopUp
                parent={parent}
                close={handleCloseCarrierList}
                className="win_modal"
                title="Lista de mensajeros">

                <SearchCarrier
                    name="buscaMensajero"
                    value={criterioMensajero}
                    placeholder="Buscar mensajero"
                    onChange={searchCarrier} />

                <div className="_scroll">
                    <RowsCarrier />
                </div>

                <hr className="_line" />

                <div className="_in_row">
                    {showLoading ?
                        <>
                            <button type="button" className="btn btn-common width-static"><Icon.Loading /></button>
                            <button type="button" className="btn btn-common width-static"><Icon.Loading /></button>
                        </>
                        :
                        <>
                            <button type="button" className="btn btn-common width-static" onClick={cancelCarrier}>Cancelar</button>
                            <button type="button" className="btn btn-common width-static" onClick={verificaCarrier}>Asignar</button>
                        </>
                    }
                </div>
            </MovePopUp>}
        </>
    )
}

export default MsjsListAdmin;