import * as React from "react";
import MenuPacks from "./MenuPacks";
import MenuRecolectas from "./MenuRecolectas";

const Menu = (params) => {
    const [menu, setMenu] = React.useState(<MenuPacks {...params} />);

    React.useEffect(() => {
        let _data = params.data;

        if (parseInt(_data.orden) === 0 && [3, 31, 32, 33].includes(parseInt(_data.estado))) {
            setMenu(<MenuRecolectas {...params} />);
        }
    }, [params]);

    return menu;
}

export default Menu;