import * as React from "react";
import TodosLosPendientes from "./components/TodosLosPendientes";
import { RequirePermissions } from "shared/AlertMessages";
import { TitleSticky } from "shared/Components";
import { useNavigate } from "react-router-dom";
import { Container, Row } from "reactstrap";
import funciones from "services/funciones";
import { useMedia } from "hooks";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint no-extend-native: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const ValidacionesPendientes = () => {
    const { setTitulo } = useMedia();
    const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7 };
    const [isLoading, setIsLoading] = React.useState(false);
    const navigate = useNavigate();

    React.useEffect(() => {
        setTitulo("Todos los pendientes de validar");
        document.body.style.overflow = "auto";
    }, []);

    return (
        <RequirePermissions navigate={navigate}
            permission={funciones.validaPermisos("validador") && [1, 2].includes(parseInt(data_user.nivel))}>

            <TitleSticky className="_in_page">Todos los pendientes de validar</TitleSticky>

            <Container className="dashboard _caja">
                <Row>

                    <TodosLosPendientes inLoad={isLoading} onChange={setIsLoading} />
                </Row>
            </Container>
        </RequirePermissions>
    )
}

export default ValidacionesPendientes;
