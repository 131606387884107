import * as React from "react";
import { RequirePermissions } from "shared/AlertMessages";
import SugerenciasChat from "./components/SugerenciasChat";
import VistaChat from "./components/VistaChat";
import withRouter from "services/withRouter";
import funciones from "services/funciones";
import { SearchChat } from "shared/Input";
import { Alert } from "shared/Alert";
import { Api } from "services/api";
import { useMedia } from "hooks";
import log from "services/log";


/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const Chat = (router) => {
    const { location, navigate } = router;
    const { media_movil, setTitulo, setDataUser } = useMedia();
    const data = location.state ? location.state.data : null;
    const [showSugestions, setShowSugestions] = React.useState(data != null ? data.showSugestions : true);
    const [showChat, setShowChat] = React.useState(typeof android !== "undefined" ? false : !media_movil);
    const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7 };
    const [guest, setGuest] = React.useState(data != null ? data.guest : null);
    const api = React.useRef(new Api({ navigate, setDataUser }));
    const [userList, setUserList] = React.useState([]);
    const [criterio, setCriterio] = React.useState("");


    const ListaVacia = () => {
        return (
            <div className="sugerencia">
                <p className="nota">No hay sugerencias de chat</p>
            </div>
        );
    }

    const getUserList = async () => {
        let _data = await api.current.fetchJson({
            url: "/chats",
            data: {
                task: "mischats",
                mi_id: window.atob(data_user.auth),
            }
        });

        if (_data.response == 1) {
            let _list = _data.data.filter(x => x.id_user != window.atob(data_user.auth));

            setUserList(_list);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }
    }

    const searchChater = async (_val) => {
        setCriterio(_val);

        if (_val.length > 0) {

            let _data = await api.current.fetchJson({
                url: "/chats",
                data: {
                    task: "chaters",
                    mi_id: window.atob(data_user.auth),
                    criterio: _val
                }
            });

            if (_data.response == 1) {
                let _list = _data.data.filter(x => x.id_user != window.atob(data_user.auth));

                setUserList(_list);
            } else if (_data.response === -2) {
                Alert(_data.msg, "warning", () => {
                    document.body.style.overflow = "auto";
                    navigate("/sign-in");
                });
            } else {
                Alert(_data.msg, "warning");
            }
        } else {
            getUserList();
        }
    }

    const handleOpen = (_guest) => {
        setGuest(_guest);

        if (typeof android !== "undefined" || media_movil) {
            if (!showChat) {
                navigate("/dashboard/chat",
                    {
                        state: {
                            data: {
                                guest: _guest,
                                showSugestions: false
                            }
                        }
                    });
            }
        }
    }

    const handleViews = () => {
        if (data != null && data.fromOutside) {
            setGuest(data.guest);
            log.d("data", data);
        }

        if (typeof android !== "undefined" || media_movil) {
            log.i("es movil");

            if (data != null) {
                log.d("mostrando chat");
                setShowSugestions(data.showSugestions);
                setShowChat(true);
            } else {
                log.d("mostrando sugerencias");
                setShowSugestions(true);
                setShowChat(false);
                getUserList();
            }
        } else {
            log.i("no es movil");
            setShowSugestions(true);
            setShowChat(true);
            getUserList();
        }
    }

    React.useEffect(() => {
        setTitulo("Chat");
        document.body.style.overflow = "auto";
    }, []);

    React.useEffect(() => {
        if (funciones.validaPermisos("chat")) {
            if (typeof android !== "undefined" || media_movil) {
                // return history.listen(({ location, action }) => {
                // if (action === 'POP') {
                log.w("location", location);
                log.w("pathname", location.pathname);

                if (location.pathname === "/chat") {
                    handleViews();
                }
                // }
                // });
            }
        }
    }, [location]);

    React.useEffect(() => {
        if (funciones.validaPermisos("chat")) {
            handleViews();
        }
    }, [data]);

    return (
        <RequirePermissions navigate={navigate}
            permission={funciones.validaPermisos("chat")}>

            <div className="cont_chat">

                {showSugestions && <div className="chat_lateral" style={{ width: media_movil ? "100%" : 300 }}>

                    <div className="buscar_chat">
                        <SearchChat type="text"
                            name="Buscar"
                            placeholder="Buscar"
                            value={criterio}
                            onChange={searchChater} />
                    </div>
                    {userList.length > 0 ?
                        <SugerenciasChat userList={userList} openChat={handleOpen} />
                        :
                        <ListaVacia />
                    }
                </div>}

                {showChat && <VistaChat guest={guest} />}
            </div>
        </RequirePermissions>
    )
}

export default withRouter(Chat);