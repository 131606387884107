import * as React from "react";
import FilterConcept from "../../CajaClientes/components/FilterConcept";
import FilterDate from "../../CajaClientes/components/FilterDate";
import ChartTimelineIcon from "mdi-react/ChartTimelineIcon";
import AutorenewIcon from "mdi-react/AutorenewIcon";
import FilerIcon from "mdi-react/FilterMenuIcon";
import { Card, CardBody, Col } from "reactstrap";
import { useNavigate } from "react-router-dom";
import funciones from "services/funciones";
import * as Icon from "images";
import { Alert } from "shared/Alert";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const meses = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

const MovimientosTodos = ({ lista }) => {
    const [showLoading, setShowLoading] = React.useState(true);
    const [showModal, setShowModal] = React.useState(false);
    const [modalView, setModalView] = React.useState(null);
    const [showLazy, setShowLazy] = React.useState(true);
    const [dataList, setDataList] = React.useState([]);
    const navigate = useNavigate();

    const handlerRefresh = (e) => {
        e.preventDefault();
        getList();
    }

    const handleModal = (e, _option, _data) => {
        e.preventDefault();
        e.stopPropagation();
        let _window;

        switch (_option) {
            case "fil_fecha":
                _window = <FilterDate
                    parent={{ ...e.target.getBoundingClientRect().toJSON(), width: 230 }}
                    task={lista === "history" ? "movimientos_msjs_history_fil_fecha" : "movimientos_msjs_todos_fil_fecha"}
                    close={setShowModal}
                    call={filterList} />;
                break;
            case "fil_concepto":
                _window = <FilterConcept
                    parent={{ ...e.target.getBoundingClientRect().toJSON(), width: 230 }}
                    task={lista === "history" ? "movimientos_msjs_history_fil_concepto" : "movimientos_msjs_todos_fil_concepto"}
                    close={setShowModal}
                    call={filterList} />;
                break;
            default:
                _window = null;
                break;
        }

        setModalView(_window);
        setShowModal(true);
    }

    const filterList = async (_criterio) => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "info-caja",
            data: {
                task: _criterio.task,
                criterio: _criterio.criterio,
                offset: 0,
                lista
            }
        });

        if (_data.response == 1) {
            if (_data.data.length < 1) {
                Alert(`No se encontraron resultados para "${_criterio.criterio}" intentalo de nuevo.`, "warning");
            } else {
                // setPreviousQuery({ task: _criterio.task, offset: 0 });
                setShowLazy(_data.data.length < 50 ? false : true);
                setDataList(_data.data);
            }
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowModal(false);
        setShowLoading(false);
    }

    const getListLazy = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "info-caja",
            data: {
                task: lista === "history" ? "movimientos_msjs_history" : "movimientos_msjs_todos",
                offset: dataList.length
            }
        });

        if (_data.response == 1) {
            let _packs = [];

            dataList.map(item => {
                _packs.push(item);
            });

            _data.data.map(item => {
                _packs.push(item);
            });

            setShowLazy(_data.data.length < 50 ? false : true);
            setDataList(_packs);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const getList = async () => {
        setShowLoading(true);
        setDataList([]);

        let _data = await api.fetchJson({
            url: "info-caja",
            data: {
                task: lista === "history" ? "movimientos_msjs_history" : "movimientos_msjs_todos",
                offset: 0
            }
        });

        if (_data.response == 1) {
            setShowLazy(_data.data.length < 50 ? false : true);
            setDataList(_data.data);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const History = () => {
        return <div className="dashboard__sales-table">
            {dataList.length < 1 ?
                <h4>
                    {lista === "history" ? "No hay entregas a caja principal" : "No hay movimientos en esta caja"}

                </h4>
                :
                <>
                    <table className="table">
                        <tbody>

                            <tr className="table_title">
                                <th><FilerIcon className="to_filter" onClick={e => handleModal(e, "fil_fecha")} />Fecha</th>
                                <th>Tipo</th>
                                <th>Monto</th>
                                <th><FilerIcon className="to_filter" onClick={e => handleModal(e, "fil_concepto")} />Concepto</th>
                                <th style={{ whiteSpace: "nowrap" }}>Quien registró</th>
                            </tr>

                            {dataList.map((item, index) => {
                                let fecha_creado = new Date(item.fecha);

                                switch (parseInt(item.movimiento)) {
                                    case 15:
                                        item.nota = item.estado == 4 ?
                                            item.concepto.replaceAll("abono desde caja de bodega", "abono a caja principal") :
                                            "Abono a caja principal" + item.concepto.split("abono desde caja de bodega")[1];
                                        break;
                                    case 22:
                                        item.nota = item.estado == 4 ?
                                            item.concepto.replaceAll("entrega caja de bodega", "entrega a caja principal") :
                                            "Entrega a caja principal" + item.concepto.split("entrega caja de bodega")[1];
                                        break;
                                    default:
                                        item.nota = item.concepto;
                                        break;
                                }

                                return <tr key={index}>
                                    <td>{format(fecha_creado.getDate())}/{meses[fecha_creado.getMonth()]}/{fecha_creado.getFullYear()} {format(fecha_creado.getHours())}:{format(fecha_creado.getMinutes())}</td>
                                    <td>{item.metodo == 1 ? "efectivo" : "transferencia"}</td>
                                    <td style={{ textAlign: "right" }}>{funciones.formatPrice(item.monto).format}</td>
                                    <td style={{ whiteSpace: "pre" }}>{item.nota}</td>
                                    <td style={{ whiteSpace: "nowrap" }}>{item.gestor_name}</td>
                                </tr>
                            })}

                            <tr className="_no_margin">
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>

                    {showLoading ?
                        <button className="btn_lazy_load"><Icon.Loading /></button>
                        :
                        <>
                            {showLazy ?
                                <button className="btn_lazy_load" onClick={() => getListLazy()}>Cargar más</button>
                                :
                                <h4 className="h4_lazy_load">¡No hay más información!</h4>
                            }
                        </>
                    }
                </>
            }
        </div>;
    }

    React.useEffect(() => {
        document.body.style.overflow = "auto";
        if (funciones.validaPermisos("caja_bodega")) {
            getList();
        }
    }, [lista]);

    return (
        <div className="content_reportes">
            <Col className="col_table">
                <Card style={{ overflow: "hidden" }}>
                    <CardBody className="dashboard__card-widget">
                        <div className="card__title">
                            <h5 className="bold-text">
                                {lista === "history" ?
                                    <Icon.BoxHistory className="dashboard__money-icon" />
                                    :
                                    <ChartTimelineIcon className="dashboard__money-icon" />
                                }
                                {lista === "history" ? "Hitórico de entregas" : "Movimientos"}
                            </h5>
                        </div>

                        <div className="panel__btns">

                            <button
                                className="panel__btn"
                                aria-label="panel__btn"
                                type="button"
                                onClick={handlerRefresh}>
                                <AutorenewIcon className={showLoading ? "on_refresh" : ""} />
                            </button>

                        </div>

                        <History />
                    </CardBody>
                </Card>
            </Col>

            {showModal && modalView}
        </div>
    )
}

export default MovimientosTodos;