import * as React from "react";
import AutorenewIcon from 'mdi-react/AutorenewIcon';
import { Card, CardBody, Col } from "reactstrap";
import funciones from "services/funciones";
import { useNavigate } from "react-router";
import * as Iconos from "images";
import { useMedia } from "hooks";
import log from "services/log";

/* eslint eqeqeq: 0 */
/* eslint no-undef: 0 */
/* eslint default-case: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const meses = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

const ListPaquage = (params) => {
    const { children, userToClose, sales, conteo = 0, loading, onRefresh, preCorte = [] } = params;
    const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7 };
    const [showLoading, setShowLoading] = React.useState(true);
    const [listPreCorte, setListPreCorte] = React.useState([]);
    const [dataList, setDataList] = React.useState([]);
    const { media_movil } = useMedia();
    const navigate = useNavigate();

    const goOpen = (e, item) => {
        e.preventDefault();
        e.stopPropagation();

        if (typeof android !== "undefined") {
            let _version = typeof android.getVersion === "function" ? android.getVersion() : "old_version";
            log.d("App version", _version);
            if (["1.1.0", "1.1.1", "2.0.0"].includes(_version)) {
                android.verWebAuxiliar("/dashboard/android-detalle-paquete/" + item.codigo);
            } else {
                navigate("/dashboard/detalle-paquete", { state: { data: item } });
            }
        } else {
            window.open("/dashboard/detalle-paquete/" + item.codigo, '_blank');
        }
    }

    const resalta = (e) => {
        e.preventDefault();
        let _row = e.target.parentNode

        if (_row.classList.contains("_resalta")) {
            _row.classList.remove("_resalta");
        } else {
            _row.classList.add("_resalta");
            // _row.className = "_resalta";
        }
    }

    const handlerRefresh = (e) => {
        e.preventDefault();
        setShowLoading(true);
        onRefresh();
    }

    const setList = async () => {
        if (userToClose && userToClose.id_user) {
            let _dataList = funciones.ordenaValores(sales, userToClose);

            if (parseInt(userToClose.nivel) == 4) {
                let _data_pre_corte = funciones.ordenaValores(preCorte, userToClose);
                setListPreCorte(_data_pre_corte);
            }

            setDataList(_dataList);
        }
    }

    const Devoluciones = () => {
        let _list_dev = params.devoluciones ?? [];


        return <Col className="col_table">
            <Card style={{ overflow: "hidden" }}>
                <CardBody className="dashboard__card-widget">
                    <div className="card__title">
                        <h5 className="bold-text" style={{ color: userToClose.nivel == 6 ? "#172bc8" : "#f55a0a" }}><Iconos.PackReceive className="dashboard__money-icon" /> {_list_dev.length} devoluciones pendientes</h5>
                    </div>

                    <div className="panel__btns">

                        <button
                            className="panel__btn"
                            aria-label="panel__btn"
                            type="button"
                            onClick={handlerRefresh}>
                            <AutorenewIcon className={showLoading ? "on_refresh" : ""} />
                        </button>

                    </div>

                    <div className="dashboard__sales-table">
                        {_list_dev.length < 1 ?
                            <h4 style={{ color: userToClose.nivel == 6 ? "#172bc8" : "#f55a0a" }}>No hay pendientes en bodega</h4>
                            :
                            <table className="table">
                                <tbody>

                                    <tr className="table_title">
                                        <th></th>
                                        <th style={{ maxWidth: "max-content" }}>Estado</th>
                                        <th>Código</th>
                                        <th>Pend. desde</th>
                                        {data_user.nivel != 6 && <th>Vendedor</th>}
                                        <th>Producto</th>
                                    </tr>

                                    {_list_dev.map((item, index) => {
                                        let fecha_creado = new Date(item.fecha_pend);

                                        return <tr key={"dev_" + index} className="_to_clic" onClick={resalta} onDoubleClick={e => goOpen(e, item)}>
                                            <td>{index + 1}</td>
                                            {funciones.getStateTd(item)}
                                            <td>{item.codigo}</td>
                                            <td>{format(fecha_creado.getDate())}/{meses[fecha_creado.getMonth()]}/{fecha_creado.getFullYear()}</td>
                                            {data_user.nivel != 6 && <td>{item.consignee_name}</td>}
                                            <td>{funciones.formatPrice(item.price).format}</td>
                                        </tr>;
                                    })}

                                    <tr className="_no_margin">
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        {data_user.nivel != 6 && <td></td>}
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        }
                    </div>
                </CardBody>
            </Card>
        </Col>;
    }

    const PacksDetails = () => {
        const [currentList, setCurrentList] = React.useState([]);
        const [showLazy, setShowLazy] = React.useState(true);
        const [contenido, setContenido] = React.useState([]);
        const limit = 50;


        const getListLazy = () => {
            let _current_length = 0;
            let _list = [];

            currentList.forEach(item => {
                _list.push(item);
            });

            dataList.forEach(item => {
                if (_current_length < limit) {
                    if (!_list.some(obj => obj.id === item.id)) {
                        _list.push(item);
                        _current_length++;
                    }
                }
            });

            setCurrentList(_list);
            setShowLazy(_current_length < limit ? false : true);
        }

        React.useEffect(() => {
            let _t_domicilio = 0;
            let _t_mensajero = 0;
            let _contenido = [];
            let _t_efectivo = 0;
            let _t_producto = 0;
            let _t_bold = 0;

            if (userToClose) {
                dataList.map(item => {
                    _t_producto += parseInt(item.price || 0);
                    _t_mensajero += parseInt(item.v_mensajero || 0);
                    _t_domicilio += parseInt(item.v_domicilio || 0);
                    _t_efectivo += parseInt(item.efectivo || 0);
                    _t_bold += parseInt(item.bold || 0);
                });

                switch (parseInt(userToClose.nivel)) {
                    case 4:
                        _contenido = <div className="dashboard__sales-table">
                            {currentList.length < 1 ?
                                <h4 key={Math.random()}>No hay entregas en caja</h4>
                                :
                                <table className="table" key={Math.random()}>
                                    <tbody>

                                        <tr className="table_title">
                                            <th></th>
                                            <th style={{ maxWidth: "max-content" }}>Estado</th>
                                            <th>Código</th>
                                            {!media_movil && <th>Fecha Creado</th>}
                                            {!media_movil && <th>Vendedor</th>}

                                            <th>Producto</th>
                                            <th>Domicilio</th>
                                            <th>Efectivo</th>
                                            <th>Bold</th>
                                        </tr>

                                        {currentList.map((item, index) => {
                                            let fecha_creado = new Date(item.date_create + "T05:00:00");

                                            return <tr key={index} className="_to_clic" onClick={resalta} onDoubleClick={e => goOpen(e, item)}>
                                                <td>{index + 1}</td>
                                                {funciones.getStateTd(item)}
                                                <td>{item.codigo}</td>
                                                {!media_movil && <td>{format(fecha_creado.getDate())}/{meses[fecha_creado.getMonth()]}/{fecha_creado.getFullYear()}</td>}
                                                {!media_movil && <td>{item.consignee_name}</td>}

                                                <td>{funciones.formatPrice(item.price).format}</td>
                                                <td>{item.v_mensajero ? funciones.formatPrice(item.v_mensajero).format : ""}</td>
                                                <td>{item.efectivo ? funciones.formatPrice(item.efectivo).format : ""}</td>
                                                <td>{item.bold ? funciones.formatPrice(item.bold).format : ""}</td>
                                            </tr>;
                                        })}

                                        <tr className="_no_margin">
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            {!media_movil && <td></td>}
                                            {!media_movil && <td></td>}
                                            <td><b>{funciones.formatPrice(_t_producto).format}</b></td>
                                            <td><b>{funciones.formatPrice(_t_mensajero).format}</b></td>
                                            <td><b>{funciones.formatPrice(_t_efectivo).format}</b></td>
                                            <td><b>{funciones.formatPrice(_t_bold).format}</b></td>
                                        </tr>
                                    </tbody>
                                </table>
                            }
                        </div>;
                        break;
                    case 6:
                        _contenido = <div className="dashboard__sales-table">
                            {currentList.length < 1 ?
                                <h4 key={Math.random()}>No hay entregas en caja</h4>
                                :
                                <table className="table" key={Math.random()}>
                                    <tbody>

                                        <tr className="table_title">
                                            <th></th>
                                            <th style={{ maxWidth: "max-content" }}>Estado</th>
                                            <th>Código</th>
                                            {!media_movil && <th>Fecha Creado</th>}
                                            {!media_movil && <th>Mensajero</th>}

                                            <th>Producto</th>
                                            <th>Domicilio</th>
                                            <th>Efectivo</th>
                                            <th>Bold</th>
                                        </tr>

                                        {currentList.map((item, index) => {
                                            let fecha_creado = new Date(item.date_create + "T05:00:00");

                                            return <tr key={index} className="_to_clic" onClick={resalta} onDoubleClick={e => goOpen(e, item)}>
                                                <td>{index + 1}</td>
                                                {funciones.getStateTd(item)}
                                                <td>{item.codigo}</td>
                                                {!media_movil && <td>{format(fecha_creado.getDate())}/{meses[fecha_creado.getMonth()]}/{fecha_creado.getFullYear()}</td>}
                                                {!media_movil && <td>{item.carrier_name}</td>}

                                                <td>{funciones.formatPrice(item.price).format}</td>
                                                <td>{item.v_domicilio ? funciones.formatPrice(item.v_domicilio).format : ""}</td>
                                                <td>{item.efectivo ? funciones.formatPrice(item.efectivo).format : ""}</td>
                                                <td>{item.bold ? funciones.formatPrice(item.bold).format : ""}</td>
                                            </tr>;
                                        })}

                                        <tr className="_no_margin">
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            {!media_movil && <td></td>}
                                            {!media_movil && <td></td>}
                                            <td><b>{funciones.formatPrice(_t_producto).format}</b></td>
                                            <td><b>{funciones.formatPrice(_t_domicilio).format}</b></td>
                                            <td><b>{funciones.formatPrice(_t_efectivo).format}</b></td>
                                            <td><b>{funciones.formatPrice(_t_bold).format}</b></td>
                                        </tr>
                                    </tbody>
                                </table>
                            }
                        </div>;
                        break;
                    default:
                        _contenido = <></>;
                        break;
                }
            }

            setContenido(_contenido);
        }, [currentList]);

        React.useEffect(() => {
            let _list = [];

            dataList.forEach((item, index) => {
                if (index < limit) {
                    _list.push(item);
                }
            });

            setCurrentList(_list);
            setShowLazy(dataList.length < limit ? false : true);
        }, []);

        return <Col className="col_table">
            <Card style={{ overflow: "hidden" }}>
                <CardBody className="dashboard__card-widget">
                    <div className="card__title">
                        <h5 className="bold-text" style={{ color: "#009b3b" }}><Iconos.Pack className="dashboard__money-icon" /> {conteo} paquetes</h5>
                    </div>

                    <div className="panel__btns">

                        <button
                            className="panel__btn"
                            aria-label="panel__btn"
                            type="button"
                            onClick={handlerRefresh}>
                            <AutorenewIcon className={showLoading ? "on_refresh" : ""} />
                        </button>

                    </div>

                    {contenido}

                    {currentList.length > 0 && <>
                        {showLoading ?
                            <button className="btn_lazy_load"><Iconos.Loading /></button>
                            :
                            <>
                                {showLazy ?
                                    <button className="btn_lazy_load" onClick={() => getListLazy()}>Cargar más</button>
                                    :
                                    <h4 className="h4_lazy_load _margin_top">No hay más paquetes</h4>
                                }
                            </>
                        }
                    </>}
                </CardBody>
            </Card>
        </Col>;
    }

    const PreCorte = () => {
        let _t_producto = 0;
        let _t_mensajero = 0;
        let _t_efectivo = 0;
        let _t_bold = 0;
        let _contenido = [];

        if (userToClose) {
            _contenido = <div className="dashboard__sales-table">
                {listPreCorte.length < 1 ?
                    <h4>No hay devoluciones pre-corte</h4>
                    :
                    <table className="table">
                        <tbody>

                            <tr className="table_title">
                                <th></th>
                                <th style={{ maxWidth: "max-content" }}>Estado</th>
                                <th>Código</th>
                                {!media_movil && <th>Fecha Creado</th>}
                                {!media_movil && <th>Vendedor</th>}

                                <th>Producto</th>
                                <th>Domicilio</th>
                                <th>Efectivo</th>
                                <th>Bold</th>
                            </tr>

                            {listPreCorte.map((item, index) => {
                                let fecha_creado = new Date(item.date_create + "T05:00:00");
                                _t_producto += item.price;
                                _t_mensajero += item.v_mensajero;
                                _t_efectivo += parseInt(item.efectivo || 0);
                                _t_bold += parseInt(item.bold || 0);

                                return <tr key={index} className="_to_clic" onClick={resalta} onDoubleClick={e => goOpen(e, item)}>
                                    <td>{index + 1}</td>
                                    {funciones.getStateTd(item)}
                                    <td>{item.codigo}</td>
                                    {!media_movil && <td>{format(fecha_creado.getDate())}/{meses[fecha_creado.getMonth()]}/{fecha_creado.getFullYear()}</td>}
                                    {!media_movil && <td>{item.consignee_name}</td>}

                                    <td>{funciones.formatPrice(item.price).format}</td>
                                    <td>{item.v_mensajero ? funciones.formatPrice(item.v_mensajero).format : ""}</td>
                                    <td>{item.efectivo ? funciones.formatPrice(item.efectivo).format : ""}</td>
                                    <td>{item.bold ? funciones.formatPrice(item.bold).format : ""}</td>
                                </tr>;
                            })}

                            <tr className="_no_margin">
                                <td></td>
                                <td></td>
                                <td></td>
                                {!media_movil && <td></td>}
                                {!media_movil && <td></td>}
                                <td><b>{funciones.formatPrice(_t_producto).format}</b></td>
                                <td><b>{funciones.formatPrice(_t_mensajero).format}</b></td>
                                <td><b>{funciones.formatPrice(_t_efectivo).format}</b></td>
                                <td><b>{funciones.formatPrice(_t_bold).format}</b></td>
                            </tr>
                        </tbody>
                    </table>
                }
            </div>;
        }

        if (parseInt(userToClose.nivel) == 4) {
            return <Col className="col_table">
                <Card style={{ overflow: "hidden" }}>
                    <CardBody className="dashboard__card-widget">
                        <div className="card__title">
                            <h5 className="bold-text" style={{ color: "#009b0b" }}><Iconos.Pack className="dashboard__money-icon" /> {listPreCorte.length} devoluciones pre-corte en bodega</h5>
                        </div>

                        <div className="panel__btns">

                            <button
                                className="panel__btn"
                                aria-label="panel__btn"
                                type="button"
                                onClick={handlerRefresh}>
                                <AutorenewIcon className={showLoading ? "on_refresh" : ""} />
                            </button>

                        </div>

                        {_contenido}
                    </CardBody>
                </Card>
            </Col>;
        } else {
            return <></>;
        }
    }

    React.useEffect(() => {
        setShowLoading(loading);
    }, [loading]);

    React.useEffect(() => {
        setList();
    }, [userToClose, sales]);

    return (
        <div className="content_reportes">
            {children}

            {userToClose && <PacksDetails />}
            {userToClose && <PreCorte />}
            {userToClose && <Devoluciones />}
        </div>
    )
}

export default ListPaquage;