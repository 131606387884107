import * as React from "react";
import * as ReactDOMClient from 'react-dom/client';
import * as Iconos from "images";
import log from "services/log";

/* eslint jsx-a11y/anchor-is-valid: 0 */

const SearchComponent = ({ modal, action, title }) => {
    const [codigo, setCodigo] = React.useState("");
    const [error, setError] = React.useState("");

    const handleClose = () => {
        modal.unmount();
    }

    const handleKeyUp = (e) => {
        if (e.keyCode === 13) {
            onDismiss(e);
        }
    }

    const onDismiss = (e) => {
        e.preventDefault();
        setError("");

        if (codigo.length < 1) {
            setError("¡No hay contenido para buscar!");
        } else {
            action(codigo);
            modal.unmount();
        }
    }

    return (
        <div className="alert__content search_flotante">
            <h4><Iconos.CloseCircle onClick={handleClose} />{title}</h4>

            <div className="cont_search">
                <input type="text" placeholder="Buscar código" value={codigo}
                    onKeyUp={handleKeyUp}
                    onChange={e => { setError(""); setCodigo(e.target.value); }} />
                <Iconos.Magnify />
            </div>

            {error.length > 1 && <i className="_error">{error}</i>}
            <a className="close" onClick={onDismiss}>Buscar</a>
        </div>
    );
}

const AlertComponent = ({ modal, msg, icono, action }) => {
    let Icon;

    const onDismiss = () => {
        action();
        document.body.style.overflow = "auto";
        modal.unmount();
    }

    switch (icono) {
        case "alert":
        case "warning":
            Icon = <Iconos.AlertRhombus className="red" />;
            break;
        case "ok":
            Icon = <Iconos.CheckBold className="green" />;
            break;
        case "infoc":
            Icon = <Iconos.InformationCircle />;
            break;
        case "info":
        default:
            Icon = <Iconos.Information />;
            break;
    }

    React.useEffect(() => {
        document.body.style.overflow = "hidden";
        try {
            navigator.vibrate(250);
        } catch (e) {
            log.e('No se puede ejecutar el vibrador', e);
        }
    }, []);

    return (
        <div className="alert__content">
            <div className="alert__icon">{Icon}</div>
            <div className="to_scroll">
                <p>{msg}</p>
            </div>
            <a className="close" onClick={onDismiss}>Aceptar</a>
        </div>
    );
}

const ConfirmComponent = ({ modal, msg, action, icono }) => {
    let Icon;

    switch (icono) {
        case "alert":
        case "warning":
            Icon = <Iconos.AlertRhombus className="red" />;
            break;
        case "ok":
            Icon = <Iconos.CheckBold className="green" />;
            break;
        case "infoc":
            Icon = <Iconos.InformationCircle />;
            break;
        case "info":
            Icon = <Iconos.Information />;
            break;
        default:
            Icon = <Iconos.Alert className="yellow" />;
            break;
    }

    const onDismiss = (e, _response) => {
        e.preventDefault();
        e.stopPropagation();

        action(_response);
        document.body.style.overflow = "auto";
        modal.unmount();
    }

    React.useEffect(() => {
        document.body.style.overflow = "hidden";
        try {
            navigator.vibrate(250);
        } catch (e) {
            log.e('No se puede ejecutar el vibrador', e);
        }
    }, []);

    return (
        <div className="alert__content">
            <div className="alert__icon">{Icon}</div>
            <p>{msg}</p>
            <div className="in_row">
                <a className="close" onClick={e => onDismiss(e, true)}>Aceptar</a>
                <a className="close" onClick={e => onDismiss(e, false)}>Cancelar</a>
            </div>
        </div>
    );
}

const ResumenComponentCarrier = ({ modal, titulo, data, action }) => {
    const [devoluciones, setDevoluciones] = React.useState({});
    const [cancelados, setCancelados] = React.useState({});


    let _dev_ko = parseInt(data.cantidad_ok_dv) > 0 ?
        <>
            {data.ok_dv_cambio} <br />
            {data._ok_dv_cambio && data._ok_dv_cambio.map(item => <i key={item.id} className="dev_pack">
                <input type="checkbox"
                    defaultChecked={devoluciones[item.id] && devoluciones[item.id].checked}
                    onChange={e => handleDevoluciones(e.target.checked, item)} />
                {item.code}  {"( " + item.name + " )"}<br />
            </i>)}
            <br />
            {data.ok_dv_sobrante} <br />
            {data._ok_dv_sobrante && data._ok_dv_sobrante.map(item => <i key={item.id} className="dev_pack">
                <input type="checkbox"
                    defaultChecked={devoluciones[item.id] && devoluciones[item.id].checked}
                    onChange={e => handleDevoluciones(e.target.checked, item)} />
                {item.code}  {"( " + item.name + " )"}<br />
            </i>)}
        </> : <>¡Sin entregas OK.DV!</>;

    const handleDevoluciones = (_check, _item) => {

        setDevoluciones(i => {
            return { ...i, [_item.id]: { id: _item.id, code: _item.code, checked: _check } }
        });
    }

    const handleCancelados = (_check, _item) => {

        setCancelados(i => {
            return { ...i, [_item.id]: { id: _item.id, code: _item.code, checked: _check } }
        });
    }

    const onDismiss = () => {
        let _lista_devoluciones = "";
        let _lista_cancelados = "";

        for (const key in devoluciones) {
            if (Object.hasOwnProperty.call(devoluciones, key)) {
                if (devoluciones[key].checked) {
                    _lista_devoluciones += "\"" + devoluciones[key].id + "\",";
                }
            }
        }

        for (const key in cancelados) {
            if (Object.hasOwnProperty.call(cancelados, key)) {
                if (cancelados[key].checked) {
                    _lista_cancelados += "\"" + cancelados[key].id + "\",";
                }
            }
        }

        action(_lista_devoluciones.slice(0, -1), _lista_cancelados.slice(0, -1));

        document.body.style.overflow = "auto";
        modal.unmount();
    }

    const onClose = () => {
        document.body.style.overflow = "auto";
        modal.unmount();
    }

    React.useEffect(() => {
        document.body.style.overflow = "hidden";
    }, []);

    return (
        <div className="alert__content _resumen">
            <h4>{titulo}</h4>
            <div className="to_scroll">
                <p>{data._entregada}</p>
                <p>{data._efectivo}</p>
                <p>{data._transferencias}</p>
                <p>{_dev_ko}</p>
                <p>{data._devuelto} <br />
                    {data._res_dev && data._res_dev.map(item => <i key={item.id} className="dev_pack">
                        <input type="checkbox"
                            defaultChecked={cancelados[item.id] && cancelados[item.id].checked}
                            onChange={e => handleCancelados(e.target.checked, item)} />
                        {item.code}  {"( " + item.name + " )"}<br />
                    </i>)}
                </p>
            </div>

            <div className="cont_btns">
                <a onClick={onDismiss}>Aceptar</a>
                <a onClick={onClose}>Cancelar</a>
            </div>
        </div>
    );
}

const ResumenComponentClient = ({ modal, titulo, data, action }) => {
    const [devoluciones, setDevoluciones] = React.useState({});
    const [cancelados, setCancelados] = React.useState({});
    let _dev_ko = parseInt(data.cantidad_ok_dv) > 0 ?
        <>
            {data.ok_dv_cambio} <br />
            {data._ok_dv_cambio && data._ok_dv_cambio.map(item => <i key={item.id} className="dev_pack">
                <input type="checkbox"
                    defaultChecked={devoluciones[item.id] && devoluciones[item.id].checked}
                    onChange={e => handleDevoluciones(e.target.checked, item)} />
                {item.code}  {"( " + item.name + " )"}<br />
            </i>)}
            <br />
            {data.ok_dv_sobrante} <br />
            {data._ok_dv_sobrante && data._ok_dv_sobrante.map(item => <i key={item.id} className="dev_pack">
                <input type="checkbox"
                    defaultChecked={devoluciones[item.id] && devoluciones[item.id].checked}
                    onChange={e => handleDevoluciones(e.target.checked, item)} />
                {item.code}  {"( " + item.name + " )"}<br />
            </i>)}
        </> : <>¡Sin entregas OK.DV!</>;


    const handleDevoluciones = (_check, _item) => {

        setDevoluciones(i => {
            return { ...i, [_item.id]: { id: _item.id, code: _item.code, checked: _check } }
        });
    }

    const handleCancelados = (_check, _item) => {

        setCancelados(i => {
            return { ...i, [_item.id]: { id: _item.id, code: _item.code, checked: _check } }
        });
    }

    const onDismiss = () => {
        let _lista_devoluciones = "";
        let _lista_cancelados = "";

        for (const key in devoluciones) {
            if (Object.hasOwnProperty.call(devoluciones, key)) {
                if (devoluciones[key].checked) {
                    _lista_devoluciones += "\"" + devoluciones[key].id + "\",";
                }
            }
        }

        for (const key in cancelados) {
            if (Object.hasOwnProperty.call(cancelados, key)) {

                if (cancelados[key].checked) {
                    _lista_cancelados += "\"" + cancelados[key].id + "\",";
                }
            }
        }

        action(_lista_devoluciones.slice(0, -1), _lista_cancelados.slice(0, -1));

        document.body.style.overflow = "auto";
        modal.unmount();
    }

    const onClose = () => {
        document.body.style.overflow = "auto";
        modal.unmount();
    }

    React.useEffect(() => {
        document.body.style.overflow = "hidden";
    }, []);

    return (
        <div className="alert__content _resumen">
            <h4>{titulo}</h4>
            <div className="to_scroll">
                <p>{data._entregada}</p>
                <p>{data._efectivo}</p>
                <p>{data._transferencias}</p>
                <p>{_dev_ko}</p>
                <p>{data._devuelto} <br />
                    {data._res_dev && data._res_dev.map(item => <i key={item.id} className="dev_pack">
                        <input type="checkbox"
                            defaultChecked={cancelados[item.id] && cancelados[item.id].checked}
                            onChange={e => handleCancelados(e.target.checked, item)} />
                        {item.code}  {"( " + item.name + " )"}<br />
                    </i>)}
                </p>
            </div>

            <div className="cont_btns">
                <a onClick={onDismiss}>Aceptar</a>
                <a onClick={onClose}>Cancelar</a>
            </div>
        </div>
    );
}

/**
 * Muestra un pop up con el resumen del mensajero obtenido desde precorte.
 * 
 * @param {string} _titulo El título que tendrá el pop up. 
 * @param {JSON} _data Los datos en formato JSON. 
 * @param {Function} action Callback para la acción del botón aceptar. 
 */
export const ResumenEntregaCarrier = (_titulo, _data, action = () => log.d("No action defined")) => {
    const _modal = ReactDOMClient.createRoot(document.getElementById("modal"));

    _modal.render(
        <div className="alert">
            <ResumenComponentCarrier modal={_modal} titulo={_titulo} data={_data} action={action} />
        </div>);
}

/**
 * Muestra un pop up con el resumen del cliente obtenido desde precorte.
 * 
 * @param {string} _titulo El título que tendrá el pop up. 
 * @param {JSON} _data Los datos en formato JSON. 
 * @param {Function} action Callback para la acción del botón aceptar. 
 */
export const ResumenEntrega = (_titulo, _data, action = () => log.d("No action defined")) => {
    const _modal = ReactDOMClient.createRoot(document.getElementById("modal"));

    _modal.render(
        <div className="alert">
            <ResumenComponentClient modal={_modal} titulo={_titulo} data={_data} action={action} />
        </div>);
}

export const Alert = (_msg, _icono = "info", action = () => log.d("No action defined")) => {
    const _modal = ReactDOMClient.createRoot(document.getElementById("modal"));

    _modal.render(
        <div className="alert">
            <AlertComponent modal={_modal} msg={_msg} icono={_icono} action={action} />
        </div>);
}

export const Search = (action = () => { }, title = "Ingresa el código a buscar") => {
    const _modal = ReactDOMClient.createRoot(document.getElementById("modal"));

    _modal.render(
        <div className="alert">
            <SearchComponent modal={_modal} action={action} title={title} />
        </div>);
}

/**
 * Muestra una ventana de confirmación
 * @param {String} _msg Mensaje a mostrar
 * @param {Function} action función de respuesta callback
 */
export const Confirm = (_msg, action = () => { }, icono = "" ) => {
    const _modal = ReactDOMClient.createRoot(document.getElementById("modal"));

    _modal.render(
        <div className="alert">
            <ConfirmComponent modal={_modal} msg={_msg} action={action} icono={icono} />
        </div>);
}

export const alertArea = (_current_node, _msg) => {
    let _new_node = document.createElement("span");
    let _add_alert = true;

    if (_current_node.classList.contains("alert__resalta_input")) {
        _add_alert = false;
    }

    if (_add_alert) {
        _current_node.classList.add("alert__resalta_input");
        _new_node.className = "alert__input _up _area";
        _new_node.innerText = _msg;

        _current_node.insertAdjacentElement("afterend", _new_node);
    }

    try {
        navigator.vibrate(250);
    } catch (e) {
        log.e('No se puede ejecutar el vibrador', e);
    }
}

export const alerta = (_current_node, _msg) => {
    let _new_node = document.createElement("span");
    let _add_alert = true;

    if (_current_node.classList.contains("alert__resalta_input")) {
        _add_alert = false;
    }

    if (_add_alert) {
        _current_node.classList.add("alert__resalta_input");
        _new_node.className = "alert__input _up";
        _new_node.innerText = _msg;

        _current_node.insertAdjacentElement("afterend", _new_node);
    }

    try {
        navigator.vibrate(250);
    } catch (e) {
        log.e('No se puede ejecutar el vibrador', e);
    }
}

export const alertDown = (_current_node, _msg) => {
    let _new_node = document.createElement("span");
    let _add_alert = true;

    if (_current_node.classList.contains("alert__resalta_input")) {
        _add_alert = false;
    }

    if (_add_alert) {
        _current_node.classList.add("alert__resalta_input");
        _new_node.className = "alert__input _down";
        _new_node.innerText = _msg;

        _current_node.insertAdjacentElement("afterend", _new_node);
    }

    try {
        navigator.vibrate(250);
    } catch (e) {
        log.e('No se puede ejecutar el vibrador', e);
    }
}

export const alertaRemove = (_current_node) => {
    let _alerta = _current_node == null ? null : _current_node.nextSibling;

    if (_alerta != null) {
        _current_node.classList.remove("alert__resalta_input");

        if (_alerta.className === "alert__input _up"
            || _alerta.className === "alert__input _down"
            || _alerta.className === "alert__input _up _area") {
            _alerta.parentNode.removeChild(_alerta);
        }
    }
}
