import * as React from "react";
import Resumen from "./Resumen";
import AutorenewIcon from "mdi-react/AutorenewIcon";
import DownloadIcon from "mdi-react/DownloadIcon";
import { Card, CardBody, Col } from "reactstrap";
import { useNavigate } from "react-router-dom";
import ReactExport from "react-export-excel";
import funciones from "services/funciones";
import { Alert } from "shared/Alert";
import * as Iconos from "images";
import { useMedia } from "hooks";
import * as Icon from "images";
import api from "services/api";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const meses = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

const _border = {
    top: { style: "thin", color: { rgb: "ff000000" } },
    bottom: { style: "thin", color: { rgb: "ff000000" } },
    left: { style: "thin", color: { rgb: "ff000000" } },
    right: { style: "thin", color: { rgb: "ff000000" } },
};

const _align_rigth = { horizontal: "right", vertical: "center", wrapText: true };

const _heads = {
    fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
    font: { sz: "12", bold: true, color: { rgb: "ffffffff" } },
    alignment: { horizontal: "left", vertical: "center", wrapText: false },
    border: _border
};

const _resum = {
    fill: { patternType: "solid", fgColor: { rgb: "ff6060ff" } },
    font: { sz: "12", bold: true, color: { rgb: "ffffffff" } },
    border: _border
};

const _txt = { font: { sz: "10.5" }, border: _border };

const _txt_green = {
    fill: { patternType: "solid", fgColor: { rgb: "ff00ff00" } },
    font: { sz: "10.5", color: { rgb: "ff000000" } },
    border: _border
};

const _txt_blue = {
    fill: { patternType: "solid", fgColor: { rgb: "ff4040ff" } },
    font: { sz: "10.5", color: { rgb: "ffffffff" } },
    border: _border
};

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

const ReportsExcelHistory = ({ userToClose, fileName, children }) => {
    const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7 };
    const [showLoading, setShowLoading] = React.useState(true);
    const [resumenPago, setResumenPago] = React.useState([]);
    const [dataResum, setDataResum] = React.useState([]);
    const [dataList, setDataList] = React.useState([]);
    const [conteo, setConteo] = React.useState(0);
    const { media_movil } = useMedia();
    const navigate = useNavigate();


    const resalta = (e) => {
        e.preventDefault();
        let _row = e.target.parentNode

        if (_row.classList.contains("_resalta")) {
            _row.classList.remove("_resalta");
        } else {
            _row.classList.add("_resalta");
            // _row.className = "_resalta";
        }
    }

    const handlerRefresh = (e) => {
        e.preventDefault();

        setConteo(0);
        setDataList([]);
        getList();
    }

    const armaMensajero = (_datos) => {
        // 304 378 3429
        let _sales = [
            {
                id: 0,
                title: 'Totales',
                abonos: _datos.valor_abono || 0,
                entregas: _datos.valor_subtotal || 0,
                recogidas: _datos.valor_recogidas || 0,
                v_domicilio: _datos.domicilios || 0,
                v_mensajero: _datos.domicilios || 0,
                v_mensajero_r: _datos.domicilios_recogidas || 0,
                subtotal: _datos.valor_total || 0,
                efectivo: _datos.efectivo || 0,
                transferencia: _datos.transferencia || 0,
                domicilio_ko: _datos.domicilios_ko || 0,
            },
        ];

        setDataResum(
            [
                [
                    { merge: "start", value: "Valor entregas", style: _txt_green },
                    { merge: "end", value: "", style: _txt },
                    { value: "" + _sales[0].entregas, style: { ..._txt_green, alignment: _align_rigth } },
                ],
                [
                    { merge: "start", value: "KO.DOM.PAGO", style: _txt },
                    { merge: "end", value: "", style: _txt },
                    { value: "" + _sales[0].domicilio_ko, style: { ..._txt, alignment: _align_rigth } },
                ],
                [
                    { merge: "start", value: "Pagos por recogidas", style: _txt },
                    { merge: "end", value: "", style: _txt },
                    { value: _sales[0].recogidas > 0 ? "-" + _sales[0].recogidas : "0", style: { ..._txt, alignment: _align_rigth } },
                ],
                [
                    { merge: "start", value: "Domicilios recogidas", style: _txt },
                    { merge: "end", value: "", style: _txt },
                    { value: _sales[0].v_mensajero_r > 0 ? "-" + _sales[0].v_mensajero_r : "0", style: { ..._txt, alignment: _align_rigth } },
                ],
                [
                    { merge: "start", value: "Domicilios entregas", style: _txt },
                    { merge: "end", value: "", style: _txt },
                    { value: _sales[0].v_mensajero > 0 ? "-" + _sales[0].v_mensajero : "0", style: { ..._txt, alignment: _align_rigth } },
                ],
                [
                    { merge: "start", value: "Abonos", style: _txt },
                    { merge: "end", value: "", style: _txt },
                    { value: "" + _sales[0].abonos, style: { ..._txt, alignment: _align_rigth } },
                ],
                [
                    { merge: "start", value: "Total", style: _txt_green },
                    { merge: "end", value: "", style: _txt },
                    { value: "" + _sales[0].subtotal, style: { ..._txt_green, alignment: _align_rigth } },
                ],
                [
                    null,
                    null,
                    null,
                ],
                [
                    { merge: "start", value: "Efectivo", style: _txt_blue },
                    { merge: "end", value: "", style: _txt_blue },
                    { value: "" + _sales[0].efectivo, style: { ..._txt_blue, alignment: _align_rigth } },
                ],
                [
                    { merge: "start", value: "Transferencias", style: _txt_blue },
                    { merge: "end", value: "", style: _txt_blue },
                    { value: "" + _sales[0].transferencia, style: { ..._txt_blue, alignment: _align_rigth } },
                ],
            ]);

        setResumenPago(_sales);
    }

    const armaCliente = (_datos) => {
        // 304 378 3429
        let _sales = [
            {
                id: 0,
                title: 'Totales',
                abonos: _datos.valor_abono || 0,
                entregas: _datos.valor_subtotal || 0,
                recogidas: _datos.valor_recogidas || 0,
                v_domicilio: _datos.domicilios || 0,
                v_domicilio_r: _datos.domicilios_recogidas || 0,
                v_mensajero: 0,
                subtotal: _datos.valor_total || 0,
                efectivo: _datos.efectivo || 0,
                transferencia: _datos.transferencia || 0,
                domicilio_ko: _datos.domicilios_ko || 0,
            },
        ];

        setDataResum(
            [
                [
                    { merge: "start", value: "Valor entregas", style: _txt_green },
                    { merge: "end", value: "", style: _txt },
                    { value: "" + _sales[0].entregas, style: { ..._txt_green, alignment: _align_rigth } },
                ],
                [
                    { merge: "start", value: "KO.DOM.PAGO", style: _txt },
                    { merge: "end", value: "", style: _txt },
                    { value: "" + _sales[0].domicilio_ko, style: { ..._txt, alignment: _align_rigth } },
                ],
                [
                    { merge: "start", value: "Pagos por recogidas", style: _txt },
                    { merge: "end", value: "", style: _txt },
                    { value: _sales[0].recogidas > 0 ? "-" + _sales[0].recogidas : "0", style: { ..._txt, alignment: _align_rigth } },
                ],
                [
                    { merge: "start", value: "Domicilios recogidas", style: _txt },
                    { merge: "end", value: "", style: _txt },
                    { value: _sales[0].v_domicilio_r > 0 ? "-" + _sales[0].v_domicilio_r : "0", style: { ..._txt, alignment: _align_rigth } },
                ],
                [
                    { merge: "start", value: "Domicilios entregas", style: _txt },
                    { merge: "end", value: "", style: _txt },
                    { value: _sales[0].v_domicilio > 0 ? "-" + _sales[0].v_domicilio : "0", style: { ..._txt, alignment: _align_rigth } },
                ],
                [
                    { merge: "start", value: "Abonos", style: _txt },
                    { merge: "end", value: "", style: _txt },
                    { value: "" + _sales[0].abonos, style: { ..._txt, alignment: _align_rigth } },
                ],
                [
                    { merge: "start", value: "Total", style: _txt_green },
                    { merge: "end", value: "", style: _txt },
                    { value: "" + _sales[0].subtotal, style: { ..._txt_green, alignment: _align_rigth } },
                ],
                [
                    null,
                    null,
                    null,
                ],
                [
                    { merge: "start", value: "Efectivo", style: _txt_blue },
                    { merge: "end", value: "", style: _txt_blue },
                    { value: "" + _sales[0].efectivo, style: { ..._txt_blue, alignment: _align_rigth } },
                ],
                [
                    { merge: "start", value: "Transferencias", style: _txt_blue },
                    { merge: "end", value: "", style: _txt_blue },
                    { value: "" + _sales[0].transferencia, style: { ..._txt_blue, alignment: _align_rigth } },
                ],
            ]);

        setResumenPago(_sales);
    }

    const getList = async () => {
        setShowLoading(true);

        if (userToClose && userToClose.fecha.length > 1) {
            let _data = await api.fetchJson({
                url: "get-history-cortes",
                data: {
                    task: "packages",
                    resumen_excel: true,
                    fecha: userToClose.fecha,
                    id_user: userToClose.id_user
                }
            });

            if (_data.response == 1) {
                let _lista = funciones.ordenaValoresExcel(_data.data);

                if (userToClose.nivel == 4) {
                    armaMensajero(_data.resumen[0]);
                }

                if (userToClose.nivel == 6) {
                    armaCliente(_data.resumen[0]);
                }

                setConteo(parseInt(_data.conteo));
                setDataList(_lista.lista);
            } else if (_data.response === -2) {
                Alert(_data.msg, "warning", () => navigate("/sign-in"));
            } else {
                Alert(_data.msg, "warning");
            }
        }

        setShowLoading(false);
    }

    const BotonDescargar = () => {
        let _btn = <button className="download-button _deshabilitado"><DownloadIcon />Descargar Excel</button>;

        if (showLoading) {
            _btn = <button className="download-button"><Icon.Loading />Cargando info...</button>;
        } else if (dataList.length > 0) {
            _btn = <button className="download-button"><DownloadIcon />Descargar Excel</button>;
        }

        return (
            <div className="cont_btns_caja" >
                {_btn}
            </div>)
    }

    const HojaDeCalculo = () => {
        let multiDataSet = [];

        switch (parseInt(data_user.nivel)) {
            case 1:
            case 2:
            case 3:
                multiDataSet = [
                    {
                        columns: [
                            { value: "Vendedor", style: _heads, width: 20 },
                            { value: "Código", style: _heads, width: 10 },
                            { value: "Estado", style: _heads, width: 11 },
                            { value: "Mensajero", style: _heads, width: 20 },
                            { value: "Valor producto", style: _heads, width: 7 },
                            { value: "Domicilio mensajero", style: _heads, width: 8 },
                            { value: "Domicilio cliente", style: _heads, width: 6 },
                            { value: "Subtotal mensajero", style: _heads, width: 8 },
                            { value: "Subtotal cliente", style: _heads },
                            { value: "Efectivo", style: _heads },
                            { value: "Bold", style: _heads },
                        ],
                        data: dataList.map(item => {
                            return [
                                { value: "" + item.consignee_name, style: _txt },
                                { value: "" + item.codigo, style: _txt },
                                { value: "" + funciones.unirEstados(item), style: _txt },
                                { value: "" + item.carrier_name, style: _txt },
                                { value: "" + item.price, style: _txt },
                                { value: "" + item.v_mensajero, style: _txt },
                                { value: "" + item.v_domicilio, style: _txt },
                                { value: "" + item.subtotal_mensajero, style: _txt },
                                { value: "" + item.subtotal, style: _txt },
                                { value: "" + item.efectivo, style: _txt },
                                { value: "" + item.bold, style: _txt },
                            ];
                        })
                    },
                    {
                        xSteps: 4,
                        ySteps: 3,
                        columns: [
                            { merge: "start", value: "Resumen del corte", style: { ..._resum, alignment: { horizontal: "center", vertical: "center" } } },
                            { merge: "pas", value: "" },
                            { merge: "end", value: "" },
                        ],
                        data: dataResum
                    }
                ];
                break;
            case 4:
                multiDataSet = [
                    {
                        columns: [
                            { value: "Vendedor", style: _heads, width: 20 },
                            { value: "Código", style: _heads, width: 10 },
                            { value: "Estado", style: _heads, width: 11 },
                            { value: "Valor producto", style: _heads, width: 7 },
                            { value: "Domicilio", style: _heads, width: 8 },
                            { value: "Subtotal", style: _heads },
                            { value: "Efectivo", style: _heads },
                            { value: "Bold", style: _heads },
                        ],
                        data: dataList.map(item => {
                            return [
                                { value: "" + item.consignee_name, style: _txt },
                                { value: "" + item.codigo, style: _txt },
                                { value: "" + funciones.unirEstados(item), style: _txt },
                                { value: "" + item.price, style: _txt },
                                { value: "" + item.v_mensajero, style: _txt },
                                { value: "" + item.subtotal_mensajero, style: _txt },
                                { value: "" + item.efectivo, style: _txt },
                                { value: "" + item.bold, style: _txt },
                            ];
                        })
                    },
                    {
                        xSteps: 4,
                        ySteps: 3,
                        columns: [
                            { merge: "start", value: "Resumen del corte", style: { ..._resum, alignment: { horizontal: "center", vertical: "center" } } },
                            { merge: "pas", value: "" },
                            { merge: "end", value: "" },
                        ],
                        data: dataResum
                    }
                ];
                break;
            case 6:
                multiDataSet = [
                    {
                        columns: [
                            { value: "Código", style: _heads, width: 10 },
                            { value: "Mensajero", style: _heads, width: 20 },
                            { value: "Estado", style: _heads, width: 11 },
                            { value: "Valor producto", style: _heads, width: 7 },
                            { value: "Domicilio", style: _heads },
                            { value: "Subtotal", style: _heads },
                            { value: "Efectivo", style: _heads },
                            { value: "Bold", style: _heads },
                        ],
                        data: dataList.map(item => {
                            return [
                                { value: "" + item.codigo, style: _txt },
                                { value: "" + item.carrier_name, style: _txt },
                                { value: "" + funciones.unirEstados(item), style: _txt },
                                { value: "" + item.price, style: _txt },
                                { value: "" + item.v_domicilio, style: _txt },
                                { value: "" + item.subtotal, style: _txt },
                                { value: "" + item.efectivo, style: _txt },
                                { value: "" + item.bold, style: _txt },
                            ];
                        })
                    },
                    {
                        xSteps: 4,
                        ySteps: 3,
                        columns: [
                            { merge: "start", value: "Resumen del corte", style: { ..._resum, alignment: { horizontal: "center", vertical: "center" } } },
                            { merge: "pas", value: "" },
                            { merge: "end", value: "" },
                        ],
                        data: dataResum
                    }
                ];
                break;
            case 8:
                multiDataSet = [
                    {
                        columns: [
                            { value: "Vendedor", style: _heads, width: 20 },
                            { value: "Código", style: _heads, width: 10 },
                            { value: "Estado", style: _heads, width: 11 },
                            { value: "Mensajero", style: _heads, width: 20 },
                            { value: "Valor producto", style: _heads, width: 7 },
                            { value: "Domicilio", style: _heads, width: 8 },
                            { value: "Subtotal", style: _heads },
                            { value: "Efectivo", style: _heads },
                            { value: "Bold", style: _heads },
                        ],
                        data: dataList.map(item => {
                            return [
                                { value: "" + item.consignee_name, style: _txt },
                                { value: "" + item.codigo, style: _txt },
                                { value: "" + funciones.unirEstados(item), style: _txt },
                                { value: "" + item.carrier_name, style: _txt },
                                { value: "" + item.price, style: _txt },
                                { value: "" + item.v_mensajero, style: _txt },
                                { value: "" + item.subtotal_mensajero, style: _txt },
                                { value: "" + item.efectivo, style: _txt },
                                { value: "" + item.bold, style: _txt },
                            ];
                        })
                    },
                    {
                        xSteps: 4,
                        ySteps: 3,
                        columns: [
                            { merge: "start", value: "Resumen del corte", style: { ..._resum, alignment: { horizontal: "center", vertical: "center" } } },
                            { merge: "pas", value: "" },
                            { merge: "end", value: "" },
                        ],
                        data: dataResum
                    }
                ];
                break;
            default:
                break;
        }

        return <ExcelFile filename={fileName} element={<BotonDescargar />}>
            <ExcelSheet dataSet={multiDataSet} name={userToClose.fecha.slice(0, 10)} />
        </ExcelFile>;
    }

    const ResumenVentas = () => {
        const [currentList, setCurrentList] = React.useState([]);
        const [showLazy, setShowLazy] = React.useState(true);
        const [contenido, setContenido] = React.useState([]);
        const limit = 50;


        const getListLazy = () => {
            let _current_length = 0;
            let _list = [];

            currentList.forEach(item => {
                _list.push(item);
            });

            dataList.forEach(item => {
                if (_current_length < limit) {
                    if (!_list.some(obj => obj.id === item.id)) {
                        _list.push(item);
                        _current_length++;
                    }
                }
            });

            setCurrentList(_list);
            setShowLazy(_current_length < limit ? false : true);
        }

        React.useEffect(() => {
            let _t_producto = 0;
            let _t_domicilio = 0;
            let _t_mensajero = 0;
            let _t_efectivo = 0;
            let _t_bold = 0;
            let _contenido = [];
            let _tiempo = "";


            if (userToClose) {
                dataList.map(item => {
                    _t_producto += parseInt(item.price || 0);
                    _t_mensajero += parseInt(item.v_mensajero || 0);
                    _t_domicilio += parseInt(item.v_domicilio || 0);
                    _t_efectivo += parseInt(item.efectivo || 0);
                    _t_bold += parseInt(item.bold || 0);
                });

                switch (parseInt(userToClose.nivel)) {
                    case 4:
                        _contenido = <div className="dashboard__sales-table">
                            {currentList.length < 1 ?
                                <h4>No hay paquetes en el historico</h4>
                                :
                                <table className="table">
                                    <tbody>

                                        <tr className="table_title">
                                            <th></th>
                                            <th>Estado</th>
                                            <th>Código</th>
                                            {!media_movil && <th>Fecha Creado</th>}
                                            {!media_movil && <th>Vendedor</th>}

                                            <th>Producto</th>
                                            <th>Domicilio</th>
                                            <th>Efectivo</th>
                                            <th>Bold</th>
                                        </tr>

                                        {currentList.map((item, index) => {
                                            let fecha_creado = new Date(item.date_create + "T05:00:00");

                                            return <tr key={index} className="_to_clic" onClick={resalta}>
                                                <td>{index + 1}</td>
                                                {funciones.getStateTd(item)}
                                                <td>{item.codigo}</td>
                                                {!media_movil && <td>{format(fecha_creado.getDate())}/{meses[fecha_creado.getMonth()]}/{fecha_creado.getFullYear()}</td>}
                                                {!media_movil && <td>{item.consignee_name}</td>}

                                                <td>{funciones.formatPrice(item.price).format}</td>
                                                <td>{item.v_mensajero ? funciones.formatPrice(item.v_mensajero).format : ""}</td>
                                                <td>{item.efectivo ? funciones.formatPrice(item.efectivo).format : ""}</td>
                                                <td>{item.bold ? funciones.formatPrice(item.bold).format : ""}</td>
                                            </tr>;
                                        })}

                                        <tr className="_no_margin">
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            {!media_movil && <td></td>}
                                            {!media_movil && <td></td>}
                                            <td><b>{funciones.formatPrice(_t_producto).format}</b></td>
                                            <td><b>{funciones.formatPrice(_t_mensajero).format}</b></td>
                                            <td><b>{funciones.formatPrice(_t_efectivo).format}</b></td>
                                            <td><b>{funciones.formatPrice(_t_bold).format}</b></td>
                                        </tr>
                                    </tbody>
                                </table>
                            }
                        </div>;
                        break;
                    case 6:
                        _contenido = <div className="dashboard__sales-table">
                            {currentList.length < 1 ?
                                <h4>No hay entregas {_tiempo}</h4>
                                :
                                <table className="table">
                                    <tbody>

                                        <tr className="table_title">
                                            <th></th>
                                            <th>Estado</th>
                                            <th>Código</th>
                                            {!media_movil && <th>Fecha Creado</th>}
                                            {!media_movil && <th>Mensajero</th>}

                                            <th>Producto</th>
                                            <th>Domicilio</th>
                                            <th>Efectivo</th>
                                            <th>Bold</th>
                                        </tr>

                                        {currentList.map((item, index) => {
                                            let fecha_creado = new Date(item.date_create + "T05:00:00");

                                            return <tr key={index} className="_to_clic" onClick={resalta}>
                                                <td>{index + 1}</td>
                                                {funciones.getStateTd(item)}
                                                <td>{item.codigo}</td>
                                                {!media_movil && <td>{format(fecha_creado.getDate())}/{meses[fecha_creado.getMonth()]}/{fecha_creado.getFullYear()}</td>}
                                                {!media_movil && <td>{item.carrier_name}</td>}

                                                <td>{funciones.formatPrice(item.price).format}</td>
                                                <td>{item.v_domicilio ? funciones.formatPrice(item.v_domicilio).format : ""}</td>
                                                <td>{item.efectivo ? funciones.formatPrice(item.efectivo).format : ""}</td>
                                                <td>{item.bold ? funciones.formatPrice(item.bold).format : ""}</td>
                                            </tr>;
                                        })}

                                        <tr className="_no_margin">
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            {!media_movil && <td></td>}
                                            {!media_movil && <td></td>}
                                            <td><b>{funciones.formatPrice(_t_producto).format}</b></td>
                                            <td><b>{funciones.formatPrice(_t_domicilio).format}</b></td>
                                            <td><b>{funciones.formatPrice(_t_efectivo).format}</b></td>
                                            <td><b>{funciones.formatPrice(_t_bold).format}</b></td>
                                        </tr>
                                    </tbody>
                                </table>
                            }
                        </div>;
                        break;
                    default:
                        _contenido = <></>;
                        break;
                }
            }

            setContenido(_contenido);
        }, [currentList]);

        React.useEffect(() => {
            let _list = [];

            dataList.forEach((item, index) => {
                if (index < limit) {
                    _list.push(item);
                }
            });

            setCurrentList(_list);
            setShowLazy(dataList.length < limit ? false : true);
        }, []);

        return <Col className="col_table">
            <Card style={{ overflow: "hidden" }}>
                <CardBody className="dashboard__card-widget">
                    <div className="card__title">
                        <h5 className="bold-text" style={{ color: "#009b3b" }}><Iconos.Pack className="dashboard__money-icon" /> {conteo} paquetes</h5>
                    </div>

                    <div className="panel__btns">

                        <button
                            className="panel__btn"
                            aria-label="panel__btn"
                            type="button"
                            onClick={handlerRefresh}>
                            <AutorenewIcon className={showLoading ? "on_refresh" : ""} />
                        </button>

                    </div>

                    {contenido}

                    {currentList.length > 0 && <>
                        {showLoading ?
                            <button className="btn_lazy_load"><Iconos.Loading /></button>
                            :
                            <>
                                {showLazy ?
                                    <button className="btn_lazy_load" onClick={() => getListLazy()}>Cargar más</button>
                                    :
                                    <h4 className="h4_lazy_load _margin_top">No hay más paquetes</h4>
                                }
                            </>
                        }
                    </>}
                </CardBody>
            </Card>
        </Col>;
    }

    React.useEffect(() => {
        getList();
    }, [userToClose]);

    return (
        <div className="content_reportes">
            {dataList.length > 0 ?
                <HojaDeCalculo />
                :
                <BotonDescargar />
            }

            <Resumen
                titulo="Total entregado"
                userToClose={userToClose}
                data={resumenPago}
                onRefresh={handlerRefresh} />

            {userToClose && <ResumenVentas />}
        </div>
    );
}

export default ReportsExcelHistory;