import * as React from "react";
import { ResponsiveContainer, BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip } from "recharts";
import AutorenewIcon from "mdi-react/AutorenewIcon";
import { Card, CardBody, Col } from "reactstrap";
import funciones from "services/funciones";
import * as Iconos from "images";
import { useMedia } from "hooks";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const Recogidas = (params) => {
    const { list, loading, onRefresh, userToClose } = params;
    const [refresh, setRefresh] = React.useState(false);
    const [totalPend, setTotalPend] = React.useState(0);
    const [valorPend, setValorPend] = React.useState(0);
    const [totalOk, setTotalOk] = React.useState(0);
    const [valorOk, setValorOk] = React.useState(0);
    const [data, setData] = React.useState([]);
    const { media_movil } = useMedia();


    const handlerRefresh = (e) => {
        e.preventDefault();

        setRefresh(true);
        onRefresh();
    }

    const CustomTooltip = ({ active, payload, label }) => {

        if (active && payload && payload.length > 0) {

            return (
                <>
                    {userToClose.nivel == 6 ?
                        <div className="custom_tooltip">
                            <p className="tooltip_title">Paquetes</p>
                            <p className="tooltip_label" style={{ color: payload[0].color }}> {`Por recoger: ${payload[0].value}`}</p>
                            <p className="tooltip_label" style={{ color: payload[1].color }}> {`Recogido: ${payload[1].value}`}</p>
                            {/* <p className="tooltip_label" style={{ color: payload[2].color }}> {`Por pagar: ${payload[2].value}`}</p> */}
                        </div>
                        :
                        <div className="custom_tooltip">
                            <p className="tooltip_title">Paquetes</p>
                            <p className="tooltip_label" style={{ color: payload[0].color }}> {`Pendiente ${payload[0].value}`}</p>
                            <p className="tooltip_label" style={{ color: payload[1].color }}> {`Recogido ${payload[1].value}`}</p>
                        </div>
                    }
                </>
            );
        }

        return null;
    }

    const armaVista = () => {
        let _total_pend = 0;
        let _valor_pend = 0;
        let _total_ok = 0;
        let _valor_ok = 0;
        let _lista = [{ por_recoger: 0, recogido: 0, otro: 0 }];

        list.map(item => {
            item.cantidad = parseInt(item.cantidad);

            switch (parseInt(item.estado)) {
                case 0:
                    _lista[0].por_recoger += item.cantidad;
                    _total_pend += item.cantidad;
                    _valor_pend += item.collect;
                    break;
                default:
                    _lista[0].recogido += item.cantidad;
                    _total_ok += item.cantidad;
                    _valor_ok += item.collect;

                    // _lista[0].otro += item.cantidad;
                    // _total_pag += item.cantidad;
                    // _valor_pag += item.collect;
                    break;
            }

        });

        if (list.length > 0) {
            setData(_lista);
        }

        setTotalPend(_total_pend);
        setValorPend(_valor_pend);
        setTotalOk(_total_ok);
        setValorOk(_valor_ok);
        // setTotalPag(_total_pag);
        // setValorPag(_valor_pag);
    }

    React.useEffect(() => {
        setRefresh(loading);
    }, [loading]);

    React.useEffect(() => {
        setTotalPend(0);
        setTotalOk(0);
        // setTotalPag(0);
        setData([]);

        if (userToClose) {
            armaVista();
        }
    }, [list, userToClose]);

    return (
        <Col md={12} lg={6} xs={12}>
            <Card>
                <CardBody className="dashboard__card-widget">
                    {data.length > 0 ?
                        <>
                            <div className="card__title">
                                <h5 className="bold-text"><Iconos.Pack className="dashboard__money-icon" />Recogida de paquetes</h5>

                                <p className="dashboard__total-text">
                                    <samp style={{ backgroundColor: "#172ba8" }} /> <i>{totalPend} por recoger = ${funciones.formatPrice(valorPend).format}</i>
                                    <br />
                                    <samp style={{ backgroundColor: "#faff00" }} /> <i>{totalOk} recogidos = ${funciones.formatPrice(valorOk).format}</i>
                                </p>
                            </div>

                            <div className="panel__btns">

                                <button
                                    className="panel__btn"
                                    aria-label="panel__btn"
                                    type="button"
                                    onClick={handlerRefresh}>
                                    <AutorenewIcon className={refresh ? "on_refresh" : ""} />
                                </button>

                            </div>

                            <div className="dashboard__total">

                                <div className="dashboard__chart-container">
                                    <ResponsiveContainer height={200}>
                                        <BarChart height={200} data={data}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="resum_name" />
                                            {!media_movil && <YAxis />}
                                            <Tooltip content={<CustomTooltip />} />

                                            <Bar dataKey="vacio" fill='#329600' />
                                            <Bar dataKey="por_recoger" fill='#172ba8' />
                                            <Bar dataKey="recogido" fill='#faff00' />
                                            <Bar dataKey="vacio" fill='#329600' />
                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="card__title">
                                <h5 className="bold-text"><Iconos.Pack className="dashboard__money-icon" />Recogida de paquetes</h5>
                            </div>

                            <div className="panel__btns">

                                <button
                                    className="panel__btn"
                                    aria-label="panel__btn"
                                    type="button"
                                    onClick={handlerRefresh}>
                                    <AutorenewIcon className={refresh ? "on_refresh" : ""} />
                                </button>

                            </div>

                            <h4>No hay paquetes por recoger</h4>
                        </>
                    }
                </CardBody>
            </Card>
        </Col>
    );
};

export default Recogidas;
