import * as React from 'react';
import AutorenewIcon from 'mdi-react/AutorenewIcon';
import { Card, CardBody, Col } from 'reactstrap';
import funciones from "services/funciones";
import * as Iconos from "images";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const Resumen = (params) => {
    const { loading, onRefresh, titulo, data, userToClose } = params;
    const [refresh, setRefresh] = React.useState(false);


    const handlerRefresh = (e) => {
        e.preventDefault();

        setRefresh(true);
        onRefresh(e);
    }

    React.useEffect(() => {
        setRefresh(loading);
    }, [loading]);

    return (
        <Col md={12} xs={12} className="col_static">
            <Card>
                <CardBody className="dashboard__card-widget">
                    <div className="card__title">
                        <h5 className="bold-text"><Iconos.CashBill className="dashboard__money-icon" />{titulo}</h5>
                    </div>

                    <div className="panel__btns">

                        <button
                            className="panel__btn"
                            aria-label="panel__btn"
                            type="button"
                            onClick={handlerRefresh}>
                            <AutorenewIcon className={refresh ? "on_refresh" : ""} />
                        </button>

                    </div>

                    <div className="dashboard__sales-report">
                        {data.map(item => (
                            <div key={item.id} className="dashboard__sales-container">
                                <p className="dashboard__sales-report-now">

                                    {userToClose.nivel == 4 && <>
                                        <span>Valor entregado:</span> <samp>$ {funciones.formatPrice(item.entregas).format}</samp>
                                        <samp className="line_h"></samp>
                                        {item.domicilio_ko > 0 && <>
                                            <span>KO.DOM.PAGO:</span> <samp>$ {funciones.formatPrice(item.domicilio_ko).format}</samp>
                                            <samp className="line_h"></samp>
                                        </>}
                                        {item.recogidas > 0 && <>
                                            <span>Pagos por recogidas:</span> <samp>$ -{funciones.formatPrice(item.recogidas).format}</samp>
                                            <samp className="line_h"></samp>
                                        </>}
                                        {item.v_mensajero_r > 0 && <>
                                            <span>Domicilios recogidas:</span> <samp>$ -{funciones.formatPrice(item.v_mensajero_r).format}</samp>
                                            <samp className="line_h"></samp>
                                        </>}
                                        <span>Domicilios entregas:</span> <samp>$ {item.v_mensajero > 0 ? "-" + funciones.formatPrice(item.v_mensajero).format : "0"}</samp>
                                        <samp className="line_h"></samp>
                                        {item.abonos > 0 && <>
                                            <span>Abonos:</span> <samp>$ -{funciones.formatPrice(item.abonos).format}</samp>
                                            <samp className="line_h"></samp>
                                        </>}
                                        <span>Total a entregar:</span> <samp>$ {funciones.formatPrice(item.subtotal).format}</samp>
                                        <samp className="line_h"></samp>
                                        <br />
                                        <span>Efectivo:</span> <samp>$ {funciones.formatPrice(item.efectivo).format}</samp>
                                        <samp className="line_h"></samp>
                                        <span>Transferencias:</span> <samp>$ {funciones.formatPrice(item.transferencia).format}</samp>
                                        <samp className="line_h"></samp>
                                    </>}

                                    {userToClose.nivel == 6 && <>
                                        <span>Valor entregas:</span> <samp>$ {funciones.formatPrice(item.entregas).format}</samp>
                                        <samp className="line_h"></samp>
                                        {item.domicilio_ko !== 0 && <>
                                            <span>KO.DOM.PAGO:</span> <samp>$ {funciones.formatPrice(item.domicilio_ko).format}</samp>
                                            <samp className="line_h"></samp>
                                        </>}
                                        {item.recogidas !== 0 && <>
                                            <span>Pagos por recogidas:</span> <samp>$ {funciones.formatPrice(item.recogidas).format}</samp>
                                            <samp className="line_h"></samp>
                                        </>}
                                        {item.v_domicilio_r !== 0 && <>
                                            <span>Domicilios recogidas:</span> <samp>$ {funciones.formatPrice(item.v_domicilio_r).format}</samp>
                                            <samp className="line_h"></samp>
                                        </>}
                                        <span>Domicilios entregas:</span> <samp>$ {funciones.formatPrice(item.v_domicilio).format}</samp>
                                        <samp className="line_h"></samp>
                                        {item.abonos > 0 && <>
                                            <span>Abonos:</span> <samp>$ {funciones.formatPrice(item.abonos).format}</samp>
                                            <samp className="line_h"></samp>
                                        </>}
                                        <span>Total a pagar:</span> <samp>$ {funciones.formatPrice(item.subtotal).format}</samp>
                                        <samp className="line_h"></samp>
                                        <br />
                                        <span>Efectivo:</span> <samp>$ {funciones.formatPrice(item.efectivo).format}</samp>
                                        <samp className="line_h"></samp>
                                        <span>Transferencias:</span> <samp>$ {funciones.formatPrice(item.transferencia).format}</samp>
                                        <samp className="line_h"></samp>
                                    </>}
                                </p>
                            </div>
                        ))}
                    </div>
                </CardBody>
            </Card>
        </Col >
    );
};

export default Resumen;
