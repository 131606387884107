import * as React from "react";
import { NewAgent, EditAgent } from "../../Usuarios/components/Agent";
import BarcodeScanIcon from "mdi-react/BarcodeScanIcon";
import CajaIcon from "mdi-react/CashRegisterIcon";
import PlusIcon from "mdi-react/PlusThickIcon";
import { Alert, Confirm } from "shared/Alert";
import DeleteIcon from "mdi-react/DeleteIcon";
import funciones from "services/funciones";
import EditIcon from "mdi-react/EditIcon";
import PackageList from "./PackageList";
import OrderList from "./OrderList";
import * as Iconos from "images";
import { useMedia } from "hooks";
import api from "services/api";
import log from "services/log";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const MenuRow = (params) => {
    const { navigate, data, blue, children, onSel, reload, current } = params;
    const [clases, setClases] = React.useState(blue == 0 ? "table_row bkg_blue" : "table_row");
    const [_pos, set_pos] = React.useState({ top: "0", left: "0" });
    const [modalView, setModalView] = React.useState(null);
    const [showMenu, setShowMenu] = React.useState(false);
    const [_e, set_e] = React.useState();
    const { media_movil } = useMedia();
    const menuRef = React.useRef();
    const row = React.useRef(null);


    const asignaPaquete = async (codigo) => {
        let _data = await api.fetchJson({
            url: "save-assign-package-code",
            data: {
                codigo: codigo,
                mensajero: data.id_user
            }
        });

        if (_data.response == 1) {
            Confirm(`El paquete ha sido asignado correctamente a "${data.nombre} ${data.apellido}" \n\n¿Quieres asignarle otro paquete?`, response => {
                if (response) {
                    callScanner();
                } else {
                    document.body.style.overflow = "auto";
                    reload();
                }
            });
        } else {
            Alert(_data.msg, "warning");
        }
    }

    const asignaRecogida = async (codigo) => {
        let toSend = codigo.split("-");
        let _data = await api.fetchJson({
            url: "save-assign-order-code",
            data: {
                mensajero: data.id_user,
                consignee: toSend[0],
                consecutive: toSend[1]
            }
        });

        if (_data.response == 1) {
            Confirm(`La recogida ha sido asignada correctamente a "${data.nombre} ${data.apellido}" \n\n¿Quieres asignarle otra recogida?`, response => {
                if (response) {
                    callScanner();
                } else {
                    document.body.style.overflow = "auto";
                    reload();
                }
            });
        } else {
            Alert(_data.msg, "warning");
        }
    }

    const asignaRecogidas = (e) => {
        e.preventDefault();
        e.stopPropagation();

        handleModal("pickup");
    }

    const asignaEntregas = (e) => {
        e.preventDefault();
        e.stopPropagation();

        handleModal("paquetes");
    }

    const callScanner = () => {
        if (typeof android !== "undefined") {

            // eslint-disable-next-line no-undef
            scanner.response = (codigo) => {
                if (codigo.substring(0, 3) === "OS-") {
                    log.d("Es una orden de servicio");
                    Alert("¡No se puede asignar una orden de servicio!", "warning");
                } else if (codigo.substring(0, 3) === "OR-") {
                    asignaRecogida(codigo.substring(3));
                } else {
                    asignaPaquete(codigo);
                }
            };

            // eslint-disable-next-line no-undef
            scanner.error = (format) => Alert(`Error de lectura: \nEl formato del código es "${format}" y no es compatible con esta función.`, "warning");

            // eslint-disable-next-line no-undef
            android.scannerQR();
        }
    }

    const handleScanner = (e) => {
        e.preventDefault();
        e.stopPropagation();

        callScanner();
        setShowMenu(false);
    }

    const goReport = (e) => {
        e.preventDefault()
        e.stopPropagation();

        document.body.style.overflow = "auto";
        navigate("/dashboard/reporte-mensajeros", { state: { nombre: data.nombre + " " + data.apellido } });
    }

    const goNew = (e) => {
        e.preventDefault()
        e.stopPropagation();

        handleModal("nuevo");
    }

    const goEdit = (e) => {
        e.preventDefault()
        e.stopPropagation();

        handleModal("editar");
    }

    // const goBlockUser = (e) => {
    //     hiddenMenu(e);

    //     Confirm(`¿Estás seguro de bloquear la cuenta del mensajero "${data.nombre} ${data.apellido}"?`, async (response) => {
    //         if (response) {
    //             let _data = await api.deleteUser({ id_user: data.id_user });

    //             if (_data.response == 1) {
    //                 document.body.style.overflow = "auto";
    //                 reload();
    //             } else if (_data.response === -2) {
    //                 Alert(_data.msg, "warning", () => {
    //                       document.body.style.overflow = "auto";
    //                       navigate("/sign-in");
    //                   });
    //             } else {
    //                 Alert(_data.msg, "warning");
    //             }
    //         }
    //     });
    // }

    const goDelete = (e) => {
        hiddenMenu(e);

        Confirm(`¿Estás seguro de borrar la cuenta del mensajero "${data.nombre} ${data.apellido}"?`, async (response) => {
            if (response) {
                let _data = await api.deleteUser({ id_user: data.id_user });

                if (_data.response == 1) {
                    document.body.style.overflow = "auto";
                    reload();
                } else if (_data.response === -2) {
                    Alert(_data.msg, "warning", () => {
                        document.body.style.overflow = "auto";
                        navigate("/sign-in");
                    });
                } else {
                    Alert(_data.msg, "warning");
                }
            }
        });
    }

    const onSelect = (e) => {
        e.preventDefault()
        e.stopPropagation();

        onSel(data);
    }

    const stopClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    const hiddenMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();

        document.body.style.overflow = "auto";
        setShowMenu(false);
    }

    const handleContextMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();

        set_e(e);
        document.body.style.overflow = "hidden";
        setShowMenu(true);
    }

    const handleModal = (_option) => {
        let _window;

        switch (_option) {
            case "pickup":
                _window = <OrderList
                    data={{
                        ...data,
                        parent: { ..._pos, width: 250 }
                    }}
                    close={() => setModalView(null)}
                    reload={reload} />;
                break;
            case "paquetes":
                _window = <PackageList
                    data={{
                        ...data,
                        parent: { ..._pos, width: 250 }
                    }}
                    close={() => setModalView(null)}
                    reload={reload} />;
                break;
            case "editar":
                _window = <EditAgent
                    data={data}
                    parent={{ ..._pos, width: media_movil ? (document.getElementById("root").offsetWidth - 16) : 500 }}
                    close={() => setModalView(null)}
                    reload={reload} />;
                break;
            case "nuevo":
                _window = <NewAgent
                    data={{ ...data, rol: 4 }}
                    parent={{ ..._pos, width: media_movil ? (document.getElementById("root").offsetWidth - 16) : 500 }}
                    close={() => setModalView(null)}
                    reload={reload} />;
                break;
            default:
                _window = null;
                break;
        }

        setShowMenu(false);
        setModalView(_window);
    }

    React.useEffect(() => {
        if (showMenu) {
            set_pos(funciones.getPositonDisplay(_e, menuRef.current));
        }
    }, [showMenu]);

    React.useEffect(() => {
        parseInt(data.sincorte) >= 1 && setClases(c => c + " bkg_orange");
        parseInt(data.devoluciones) >= 1 && setClases(c => c + " bkg_red");
    }, [data]);

    React.useEffect(() => {
        current === data.id_user && setClases(c => c + " bkg_select");
    }, [current]);

    return (
        <tr className={clases} ref={row}
            onDoubleClick={goReport}
            onClick={onSelect}
            onContextMenu={handleContextMenu}>

            {children}
            <td className="rowMenu" onClick={stopClick} onDoubleClick={stopClick} onContextMenu={stopClick}>
                {showMenu &&
                    <>
                        <div className="back_form _bkg_black" onClick={hiddenMenu} onContextMenu={hiddenMenu}></div>
                        <nav className="menu" style={{ ..._pos, width: media_movil ? 215 : 160 }} ref={menuRef}>
                            <ul>
                                <li onClick={goReport}><CajaIcon /> <span><strong>Ver en cortes</strong></span></li>
                                <li onClick={goNew}><PlusIcon /> <span>Crear Nuevo</span></li>
                                <li onClick={goEdit}><EditIcon /> <span>Editar</span></li>

                                <hr />
                                <li onClick={asignaRecogidas}><Iconos.BoxMarker /> <span>Asignar recogidas</span></li>
                                <li onClick={asignaEntregas}><Iconos.BoxUser /> <span>Asignar entregas</span></li>
                                {typeof android !== "undefined" && <li onClick={handleScanner}><BarcodeScanIcon /> <span>Asigna con escaner</span></li>}

                                <hr />
                                {/* <li onClick={goBlockUser}><Iconos.Block /> <span>Bloquear</span></li> */}
                                <li onClick={goDelete}><DeleteIcon /> <span>Borrar</span></li>
                            </ul>
                        </nav>
                    </>}

                {modalView}
            </td>
        </tr>
    )
}

export default MenuRow;