import * as React from "react";
import AccountGroupIcon from "mdi-react/AccountGroupIcon";
import ChatIcon from "mdi-react/ChatProcessingIcon";
import BarcodeIcon from "mdi-react/BarcodeIcon";
import ArchiveIcon from "mdi-react/ArchiveIcon";
import { TitleSticky } from "shared/Components";
import { useNavigate } from "react-router-dom";
import funciones from "services/funciones";
import { Alert } from "shared/Alert";
import { Api } from "services/api";
import * as Iconos from "images";
import { useMedia } from "hooks";

/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const Dashboard = () => {
    const { data_user, setDataUser, setTitulo } = useMedia();
    const navigate = useNavigate();
    const api = React.useRef(new Api({ navigate, setDataUser }));


    const getStatus = async () => {
        let _data = await api.current.getSession();

        if (_data.response == 1) {

        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
        } else {
            Alert(_data.msg, "warning");
        }
    }

    React.useEffect(() => {
        setTitulo("Best Transport Service");
        document.body.style.overflow = "auto";
        getStatus();
    }, []);

    return (
        <div className="comun">
            <TitleSticky>Inicio</TitleSticky>

            {[6, 61].includes(parseInt(data_user.nivel)) ?
                <>
                    <div className="option_up">

                        {funciones.validaPermisos("company_recogidas") &&
                            <div className="_btn_up" onClick={() => navigate("/dashboard/mis-recogidas")}>
                                <div className="icono"><Iconos.BoxMarker /></div>
                                <h4>Recogidas</h4>
                            </div>}

                        {funciones.validaPermisos("company_recolectas") &&
                            <div className="_btn_up" onClick={() => navigate("/dashboard/mis-recolectas")}>
                                <div className="icono"><Iconos.BoxColect /></div>
                                <h4>Recolectas</h4>
                            </div>}

                        {funciones.validaPermisos("company_entregas") &&
                            <div className="_btn_up" onClick={() => navigate("/dashboard/mis-entregas")}>
                                <div className="icono"><ArchiveIcon /></div>
                                <h4>Entregas</h4>
                            </div>}

                        {funciones.validaPermisos("company_dev_pendientes") &&
                            <div className="_btn_up" onClick={() => navigate("/dashboard/mis-devoluciones-pendientes")}>
                                <div className="icono"><Iconos.BoxReturnPending /></div>
                                <h4>Devoluciones Pendientes</h4>
                            </div>}

                        {funciones.validaPermisos("company_dev_entregadas") &&
                            <div className="_btn_up" onClick={() => navigate("/dashboard/mis-devoluciones-entregadas")}>
                                <div className="icono"><Iconos.BoxReturnOk /></div>
                                <h4>Devoluciones Entregadas</h4>
                            </div>}
                    </div>
                    <hr />
                    <div className="option_up _compleneto">

                        {funciones.validaPermisos("company_crear_recogidas") &&
                            <div className="_btn_up" onClick={() => navigate("/dashboard/tienda/nueva-recogida")}>
                                <div className="icono"><Iconos.BoxAddOutLine /></div>
                                <h4>Nueva Recogida</h4>
                            </div>}

                        {funciones.validaPermisos("company_gestion_paquete") &&
                            <div className="_btn_up" onClick={() => navigate("/dashboard/tienda/nuevo-paquete")}>
                                <div className="icono"><Iconos.BoxAdd /></div>
                                <h4>Nuevo <br />Paquete</h4>
                            </div>}

                    </div>
                    <div className="option_down">

                        {funciones.validaPermisos("company_users") &&
                            <div className="_btn_down" onClick={() => navigate("/dashboard/tienda/usuarios", { state: { rol: 61 } })}>
                                <div className="icono _large"><AccountGroupIcon /></div>
                                <h4>Usuarios</h4>
                            </div>}

                        {funciones.validaPermisos("company_reporte") &&
                            <div className="_btn_down" onClick={() => navigate("/dashboard/tienda/reportes")}>
                                <div className="icono"><Iconos.Caja /></div>
                                <h4>Caja y reportes</h4>
                            </div>}

                        {funciones.validaPermisos("company_gestiones") &&
                            <div className="_btn_down" onClick={() => navigate("/dashboard/chat")}>
                                <div className="icono"><ChatIcon /></div>
                                <h4>Gestiones</h4>
                            </div>}
                    </div>
                </>
                :
                <>
                    <div className="option_up">

                        {funciones.validaPermisos("pack_recogidas") && <div className="_btn_up" onClick={() => navigate("/dashboard/ordenes-de-recogida")}>
                            <div className="icono"><Iconos.BoxMarker /></div>
                            <h4>Recogidas</h4>
                        </div>}

                        {funciones.validaPermisos("recolectas_gestion") &&
                            <div className="_btn_up" onClick={() => navigate("/dashboard/todas-las-recolectas")}>
                                <div className="icono"><Iconos.BoxColect /></div>
                                <h4>Recolectas</h4>
                            </div>}

                        {funciones.validaPermisos("pack_edicion") && <div className="_btn_up" onClick={() => navigate("/dashboard/ordenes-de-recepcion")}>
                            <div className="icono"><Iconos.BoxReception /></div>
                            <h4>Recepción</h4>
                        </div>}

                        {funciones.validaPermisos("pack_entregas") && <div className="_btn_up" onClick={() => navigate("/dashboard/todos-los-paquetes")}>
                            <div className="icono"><ArchiveIcon /></div>
                            <h4>Entregas</h4>
                        </div>}
                    </div>
                    <hr />
                    <div className="option_up _compleneto">

                        {(funciones.validaPermisos("pack_recogidas") && funciones.validaPermisos("pack_edicion")) && <div className="_btn_up" onClick={() => navigate("/dashboard/nueva-orden-de-recogida")}>
                            <div className="icono"><Iconos.BoxAddOutLine /></div>
                            <h4>Nueva Recogida</h4>
                        </div>}

                        {funciones.validaPermisos("pack_edicion") && <div className="_btn_up" onClick={() => navigate("/dashboard/nueva-orden-de-servicio")}>
                            <div className="icono"><Iconos.BoxAdd /></div>
                            <h4>Nueva Recepción</h4>
                        </div>}

                    </div>
                    <div className="option_down">

                        {funciones.validaPermisos("user_cliente") &&
                            <div className="_btn_down" onClick={() => navigate("/dashboard/tiendas", { state: { rol: 6 } })}>
                                <div className="icono _large"><Iconos.Tienda /></div>
                                <h4>Tiendas</h4>
                            </div>}

                        {funciones.validaPermisos("user_mensajero") &&
                            <div className="_btn_down" onClick={() => navigate("/dashboard/mensajeros")}>
                                <div className="icono _large"><Iconos.DeliveryMan /></div>
                                <h4>Mensajeros</h4>
                            </div>}

                        {funciones.validaPermisos("reporte_cli") &&
                            <div className="_btn_down" onClick={() => navigate("/dashboard/reporte-clientes")}>
                                <div className="icono"><Iconos.ReporteCli /></div>
                                <h4>Reporte  <br />Clientes</h4>
                            </div>}

                        {funciones.validaPermisos("reporte_msj") &&
                            <div className="_btn_down" onClick={() => navigate("/dashboard/reporte-mensajeros")}>
                                <div className="icono"><Iconos.ReporteMsj /></div>
                                <h4>Reporte <br />Mensajeros</h4>
                            </div>}

                        {(funciones.validaPermisos("ver_mi_caja") && data_user.nivel == 4) &&
                            <div className="_btn_down" onClick={() => navigate("/dashboard/reporte-mi-caja")}>
                                <div className="icono"><Iconos.Caja /></div>
                                <h4>Mi Caja</h4>
                            </div>}

                        {funciones.validaPermisos("caja_cli") &&
                            <div className="_btn_down" onClick={() => {
                                if (funciones.validaPermisos("caja_cli_aux")) {
                                    navigate("/dashboard/caja-clientes-aux");
                                } else {
                                    navigate("/dashboard/caja-clientes");
                                }
                            }}>
                                <div className="icono"><Iconos.CajaCli /></div>
                                <h4>Caja Clientes</h4>
                            </div>}

                        {(funciones.validaPermisos("caja_msj_aux") ||
                            funciones.validaPermisos("caja_msj")) &&
                            <div className="_btn_down" onClick={() => {
                                if (funciones.validaPermisos("caja_msj_aux")) {
                                    navigate("/dashboard/caja-mensajeros-aux");
                                } else {
                                    navigate("/dashboard/caja-mensajeros");
                                }
                            }}>
                                <div className="icono"><Iconos.CajaMsj /></div>
                                <h4>Caja mensajeros</h4>
                            </div>}

                        {funciones.validaPermisos("caja_bodega") &&
                            <div className="_btn_down" onClick={() => navigate("/dashboard/caja-bodega")}>
                                <div className="icono"><Iconos.CajaBdg /></div>
                                <h4>Caja Bodega</h4>
                            </div>}

                        {(funciones.validaPermisos("chat") && [1, 2].includes(parseInt(data_user.nivel))) &&
                            <div className="_btn_down" onClick={() => navigate("/dashboard/chat")}>
                                <div className="icono"><ChatIcon /></div>
                                <h4>Chat</h4>
                            </div>}

                        {funciones.validaPermisos("genera_codigos") &&
                            <div className="_btn_down" onClick={() => navigate("/dashboard/generar-codigos")}>
                                <div className="icono _large"><BarcodeIcon /></div>
                                <h4>Generar Códigos</h4>
                            </div>}
                    </div>
                </>
            }
        </div>
    );
}

export default Dashboard;