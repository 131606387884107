import * as React from "react";
import { Alert, alerta, alertaRemove } from "shared/Alert";
import KeyVariantIcon from "mdi-react/KeyVariantIcon";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import EyeOffIcon from "mdi-react/EyeOffIcon";
import EmailIcon from "mdi-react/EmailIcon";
import EyeIcon from "mdi-react/EyeIcon";
import Loading from "shared/Loading";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint no-useless-escape: 0 */

const LogInForm = () => {
    const { t } = useTranslation('common');
    const [isPasswordShown, setIsPasswordShown] = React.useState(false);
    const [showLoading, setShowLoading] = React.useState(false);
    const [email, setEmail] = React.useState(null);
    const [pass, setPass] = React.useState(null);
    const navigate = useNavigate();
    const inputMail = React.useRef(null);
    const inputPass = React.useRef(null);


    const handleShowPassword = () => {
        setIsPasswordShown(!isPasswordShown);
    }

    const validateFormatEmail = () => {
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!regex.test(String(email.trim()).toLowerCase())) {
            alerta(inputMail.current, t("msg.mail_format_error"));

            return false;
        } else {
            return true;
        }
    }

    const validaMail = () => {
        alertaRemove(inputMail.current);

        if (email == null || email.length < 1) {
            alerta(inputMail.current, t("msg.user_require"));
            //inputMail.current.focus();
            return false;
        } else {
            return true;
        }
    }

    const validaPass = () => {
        alertaRemove(inputPass.current);

        if (pass == null || pass.length < 1) {
            alerta(inputPass.current, t("msg.pass_require"));
            //inputPass.current.focus();
            return false;
        } else if (pass.length < 8) {
            alerta(inputPass.current, "The password is too short");

            return false;
        } else {
            return true;
        }
    }

    const validate = async () => {
        if (validaMail() && validateFormatEmail() && validaPass()) {
            setShowLoading(true);
            let _data = await api.signIn(email.trim(), pass.trim());

            if (_data.response == "1") {
                let _info = JSON.parse(_data.data.info ?? "{}");
                if (_info.company?.redes) {
                    _info.company.redes = JSON.parse(_info.company.redes);
                }

                let _user = {
                    auth: _data.data.auth,
                    permisos: JSON.parse(_data.data.permisos),
                    info: _info,
                    nivel: _data.data.nivel,
                    nombre: _data.data.nombre + " " + _data.data.apellido,
                    time: _data.data.time,
                    token: _data.data.token,
                    nonce: _data.data.nonce,
                    version: _data.data.version,
                    validate: _data.data.validate,
                    zona: _data.data.zone_name
                };

                localStorage.setItem('usuario', JSON.stringify(_user));

                if (typeof android !== "undefined") {

                    // eslint-disable-next-line no-undef
                    android.saveDataLogin(JSON.stringify({ ..._user, correo: email, clave: pass }));
                }

                setTimeout(() => {
                    let _footer = document.getElementsByClassName("footer")[0];
                    _footer.style.display = "flex";
                    setShowLoading(false);
                    navigate("/dashboard");
                }, 2000);

            } else {
                let _msg = _data.data ? _data.data.msg : _data.msg;
                setShowLoading(false);
                Alert(_msg, "warning");
            }
        }
    }

    return (
        <form className="form">

            <div className="form__form-group">
                <span className="form__form-group-label">Correo electrónico</span>
                <div className="form__form-group-field input_radius">
                    <div className="form__form-group-icon">
                        <EmailIcon />
                    </div>
                    <div className="input">
                        <input ref={inputMail} type="text" placeholder="Correo electrónico" className="radius_right"
                            onChange={({ target: { value } }) => { alertaRemove(inputMail.current); setEmail(value) }} />
                    </div>
                </div>
            </div>

            <div className="form__form-group">
                <span className="form__form-group-label">Contraseña</span>
                <div className="form__form-group-field input_radius">
                    <div className="form__form-group-icon">
                        <KeyVariantIcon />
                    </div>
                    <button type="button" className="form__form-group-button" onClick={() => handleShowPassword()}>
                        {isPasswordShown ? <EyeOffIcon /> : <EyeIcon />}
                    </button>
                    <div className="input">
                        <input ref={inputPass} type={isPasswordShown ? 'text' : 'password'} placeholder="Contraseña" style={{ borderRadius: "0 8px 8px 0" }}
                            onChange={({ target: { value } }) => { alertaRemove(inputPass.current); setPass(value) }} />
                    </div>
                </div>
                {/* <div className="account__forgot-password"><Link to="/forgot-password">{t('form_login.forgot_password')}</Link></div> */}
            </div>

            <button type="button" className="btn btn-primary account__btn account__btn-login" onClick={validate}>{t('form_login.sign_in')}</button>

            {/* <Link className="btn btn-primary account__btn account__btn-login" to="/sign-sup">Registrate</Link> */}

            {showLoading && <Loading />}
        </form>
    );
}

export default LogInForm;
