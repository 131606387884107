import * as React from "react";
import CalendarMonthIcon from "mdi-react/CalendarMonthIcon";
import Information from "mdi-react/InformationOutlineIcon";
import DownloadIcon from "mdi-react/DownloadIcon";
import { useNavigate } from "react-router-dom";
import ReactExport from "react-export-excel";
import funciones from "services/funciones";
import DatePicker from "react-datepicker";
import { MovePopUp } from "shared/MovePopUp";
import * as Icon from "images";
import { Alert } from "shared/Alert";
import api from "services/api";

const rowData = ReactExport.rowData;
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

const _border = {
    top: { style: "thin", color: { rgb: "ff000000" } },
    bottom: { style: "thin", color: { rgb: "ff000000" } },
    left: { style: "thin", color: { rgb: "ff000000" } },
    right: { style: "thin", color: { rgb: "ff000000" } },
};

const _align_rigth = { horizontal: "right", vertical: "center", wrapText: false };
const _align_left = { horizontal: "left", vertical: "center", wrapText: false };

const _heads = {
    fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
    font: { sz: "12", bold: true, color: { rgb: "ffffffff" } },
    alignment: _align_left,
    border: _border
};

const _resum = {
    fill: { patternType: "solid", fgColor: { rgb: "ff6060ff" } },
    font: { sz: "12", bold: true, color: { rgb: "ffffffff" } },
    border: _border
};

const _txt = { font: { sz: "10.5" }, border: _border };

const _txt_green = {
    fill: { patternType: "solid", fgColor: { rgb: "ff00ff00" } },
    font: { sz: "10.5", color: { rgb: "ff000000" } },
    border: _border
};

const _txt_blue = {
    fill: { patternType: "solid", fgColor: { rgb: "ff4040ff" } },
    font: { sz: "10.5", color: { rgb: "ffffffff" } },
    border: _border
};

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

const InformeMensajeros = ({ parent, close }) => {
    const navigate = useNavigate();
    const [fechaCorte, setFechaCorte] = React.useState(new Date());
    const [showLoading, setShowLoading] = React.useState(false);
    const [fileName, setFileName] = React.useState("No name");
    const [dataResum, setDataResum] = React.useState([]);
    const [dataList, setDataList] = React.useState([]);
    const [error, setError] = React.useState("");

    const handleClose = () => {
        document.body.style.overflow = "auto";
        close(false);
    }

    const armaMensajero = (_resumen, _transfer) => {
        let _sales = {
            abonos: 0,
            entregas: 0,
            recogidas: 0,
            v_domicilio: 0,
            v_mensajero: 0,
            v_mensajero_r: 0,
            subtotal: 0,
            efectivo: 0,
            transferencia: 0,
            domicilio_ko: 0,
        };

        _resumen.map(_data => {

            _sales.abonos += parseInt(_data.valor_abono || 0);
            _sales.entregas += parseInt(_data.valor_subtotal || 0);
            _sales.recogidas += parseInt(_data.valor_recogidas || 0);
            _sales.v_domicilio += parseInt(_data.domicilios || 0);
            _sales.v_mensajero += parseInt(_data.domicilios || 0);
            _sales.v_mensajero_r += parseInt(_data.domicilios_recogidas || 0);
            _sales.subtotal += parseInt(_data.valor_total || 0);
            _sales.efectivo += parseInt(_data.efectivo || 0);
            _sales.transferencia += parseInt(_data.transferencia || 0);
            _sales.domicilio_ko += parseInt(_data.domicilios_ko || 0);

        });

        let _tabla_resumen = [
            [
                { value: "Valor entregas", style: _txt_green },
                { value: _sales.entregas, style: { ..._txt_green, alignment: _align_rigth } },
            ],
            [
                { value: "Pagos por recogidas", style: _txt },
                { value: _sales.recogidas > 0 ? -_sales.recogidas : 0, style: { ..._txt, alignment: _align_rigth } },
            ],
            [
                { value: "Domicilios recogidas", style: _txt },
                { value: _sales.v_mensajero_r > 0 ? -_sales.v_mensajero_r : 0, style: { ..._txt, alignment: _align_rigth } },
            ],
            [
                { value: "Domicilios entregas", style: _txt },
                { value: _sales.v_mensajero > 0 ? -_sales.v_mensajero : 0, style: { ..._txt, alignment: _align_rigth } },
            ],
            [
                { value: "Abonos mensajeros", style: _txt },
                { value: _sales.abonos, style: { ..._txt, alignment: _align_rigth } },
            ],
            [
                { value: "Total", style: _txt_green },
                { value: _sales.subtotal, style: { ..._txt_green, alignment: _align_rigth } },
            ],
            [
                null,
                null,
            ],
            [
                { value: "Efectivo", style: _txt_blue },
                { value: _sales.efectivo, style: { ..._txt_blue, alignment: _align_rigth } },
            ],
            [
                { value: "Transferencias", style: _txt_blue },
                { value: _sales.transferencia, style: { ..._txt_blue, alignment: _align_rigth } },
            ]
        ];

        let _entrada = 0;
        let _tabla_transterf = _transfer.map(trans => {
            trans.concepto = trans.concepto.replaceAll("\n", " ");

            if (trans.tipo === "1") {
                _entrada += parseInt(trans.monto);
                return [
                    { value: trans.gestor, style: _txt },
                    { value: trans.concepto, style: _txt },
                    { value: parseInt(trans.monto), style: _txt },
                ];
            }
        });
        _tabla_transterf.push([
            { merge: "start", value: "Total", style: _txt_green },
            { merge: "end", value: "" },
            { value: _entrada, style: _txt_green },
        ]);

        let _length = Math.max(_tabla_resumen.length, _tabla_transterf.length);
        let _rows = [];

        for (let i = 0; i < _length; i++) {
            _rows.push(rowData(_tabla_resumen[i] ?? [null, null]).next(_tabla_transterf[i], 1).insert);
        }

        setDataResum(_rows);
    }

    const getList = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "get-history-cortes",
            data: {
                task: "info_carrier",
                resumen_msj: true,
                caja: true,
                fecha: `${fechaCorte.getFullYear()}-${format(fechaCorte.getMonth() + 1)}-${format(fechaCorte.getDate())}`
            }
        });

        if (_data.response == 1) {
            let _lista = funciones.ordenaValoresExcel(_data.data);

            _data.recogidas.map(item => {
                let _inf_order = JSON.parse(item.info);
                _inf_order.recogida.valor_producto = parseInt(_inf_order.recogida.valor_producto || 0);
                _inf_order.recogida.valor_producto = _inf_order.recogida.valor_producto > 0 ? -_inf_order.recogida.valor_producto : _inf_order.recogida.valor_producto;

                _lista.lista.push(
                    {
                        estado: 100,
                        all_states: "100",
                        consignee_name: item.consignee_name,
                        codigo: "OR-" + item.consignee + "-" + item.consecutive,
                        carrier_name: item.carrier_name,
                        carrier: _inf_order.recogida.carrier,
                        price: _inf_order.recogida.valor_producto,
                        v_mensajero: parseInt(_inf_order.recogida.valor_mensajero || 0),
                        v_domicilio: parseInt(_inf_order.recogida.valor_domicilio || 0),
                        subtotal_mensajero: (_inf_order.recogida.valor_producto + -parseInt(_inf_order.recogida.valor_mensajero || 0)),
                        subtotal: (_inf_order.recogida.valor_producto + -parseInt(_inf_order.recogida.valor_domicilio || 0)),
                        efectivo: _inf_order.recogida.valor_producto,
                        bold: 0,
                    });
            });

            armaMensajero(_data.resumen, _data.caja);
            setDataList(_lista.lista);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            setError(_data.msg);
        }

        setShowLoading(false);
    }

    const BotonDescargar = () => {
        let _btn = <button className="download-button _marco _deshabilitado"><DownloadIcon />Descargar Excel</button>;

        if (showLoading) {
            _btn = <button className="download-button _marco"><Icon.Loading />Cargando info...</button>;
        } else if (dataList.length > 0) {
            _btn = <button className="download-button _marco"><DownloadIcon />Descargar Excel</button>;
        }

        return _btn;
    }

    const HojaDeCalculo = () => {

        const multiDataSet = [
            {
                columns: [
                    { value: "Vendedor", style: _heads, width: 20 },
                    { value: "Código", style: _heads, width: 10 },
                    { value: "Estado", style: _heads, width: 11 },
                    { value: "Mensajero", style: _heads, width: 20 },
                    { value: "Valor producto", style: _heads, width: 7 },
                    { value: "Domicilio mensajero", style: _heads, width: 8 },
                    { value: "Domicilio cliente", style: _heads, width: 6 },
                    { value: "Subtotal mensajero", style: _heads, width: 8 },
                    { value: "Subtotal cliente", style: _heads, width: 7 },
                    { value: "Efectivo", style: _heads, width: 8 },
                    { value: "Bold", style: _heads, width: 7 },
                ],
                data: dataList.map(item => {
                    return [
                        { value: item.consignee_name, style: _txt },
                        { value: item.codigo, style: _txt },
                        { value: funciones.unirEstados(item), style: _txt },
                        { value: item.carrier_name, style: _txt },
                        { value: item.price, style: _txt },
                        { value: item.v_mensajero, style: _txt },
                        { value: item.v_domicilio, style: _txt },
                        { value: item.subtotal_mensajero ?? "", style: _txt },
                        { value: item.subtotal ?? "", style: _txt },
                        { value: item.efectivo ?? "", style: _txt },
                        { value: item.bold ?? "", style: _txt },
                    ];
                })
            },
            {
                // xSteps: 3,
                ySteps: 3,
                columns: [
                    { merge: "start", value: "Resumen", style: { ..._resum, alignment: { horizontal: "center", vertical: "center" } } },
                    { merge: "end", value: "" },
                    null,
                    { merge: "start", value: "Transferencias ", style: { ..._resum, alignment: { horizontal: "center", vertical: "center" } } },
                    { merge: "pas", value: "" },
                    { merge: "end", value: "" },
                ],
                data: dataResum
            }
        ];

        return <ExcelFile filename={fileName} element={<BotonDescargar />}>
            <ExcelSheet dataSet={multiDataSet} name={`${meses[fechaCorte.getMonth()].slice(0, 3)} ${format(fechaCorte.getDate())} de ${fechaCorte.getFullYear()}`} />
        </ExcelFile>;
    }

    React.useEffect(() => {
        document.body.style.overflow = "hidden";
    }, []);

    React.useEffect(() => {
        setFileName(`Caja mensajeros ${format(fechaCorte.getDate())} de ${meses[fechaCorte.getMonth()]} del ${fechaCorte.getFullYear()}`);
        getList();
    }, [fechaCorte]);

    return (
        <MovePopUp
            parent={parent}
            close={handleClose}
            className="win_modal _overflow"
            title="Informe mensajeros">

            <div className="_in_column">

                <label className="__titulo">Fecha</label>
                <div className="calendar_in_modal">
                    <DatePicker dateFormat="yyyy/MM/dd" selected={fechaCorte} onChange={(date) => { setError(""); setFechaCorte(date); }} />
                    <CalendarMonthIcon />
                </div>

            </div>

            {error.length > 1 && <h4 className="_error"><Information />{error}</h4>}

            {dataList.length > 0 ?
                <HojaDeCalculo />
                :
                <BotonDescargar />
            }
        </MovePopUp>
    )
}

export default InformeMensajeros;