import * as React from "react";
import funciones from "services/funciones";
import UserIcon from "mdi-react/AccountTieIcon";


const SugerenciasChat = (params) => {
    const { userList, openChat } = params;


    const ItemUser = ({ user }) => {

        return (
            <div className="usuarios_chat" onClick={() => openChat(user)}>
                <UserIcon/>

                <div className="datos_chat">
                    <h4>{user.nombre} {user.apellido} <span>{funciones.getPerfil(user.nivel)}</span></h4>
                    <h5>{user.last_msg != null ? user.last_msg : user.imagen_last_msg != null ? "Imagen" : "..."}</h5>
                    <h6>{funciones.getTimeAgo(user.date_last_msg)}</h6>
                </div>

            </div>
        );
    }

    return (
        <div className="sugerencias_chat">
            {userList.map((user, index) => (<ItemUser key={index} user={user} />))}
        </div>
    );
}

export default SugerenciasChat;