import * as React from 'react';
import SignUpForm from './components/SignUpForm';


const SignUp = ({ history }) => {

  React.useEffect(() => {
    document.title = "Registro de usuario";
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="account">
      <div className="account_background">
        <div className="account__wrapper">

          <div className="account__card">

            <SignUpForm history={history} />

            <p>© 2021 - {new Date().getFullYear()} <b>Best Transport Service</b> todos los derechos reservados.</p>

          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;