import * as React from "react";
import { useReactToPrint } from "react-to-print";
import withRouter from "services/withRouter";
import funciones from "services/funciones";
import { ReactComponent as Logo } from "images/logo/logo.svg";
import BarCode from "shared/BarCode";
import { useMedia } from "hooks";
import * as Iconos from "images";


/* eslint eqeqeq: 0 */

const PrintLabel = (params) => {
    const { navigate, location } = params;
    const { setTitulo } = useMedia();
    const [company, setCompany] = React.useState({});
    const [redes, setRedes] = React.useState([]);
    const [data, setData] = React.useState({});
    const template_print = React.useRef();


    const printRotulo = useReactToPrint({
        content: () => template_print.current
    });

    React.useEffect(() => {
        document.body.style.overflow = "auto";

        if (location.state) {
            let _data = location.state.data;
            setTitulo("Rótulo de paquete " + _data.codigo);
            _data.extra = typeof _data.extra === "string" ? JSON.parse(_data.extra) : _data.extra;
            _data.info = typeof _data.info_tienda === "string" ? JSON.parse(_data.info_tienda) : _data.info_tienda;
            if (_data.info?.company?.redes) {
                _data.info.company.redes = typeof _data.info.company.redes === "string" ? JSON.parse(_data.info.company.redes) : _data.info.company.redes;
            }

            setData(_data);
            setCompany(_data.info?.company);
            setRedes(_data.info?.company?.redes ?? []);
        }
    }, [location.state]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div className="main_container rorulo_print" ref={template_print}>
                <div className="t_header in_column">
                    <div className="_fila_tienda">
                        <img className="logo_tienda"
                            src={company?.logo ?? "https://btsmensajeria.com/static/img/shop.png"}
                            style={{ filter: company?.logo ? "none" : "opacity(0.4)" }}
                            alt="logo de la tienda" />
                        <div className="_columna">
                            <h5><b className="title">{company?.nombre}</b></h5>
                            <h5>{company?.direccion} <br />{company?.localidad ?? ""} {company?.ciudad ?? ""}</h5>
                        </div>
                    </div>
                    <div className="_fila_redes">
                        {company?.telefono && <i><Iconos.Phone /> <span>{company?.telefono}</span></i>}
                        {redes.map((data, index) => {
                            let _icon;

                            switch (data.red) {
                                case "whatsapp":
                                    _icon = <Iconos.Whatsapp />;
                                    break;
                                case "facebook":
                                    _icon = <Iconos.Facebook />;
                                    break;
                                case "instagram":
                                    _icon = <Iconos.Instagram />;
                                    break;
                                case "youtube":
                                    _icon = <Iconos.Youtube />;
                                    break;
                                case "tiktok":
                                    _icon = <Iconos.Tiktok />;
                                    break;
                                default:
                                    _icon = <Iconos.Share />;
                                    break;
                            }

                            return <i key={index}>{_icon} <span>{data.hashtag}</span> </i>
                        })}
                    </div>
                    <div className="_columna _code_bar">
                        <svg className="logo_bts" width="100%" height="5.5mm" viewBox="0 0 390.16352 57.699938">
                            <g transform="matrix(1.3333333,0,0,-1.3333333,-36.212066,209.77943)" fill="#172940">
                                <g>
                                    <g>
                                        <g transform="translate(46.5269,135.5516)">
                                            <path d="M 0,0 83.635,-1.738 V 1.738 Z" />
                                        </g>
                                        <g transform="translate(36.1626,146.8992)">
                                            <path d="m 0,0 96.3,-1.865 v 3.73 z" />
                                        </g>
                                        <g transform="translate(57.5132,124.204)">
                                            <path d="M 0,0 69.982,-1.574 V 1.574 Z" />
                                        </g>
                                    </g>
                                    <g transform="translate(-295.61636)">
                                        <g transform="translate(596.0302,135.5516)">
                                            <path d="M 0,0 -83.635,-1.738 V 1.738 Z" />
                                        </g>
                                        <g transform="translate(606.3945,146.8992)">
                                            <path d="m 0,0 -96.3,-1.865 v 3.73 z" />
                                        </g>
                                        <g transform="translate(585.044,124.204)">
                                            <path d="M 0,0 -69.982,-1.574 V 1.574 Z" />
                                        </g>
                                    </g>
                                </g>
                                <g transform="matrix(0.83915857,0,0,-1,143.4955,124.0684)">
                                    <path
                                        d="m 1.5833797,0 q 0.3197209,-3.3494571 0.4262946,-5.2677826 0.152248,-2.6186664 0.2892713,-7.1861084 0.152248,-4.567441 0.152248,-6.440092 0,-0.669892 -0.015225,-1.887876 2.0248991,0.04567 3.2124339,0.04567 1.6595037,0 3.8671005,-0.0609 2.207597,-0.07612 3.790977,-0.07612 1.9031,0 2.710015,0.106574 1.065736,0.137023 1.857426,0.487194 0.806915,0.35017 1.339783,0.867813 0.593767,0.608993 0.928713,1.461582 0.38062,0.974387 0.38062,2.085798 0,0.867814 -0.228372,1.659504 -0.213147,0.79169 -0.624217,1.415907 -0.395845,0.608992 -0.898263,1.020062 -0.502419,0.411069 -0.989613,0.639441 -0.471969,0.228373 -1.278883,0.456745 0.806914,0.137023 1.294108,0.319721 0.487194,0.167472 1.035287,0.5633173 0.563318,0.3806201 1.004837,0.9896124 0.441519,0.6089922 0.654667,1.3702324 0.228372,0.7460155 0.228372,1.5833797 0,1.1570852 -0.38062,2.1771472 -0.380621,1.0200619 -1.065737,1.7508526 -0.578542,0.624217 -1.507255,1.06573631 -0.928714,0.44151935 -1.903101,0.60899221 -0.79169,0.12179844 -2.892713,0.18269766 Q 10.885736,0 8.5411157,0 L 4.5369919,-0.01522481 Z m 6.7902631,-12.682263 h 2.1619222 q 1.065736,0 1.507256,-0.04567 0.456744,-0.0609 0.883039,-0.197923 0.426294,-0.137023 0.73079,-0.456744 0.319721,-0.319721 0.44152,-0.715566 0.137023,-0.395844 0.137023,-0.791689 0,-0.563318 -0.274047,-1.004838 -0.258821,-0.441519 -0.852589,-0.654666 -0.593767,-0.213147 -1.811752,-0.213147 H 8.5563405 Z m -0.3501705,8.5106664 h 2.9231627 q 1.157085,0 1.705178,-0.1370232 0.669891,-0.1674729 1.020062,-0.4415194 0.35017,-0.2740465 0.548093,-0.7460154 0.213147,-0.471969 0.213147,-1.0505116 0,-0.5024186 -0.167473,-0.8830387 -0.167472,-0.3806201 -0.502418,-0.6394418 -0.334946,-0.2588217 -0.82214,-0.3653953 -0.487193,-0.1217985 -1.52248,-0.1217985 H 8.2061699 Z" />
                                    <path
                                        d="m 32.843884,0 q 0.76124,-7.3992553 0.913488,-16.290541 l -6.485767,0.167472 0.07612,-1.689953 q 0.0609,-1.355008 0.09135,-2.968837 4.323845,0.03045 10.078821,0.03045 l 3.760534,-0.01523 h 3.65395 l 1.97922,-0.01523 -0.0913,1.476806 q -0.0609,1.187535 -0.0914,1.964 -0.0152,0.776465 -0.0152,1.17231 -3.25811,-0.152248 -5.37435,-0.152248 h -1.06574 q -0.22837,2.69479 -0.35017,4.978511 -0.16747,3.2733333 -0.25882,6.3639687 -0.0914,3.0754107 -0.0914,4.9785113 -2.496873,-0.03044961 -3.227664,-0.03044961 -0.654666,0 -3.501705,0.03044961 z" />
                                    <path
                                        d="m 55.439,-0.31972091 q -0.19792,-3.24288349 -0.45674,-5.19165849 1.70518,0.7612402 3.02974,1.0657363 1.33978,0.2892713 2.34462,0.2892713 1.32455,0 2.10102,-0.3806201 0.79169,-0.395845 0.79169,-1.1570852 0,-0.2588217 -0.0761,-0.4871938 -0.0761,-0.2435969 -0.28927,-0.5328681 -0.21314,-0.3044962 -0.60899,-0.6546667 l -2.86226,-2.496868 q -1.00484,-0.8525893 -1.40068,-1.2332093 -0.80692,-0.776465 -1.26366,-1.446356 -0.44152,-0.669892 -0.65467,-1.355008 -0.19792,-0.700341 -0.19792,-1.476806 0,-0.928713 0.31972,-1.811752 0.33494,-0.898263 0.98961,-1.674729 0.65467,-0.776465 1.73563,-1.339782 1.08096,-0.563318 2.31417,-0.761241 1.23321,-0.213147 2.54254,-0.213147 2.57299,0 5.60273,0.593768 0.0457,1.050511 0.10657,1.93355 0.0609,0.867814 0.28927,3.060186 -1.40068,-0.563318 -2.55776,-0.776465 -1.15709,-0.228373 -1.99445,-0.228373 -1.18754,0 -1.97923,0.44152 -0.77646,0.441519 -0.77646,1.096186 0,0.304496 0.1218,0.593767 0.12179,0.289271 0.45674,0.685116 0.33495,0.380621 0.88304,0.852589 0.54809,0.456745 1.7204,1.370233 l 1.09619,0.867814 q 0.48719,0.395845 1.05051,0.9591625 0.56332,0.5633178 0.97439,1.1114108 0.41107,0.548093 0.62421,1.0048371 0.22838,0.4567442 0.33495,0.9287132 0.10657,0.4567441 0.10657,0.9896123 0,1.3702325 -0.65466,2.6186665 -0.65467,1.2332092 -1.85743,2.0401239 -1.20276,0.79168982 -2.64912,1.11141073 -1.43113,0.3197209 -3.02973,0.3197209 -2.46642,0 -6.22695,-0.71556584 z" />
                                </g>
                            </g>
                        </svg>
                        <BarCode code={data.codigo} />
                    </div>
                </div>
                <div className="_columna _margen _body" style={{ marginTop: -12 }}>
                    <p>Valor producto:  <b>${funciones.formatPrice(data.price).format}</b></p>

                    <h3>
                        {data?.extra?.volumen && <span className="marco">{data?.extra?.volumen}</span>} {data?.extra?.peso && <span className="marco">{data?.extra?.peso}</span>}
                        {(data?.extra?.volumen || data?.extra?.peso) && <br />}
                        {data?.descripcion ?? "Sin descripción"}</h3>
                </div>
                <div className="_fila_footer">
                    <div className="_columna">
                        <h5>Destinatario: <span>{data?.extra?.destinatario}</span></h5>
                        <h5>Teléfono: <span>{data?.extra?.telefono}</span></h5>
                        <h5>Dirección: <span>{data?.extra?.direccion}, {data?.extra?.localidad}, {data?.extra?.ciudad}</span></h5>
                    </div>
                    <Logo className="logo_bts" />
                </div>
            </div>

            <button className="btn btn-common btn_icon" onClick={printRotulo}
                style={{ display: "block", margin: "1rem auto" }}>
                <Iconos.PrinterOutline /> Imprimir Rótulo
            </button>

            {data.is_new && <>
                <button className="btn btn-common btn_icon" onClick={() => navigate("/dashboard/tienda/nuevo-paquete")}
                    style={{ display: "block", margin: "1rem auto" }}>
                    <Iconos.BoxAdd /> Nuevo Paquete
                </button>
            </>}

            {(data.is_edit || data.is_new) && <>
                <button className="btn btn-common btn_icon" onClick={() => navigate("/dashboard/mis-recolectas")}
                    style={{ display: "block", margin: "1rem auto" }}>
                    <Iconos.Lista /> Todas las recolectas
                </button>
            </>}
        </>
    )
}

export default withRouter(PrintLabel);