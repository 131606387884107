import * as React from 'react';
import { ResponsiveContainer, BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip } from "recharts";
import AutorenewIcon from 'mdi-react/AutorenewIcon';
import { Card, CardBody, Col } from 'reactstrap';
import * as Iconos from "images";
import { useMedia } from 'hooks';

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const Entregas = (params) => {
    const { list, loading, onRefresh, userToClose } = params;
    const [totalPend, setTotalPend] = React.useState(0);
    const [refresh, setRefresh] = React.useState(false);
    const [totalOk, setTotalOk] = React.useState(0);
    const [data, setData] = React.useState([]);
    const { media_movil } = useMedia();


    const handlerRefresh = (e) => {
        e.preventDefault();

        setRefresh(true);
        onRefresh();
    }

    const CustomTooltip = ({ active, payload }) => {

        if (active && payload && payload.length > 0) {
            // log.d("payload", payload);
            return (
                <div className="custom_tooltip">
                    <p className="tooltip_title">{payload[0].payload.carrier_name || "Sin asignar"}</p>
                    <p className="tooltip_label" style={{ color: payload[0].color }}> {`Por entregar: ${payload[0].value}`}</p>
                    <p className="tooltip_label" style={{ color: payload[1].color }}> {`Entregados: ${payload[1].value}`}</p>
                    <p className="tooltip_label" style={{ color: payload[2].color }}> {`KO.CANCELADO: ${payload[2].value}`}</p>
                    <p className="tooltip_label" style={{ color: payload[3].color }}> {`KO.DOM: ${payload[3].value}`}</p>
                    {/* <p className="tooltip_label" style={{ color: payload[4].color }}> {`KO.CF: ${payload[4].value}`}</p> */}
                    <p className="tooltip_label" style={{ color: payload[5].color }}> {`KO.REPROGRAMADO: ${payload[5].value}`}</p>
                    <p className="tooltip_label" style={{ color: payload[6].color }}> {`Otros: ${payload[6].value}`}</p>
                </div>
            );
        }

        return null;
    }

    const armaMensajero = () => {
        let _new_list = [];
        let _temp_list = [];
        let _total_pend = 0;
        let _total_ok = 0;

        list.map(item => {
            let _item = _new_list.find(item_list => item_list.carrier === item.carrier);

            if (_item) {
                // log.d(item.carrier + " Esta en la lista", item.estado);

                _new_list.map(item_list => {

                    if (item_list.carrier === item.carrier) {

                        switch (parseInt(item.estado)) {
                            case 5:
                                item_list.pendiente += item.counter;
                                _total_pend += item.counter;
                                break;
                            case 6:
                            case 9:
                            case 18:
                                item_list.ok += item.counter;
                                _total_ok += item.counter;
                                break;
                            case 7:
                                item_list.ko += item.counter;
                                break;
                            case 8:
                            case 12:
                                item_list.dom += item.counter;
                                break;
                            case 10:
                                item_list.cf += item.counter;
                                break;
                            case 13:
                                item_list.rep += item.counter;
                                break;
                            default:
                                item_list.otros += item.counter;
                                break
                        }
                    }
                });

            } else {
                // log.d(item.carrier + " No esta en la lista", item.estado);

                switch (parseInt(item.estado)) {
                    case 5:
                        item.pendiente = item.counter;
                        item.ok = 0;
                        item.ko = 0;
                        item.dom = 0;
                        item.cf = 0;
                        item.rep = 0;
                        item.otros = 0;
                        _total_pend += item.counter;
                        break;
                    case 6:
                    case 9:
                    case 18:
                        item.pendiente = 0;
                        item.ok = item.counter;
                        item.ko = 0;
                        item.dom = 0;
                        item.cf = 0;
                        item.rep = 0;
                        item.otros = 0;
                        _total_ok += item.counter;
                        break;
                    case 7:
                        item.pendiente = 0;
                        item.ok = 0;
                        item.ko = item.counter;
                        item.dom = 0;
                        item.cf = 0;
                        item.rep = 0;
                        item.otros = 0;
                        break;
                    case 8:
                    case 12:
                        item.pendiente = 0;
                        item.ok = 0;
                        item.ko = 0;
                        item.dom = item.counter;
                        item.cf = 0;
                        item.rep = 0;
                        item.otros = 0;
                        break;
                    case 10:
                        item.pendiente = 0;
                        item.ok = 0;
                        item.ko = 0;
                        item.dom = 0;
                        item.cf = item.counter;
                        item.rep = 0;
                        item.otros = 0;
                        break;
                    case 13:
                        item.pendiente = 0;
                        item.ok = 0;
                        item.ko = 0;
                        item.dom = 0;
                        item.cf = 0;
                        item.rep = item.counter;
                        item.otros = 0;
                        break;
                    default:
                        item.pendiente = 0;
                        item.ok = 0;
                        item.ko = 0;
                        item.dom = 0;
                        item.cf = 0;
                        item.rep = 0;
                        item.otros = item.counter;
                        break
                }

                _new_list.push(item);
            }
        });

        if (_new_list.length < 1) {
            _temp_list = [];
        } else {

            for (let i = 0; i < _new_list.length; i++) {
                _temp_list.push(_new_list[i]);
            }
        }

        setTotalPend(_total_pend);
        setTotalOk(_total_ok);
        setData(_temp_list);
    }

    const armaCliente = () => {
        let _new_list = [];
        let _temp_list = [];
        let _total_pend = 0;
        let _total_ok = 0;

        list.map(item => {

            if (_new_list.length > 0) {

                _new_list.map(item_list => {

                    switch (parseInt(item.estado)) {
                        case 5:
                            item_list.pendiente += item.counter;
                            _total_pend += item.counter;
                            break;
                        case 6:
                        case 9:
                            item_list.ok += item.counter;
                            _total_ok += item.counter;
                            break;
                        case 7:
                            item_list.ko += item.counter;
                            break;
                        case 8:
                        case 12:
                            item_list.dom += item.counter;
                            break;
                        case 10:
                            item_list.cf += item.counter;
                            break;
                        case 13:
                            item_list.rep += item.counter;
                            break;
                        default:
                            item_list.otros += item.counter;
                            break
                    }
                });

            } else {

                switch (parseInt(item.estado)) {
                    case 5:
                        item.pendiente = item.counter;
                        item.ok = 0;
                        item.ko = 0;
                        item.dom = 0;
                        item.cf = 0;
                        item.rep = 0;
                        item.otros = 0;
                        _total_pend += item.counter;
                        break;
                    case 6:
                    case 9:
                        item.pendiente = 0;
                        item.ok = item.counter;
                        item.ko = 0;
                        item.dom = 0;
                        item.cf = 0;
                        item.rep = 0;
                        item.otros = 0;
                        _total_ok += item.counter;
                        break;
                    case 7:
                        item.pendiente = 0;
                        item.ok = 0;
                        item.ko = item.counter;
                        item.dom = 0;
                        item.cf = 0;
                        item.rep = 0;
                        item.otros = 0;
                        break;
                    case 8:
                    case 12:
                        item.pendiente = 0;
                        item.ok = 0;
                        item.ko = 0;
                        item.dom = item.counter;
                        item.cf = 0;
                        item.rep = 0;
                        item.otros = 0;
                        break;
                    case 10:
                        item.pendiente = 0;
                        item.ok = 0;
                        item.ko = 0;
                        item.dom = 0;
                        item.cf = item.counter;
                        item.rep = 0;
                        item.otros = 0;
                        break;
                    case 13:
                        item.pendiente = 0;
                        item.ok = 0;
                        item.ko = 0;
                        item.dom = 0;
                        item.cf = 0;
                        item.rep = item.counter;
                        item.otros = 0;
                        break;
                    default:
                        item.pendiente = 0;
                        item.ok = 0;
                        item.ko = 0;
                        item.dom = 0;
                        item.cf = 0;
                        item.rep = 0;
                        item.otros = item.counter;
                        break
                }

                _new_list.push(item);
            }
        });

        if (_new_list.length < 1) {
            _temp_list = [];
        } else {

            for (let i = 0; i < _new_list.length; i++) {
                _temp_list.push(_new_list[i]);
            }
        }

        setTotalPend(_total_pend);
        setTotalOk(_total_ok);
        setData(_temp_list);
    }

    React.useEffect(() => {
        setRefresh(loading);
    }, [loading]);

    React.useEffect(() => {
        setTotalPend(0);
        setTotalOk(0);
        setData([]);

        if (userToClose && userToClose.nivel == 4) {
            armaMensajero();
        }

        if (userToClose && userToClose.nivel == 6) {
            armaCliente();
        }
    }, [list, userToClose]);

    return (
        <Col md={12} lg={6} xs={12}>
            <Card>
                <CardBody className="dashboard__card-widget">
                    {data.length > 0 ?
                        <>
                            <div className="card__title">
                                <h5 className="bold-text"><Iconos.PackOk className="dashboard__money-icon" />Entregas</h5>

                                <p className="dashboard__total-text">
                                    <samp style={{ backgroundColor: "#fadc3c" }} /> <i>{totalPend} paquetes por entregar</i>
                                    <br />
                                    <samp style={{ backgroundColor: "#329600" }} /> <i>{totalOk} paquetes entregados</i>
                                </p>
                            </div>

                            <div className="panel__btns">

                                <button
                                    className="panel__btn"
                                    aria-label="panel__btn"
                                    type="button"
                                    onClick={handlerRefresh}>
                                    <AutorenewIcon className={refresh ? "on_refresh" : ""} />
                                </button>

                            </div>

                            <div className="dashboard__total">

                                <div className="dashboard__chart-container">
                                    <ResponsiveContainer height={200}>
                                        <BarChart height={200} data={data}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="resum_name" />
                                            {!media_movil && <YAxis />}
                                            <Tooltip content={<CustomTooltip />} />

                                            <Bar dataKey="pendiente" fill="#fadc3c" />
                                            <Bar dataKey="ok" fill="#329600" />
                                            <Bar dataKey="ko" fill="#f5320d" />
                                            <Bar dataKey="dom" fill="#f53c0d" />
                                            <Bar dataKey="cf" fill="#f5460d" />
                                            <Bar dataKey="rep" fill="#f55a0d" />
                                            <Bar dataKey="otros" fill="#145a64" />

                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="card__title">
                                <h5 className="bold-text"><Iconos.PackOk className="dashboard__money-icon" />Entregas</h5>
                            </div>

                            <div className="panel__btns">

                                <button
                                    className="panel__btn"
                                    aria-label="panel__btn"
                                    type="button"
                                    onClick={handlerRefresh}>
                                    <AutorenewIcon className={refresh ? "on_refresh" : ""} />
                                </button>

                            </div>

                            <h4>No hay entregas</h4>
                        </>
                    }
                </CardBody>
            </Card>
        </Col>
    );
};

export default Entregas;
