import * as React from "react";
import ListPaquagesClients from "./components/ListPaquagesClients";
import { RequirePermissions } from "shared/AlertMessages";
import CierreCaja from "./components/CierreCaja";
import { TitleSticky } from "shared/Components";
import Recogidas from "./components/Recogidas";
import { Alert, Confirm } from "shared/Alert";
import { SearchInReport } from "shared/Input";
import Entregas from "./components/Entregas";
import withRouter from "services/withRouter";
import { Container, Row } from "reactstrap";
import funciones from "services/funciones";
import Resumen from "./components/Resumen";
import Cortes from "./components/Cortes";
import { useMedia } from "hooks";
import * as Icon from "images";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint no-extend-native: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

Date.prototype.getWeekNumber = function () {
    var d = new Date(+this);
    d.setHours(0, 0, 0, 0);
    d.setDate(d.getDate() + 4 - (d.getDay() || 7));

    return Math.ceil((((d - new Date(d.getFullYear(), 0, 1)) / 8.64e7) + 1) / 7);
}

const ReportClient = (router) => {
    const { navigate, location } = router;
    const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7 };
    const [listCountDelivery, setListCountDelivery] = React.useState([]);
    const [showLoading, setShowLoading] = React.useState(true);
    const [listPickups, setListPickups] = React.useState([]);
    const [resumenPago, setResumenPago] = React.useState([]);
    const [paraExcel, setParaExcel] = React.useState([]);
    const [devoluciones, setDevoluciones] = React.useState([]);
    const [userList, setUserList] = React.useState([]);
    const [userNameList, setUserNameList] = React.useState([]);
    const [userToClose, setUserToClose] = React.useState();
    const [modalView, setModalView] = React.useState(null);
    const [criterio, setCriterio] = React.useState("");
    const [conteo, setConteo] = React.useState(0);
    const { setTitulo } = useMedia();


    const handleModal = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setModalView(
            <CierreCaja
                reload={getSales}
                close={() => setModalView(null)}
                data={{ ...userToClose, ...resumenPago[0] }}
                parent={{ ...e.target.getBoundingClientRect().toJSON(), width: 260 }} />);
    }

    const handleSelectUser = (_nombre) => {
        setCriterio(_nombre);

        let _item = userList.filter((item) => item.nombre + " " + item.apellido === _nombre);

        if (_item.length >= 1) {
            if (_item[0].id_user === "all") {
                Confirm("Ten en cuenta que la consulta de 'TODOS' los registros puede ser muy pesada y tardar mucho tiempo. \n\n¿Desea continuar con la consulta?", continuar => {
                    if (continuar) {
                        setUserToClose(_item[0]);
                    } else {
                        setCriterio(userToClose.nombre + " " + userToClose.apellido);
                    }
                });
            } else {
                setUserToClose(_item[0]);
            }
        }
    }

    const handlePasedUser = (_lista_usuarios, _nombre) => {
        setCriterio(_nombre);

        let _item = _lista_usuarios.filter((item) => item.nombre + " " + item.apellido === _nombre);

        if (_item.length >= 1) {
            setUserToClose(_item[0]);
        }
    }

    const getUserList = async () => {
        setShowLoading(true);

        let _users = [];
        let _all_users = [];
        let _data = await api.fetchJson({
            url: "get-users",
            data: {
                roll: "users",
                nivel: "6"
            }
        });

        if (_data.response == 1) {
            _data.data.map(item => {
                _users.push(item.nombre + " " + item.apellido);
                _all_users.push(item);
            });

            setUserList(_all_users);
            setUserNameList(_users);

            if (location.state && location.state.nombre) {
                handlePasedUser(_data.data, location.state.nombre);
            } else {
                setShowLoading(false);
            }
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
            setShowLoading(false);
        } else {
            Alert(_data.msg, "warning");
            setShowLoading(false);
        }

        return _users;
    }

    const armaCliente = (_resumen) => {
        // 304 378 3429
        let _sales = [
            {
                id: 0,
                title: 'Totales',
                abonos: _resumen.abonos,
                entregas: _resumen.valor_entregado,
                recogidas: _resumen.pagos_recogidas,
                v_domicilio: _resumen.domicilios_entregas,
                v_domicilio_r: _resumen.domicilios_recogidas,
                subtotal: _resumen.total,
                efectivo: _resumen.efectivo,
                transferencia: _resumen.transferencia,
                domicilio_ko: _resumen.ko_dom_pago,
            },
        ];

        setResumenPago(_sales);
    }

    const getSales = async () => {
        if (userToClose && userToClose.id_user) {
            setShowLoading(true);
            let _obj = {
                nivel: "user"
            };

            switch (parseInt(data_user.nivel)) {
                case 1:
                case 2:
                case 3:
                    _obj = {
                        nivel: "admin",
                        user_close: userToClose ? userToClose.id_user : "",
                        nivel_close: userToClose ? userToClose.nivel : ""
                    };
                    break;
                case 4:
                    _obj = {
                        nivel: "carrier"
                    };
                    break;
                case 8:
                    _obj = {
                        nivel: "recepcion",
                        user_close: userToClose ? userToClose.id_user : "",
                        nivel_close: userToClose ? userToClose.nivel : ""
                    };
                    break;
                case 6:
                    _obj = {
                        nivel: "cliente"
                    };
                    break;
            }

            let _data = await api.fetchJson({
                url: "cuentas-cliente",
                data: {
                    ..._obj,
                    resumen: true,
                    entregas: true,
                    recogidas: true,
                    devoluciones: true
                }
            });

            if (_data.response == 1) {
                setDevoluciones(_data.devoluciones);
                setParaExcel(JSON.parse(_data.data));
                setConteo(parseInt(_data.conteo));

                if (userToClose.nivel == 6) {
                    armaCliente(_data.resumen);
                }

                if (_data.entregas?.length > 0) {
                    let _delivery = _data.entregas.map(item => {
                        item.counter = parseInt(item.counter) || 0;
                        return item;
                    });

                    setListCountDelivery(_delivery);
                }

                if (_data.recogidas?.length > 0) {
                    _data.recogidas.map(item => {
                        item.collect = parseInt(item.collect_product) || 0;
                        return item;
                    });

                    setListPickups(_data.recogidas);
                }

            } else if (_data.response === -2) {
                Alert(_data.msg, "warning", () => {
                    document.body.style.overflow = "auto";
                    navigate("/sign-in");
                });
            } else {
                Alert(_data.msg, "warning");
            }
        }

        setShowLoading(false);
    }

    const BtnClose = () => {
        let _btn = <button className="close-button _deshabilitado"><Icon.CajaCli />Corte de caja</button>;

        if (userToClose) {

            if (showLoading) {
                _btn = <button className="close-button"><Icon.Loading />Cargando...</button>;
            } else {
                _btn = <button className="close-button" onClick={handleModal}><Icon.CajaCli />Corte de caja</button>;
            }

        }

        return (
            <div className="cont_btns_caja">
                {_btn}
            </div>)
    }

    React.useEffect(() => {
        setTitulo("Reporte de cliente");
        document.body.style.overflow = "auto";
        if (funciones.validaPermisos("reporte_cli")) {
            getUserList();
        }
    }, []);

    React.useEffect(() => {
        if (funciones.validaPermisos("reporte_cli")) {
            getSales();
        }
    }, [userToClose]);

    return (
        <RequirePermissions navigate={navigate}
            permission={funciones.validaPermisos("reporte_cli")}>

            <TitleSticky className="_in_page">Reporte de cliente <i>{(userToClose?.nombre ? "\"" + userToClose?.nombre + " " + userToClose?.apellido + "\"" : "").toLowerCase()}</i></TitleSticky>

            <Container className="dashboard">
                <Row>
                    <SearchInReport
                        name="Buscar"
                        placeholder="Buscar cliente"
                        value={criterio}
                        list={userNameList}
                        onChange={handleSelectUser} />

                    <ListPaquagesClients
                        userToClose={userToClose}
                        sales={paraExcel}
                        conteo={conteo}
                        devoluciones={devoluciones}
                        loading={showLoading}
                        onRefresh={() => getSales()}>
                        {([1, 2].includes(parseInt(data_user.nivel)) ||
                            funciones.validaPermisos("caja_cli_aux") ||
                            funciones.validaPermisos("caja_cli_principal")) && <BtnClose />}

                        {userToClose && <Resumen
                            titulo="Total entrega"
                            userToClose={userToClose}
                            data={resumenPago}
                            loading={showLoading}
                            onRefresh={() => getSales()} />}

                    </ListPaquagesClients>
                </Row>

                <Row>

                    <Entregas
                        userToClose={userToClose}
                        list={listCountDelivery}
                        loading={showLoading}
                        onRefresh={() => getSales()} />

                    <Recogidas
                        userToClose={userToClose}
                        list={listPickups}
                        loading={showLoading}
                        onRefresh={() => getSales()} />

                </Row>

                <Row>
                    {(userToClose && userToClose.id_user !== "all") && <Cortes data_user={userToClose} sales={resumenPago} />}
                </Row>

                {modalView}
            </Container>
        </RequirePermissions>
    )
}

export default withRouter(ReportClient);
