import * as React from "react";
import { Container, Row, Card, CardBody, Col } from "reactstrap";
import ChartTimelineIcon from "mdi-react/ChartTimelineIcon";
import { RequirePermissions } from "shared/AlertMessages";
import SwapVerticalIcon from "mdi-react/SwapVerticalIcon";
import AutorenewIcon from "mdi-react/AutorenewIcon";
import Resumen from "../Reports/components/Resumen";
import MenuCliente from "./components/MenuCliente";
import { TitleSticky } from "shared/Components";
import withRouter from "services/withRouter";
import funciones from "services/funciones";
import { Alert } from "shared/Alert";
import { Api } from "services/api";
import { useMedia } from "hooks";
import * as Icon from "images";

/* eslint eqeqeq: 0 */

const TiendaPerfil = (router) => {
    const { navigate, location } = router;
    const { media_movil, data_user, setDataUser, setTitulo } = useMedia();
    const api = React.useRef(new Api({ navigate, setDataUser }));
    const [loadResult, setLoadResult] = React.useState("Cargando usuarios...");
    const [selected, setSelected] = React.useState({ id_user: 0 });
    const [showLoading, setShowLoading] = React.useState(true);
    const [resumenPago, setResumenPago] = React.useState([]);
    const [showLazy, setShowLazy] = React.useState(true);
    const [dataList, setDataList] = React.useState([]);
    const [asc, setAsc] = React.useState(false);
    const [data, setData] = React.useState({});


    const RowMenu = ({ data, blue }) => {
        return (
            <MenuCliente data={{ ...data, rol: data.nivel }} blue={blue}
                navigate={navigate}
                current={selected.id_user}
                onSel={setSelected}
                reload={() => getList()}>

                <td>{data.nombre} {data.apellido}</td>
                <td>{data.correo}</td>
                <td>{data.telefono}</td>
            </MenuCliente>
        );
    }

    const AllRow = () => {
        let _data = dataList.map((item, index) => <RowMenu key={item.id_user} blue={index % 2 === 0} data={item} />);

        if (_data.length < 1) {
            return <h4 className="_titulo">{loadResult}</h4>;
        } else {
            return <>
                <div style={{ overflowX: "scroll", padding: media_movil ? "0 0.4rem" : "0" }}>
                    <table className="table">
                        <tbody>

                            <tr className="table_title">
                                <th><SwapVerticalIcon onClick={() => sortBy("nombre")} />Nombre y apellido</th>
                                <th>Correo</th>
                                <th>Teléfono</th>
                            </tr>

                            {_data}
                        </tbody>
                    </table>
                </div>

                {showLoading ?
                    <button className="btn_lazy_load"><Icon.Loading /></button>
                    :
                    <>
                        {showLazy ?
                            <button className="btn_lazy_load" onClick={() => getListLazy()}>Cargar más</button>
                            :
                            <h4 className="h4_lazy_load">{loadResult}</h4>
                        }
                    </>
                }
            </>;
        }
    }

    const armaCliente = (_resumen) => {
        let _sales = [
            {
                id: 0,
                title: 'Totales',
                abonos: _resumen.abonos,
                entregas: _resumen.valor_entregado,
                recogidas: _resumen.pagos_recogidas,
                v_domicilio: _resumen.domicilios_entregas,
                v_domicilio_r: _resumen.domicilios_recogidas,
                subtotal: _resumen.total,
                efectivo: _resumen.efectivo,
                transferencia: _resumen.transferencia,
                domicilio_ko: _resumen.ko_dom_pago,
            },
        ];

        setResumenPago(_sales);
    }

    const sortBy = (_key) => {

        let mapped = dataList.map((item, i) => {
            return { index: i, value: item[_key].toLowerCase() };
        });

        mapped.sort(function (a, b) {
            if (asc) {
                if (a.value > b.value) {
                    return 1;
                }

                if (a.value < b.value) {
                    return -1;
                }
            } else {
                if (a.value > b.value) {
                    return -1;
                }

                if (a.value < b.value) {
                    return 1;
                }
            }

            return 0;
        });

        let _new = mapped.map(item => {
            return dataList[item.index];
        });

        setAsc(!asc);
        setDataList(_new);
    }

    const getListLazy = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "get-users",
            data: {
                roll: "users_tienda",
                nivel: 61,
                criterio: "",
                tienda: data_user.info.company.id,
                limit: 20,
                offset: dataList.length
            }
        });

        if (_data.response == 1) {
            let _packs = [];

            dataList.forEach(item => {
                _packs.push(item);
            });

            _data.data.forEach(item => {
                _packs.push(item);
            });

            setDataList(_packs);
            setShowLazy(_data.data.length < 20 ? false : true);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setLoadResult((!_data?.data || _data?.data.length < 1) ? "¡No hay usuarios!" : "¡No hay más usuarios!");
        setShowLoading(false);
    }

    const getList = async () => {
        setLoadResult("Cargando usuarios...");
        setShowLoading(true);
        setDataList([]);
        let _data = await api.current.fetchJson({
            url: "get-users",
            data: {
                roll: "users_tienda",
                nivel: 61,
                criterio: "",
                tienda: location.state.data.id_user,
                limit: 20,
                offset: 0
            }
        });

        if (_data.response == 1) {
            setDataList(_data.data);
            setShowLazy(_data.data.length < 20 ? false : true);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setLoadResult((!_data?.data || _data?.data.length < 1) ? "¡No hay usuarios!" : "¡No hay más usuarios!");
        setShowLoading(false);
    }

    React.useEffect(() => {
        document.body.style.overflow = "auto";
        if (funciones.validaPermisos("user_cliente") && [1, 2].includes(parseInt(data_user.nivel))) {
            if (location.state && location.state.data) {
                let _data = location.state.data;
                _data.info = JSON.parse(_data.info);
                _data.resumen = JSON.parse(_data.resumen);
                _data.permisos = JSON.parse(_data.permisos);

                console.log("datos", _data);

                getList();
                setData(_data);
                armaCliente(_data.resumen);
                setTitulo("Tienda: " + _data.info.company.nombre.toLowerCase());
            } else {
                Alert("No se pasaron parámetros o la pagina se cargo de forma incorrecta",
                    "warning",
                    () => navigate("/dashboard"));
                // setShowLoading(false);
            }
        }
    }, [location.state]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <RequirePermissions navigate={navigate}
            permission={funciones.validaPermisos("user_cliente")}>
            <TitleSticky>Tienda: {data?.info?.company?.nombre?.toLowerCase()}</TitleSticky>

            <Container className="dashboard">
                <Row>
                    <Col md={12} xs={12} className="col_static">
                        <Card>
                            <CardBody className="dashboard__card-widget">
                                <h5 className="dashboard__card-subtitle">Tienda: <b>{data.tienda}</b></h5>
                                <samp className="line_h"></samp>
                                <h5 className="dashboard__card-subtitle">Ciudad: <b>{data.city}</b></h5>
                                <samp className="line_h"></samp>
                                <h5 className="dashboard__card-subtitle">Representante: <b>{data.nombre} {data.apellido}</b></h5>
                                <samp className="line_h"></samp>
                                <h5 className="dashboard__card-subtitle">{data.typo_doc}: <b>{data.documento}</b></h5>
                                <samp className="line_h"></samp>
                                <h5 className="dashboard__card-subtitle">Correo: <b>{data.correo}</b></h5>
                                <samp className="line_h"></samp>
                                <h5 className="dashboard__card-subtitle">Teléfono: <b>{data.telefono}</b></h5>
                                <samp className="line_h"></samp>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Resumen
                        titulo="Totales"
                        userToClose={{ nivel: data.nivel }}
                        data={resumenPago}
                        loading={showLoading}
                        onRefresh={getList} />
                </Row>

                <div className="content_reportes">
                    <Col className="col_table">
                        <Card style={{ overflow: "hidden" }}>
                            <CardBody className="dashboard__card-widget">
                                <div className="card__title">
                                    <h5 className="bold-text" style={{ color: "#32c800" }}><ChartTimelineIcon className="dashboard__money-icon" /> Usuarios de la tienda</h5>
                                </div>

                                <div className="panel__btns">

                                    <button type="button"
                                        className="panel__btn"
                                        aria-label="panel__btn"
                                        onClick={getList}>
                                        <AutorenewIcon className={showLoading ? "on_refresh" : ""} />
                                    </button>

                                </div>

                                <AllRow />
                            </CardBody>
                        </Card>
                    </Col>
                </div>
            </Container>
        </RequirePermissions>
    );
}

export default withRouter(TiendaPerfil);