import * as React from "react";
import { RequirePermissions } from "shared/AlertMessages";
import RecolectasMensajero from "./RecolectasMensajero";
import RecolectasAdmin from "./RecolectasAdmin";
import withRouter from "services/withRouter";
import funciones from "services/funciones";
import { useMedia } from "hooks";

/* eslint eqeqeq: 0 */

const Recolectas = (router) => {
    const { navigate } = router;
    const { data_user } = useMedia();

    return (
        <RequirePermissions navigate={navigate}
            permission={funciones.validaPermisos("recolectas_gestion")}>
            {data_user.nivel == 4 ?
                <RecolectasMensajero {...router} />
                :
                <RecolectasAdmin {...router} />
            }
        </RequirePermissions>
    )
}

export default withRouter(Recolectas);