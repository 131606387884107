import * as React from "react";
import { Alert, alerta, alertaRemove } from "shared/Alert";
import { RequirePermissions } from "shared/AlertMessages";
import { TitleSticky } from "shared/Components";
import { useNavigate } from "react-router-dom";
import withRouter from "services/withRouter";
import funciones from "services/funciones";
import NewContainer from "./NewContainer";
import Loading from "shared/Loading";
import { useMedia } from "hooks";
import api from "services/api";

/* eslint eqeqeq: 0 */

const AddPackage = ({ location }) => {
    const [showLoading, setShowLoading] = React.useState(true);
    const [allPaquages, setAllPaquages] = React.useState([]);
    const [packages, setPackages] = React.useState({});
    const { setTitulo, data_user } = useMedia();
    const formRef = React.useRef();
    const navigate = useNavigate();


    const configPackages = async () => {
        let _pack = {
            "index": 0,
            "id": 0,
            "valorp": "0",
            "valord": data_user.info.company.domicilio.cliente,
            "valorm": data_user.info.company.domicilio.mensajero,
            "devolucion": "",
            "tiene_dev": false,
            "descripcion": "",
            "estado": 3,

            "destinatario": "",
            "telefono": "",
            "direccion": "",
            "volumen": "",
            "peso": "",
        };

        setShowLoading(false);
        setPackages({ 0: _pack });
        setAllPaquages(<NewContainer
            key={1}
            item={_pack}
            remove={removePackage}
            update={p => {
                alertaRemove(formRef.current.destinatario);
                alertaRemove(formRef.current.telefono);
                alertaRemove(formRef.current.direccion);
                alertaRemove(formRef.current.tipo_devolucion);
                alertaRemove(formRef.current.valorp);
                alertaRemove(formRef.current.valord);
                alertaRemove(formRef.current.valorm);
                setPackages({ 0: p });
            }} />);
    }

    const saveOrder = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "save-new-package",
            data: {
                tienda: data_user.info.company.id,
                ...packages[0]
            }
        });

        if (_data.response == 1) {
            navigate("/dashboard/tienda/rotulo", { state: { data: { ..._data.package, info_tienda: data_user.info } } });
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const validateFields = (e) => {
        e.preventDefault();
        let _is_empty = false;
        let _message = "";

        alertaRemove(formRef.current.destinatario);
        alertaRemove(formRef.current.telefono);
        alertaRemove(formRef.current.direccion);
        alertaRemove(formRef.current.tipo_devolucion);
        alertaRemove(formRef.current.valorp);
        alertaRemove(formRef.current.valord);
        alertaRemove(formRef.current.valorm);


        if (typeof packages !== "object" || Object.entries(packages).length < 1) {
            Alert("Debes agregar al menos un paquete!");
            return;
        }

        for (let i in packages) {
            if (parseInt(packages[i]["estado"]) < 1) {
                _is_empty = true;
                _message = `¡Debes seleccionar un estado!`;
                break
            }

            if (packages[i]["destinatario"] == null || packages[i]["destinatario"].length < 1) {
                _is_empty = true;
                _message = `Debes ingresar el nombre del destinatario!`;
                alerta(formRef.current.destinatario, _message);
                formRef.current.destinatario.focus();
                return;
            }

            if (packages[i]["telefono"] == null || packages[i]["telefono"].length < 1) {
                _is_empty = true;
                _message = `Debes ingresar el teléfono del destinatario!`;
                alerta(formRef.current.telefono, _message);
                formRef.current.telefono.focus();
                return;
            }

            if (packages[i]["direccion"] == null || packages[i]["direccion"].length < 1) {
                _is_empty = true;
                _message = `Debes ingresar la dirección del destinatario!`;
                alerta(formRef.current.direccion, _message);
                formRef.current.direccion.focus();
                return;
            }

            if (packages[i].tiene_dev) {
                if (packages[i].devolucion.length < 1) {
                    _is_empty = true;
                    _message = `Debes indicar el tipo de devolución`;
                    alerta(formRef.current.tipo_devolucion, _message);
                    formRef.current.tipo_devolucion.focus();
                    return;
                }
            } else {
                packages[i].devolucion = "";
            }

            if (packages[i]["valorp"] == null) {
                _is_empty = true;
                _message = `¡No hay cambios para guardar!`;
                break
            }

            if (packages[i]["valorp"].length < 1) {
                _is_empty = true;
                _message = `Debes ingresar el valor del producto!`;
                alerta(formRef.current.valorp, _message);
                formRef.current.valorp.focus();
                return;
            }

            if (packages[i]["valord"].length < 1) {
                _is_empty = true;
                _message = `Debes ingresar el valor del domicilio!`;
                alerta(formRef.current.valord, _message);
                formRef.current.valord.focus();
                return;
            }

            if (packages[i]["valorm"].length < 1) {
                _is_empty = true;
                _message = `Debes ingresar el valor del mensajero!`;
                alerta(formRef.current.valorm, _message);
                formRef.current.valorm.focus();
                return;
            }
        }

        if (_is_empty) {
            Alert(_message);
            return;
        }

        saveOrder();
    }

    const removePackage = (_index, _id) => {
        navigate(-1);
    }

    React.useEffect(() => {
        setTitulo("Nuevo paquete");
        document.body.style.overflow = "auto";

        if (funciones.validaPermisos("company_gestion_paquete")) {
            configPackages();
        }
    }, [location.state]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <RequirePermissions navigate={navigate}
            permission={funciones.validaPermisos("company_gestion_paquete")}>

            <form className="pickup" ref={formRef} onSubmit={e => e.preventDefault()}>
                <TitleSticky>Nuevo paquete</TitleSticky>

                <div className="pickup_container" style={{ marginBottom: 28 }}>
                    {allPaquages}
                </div>

                <div className="boton_agregar">
                    <button type="button" className="btn btn-common width-static" onClick={validateFields}>Guardar</button>
                    <button type="button" className="btn btn-common width-static" onClick={() => navigate(-1)}>Cancelar</button>
                </div>

                {showLoading && <Loading />}
            </form>
        </RequirePermissions>
    )
}

export default withRouter(AddPackage);