import * as React from "react";
import { useNavigate } from 'react-router-dom';
import { MovePopUp } from "./MovePopUp";
import Loading from "shared/Loading";
import { Alert } from "./Alert";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const meses = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

const HistoryOrder = (params) => {
    const { data, close, title = "Historial de la orden: " + data.consecutivo } = params;
    const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7 };
    const [showLoading, setShowLoading] = React.useState(true);
    const [dataList, setDataList] = React.useState([]);
    const navigate = useNavigate();


    const callHistory = async () => {
        setShowLoading(true);
        let _data = await api.getHistory({ id: data.id, tipo: data.tipo });

        if (_data.response == 1) {
            setDataList(_data.data);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }
        setShowLoading(false);
    }

    const AllRow = () => {
        let _data = [];

        dataList.map(item => {
            let _date = new Date(item.date_sistem);

            _data.push(
                <tr key={item.id} className="table_row">
                    <td>{item.tipo}</td>
                    <td>{format(_date.getDate())}/{meses[_date.getMonth()]}/{_date.getFullYear()} {format(_date.getHours())}:{format(_date.getMinutes())}</td>
                    {data_user.nivel < 4 && <td>{item.usuario}</td>}
                </tr>
            );
        });

        if (dataList.length < 1) {
            _data.push(
                <tr key="0" className="table_row">
                    <td>...</td>
                    <td>...</td>
                    {data_user.nivel < 4 && <td>...</td>}
                </tr>
            );
        }

        return _data;
    }

    const handleClose = (e) => {
        e.preventDefault();
        document.body.style.overflow = "auto";
        close(false);
    }

    React.useEffect(() => {
        callHistory();
    }, [data]);

    return (
        <MovePopUp
            parent={data.parent}
            close={handleClose}
            className="history"
            title={title}>

            <div>
                <table className="table">
                    <tbody>

                        <tr className="table_title">
                            <th>Acción</th>
                            <th>Fecha</th>
                            {data_user.nivel < 4 && <th>Usuario</th>}
                        </tr>

                        <AllRow />
                    </tbody>
                </table>
            </div>

            {showLoading && <Loading />}
        </MovePopUp>
    )
}

export default HistoryOrder;