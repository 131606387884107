import * as React from "react";
import { Alert, alerta, alertaRemove } from "shared/Alert";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import EyeOffIcon from "mdi-react/EyeOffIcon";
import funciones from "services/funciones";
import EyeIcon from "mdi-react/EyeIcon";
import Loading from "shared/Loading";
import api from "services/api";


/* eslint eqeqeq: 0 */
/* eslint no-useless-escape: 0 */

const SignUpForm = () => {
  const { t } = useTranslation('common');
  const [showLoading, setShowLoading] = React.useState(false);
  const [idtipe, setIdtipe] = React.useState("Cédula de Ciudadanía");
  const [identification, setIdentification] = React.useState("");
  const [showPass, setShowPass] = React.useState(false);
  const [lastname, setLastname] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [nombre, setNombre] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [pass, setPass] = React.useState("");
  const [city, setCity] = React.useState("");
  const ref_form = React.useRef(null);
  const navigate = useNavigate();


  const validate = () => {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    alertaRemove(ref_form.current.name);
    alertaRemove(ref_form.current.lastname);
    alertaRemove(ref_form.current.phone);
    alertaRemove(ref_form.current.email);
    alertaRemove(ref_form.current.password);
    alertaRemove(ref_form.current.identification);
    alertaRemove(ref_form.current.city);
    alertaRemove(ref_form.current.address);

    if (nombre == null || nombre.length < 1) {
      alerta(ref_form.current.name, "Debes ingresar tu nombre!");
      ref_form.current.name.focus();
      return false;
    }

    if (lastname == null || lastname.length < 1) {
      alerta(ref_form.current.lastname, "Debes ingresar tu apellido!");
      ref_form.current.lastname.focus();
      return false;
    }

    if (phone == null || phone.length < 1) {
      alerta(ref_form.current.phone, "Debes ingresar un número de contacto!");
      ref_form.current.phone.focus();
      return false;
    }

    if (email == null || email.length < 1) {
      alerta(ref_form.current.email, "Debes ingresar un correo!");
      ref_form.current.email.focus();
      return false;
    }

    if (!regex.test(String(email).toLowerCase())) {
      alerta(ref_form.current.email, t("msg.mail_format_error"));
      ref_form.current.email.focus();
      return false;
    }

    if (pass == null || pass.length < 1) {
      alerta(ref_form.current.password, "Debes ingresar una contraseña!");
      ref_form.current.password.focus();
      return false;
    }

    if (pass.length < 8) {
      alerta(ref_form.current.password, "La contraseña es muy corta!");
      ref_form.current.password.focus();
      return false;
    }

    if (identification == null || identification.length < 1) {
      alerta(ref_form.current.identification, "Debes ingresar tu número de identificación!");
      ref_form.current.identification.focus();
      return false;
    }

    if (city == null || city.length < 1) {
      alerta(ref_form.current.city, "Debes ingresar tu ciudad de residencia!");
      ref_form.current.city.focus();
      return false;
    }

    if (address == null || address.length < 1) {
      alerta(ref_form.current.address, "Debes ingresar tu dirección de residencia!");
      ref_form.current.address.focus();
      return false;
    }

    if (!funciones.validaDireccion(address)) {
      alerta(ref_form.current.address, "El formato de la direccón no es válido \nEjemplos: \nCra 94 # 69a - 42 \nCarrera 94a # 69 - 42 sur");
      ref_form.current.address.focus();
      return;
    }

    saveData();
  }

  const saveData = async () => {

    setShowLoading(true);
    let _data = await api.signUp({ email, pass, nombre, lastname, phone, idtipe, identification, city, address });

    if (_data.response == 1) {
      Alert(`¡Registro correcto! Ahora debes ingresar al correo '${email}' y validarlo para continuar.`, "warning", () => navigate(-1));
    } else {
      let _msg = _data.data ? _data.data.msg : _data.msg;
      Alert(_msg, "warning");
    }

    setShowLoading(false);
  }

  return (
    <form className="form _sign_up" ref={ref_form}>

      <div className="_in_row">

        <div className="_in_column">

          <label className="form__form-group-label">Nombre*</label>
          <div className="input">
            <input type="text" name="name" value={nombre}
              onChange={({ target: { value } }) => { setNombre(value); alertaRemove(ref_form.current.name); }} />
          </div>

          <label className="form__form-group-label">Apellidos*</label>
          <div className="input">
            <input type="text" name="lastname" value={lastname}
              onChange={({ target: { value } }) => { setLastname(value); alertaRemove(ref_form.current.lastname); }} />
          </div>

          <label className="form__form-group-label">Teléfono*</label>
          <div className="input">
            <input type="text" name="phone" value={phone} maxLength="15"
              onChange={({ target: { value } }) => { setPhone(value); alertaRemove(ref_form.current.phone); }} />
          </div>

          <label className="form__form-group-label">Correo*</label>
          <div className="input">
            <input type="email" name="email" value={email}
              onChange={({ target: { value } }) => { setEmail(value); alertaRemove(ref_form.current.email); }} />
          </div>

          <label className="form__form-group-label">Contraseña*</label>
          <div className="_toglle_pass">
            <input type={showPass ? "text" : "password"} name="password" value={pass}
              onChange={({ target: { value } }) => { setPass(value); alertaRemove(ref_form.current.password); }} />
            {showPass ? <EyeOffIcon onClick={() => setShowPass(false)} /> : <EyeIcon onClick={() => setShowPass(true)} />}
          </div>

        </div>

        <div className="_in_column">

          <label className="form__form-group-label">Tipo de identificación*</label>
          <div className="input">
            <select name="role" value={idtipe} onChange={({ target: { value } }) => setIdtipe(value)}>
              <option>Cédula de Ciudadanía</option>
              <option>Cédula de extranjería</option>
              <option>Pasapote</option>
              <option>NIT</option>
            </select>
          </div>

          <label className="form__form-group-label">Número de identificación*</label>
          <div className="input">
            <input type="text" name="identification" value={identification} maxLength="12"
              onChange={({ target: { value } }) => { setIdentification(value); alertaRemove(ref_form.current.identification); }} />
          </div>

          <label className="form__form-group-label">Ciudad*</label>
          <div className="input">
            <input type="text" name="city" value={city}
              onChange={({ target: { value } }) => { setCity(value); alertaRemove(ref_form.current.city); }} />
          </div>

          <label className="form__form-group-label">Dirección*</label>
          <div className="input">
            <textarea name="address" rows="2" value={address} placeholder="Ingresa Dirección"
              onChange={({ target: { value } }) => { setAddress(value); alertaRemove(ref_form.current.address); }} />
          </div>
        </div>

      </div>

      <button type="button" className="btn btn-primary account__btn account__btn-login" onClick={validate}>Guardar</button>

      {showLoading && <Loading />}
    </form>
  );
}

export default SignUpForm;
