import * as React from "react";
import ReactExport from "react-export-excel";
import funciones from "services/funciones";
import * as Icon from "images";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

const _border = {
    top: { style: "thin", color: { rgb: "ff000000" } },
    bottom: { style: "thin", color: { rgb: "ff000000" } },
    left: { style: "thin", color: { rgb: "ff000000" } },
    right: { style: "thin", color: { rgb: "ff000000" } },
};

// eslint-disable-next-line
const _align_rigth = { horizontal: "right", vertical: "center", wrapText: false };
const _align_left = { horizontal: "left", vertical: "center", wrapText: false };

const _heads = {
    fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
    font: { sz: "12", bold: true, color: { rgb: "ffffffff" } },
    alignment: _align_left,
    border: _border
};

// eslint-disable-next-line
const _resum = {
    fill: { patternType: "solid", fgColor: { rgb: "ff6060ff" } },
    font: { sz: "12", bold: true, color: { rgb: "ffffffff" } },
    border: _border
};

const _txt = { font: { sz: "10.5" }, border: _border };

const _txt_green = {
    fill: { patternType: "solid", fgColor: { rgb: "ff00ff00" } },
    font: { sz: "10.5", color: { rgb: "ff000000" } },
    border: _border
};

const _txt_blue = {
    fill: { patternType: "solid", fgColor: { rgb: "ff4040ff" } },
    font: { sz: "10.5", color: { rgb: "ffffffff" } },
    border: _border
};

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

const InformePorPagar = ({ data }) => {
    const [fileName, setFileName] = React.useState("No name");
    const [dataList, setDataList] = React.useState([]);
    const [fechaCorte] = React.useState(new Date());


    const armaList = () => {
        let _abono = 0;
        let _data = funciones.ordenaValoresExcel(data.packs);

        data.resumen.map(item => {
            _abono += parseInt(item.abonos || 0);
        });

        setDataList(_data.lista);
        data.update((_data.subtotal + _abono));
    }

    const armaResumen = (_subtotal) => {
        let _abono = 0;

        data.resumen.map(item => {
            _abono += parseInt(item.abonos || 0);
        });

        return [
            [
                null,
                null,
                null,
                { value: "Abonos", style: _txt_blue },
                { value: "" + _abono, style: _txt_blue },
            ],
            [
                null,
                null,
                null,
                { value: "Total", style: _txt_blue },
                { value: "" + (_subtotal + _abono), style: _txt_blue },
            ]
        ];
    }

    const BotonDescargar = () => {
        let _btn = <button className="cash-button _deshabilitado"><Icon.ExcelDownload />Por pagar</button>

        if (data.inload) {
            _btn = <button className="cash-button"><Icon.Loading />Cargando...</button>;
        } else if (dataList.length > 0) {
            _btn = <button className="cash-button"><Icon.ExcelDownload />Por pagar</button>;
        }

        return _btn;
    }

    const HojaDeCalculo = () => {
        let _price = 0, _v_mensajero = 0, _v_domicilio = 0, _subtotal_mensajero = 0, _subtotal = 0, _efectivo = 0, _bold = 0;

        const multiDataSet = [
            {
                columns: [
                    { value: "Vendedor", style: _heads, width: 20 },
                    { value: "Código", style: _heads, width: 10 },
                    { value: "Estado", style: _heads, width: 11 },
                    { value: "Mensajero", style: _heads, width: 20 },
                    { value: "Valor producto", style: _heads, width: 7 },
                    { value: "Domicilio mensajero", style: _heads, width: 8 },
                    { value: "Domicilio cliente", style: _heads, width: 6 },
                    { value: "Subtotal mensajero", style: _heads, width: 8 },
                    { value: "Subtotal cliente", style: _heads, width: 7 },
                    { value: "Efectivo", style: _heads, width: 8 },
                    { value: "Bold", style: _heads, width: 7 },
                ],
                data: dataList.map(item => {
                    _price += parseInt(item.price || 0);
                    _v_mensajero += parseInt(item.v_mensajero || 0);
                    _v_domicilio += parseInt(item.v_domicilio || 0);
                    _subtotal_mensajero += parseInt(item.subtotal_mensajero || 0);
                    _subtotal += parseInt(item.subtotal || 0);
                    _efectivo += parseInt(item.efectivo || 0);
                    _bold += parseInt(item.bold || 0);

                    return [
                        { value: item.consignee_name, style: _txt },
                        { value: item.codigo, style: _txt },
                        { value: funciones.unirEstados(item), style: _txt },
                        { value: item.carrier_name, style: _txt },
                        { value: item.price, style: _txt },
                        { value: item.v_mensajero, style: _txt },
                        { value: item.v_domicilio, style: _txt },
                        { value: item.subtotal_mensajero ?? "", style: _txt },
                        { value: item.subtotal ?? "", style: _txt },
                        { value: item.efectivo ?? "", style: _txt },
                        { value: item.bold ?? "", style: _txt },
                    ];
                })
            },
            {
                xSteps: 4,
                ySteps: 1,
                columns: [
                    { value: "" + _price, style: _txt_green },
                    { value: "" + _v_mensajero, style: _txt_green },
                    { value: "" + _v_domicilio, style: _txt_green },
                    { value: "" + _subtotal_mensajero, style: _txt_green },
                    { value: "" + _subtotal, style: _txt_green },
                    { value: "" + _efectivo, style: _txt_green },
                    { value: "" + _bold, style: _txt_green },
                ],
                data: armaResumen(_subtotal)
            }
        ];

        return <ExcelFile filename={fileName} element={<BotonDescargar />}>
            <ExcelSheet dataSet={multiDataSet} name={`${meses[fechaCorte.getMonth()].slice(0, 3)} ${format(fechaCorte.getDate())} de ${fechaCorte.getFullYear()}`} />
        </ExcelFile>;
    }

    React.useEffect(() => {
        setFileName(`Clientes por pagar ${format(fechaCorte.getDate())} de ${meses[fechaCorte.getMonth()]} del ${fechaCorte.getFullYear()}`);
        if (data.packs.length > 0) {
            armaList();
        }
    }, [data.packs]);

    return (
        <>
            {dataList.length > 0 ?
                <HojaDeCalculo />
                :
                <BotonDescargar />
            }
        </>
    )
}

export default InformePorPagar;