import * as React from "react";
import Information from "mdi-react/InformationOutlineIcon";
import { useNavigate } from "react-router-dom";
import funciones from "services/funciones";
import { MovePopUp } from "shared/MovePopUp";
import * as Icon from "images";
import VisorImg from "shared/VisorImg";
import { Alert } from "shared/Alert"
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const Opciones = ({ parent, data, close, reload }) => {
    const [showLoading, setShowLoading] = React.useState(false);
    const [transactions, setTransactions] = React.useState([]);
    const [notes, setNotes] = React.useState("");
    const [error, setError] = React.useState("");
    const viewRef = React.useRef();
    const navigate = useNavigate();


    const handleZoom = (e, imagen) => {
        e.preventDefault();

        VisorImg([imagen]);
    }

    const handleClose = (e) => {
        e.preventDefault();
        document.body.style.overflow = "auto";
        close(false);
    }

    const validaAcepta = (e) => {
        e.preventDefault();

        setError("");

        // if (monto.length < 1) {
        //     setError("¡Debes ingresar un monto!");
        //     return;
        // }

        aceptaPago();
    }

    const aceptaPago = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "validador-cierre",
            data: {
                task: "acepta_pendiente",
                id_order: data.id,
                nota: notes
            }
        });

        if (_data.response == 1) {
            Alert(_data.msg, "ok", () => {
                reload();
                document.body.style.overflow = "auto";
                close(false);
            });
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const validaRechaza = (e) => {
        e.preventDefault();

        setError("");

        if (notes.length < 1) {
            setError(`¡Debes indicar el motivo del rechazo!`);
            viewRef.current.scrollTo({
                top: viewRef.current.scrollHeight,
                behavior: "smooth"
            });
            return;
        }

        rechazaPago();
    }

    const rechazaPago = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "validador-cierre",
            data: {
                task: "rechaza_pendiente",
                id_order: data.id,
                nota: notes
            }
        });

        if (_data.response == 1) {
            Alert(_data.msg, "ok", () => {
                reload();
                document.body.style.overflow = "auto";
                close(false);
            });
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    React.useEffect(() => {
        document.body.style.overflow = "hidden";
        if (data.transactions !== null) {
            let _tran = data.transactions.split("|");
            let _pagos = [];

            _tran.map(item => {
                let _arr = item.split(": ");
                _pagos.push({ entity: _arr[0], number: _arr[1] });
            });

            setTransactions(_pagos);
        }
    }, []);

    return (
        <MovePopUp
            parent={parent}
            close={handleClose}
            className="win_modal"
            title={data.descripcion}>

            <div className="_in_column _to_scroll" ref={viewRef}>

                <div className="contain_files">
                    {data.file.split(",").toString().split(",").map((_file, index) => {
                        let _data_file = _file.split("|");

                        let _new_file = new File([_data_file[1]], _data_file[0], { type: "image/" + _data_file[0].split('.').pop() });

                        _new_file = Object.assign(_new_file, { preview: _data_file[1] });

                        return <div className="_evidence" key={"i_" + index}>
                            <img src={process.env.REACT_APP_SERVER + _data_file[1]}
                                alt={_data_file[0] + " en el paquete " + data.codigo}
                                onClick={e => handleZoom(e, _new_file)} />
                            <i className="_note">{data.codigo}</i>
                        </div>;
                    })}

                    {(data.evidence && data.evidence.length > 0) &&
                        data.evidence.split(",").map((f, i) => {
                            let _nombre = f.split("/").pop();
                            let _file_attach = new File([f], _nombre, { type: "image/" + _nombre.split('.').pop() });

                            _file_attach = Object.assign(_file_attach, { preview: f });

                            return <div className="_evidence" key={"e_" + i}>
                                <img src={process.env.REACT_APP_SERVER + f}
                                    alt={"Imagen de evidencia"}
                                    onClick={e => handleZoom(e, _file_attach)} />
                                <i className="_note">{data.fecha_creado}</i>
                            </div>;
                        })}
                </div>

                <p className="_detalle">
                    <b>Cliente:</b> {data.cliente}
                    <br />
                    {transactions.map((item, index) => <i key={index} style={{ marginTop: 8, marginLeft: 32 }}>{item.entity}: {item.number}</i>)}
                    <br />
                    <b>Valor pago:</b> ${funciones.formatPrice(data.valor).format}
                    <br />
                    {data.who_received && <><b>Recibe:</b> {data.who_received}<br /></>}
                    <b>Mensajero:</b> {data.nombre}
                    <br />
                    {(data.nota && data.nota.length > 0) && <><b style={{ display: "inline-block", marginTop: 14 }}>Nota:</b> {data.nota}</>}
                </p>

                <div style={{ width: "80%" }}>
                    <p className="__titulo">Nota de cierre</p>
                    <textarea name="comment" rows="5" placeholder="ingresa una nota"
                        value={notes} onChange={e => { setError(""); setNotes(e.target.value); }} />
                </div>

                {error.length > 1 && <h4 className="_error"><Information />{error}</h4>}
            </div>

            {showLoading ?
                <div className="cont_btns_caja">
                    <button className="btn btn-common width-static" style={{ width: "120px" }}><Icon.Loading /></button>
                    <button className="btn btn-common width-static" style={{ width: "120px" }}><Icon.Loading /></button>
                </div>
                :
                <div className="cont_btns_caja">
                    <button className="btn btn-common" style={{ width: "120px", paddingLeft: 12 }} onClick={validaAcepta}>Confirmar</button>
                    <button className="btn btn-common" style={{ width: "120px", paddingLeft: 12 }} onClick={validaRechaza}>Rechazar</button>
                </div>
            }
        </MovePopUp >
    )
}

export default Opciones;