import * as React from "react";
import CalendarMonthIcon from "mdi-react/CalendarMonthIcon";
import Information from "mdi-react/InformationOutlineIcon";
import DownloadIcon from "mdi-react/DownloadIcon";
import { useNavigate } from "react-router-dom";
import ReactExport from "react-export-excel";
import funciones from "services/funciones";
import DatePicker from "react-datepicker";
import { MovePopUp } from "shared/MovePopUp";
import * as Icon from "images";
import { Alert } from "shared/Alert";
import api from "services/api";

const rowData = ReactExport.rowData;
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

const _border = {
    top: { style: "thin", color: { rgb: "ff000000" } },
    bottom: { style: "thin", color: { rgb: "ff000000" } },
    left: { style: "thin", color: { rgb: "ff000000" } },
    right: { style: "thin", color: { rgb: "ff000000" } },
};

const _align_rigth = { horizontal: "right", vertical: "center", wrapText: false };
const _align_left = { horizontal: "left", vertical: "center", wrapText: false };

const _heads = {
    fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
    font: { sz: "12", bold: true, color: { rgb: "ffffffff" } },
    alignment: _align_left,
    border: _border
};

const _resum = {
    fill: { patternType: "solid", fgColor: { rgb: "ff6060ff" } },
    font: { sz: "12", bold: true, color: { rgb: "ffffffff" } },
    border: _border
};

const _txt = { font: { sz: "10.5" }, border: _border };

const _txt_green = {
    fill: { patternType: "solid", fgColor: { rgb: "ff00ff00" } },
    font: { sz: "10.5", color: { rgb: "ff000000" } },
    border: _border
};

const _txt_blue = {
    fill: { patternType: "solid", fgColor: { rgb: "ff4040ff" } },
    font: { sz: "10.5", color: { rgb: "ffffffff" } },
    border: _border
};

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

const InformeClientes = ({ parent, close }) => {
    const navigate = useNavigate();
    const [fechaCorte, setFechaCorte] = React.useState(new Date());
    const [showLoading, setShowLoading] = React.useState(false);
    const [fileName, setFileName] = React.useState("No name");
    const [dataResum, setDataResum] = React.useState([]);
    const [dataList, setDataList] = React.useState([]);
    const [error, setError] = React.useState("");

    const handleClose = (e) => {
        e.preventDefault();
        document.body.style.overflow = "auto";
        close(false);
    }

    const armaTransfer = (_caja_transfer = [], _info = []) => {
        let _entrada = 0;
        let _salida = 0;
        let _tabla_transfer = _caja_transfer.map(caja => {
            caja.concepto = caja.concepto.replaceAll("\n", " ");

            if (caja.tipo === "1") {
                _entrada += parseInt(caja.monto);
                return [
                    { value: caja.gestor, style: _txt },
                    { value: caja.concepto, style: _txt },
                    { value: parseInt(caja.monto), style: _txt },
                    { value: " ", style: _txt },
                ];
            } else {
                _salida += parseInt(caja.monto);
                return [
                    { value: caja.gestor, style: _txt },
                    { value: caja.concepto, style: _txt },
                    { value: " ", style: _txt },
                    { value: parseInt(caja.monto), style: _txt },
                ];
            }
        });
        _tabla_transfer.unshift([
            { value: "Usuario", style: _resum },
            { value: "Observación", style: _resum },
            { value: "entrada", style: _resum },
            { value: "salida", style: _resum },
        ]);
        _tabla_transfer.push([
            { merge: "start", value: "Total", style: _txt_green },
            { merge: "end", value: "", style: _txt_green },
            { value: _entrada, style: _txt_green },
            { value: _salida, style: _txt_green },
        ]);
        _tabla_transfer.push([
            { merge: "start", value: `Saldo ${_info.fecha}`, style: _txt_green },
            { merge: "pas", value: "", style: _txt_green },
            { merge: "end", value: "", style: _txt_green },
            { value: _info.caja_cli.transferencias, style: _txt_green },
        ]);

        return _tabla_transfer;
    }

    const armaBodega = (_caja_bodega = [], _info = []) => {
        let _entrada_caja_bodega = 0;
        let _tabla_bodega = _caja_bodega.map(caja => {
            caja.concepto = caja.concepto.replaceAll("\n", " ");
            _entrada_caja_bodega += parseInt(caja.monto);
            return [
                { merge: "start", value: caja.concepto, style: _txt },
                { merge: "end", value: "" },
                { value: parseInt(caja.monto), style: { ..._txt, alignment: _align_rigth } },
            ];
        });
        _tabla_bodega.push([
            { merge: "start", value: "Total", style: _txt_green },
            { merge: "end", value: "", style: _txt_green },
            { value: _entrada_caja_bodega, style: _txt_green },
        ]);
        _tabla_bodega.push([
            { merge: "start", value: `Saldo ${_info.fecha}`, style: _txt_green },
            { merge: "end", value: "", style: _txt_green },
            { value: (_info.caja_bdg.transferencias + _info.caja_bdg.efectivo), style: _txt_green },
        ]);

        return { lista: _tabla_bodega, total: _entrada_caja_bodega };
    }

    const armaMensajeros = (_caja_mensajeros = []) => {
        let _entrada_caja_msjrs = 0;
        let _tabla_mensajeros = _caja_mensajeros.map(caja => {
            caja.concepto = caja.concepto.replaceAll("\n", " ");
            _entrada_caja_msjrs += parseInt(caja.monto);
            return [
                { merge: "start", value: caja.concepto, style: _txt },
                { merge: "end", value: "" },
                { value: parseInt(caja.monto), style: { ..._txt, alignment: _align_rigth } },
            ];
        });
        _tabla_mensajeros.push([
            { merge: "start", value: "Total", style: _txt_green },
            { merge: "end", value: "", style: _txt_green },
            { value: _entrada_caja_msjrs, style: _txt_green },
        ]);

        return { lista: _tabla_mensajeros, total: _entrada_caja_msjrs };
    }

    const armaCliente = (_caja_clientes = []) => {
        let _entrada = 0;
        let _salida = 0;
        let _tabla_clientes = _caja_clientes.map(caja => {
            caja.concepto = caja.concepto.replaceAll("\n", " ");

            if (caja.tipo === "1") {
                _entrada += parseInt(caja.monto);
                return [
                    { value: caja.propietario, style: _txt },
                    { value: caja.concepto, style: _txt },
                    { value: parseInt(caja.monto), style: _txt },
                    { value: " ", style: _txt },
                ];
            } else {
                _salida += parseInt(caja.monto);
                return [
                    { value: caja.propietario, style: _txt },
                    { value: caja.concepto, style: _txt },
                    { value: " ", style: _txt },
                    { value: parseInt(caja.monto), style: _txt },
                ];
            }
        });
        _tabla_clientes.unshift([
            { value: "Cliente", style: _resum },
            { value: "Observación", style: _resum },
            { value: "entrada", style: _resum },
            { value: "salida", style: _resum },
        ]);
        _tabla_clientes.push([
            { merge: "start", value: "Total", style: _txt_green },
            { merge: "end", value: "", style: _txt_green },
            { value: _entrada, style: _txt_green },
            { value: _salida, style: _txt_green },
        ]);

        return { lista: _tabla_clientes, entradas: _entrada, salidas: _salida };
    }

    const armaLista = (
        _info = {},
        _caja_clientes = [],
        _caja_mensajeros = [],
        _caja_bodega = [],
        _caja_transfer = []) => {
        let _fecha = new Date(_info.fecha);
        _info.fecha = `${format(_fecha.getDate())}/${meses[_fecha.getMonth()]}/${_fecha.getFullYear()}`;

        let _obj_clientes = armaCliente(_caja_clientes);
        let _tabla_clientes = _obj_clientes.lista;
        let _obj_mensajeros = armaMensajeros(_caja_mensajeros);
        let _tabla_mensajeros = _obj_mensajeros.lista;
        let _obj_bodega = armaBodega(_caja_bodega, _info);
        let _tabla_bodega = _obj_bodega.lista;
        let _tabla_transfer = armaTransfer(_caja_transfer, _info);
        let _tabla_resumen = [
            [
                { value: `Caja de clientes (saldo ${_info.fecha})`, style: _txt_green },
                { value: (_info.caja_cli.efectivo + _info.caja_cli.transferencias), style: { ..._txt_green, alignment: _align_rigth } },
            ],
            [
                { value: "Caja de clientes (entradas)", style: _txt },
                { value: _obj_clientes.entradas, style: { ..._txt, alignment: _align_rigth } },
            ],
            [
                { value: "Caja de clientes (salidas)", style: _txt },
                { value: _obj_clientes.salidas, style: { ..._txt, alignment: _align_rigth } },
            ],
            [
                { value: "Caja de bodega", style: _txt },
                { value: _obj_bodega.total, style: { ..._txt, alignment: _align_rigth } },
            ],
            [
                { value: "Caja de mensajeros", style: _txt },
                { value: _obj_mensajeros.total, style: { ..._txt, alignment: _align_rigth } },
            ],
            [
                { value: "Cambio transf. a efectivo", style: _txt },
                { value: _info.cambio, style: { ..._txt, alignment: _align_rigth } },
            ],
            // [
            //     { value: "Total", style: _txt_green },
            //     { value: , style: { ..._txt_green, alignment: _align_rigth } },
            // ],
            [
                null,
                null,
            ],
            [
                { value: "Efectivo", style: _txt_blue },
                { value: _info.efectivo, style: { ..._txt_blue, alignment: _align_rigth } },
            ],
            [
                { value: "Transferencias", style: _txt_blue },
                { value: _info.transfer, style: { ..._txt_blue, alignment: _align_rigth } },
            ]
        ];

        let _length = Math.max(_tabla_resumen.length, _tabla_clientes.length, _tabla_mensajeros.length, _tabla_bodega.length, _tabla_transfer.length);
        let _rows = [];

        for (let i = 0; i < _length; i++) {
            _rows.push(rowData(_tabla_resumen[i] ?? [null, null])
                .next(_tabla_clientes[i] ?? [null, null, null, null], 1)
                .next(_tabla_mensajeros[i] ?? [null, null, null])
                .next(_tabla_bodega[i] ?? [null, null, null])
                .next(_tabla_transfer[i]).insert);
        }

        setDataResum(_rows);
    }

    const getList = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "get-history-cortes",
            data: {
                task: "info_client",
                resumen_cli: true,
                caja_mensajeros: true,
                caja_transfer: true,
                caja_bodega: true,
                caja: true,
                fecha: `${fechaCorte.getFullYear()}-${format(fechaCorte.getMonth() + 1)}-${format(fechaCorte.getDate())}`
            }
        });

        if (_data.response == 1) {
            let _lista = funciones.ordenaValoresExcel(_data.data);
            let _info = JSON.parse(_data.cierre_previo.info);
            _info.cambio = parseInt(_data.cambio.monto || 0);
            _info.efectivo = 0;
            _info.transfer = 0;

            _data.montos.map(item => {
                if (item.metodo == 1) {
                    _info.efectivo += parseInt(item.valor);
                } else if (item.metodo == 2) {
                    _info.transfer += parseInt(item.valor);
                }
            });

            _data.recogidas.map(item => {
                let _inf_order = JSON.parse(item.info);
                _inf_order.recogida.valor_producto = parseInt(_inf_order.recogida.valor_producto || 0);
                _inf_order.recogida.valor_producto = _inf_order.recogida.valor_producto > 0 ? -_inf_order.recogida.valor_producto : _inf_order.recogida.valor_producto;

                _lista.lista.push(
                    {
                        estado: 100,
                        all_states: "100",
                        consignee_name: item.consignee_name,
                        codigo: "OR-" + item.consignee + "-" + item.consecutive,
                        carrier_name: item.carrier_name,
                        carrier: _inf_order.recogida.carrier,
                        price: _inf_order.recogida.valor_producto,
                        v_mensajero: parseInt(_inf_order.recogida.valor_mensajero || 0),
                        v_domicilio: parseInt(_inf_order.recogida.valor_domicilio || 0),
                        subtotal_mensajero: (_inf_order.recogida.valor_producto + -parseInt(_inf_order.recogida.valor_mensajero || 0)),
                        subtotal: (_inf_order.recogida.valor_producto + -parseInt(_inf_order.recogida.valor_domicilio || 0)),
                        efectivo: _inf_order.recogida.valor_producto,
                        bold: 0,
                    });
            });

            armaLista(_info, _data.caja, _data.caja_mensajeros, _data.caja_bodega, _data.caja_transfer);
            setDataList(_lista.lista);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            setError(_data.msg);
        }

        setShowLoading(false);
    }

    const BotonDescargar = ({ enable }) => {
        let _btn = <button className="download-button _marco _deshabilitado"><DownloadIcon />Descargar Excel</button>;

        if (showLoading) {
            _btn = <button className="download-button _marco"><Icon.Loading />Cargando info...</button>;
        } else if (enable) {
            _btn = <button className="download-button _marco"><DownloadIcon />Descargar Excel</button>;
        }

        return _btn;
    }

    const HojaDeCalculo = () => {

        const multiDataSet = [
            {
                columns: [
                    { value: "Vendedor", style: _heads, width: 20 },            // A
                    { value: "Código", style: _heads, width: 10 },              // B
                    { value: "Estado", style: _heads, width: 11 },              // C
                    { value: "Mensajero", style: _heads, width: 20 },           // D
                    { value: "Valor producto", style: _heads, width: 7 },       // E
                    { value: "Domicilio mensajero", style: _heads, width: 8 },  // F
                    { value: "Domicilio cliente", style: _heads, width: 6 },      // G
                    { value: "Subtotal mensajero", style: _heads, width: 8 },   // H
                    { value: "Subtotal cliente", style: _heads, width: 7 },             // I 
                    { value: "Efectivo", style: _heads, width: 8 },             // J
                    { value: "Bold", style: _heads, width: 7 },                 // K
                    null,   // L
                    null,   // M
                    null,   // N
                    null,   // O
                    null,   // P
                    null,   // Q
                    null,   // R
                    null,   // S
                    null,   // T
                ],
                data: dataList.map(item => {
                    return [
                        { value: item.consignee_name, style: _txt },
                        { value: item.codigo, style: _txt },
                        { value: funciones.unirEstados(item), style: _txt },
                        { value: item.carrier_name, style: _txt },
                        { value: item.price, style: _txt },
                        { value: item.v_mensajero, style: _txt },
                        { value: item.v_domicilio, style: _txt },
                        { value: item.subtotal_mensajero ?? "", style: _txt },
                        { value: item.subtotal ?? "", style: _txt },
                        { value: item.efectivo ?? "", style: _txt },
                        { value: item.bold ?? "", style: _txt },
                        null,
                        null,
                        null,
                        null,
                    ];
                })
            },
            {
                ySteps: 3,
                columns: [
                    { merge: "start", value: "Resumen", style: { ..._resum, alignment: { horizontal: "center", vertical: "center" } } },
                    { merge: "end", value: "" },
                    null,
                    { merge: "start", value: "Movimientos clientes", style: { ..._resum, alignment: { horizontal: "center", vertical: "center" } } },
                    { merge: "pas", value: "" },
                    { merge: "pas", value: "" },
                    { merge: "end", value: "" },
                    null,
                    { merge: "start", value: "Caja de mensajeros", style: { ..._resum, alignment: { horizontal: "center", vertical: "center" } } },
                    { merge: "pas", value: "" },
                    { merge: "end", value: "" },
                    null,
                    { merge: "start", value: "Caja de bodega", style: { ..._resum, alignment: { horizontal: "center", vertical: "center" } } },
                    { merge: "pas", value: "" },
                    { merge: "end", value: "" },
                    null,
                    { merge: "start", value: "Caja transferencias", style: { ..._resum, alignment: { horizontal: "center", vertical: "center" } } },
                    { merge: "pas", value: "" },
                    { merge: "pas", value: "" },
                    { merge: "end", value: "" },
                ],
                data: dataResum
            }
        ];

        return <>
            {(dataList.length == 0 && dataResum.length == 0) ?
                <BotonDescargar enable={false} />
                :
                <ExcelFile filename={fileName} element={<BotonDescargar enable={true} />}>
                    <ExcelSheet dataSet={multiDataSet} name={`${meses[fechaCorte.getMonth()].slice(0, 3)} ${format(fechaCorte.getDate())} de ${fechaCorte.getFullYear()}`} />
                </ExcelFile>
            }
        </>;
    }

    React.useEffect(() => {
        document.body.style.overflow = "hidden";
    }, []);

    React.useEffect(() => {
        setFileName(`Caja clientes ${format(fechaCorte.getDate())} de ${meses[fechaCorte.getMonth()]} del ${fechaCorte.getFullYear()}`);
        getList();
    }, [fechaCorte]);

    return (
        <MovePopUp
            parent={parent}
            close={handleClose}
            className="win_modal _overflow"
            title="Informe de clientes">

            <div className="_in_column">

                <label className="__titulo">Fecha</label>
                <div className="calendar_in_modal">
                    <DatePicker dateFormat="yyyy/MM/dd" selected={fechaCorte} onChange={(date) => { setError(""); setFechaCorte(date); }} />
                    <CalendarMonthIcon />
                </div>

            </div>

            {error.length > 1 && <h4 className="_error"><Information />{error}</h4>}

            <HojaDeCalculo />
        </MovePopUp>
    )
}

export default InformeClientes;