import * as React from "react";
import FilterConcept from "../../CajaClientes/components/FilterConcept";
import FilterDate from "../../CajaClientes/components/FilterDate";
import ListStatusIcon from "mdi-react/ListStatusIcon";
import AutorenewIcon from "mdi-react/AutorenewIcon";
import FilerIcon from "mdi-react/FilterMenuIcon";
import { Card, CardBody, Col } from "reactstrap";
import { useNavigate } from "react-router";
import { Alert } from "shared/Alert";
import Opciones from "./Opciones";
import { useMedia } from "hooks";
import * as Icon from "images";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const meses = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

const Pendientes = (params) => {
    const [previousQuery, setPreviousQuery] = React.useState({ task: "pendientes", offset: 0 });
    const [showLoading, setShowLoading] = React.useState(true);
    const [showModal, setShowModal] = React.useState(false);
    const [modalView, setModalView] = React.useState(null);
    const [showLazy, setShowLazy] = React.useState(true);
    const [dataList, setDataList] = React.useState([]);
    const { media_movil } = useMedia();
    const navigate = useNavigate();


    const handleModal = (e, _option, _data) => {
        e.preventDefault();
        e.stopPropagation();
        let _window;

        switch (_option) {
            case "opciones":
                _window = <Opciones
                    parent={{ ...e.target.getBoundingClientRect().toJSON(), top: 70, width: media_movil ? (document.getElementById("root").offsetWidth - 20) : 500 }}
                    data={_data}
                    close={setShowModal}
                    reload={reloadList} />;
                break;
            case "fil_fecha_pendiente":
                _window = <FilterDate
                    parent={{ ...e.target.getBoundingClientRect().toJSON(), width: 230 }}
                    task="fil_fecha_pendiente"
                    close={setShowModal}
                    call={filterList} />;
                break;
            case "fil_concepto_pendiente":
                _window = <FilterConcept
                    parent={{ ...e.target.getBoundingClientRect().toJSON(), width: 230 }}
                    task="fil_concepto_pendiente"
                    close={setShowModal}
                    call={filterList} />;
                break;
            default:
                _window = null;
                break;
        }

        setModalView(_window);
        setShowModal(true);
    }

    const filterList = async (_criterio) => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "lista-validador",
            data: {
                task: _criterio.task,
                criterio: _criterio.criterio,
                offset: 0
            }
        });

        if (_data.response == 1) {
            if (_data.data.length < 1) {
                Alert(`No se encontraron resultados para "${_criterio.criterio}" intentalo de nuevo.`, "warning");
            } else {
                setPreviousQuery({ task: _criterio.task, offset: 0 });
                setShowLazy(_data.data.length < 50 ? false : true);
                setDataList(_data.data);
            }
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowModal(false);
        setShowLoading(false);
    }

    const reloadList = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "lista-validador",
            data: {
                task: previousQuery.task,
                limit: dataList.length,
                offset: 0
            }
        });

        if (_data.response == 1) {
            setDataList(_data.data);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const getListLazy = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "lista-validador",
            data: {
                task: previousQuery.task,
                offset: dataList.length
            }
        });

        if (_data.response == 1) {
            let _packs = [];

            dataList.map(item => {
                _packs.push(item);
            });

            _data.data.map(item => {
                _packs.push(item);
            });

            setPreviousQuery({ task: previousQuery.task, offset: dataList.length });
            setShowLazy(_data.data.length < 50 ? false : true);
            setDataList(_packs);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const getList = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "lista-validador",
            data: {
                task: "pendientes",
                offset: 0
            }
        });

        if (_data.response == 1) {
            setPreviousQuery({ task: "pendientes", offset: 0 });
            setShowLazy(_data.data.length < 50 ? false : true);
            setDataList(_data.data);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const Tabla = () => {
        return <div className="dashboard__sales-table">
            {dataList.length < 1 ?
                <h4>¡No hay pendientes!</h4>
                :
                <>
                    <table className="table">
                        <tbody>

                            <tr className="table_title">
                                <th><FilerIcon className="to_filter" onClick={e => handleModal(e, "fil_fecha_pendiente")} />Fecha</th>
                                <th>Código</th>
                                <th style={{ whiteSpace: "nowrap" }}><FilerIcon className="to_filter" onClick={e => handleModal(e, "fil_concepto_pendiente")} />Mensajero</th>
                                <th>Descripción</th>
                                <th>Nota</th>
                            </tr>

                            {dataList.map((item, index) => {
                                let fecha_creado = new Date(item.fecha);

                                item.fecha_creado = `${format(fecha_creado.getDate())}/${meses[fecha_creado.getMonth()]}/${fecha_creado.getFullYear()} ${format(fecha_creado.getHours())}:${format(fecha_creado.getMinutes())}`;
                                item.descripcion = item.descripcion.replaceAll("(Enviado a validación)", "");

                                return <tr key={index} className="_to_clic" onClick={e => handleModal(e, "opciones", item)}>
                                    <td>{item.fecha_creado}</td>
                                    <td>{item.codigo}</td>
                                    <td style={{ whiteSpace: "nowrap" }}>{item.nombre}</td>
                                    <td style={{ whiteSpace: "nowrap" }}>{item.descripcion}</td>
                                    <td style={{ whiteSpace: "nowrap" }}>{item.nota}</td>
                                </tr>
                            })}
                            
                            <tr className="_no_margin">
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>

                    {showLoading ?
                        <button className="btn_lazy_load"><Icon.Loading /></button>
                        :
                        <>
                            {showLazy ?
                                <button className="btn_lazy_load" onClick={() => getListLazy()}>Cargar más</button>
                                :
                                <h4 className="h4_lazy_load">¡No hay más pendientes!</h4>
                            }
                        </>
                    }
                </>
            }
        </div>;
    }

    React.useEffect(() => {
        params.onChange(showLoading);
    }, [showLoading]);

    React.useEffect(() => {
        if (params.inLoad) {
            getList();
        }
    }, [params.inLoad]);

    return (
        <div className="content_reportes">
            <Col className="col_table">
                <Card style={{ overflow: "hidden" }}>
                    <CardBody className="dashboard__card-widget">
                        <div className="card__title">
                            <h5 className="bold-text"><ListStatusIcon className="dashboard__money-icon" /> Confirmaciones Pendientes</h5>
                        </div>

                        <div className="panel__btns">

                            <button
                                type="button"
                                className="panel__btn"
                                aria-label="panel__btn"
                                onClick={() => setShowLoading(true)}>
                                <AutorenewIcon className={showLoading ? "on_refresh" : ""} />
                            </button>

                        </div>

                        <Tabla />
                    </CardBody>
                </Card>
            </Col>

            {showModal && modalView}
        </div>
    )
}

export default Pendientes;