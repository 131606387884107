import * as React from "react";
import AccountGroupIcon from "mdi-react/AccountGroupIcon";
import AccountOffIcon from "mdi-react/AccountOffIcon";
import AutorenewIcon from "mdi-react/AutorenewIcon";
import { useNavigate } from "react-router-dom";
import { Alert, Confirm } from "shared/Alert";
import { SearchCarrier } from "shared/Input";
import { MovePopUp } from "shared/MovePopUp";
import * as Icon from "images";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const MsjsList = (params) => {
    const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7 };
    const [parent, setParent] = React.useState({ top: "0", left: "0" });
    const [txtMensaje, setTxtMensaje] = React.useState("Cargando...");
    const [showLoading, setShowLoading] = React.useState(true);
    const [listAssigned, setListAssigned] = React.useState(0);
    const [showList, setShowList] = React.useState(false);
    const [showLazy, setShowLazy] = React.useState(true);
    const [dataList, setDataList] = React.useState([]);
    const [criterio, setCriterio] = React.useState("");
    const navigate = useNavigate();


    const handleClose = (e) => {
        e.preventDefault();
        document.body.style.overflow = "auto";
        setShowList(false);
    }

    const handleShow = (e) => {
        e.preventDefault();
        document.body.style.overflow = "hidden";
        setShowList(true);
        setParent({ ...e.target.getBoundingClientRect().toJSON(), width: 250 });
        getCarriers();
    }

    const handleRelease = (e) => {
        e.preventDefault();

        Confirm("¿Estas seguro de liberar a todos los mensajeros que tienes asignados?", (response) => {
            if (response) {
                sendRelease();
            }
        });
    }

    const sendRelease = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "validador-cierre",
            data: {
                task: "libera_mis_mensajeros"
            }
        });

        if (_data.response == 1) {
            Alert(_data.msg, "ok", () => {
                setDataList([]);
            });
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const handleSearch = async (_val) => {
        _val = _val.toLowerCase();

        if (_val.length >= 1) {
            setCriterio(_val);

            setShowLoading(true);
            setTxtMensaje(`Buscando mensajeros con "${_val}"...`);

            setDataList([]);
            let _data = await api.fetchJson({
                url: "get-users",
                data: {
                    roll: "busca_mis_mensajeros_asignados",
                    criterio: _val,
                    limit: 50,
                    offset: 0
                }
            });

            if (_data.response == 1) {
                let _mensajeros = _data.data.map(item => {
                    if (item.validador === "si") {
                        item.select = true;
                    } else {
                        item.select = false;
                    }

                    return item;
                });

                _mensajeros = _mensajeros.reduce(
                    (prev, curr) => prev.find(i => i.id_user === curr.id_user)
                        ? prev
                        : [...prev, curr]
                    , []);

                setShowLazy(_data.data.length < 50 ? false : true);
                setDataList(_mensajeros);
            } else if (_data.response === -2) {
                Alert(_data.msg, "warning", () => {
                    document.body.style.overflow = "auto";
                    navigate("/sign-in");
                });
            } else {
                Alert(_data.msg, "warning");
            }

            setTxtMensaje(`¡No se encuentran mensajeros con "${_val}"!`);
            setShowLoading(false);
        } else {
            setCriterio("");
            getCarriers();
        }
    }

    const getCarriersLazy = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "get-users",
            data: {
                roll: "mis_mensajeros_asignados",
                limit: 50,
                offset: dataList.length
            }
        });

        if (_data.response == 1) {
            let _mensajeros = [];

            dataList.map(item => {
                _mensajeros.push(item);
            });

            _data.data.map(item => {
                if (item.validador === "si") {
                    item.select = true;
                } else {
                    item.select = false;
                }

                _mensajeros.push(item);
            });

            setShowLazy(_data.data.length < 50 ? false : true);
            setDataList(_mensajeros);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setTxtMensaje("¡No hay mensajeros!");
        setShowLoading(false);
    }

    const getCarriers = async () => {
        setShowLoading(true);
        let _data = await api.fetchJson({
            url: "get-users",
            data: {
                roll: "mis_mensajeros_asignados",
                limit: 50,
                offset: 0
            }
        });

        if (_data.response == 1) {
            let _lista = _data.data.map(item => {
                if (item.validador === "si") {
                    item.select = true;
                } else {
                    item.select = false;
                }

                return item;
            });

            setShowLazy(_data.data.length < 50 ? false : true);
            setDataList(_lista);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setTxtMensaje("¡No hay mensajeros!");
        setShowLoading(false);
    }

    const countAssign = async () => {
        setShowLoading(true);
        let _data = await api.fetchJson({
            url: "lista-validador",
            data: {
                task: "mis_asignaciones",
                limit: 50,
                offset: 0
            }
        });

        if (_data.response == 1) {
            setListAssigned(_data.data[0].cantidad);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const validaAssignAll = (e) => {
        e.preventDefault();

        Confirm("Al asignarte todos los mensajeros liberarás la lista de los demás validadores.\n\n ¿Estas seguro de continuar?", (response) => {
            if (response) {
                saveAssignAll();
            }
        });
    }

    const saveAssignAll = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "validador-asigna",
            data: {
                task: "asigna_todos",
                validador: data_user.auth && window.atob(data_user.auth)
            }
        });

        if (_data.response == 1) {
            Alert(_data.msg, "ok", () => {
                setShowList(false);
                setShowLoading(true);
            });
            setListAssigned(_data.quantity);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const saveAssign = async (_to_assign) => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "validador-asigna",
            data: {
                task: "asigna_me",
                list_assign: _to_assign.toString()
            }
        });

        if (_data.response == 1) {
            Alert(_data.msg, "ok", () => {
                document.body.style.overflow = "auto";
                setShowList(false);
                setShowLoading(true);
            });
            setListAssigned(_to_assign.length);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const validate = (e) => {
        e.preventDefault();
        let _lista = [];

        dataList.map(u => {
            if (u.select) {
                _lista.push(u.id_user);
            }
        });

        if (_lista.length < 1) {
            Alert("Debes seleccionar al menos un mensajero", "warning");
            return;
        }

        saveAssign(_lista);
    }

    const AllRow = () => {
        if (dataList.length < 1) {
            return <h3 key="01">{txtMensaje}</h3>;
        } else {
            return <>
                <div key="01">
                    <p className="_btn" onClick={validaAssignAll}>Asignarme todos los mensajeros</p>
                </div>

                {dataList.map((item, index) => {
                    return <div key={index}>
                        <p className="_nowrap" onClick={() => setDataList(m => {
                            return m.filter(val => {
                                if (val.id_user === m[index].id_user) {
                                    val.select = !m[index].select;
                                }

                                return val;
                            });
                        })}>
                            <input type="checkbox" checked={item.select} readOnly />
                            <b>{item.nombre}</b>
                        </p>
                        {item.validador_name && <i>{item.validador_name}</i>}
                    </div>;
                })}

                {showLoading ?
                    <button className="btn_lazy_load"><Icon.Loading /></button>
                    :
                    <>
                        {showLazy ?
                            <button className="btn_lazy_load" onClick={() => getCarriersLazy()}>Cargar más</button>
                            :
                            <h4 className="h4_lazy_load">¡No hay más mensajeros!</h4>
                        }
                    </>
                }
            </>;
        }
    }

    React.useEffect(() => {
        params.onChange(showLoading);
    }, [showLoading]);

    React.useEffect(() => {
        if (params.inLoad) {
            setCriterio("");
            countAssign();
        }
    }, [params.inLoad]);

    return (
        <>
            <div className="panel__btns _en_caja">

                <button
                    className="panel__btn"
                    type="button"
                    onClick={() => setShowLoading(true)}>
                    <AutorenewIcon className={showLoading ? "on_refresh" : ""} />
                </button>

            </div>

            <h4 className="_precio">
                <span>Mensajeros asignados</span> {listAssigned}
            </h4>

            <div className="cont_btns_caja">
                {showLoading ?
                    <>
                        <button type="button" className="cash-button"><Icon.Loading />Libera mensajeros</button>
                        <button type="button" className="cash-button"><Icon.Loading />Lista de mensajeros</button>
                    </>
                    :
                    <>
                        <button type="button" className="cash-button" onClick={handleRelease}><AccountOffIcon />Libera mensajeros</button>
                        <button type="button" className="cash-button" onClick={handleShow}><AccountGroupIcon />Lista de mensajeros</button>
                    </>
                }
            </div>

            {showList && <MovePopUp
                parent={parent}
                close={handleClose}
                className="win_modal"
                title="Lista de mensajeros">

                <SearchCarrier
                    name="buscaMensajero"
                    value={criterio}
                    placeholder="Buscar mensajero"
                    onChange={handleSearch} />

                <div className="_scroll">
                    <AllRow />
                </div>

                <hr className="_line" />

                <div className="_in_row">
                    {showLoading ?
                        <button type="button" className="btn btn-common width-static"><Icon.Loading /></button>
                        :
                        <button type="button" className="btn btn-common width-static" onClick={validate}>Asignar</button>
                    }
                </div>
            </MovePopUp>
            }
        </>
    )
}

export default MsjsList;