import * as React from "react";
import ChartTimelineIcon from "mdi-react/ChartTimelineIcon";
import AutorenewIcon from "mdi-react/AutorenewIcon";
import { Card, CardBody, Col } from "reactstrap";
import { useNavigate } from "react-router-dom";
import funciones from "services/funciones";
import * as Icon from "images";
import { Alert } from "shared/Alert";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const meses = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

const Movimientos = ({ parent, tipo_caja }) => {
    const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7 };
    const [showLoading, setShowLoading] = React.useState(true);
    const [showLazy, setShowLazy] = React.useState(true);
    const [dataList, setDataList] = React.useState([]);
    const navigate = useNavigate();

    const handlerRefresh = (e) => {
        e.preventDefault();
        getList();
        parent.update();
    }

    const getListLazy = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "info-caja",
            data: {
                task: "movimientos_msjs",
                caja: tipo_caja,
                offset: dataList.length
            }
        });

        if (_data.response == 1) {
            let _packs = [];

            dataList.map(item => {
                _packs.push(item);
            });

            _data.data.map(item => {
                _packs.push(item);
            });

            setShowLazy(_data.data.length < 50 ? false : true);
            setDataList(_packs);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const getList = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "info-caja",
            data: {
                task: "movimientos_msjs",
                caja: tipo_caja,
                offset: 0
            }
        });

        if (_data.response == 1) {
            setShowLazy(_data.data.length < 50 ? false : true);
            setDataList(_data.data);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const History = () => {
        return <div className="dashboard__sales-table">
            {dataList.length < 1 ?
                <h4>No hay movimientos en esta caja</h4>
                :
                <>
                    <table className="table">
                        <tbody>

                            <tr className="table_title">
                                <th>Fecha</th>
                                <th>Tipo</th>
                                <th>Monto</th>
                                <th>Concepto</th>
                                <th style={{ whiteSpace: "nowrap" }}>Quien registró</th>
                            </tr>

                            {dataList.map((item, index) => {
                                let fecha_creado = new Date(item.fecha);

                                if (item.gestor == window.atob(data_user.auth)) {
                                    switch (parseInt(item.movimiento)) {
                                        case 14:
                                            item.nota = item.estado == 4 ?
                                                item.concepto.replaceAll("abono desde caja de mensajeros", "abono a caja principal") :
                                                "Abono a caja principal" + item.concepto.split("abono desde caja de mensajeros")[1];
                                            break;
                                        case 21:
                                            item.nota = item.estado == 4 ?
                                                item.concepto.replaceAll("entrega caja de mensajeros", "entrega a caja principal") :
                                                "Entrega a caja principal" + item.concepto.split("entrega caja de mensajeros")[1];
                                            break;
                                        default:
                                            item.nota = item.concepto;
                                            break;
                                    }

                                    return <tr key={index}>
                                        <td>{format(fecha_creado.getDate())}/{meses[fecha_creado.getMonth()]}/{fecha_creado.getFullYear()} {format(fecha_creado.getHours())}:{format(fecha_creado.getMinutes())}</td>
                                        <td>{item.metodo == 1 ? "efectivo" : "transferencia"}</td>
                                        <td style={{ textAlign: "right" }}>{funciones.formatPrice(item.monto).format}</td>
                                        <td style={{ whiteSpace: "pre" }}>{item.nota}</td>
                                        <td style={{ whiteSpace: "nowrap" }}>Tu</td>
                                    </tr>
                                } else {
                                    switch (parseInt(item.movimiento)) {
                                        case 14:
                                            item.nota = item.estado == 4 ?
                                                item.concepto.replaceAll("abono desde caja de mensajeros", "abono desde caja auxiliar") :
                                                "Abono desde caja auxiliar" + item.concepto.split("abono desde caja de mensajeros")[1];
                                            break;
                                        case 21:
                                            item.nota = item.estado == 4 ?
                                                item.concepto.replaceAll("entrega caja de mensajeros", "entrega desde caja auxiliar") :
                                                "Entrega desde caja auxiliar" + item.concepto.split("entrega caja de mensajeros")[1];
                                            break;
                                        default:
                                            item.nota = item.concepto;
                                            break;
                                    }

                                    return <tr key={index}>
                                        <td>{format(fecha_creado.getDate())}/{meses[fecha_creado.getMonth()]}/{fecha_creado.getFullYear()} {format(fecha_creado.getHours())}:{format(fecha_creado.getMinutes())}</td>
                                        <td>{item.metodo == 1 ? "efectivo" : "transferencia"}</td>
                                        <td style={{ textAlign: "right" }}>{funciones.formatPrice(item.monto).format}</td>
                                        <td style={{ whiteSpace: "pre" }}>{item.nota}</td>
                                        <td style={{ whiteSpace: "nowrap" }}>{item.gestor_name}</td>
                                    </tr>
                                }
                            })}

                            <tr className="_no_margin">
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>

                    {showLoading ?
                        <button className="btn_lazy_load"><Icon.Loading /></button>
                        :
                        <>
                            {showLazy ?
                                <button className="btn_lazy_load" onClick={() => getListLazy()}>Cargar más</button>
                                :
                                <h4 className="h4_lazy_load">¡No hay más información!</h4>
                            }
                        </>
                    }
                </>
            }
        </div>;
    }

    React.useEffect(() => {
        if (parent.inload) {
            getList();
        }
    }, [parent]);

    return (
        <div className="content_reportes">
            <Col className="col_table">
                <Card style={{ overflow: "hidden" }}>
                    <CardBody className="dashboard__card-widget">
                        <div className="card__title">
                            <h5 className="bold-text"><ChartTimelineIcon className="dashboard__money-icon" /> Movimientos</h5>
                        </div>

                        <div className="panel__btns">

                            <button
                                className="panel__btn"
                                aria-label="panel__btn"
                                type="button"
                                onClick={handlerRefresh}>
                                <AutorenewIcon className={showLoading ? "on_refresh" : ""} />
                            </button>

                        </div>

                        <History />
                    </CardBody>
                </Card>
            </Col>
        </div>
    )
}

export default Movimientos;