import * as React from "react";
import CloseOutlineIcon from "mdi-react/CloseCircleOutlineIcon";
import Information from "mdi-react/InformationOutlineIcon";
import { MovePopUpStatic } from "shared/MovePopUp";
import { FileUploadImg } from "shared/FileUpload";
import { useNavigate } from "react-router-dom";
import funciones from "services/funciones";
import { Alert } from "shared/Alert";
import { Input } from "shared/Input";
import * as Icon from "images";
import log from "services/log";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const DeliveryView = ({ data, close, reload }) => {
    const [objImg, setObjImg] = React.useState(process.env.NODE_ENV === 'production' ? [] : [
        {
            url: process.env.REACT_APP_IMAGE_EVIDENCE,
            name: "pruebas.jpeg",
            realname: "pruebas.jpeg"
        }]);
    const [showLoading, setShowLoading] = React.useState(false);
    const [isPayVendor, setIsPayVendor] = React.useState(false);
    const [transactions, setTransactions] = React.useState([]);
    const [whoReceives, setWhoReceives] = React.useState("");
    const [titulo, setTitulo] = React.useState("Comentario");
    const [metodosPago, setMetodosPago] = React.useState([]);
    const [estado, setEstado] = React.useState(0);
    const [notes, setNotes] = React.useState("");
    const [error, setError] = React.useState("");
    const navigate = useNavigate();


    const handleError = (_msg) => {
        setError(_msg);

        if (_msg.length > 0) {
            try {
                navigator.vibrate(250);
            } catch (e) {
                log.e('No se puede ejecutar el vibrador:', e);
            }
        }
    }

    const handleClose = (e) => {
        e.preventDefault();
        document.body.style.overflow = "auto";
        close();
    }

    const handleState = (e) => {
        let { target: { value } } = e;
        let _titulo = "Comentario";

        switch (parseInt(value)) {
            case 10:
                _titulo = "Motivo de la reprogramación";
                break;
            case 9:
                _titulo = "Nota del " + data.devolucion;
                break;
            case 12:
            case 8:
            case 7:
                _titulo = "Motivo de la cancelación";
                break;
            case 6:
            default:
                _titulo = "Comentario";
                break;
        }

        handleError("");
        setTitulo(_titulo);
        setEstado(value);
    }

    const armaDatos = (_metodos) => {
        let attachment = objImg.map(i => i.url);
        let _suma = 0;
        let _datos = {
            id_pack: data.id,
            estado: estado,
            receive: whoReceives,
            devolucion: data.devolucion,
            notes: notes,
            file: attachment.toString()
        };

        if (estado == 12) {
            _datos['domicilio'] = _metodos[0].nombre + ": " + parseInt(_metodos[0].valor);
        } else {
            _datos['domicilio'] = data.domicilio_ko;

            _metodos.map(item => {
                let _nombre = item.nombre.toLowerCase();
                _nombre = _nombre.toString().replaceAll(" ", "_");
                _datos[_nombre] = item.valor;
                _datos['metodo_value'] = item.valor;
                _datos['metodo_name'] = _nombre;
                _suma += parseInt(item.valor);
            });

            if (estado == 6 || estado == 9) {
                _datos['efectivo'] = data.price - _suma;
            }
        }

        return _datos;
    }


    /**	Estado de los paquetes
     * 
     *  0 Sin asignar            Estado inicia
     *  1 Por recoger        
     *  2 Recogido           
     *  3 En transito        
     * 11 Borrado            
     * 
     *  4 Para.salir          
     *  5 Por.entregar       
     * 
     *  6 OK                     Entregado
     *  9 OK.DV                  Entregado con devolición 
     * 
     *  8 KO.DOM                 Cancelado con desplazamiento 
     * 12 KO.DOM.PAGO            Cancelado con desplazamiento más pago
     *  7 KO.CANCELADO           Cancelado sin desplazamiento 
     * 13 KO.REPROGRAMADO        Cancelado y reprogramado 
    
     * 14 En.bodega              Devuelto a bodega
     * 10 Dev.bodega             Pendiente de volver a bodega 
     * 15 Dev.pendiente          Pendiente a devolver al cliente  
     * 16 Devuelto               Devuleto al cliente  
     * 17 Pagado                 Pagado al cliente
     *
    */
    const validateFields = (e) => {
        e.preventDefault();
        let _metodos = [];
        let _error = false;

        handleError("");

        if (estado == 0) {
            handleError("¡Debes seleccionar un estado!");
            return;
        }

        if ([6].includes(parseInt(estado))) {

            if (data.price == 0 && whoReceives.length < 1) {
                handleError("¡Debes indicar quien recibe!");
                return;
            }

            if (data.price == 0 && objImg.length < 1) {
                handleError("¡Debes tomar una foto de la fachada!");
                return;
            }
        }

        if ([9].includes(parseInt(estado))) {

            if (data.price == 0 && whoReceives.length < 1) {
                handleError("¡Debes indicar quien recibe!");
                return;
            }

            if (data.price == 0 && objImg.length < 1) {
                handleError("¡Debes tomar dos fotos:\nFoto de la fachada \nFoto del " + data.devolucion + "!");
                return;
            }
        }

        if ([6, 9, 12].includes(parseInt(estado))) {

            if (estado == 12 && metodosPago.length < 1) {
                handleError("¡Debes agregar una forma de pago con el monto del domicilio!");
                return;
            }

            _metodos = metodosPago.map(item => {
                let { metodo, nombre, valor } = item;
                valor = funciones.formatPrice(valor).int;
                item.valor = valor;


                if (parseInt(metodo) < 1) {
                    handleError("¡Debes seleccionar un método de pago!");
                    _error = true;
                    return item;
                }

                if (valor.length < 1) {
                    handleError(`¡Debes indicar el monto de ${nombre.toLowerCase()}!`);
                    _error = true;
                    return item;
                }

                if (parseInt(valor) > parseInt(data.price)) {
                    handleError(`¡El monto de ${nombre.toLowerCase()} no debe ser superior a ${funciones.formatPrice(data.price).format}!`);
                    _error = true;
                    return item;
                }

                return item;
            });

            if (_error) {
                log.e("metodo de pago", _metodos);
                return;
            }
        }

        if ([7, 8, 9, 12, 13].includes(parseInt(estado)) && notes.length < 1) {
            estado == 9 ?
                handleError(`¡Debes indicar una nota para el ${data.devolucion}!`) :
                handleError(`¡Debes indicar el ${titulo.toLowerCase()}!`);
            return;
        }

        if (isPayVendor) {
            _metodos = metodosPago.map(item => {
                let { metodo, nombre, valor } = item;
                valor = funciones.formatPrice(valor).int;
                item.valor = valor;

                if (parseInt(metodo) < 1) {
                    handleError("¡Debes seleccionar un método de pago!");
                    _error = true;
                    return item;
                }

                if (valor.length < 1) {
                    handleError(`¡Debes indicar el monto de ${nombre}!`);
                    _error = true;
                    return item;
                }

                if (parseInt(valor) < 1) {
                    handleError(`¡El monto de ${nombre} no puede ser tan bajo!`);
                    _error = true;
                    return item;
                }

                if (parseInt(valor) > parseInt(data.price)) {
                    handleError(`¡El monto de ${nombre} no debe ser superior a ${funciones.formatPrice(data.price).format}!`);
                    _error = true;
                    return item;
                }

                return item;
            });

            if (_error) {
                log.e("metodo de pago al vendedor", _metodos);
                return;
            }
        }

        if ([8, 12].includes(parseInt(estado)) && objImg.length < 1) {
            handleError("¡Debes tomar una foto de la fachada!");
            return;
        }

        if ([9].includes(parseInt(estado)) && objImg.length < 1) {
            handleError("¡Debes tomar una foto del " + data.devolucion + "!");
            return;
        }

        if (estado == 9) {
            if ((isPayVendor && objImg.length < 2) ||
                (metodosPago.length > 0 && objImg.length < 2)) {
                handleError("¡Debes tomar dos fotos:\nFoto del " + data.devolucion + " \nFoto evidencia del pago!");
                return;
            }
        } else {
            if ((isPayVendor && objImg.length < 1) ||
                (metodosPago.length > 0 && objImg.length < 1)) {
                handleError("¡Debes tomar una foto evidencia del pago!");
                return;
            }
        }

        if (_error) {
            log.e("Tiene errores");
        } else {
            let _datos = armaDatos(_metodos);

            if (isPayVendor) {
                enviaAvalidador(_datos);
            } else {
                marcaEntregado(_datos);
            }
        }
    }

    const enviaAvalidador = async (_datos) => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "envia-a-validador",
            data: _datos
        });

        if (_data.response == 1) {
            reload();
            document.body.style.overflow = "auto";
            close();
        } else if (_data.response === "-3") {
            Alert(_data.msg, "warning", () => {
                reload();
                close();
            });
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const marcaEntregado = async (_datos) => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "update-package-delivered",
            data: _datos
        });

        if (_data.response == 1) {
            reload();
            document.body.style.overflow = "auto";
            close();
        } else if (_data.response === "-3") {
            Alert(_data.msg, "warning", () => {
                reload();
                close();
            });
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    React.useEffect(() => {
        if (data.transactions) {
            let _tran = data.transactions.split("|");
            let _pagos = [];

            _tran.map(item => {
                let _arr = item.split(": ");
                _pagos.push({ entity: _arr[0], number: _arr[1] });
            });

            setTransactions(_pagos);
        }
    }, [data]);

    return (
        <MovePopUpStatic
            parent={data.parent}
            close={handleClose}
            className="win_modal _overflow _delivery"
            title="Estado de la entrega">

            <h5>Paquete: <b>{data.codigo}</b></h5>
            <h5>Valor:  <b>${funciones.formatPrice(data.price).format}</b></h5>
            {transactions.map((item, index) => <h6 key={index} style={{ marginTop: 8 }}>{item.entity}: <b>{item.number}</b></h6>)}

            <div className="_in_column">

                <div>
                    {data.devolucion && <h4 style={{ marginTop: "16px", marginBottom: "-16px" }}>Devolución con {data.devolucion}</h4>}
                    <p className="__titulo">Estado</p>
                    <select name="estado" value={estado} onChange={handleState}>
                        <option value="0">Selecciona...</option>
                        {data.devolucion ? <option value="9">OK.DV</option> : <option value="6">OK</option>}
                        <option value="8" >KO.DOM</option>
                        <option value="12">KO.DOM.PAGO</option>
                        <option value="7" >KO.CANCELADO</option>
                        {/* <option value="10">KO.CF</option> */}
                        <option value="13">KO.REPROGRAMADO</option>
                    </select>
                </div>

                {(estado == 6 || estado == 9 || estado == 12) && <>
                    {estado != 12 && <div>
                        <label className="__titulo">Quien recibe</label>
                        <Input type="text" name="carrier"
                            value={whoReceives} onChange={v => { handleError(""); setWhoReceives(v) }} />
                    </div>}

                    {metodosPago.map((item, index, array) => {
                        return <div key={index} style={{ position: "relative", paddingTop: 18 }}>
                            <CloseOutlineIcon className="close_option" onClick={() => {
                                setMetodosPago(array.filter((_item, _index) => _index !== index));
                                setIsPayVendor(false);
                            }} />

                            {array[index]['metodo'] == "0" ?
                                <>
                                    <p className="__titulo">Método de pago</p>
                                    <select name="estado" value={funciones.formatPrice(array[index]['metodo']).format} onChange={e => {
                                        let _name_pay = e.target.options[e.target.selectedIndex].text;

                                        array[index]['metodo'] = e.target.value;
                                        array[index]['nombre'] = _name_pay;
                                        array[index]['valor'] = data.price;
                                        setMetodosPago([...array]);
                                        handleError("");
                                        setIsPayVendor(_name_pay === "Pago al vendedor" || _name_pay === "Bold");
                                    }}>
                                        <option value="0">Selecciona...</option>
                                        {estado == 12 ?
                                            <option value="1">Efectivo</option>
                                            :
                                            <>
                                                <option value="4">Bold</option>
                                                <option value="5">Pago al vendedor</option>
                                            </>}
                                    </select>
                                </>
                                :
                                <>
                                    <p className="__titulo">{array[index]['nombre']}</p>
                                    <input type="tel" value={funciones.formatPrice(array[index]['valor']).format} onChange={e => {
                                        array[index]['valor'] = e.target.value;
                                        setMetodosPago([...array]);
                                        handleError("");
                                    }} />
                                </>
                            }
                        </div>
                    })}

                    {([6, 9, 12].includes(parseInt(estado)) && metodosPago.length < 1) && <h4 className="_button _width_static" onClick={() => {
                        setMetodosPago([...metodosPago, { metodo: "0", nombre: "", valor: "" }]);
                    }}>Agregar forma de pago<Icon.CashPlus /></h4>}
                </>}

                <div>
                    <p className="__titulo">{titulo}</p>
                    <textarea name="comment" rows="2"
                        value={notes} onChange={({ target: { value } }) => { handleError(""); setNotes(value); }} />
                </div>

            </div>

            {error.length > 1 && <h4 className="_error"><Information />{error}</h4>}

            <FileUploadImg
                img={objImg}
                codigo={data.codigo}
                loading={showLoading}
                onStart={() => setShowLoading(true)}
                onUpload={obj => {
                    let _new_imgs = objImg;
                    _new_imgs.push(obj);

                    handleError("");
                    setObjImg(_new_imgs);
                    setShowLoading(false);
                }}
                onError={error => {
                    handleError(error);
                    setShowLoading(false);
                }} />

            {showLoading ?
                <button className="btn btn-common width-static"><Icon.Loading /></button>
                :
                <button className="btn btn-common width-static" onClick={validateFields}>{isPayVendor ? "Validar" : "Guardar"}</button>
            }

        </MovePopUpStatic >
    )
}

export default DeliveryView;