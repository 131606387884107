import * as React from "react";
import CalendarMonthIcon from "mdi-react/CalendarMonthIcon";
import BarcodeScanIcon from "mdi-react/BarcodeScanIcon";
import PlusThickIcon from "mdi-react/PlusThickIcon";
import ReloadIcon from "mdi-react/RotateRightIcon";
import { Alert, Confirm } from "shared/Alert";
import funciones from "services/funciones";
import DatePicker from "react-datepicker";
import Loading from "shared/Loading";
import Filter from "shared/Filter";
import * as Iconos from "images";
import api from "services/api";
import log from "services/log";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

const ToolBar = (params) => {
    const {
        children,
        crear = () => log.d("No aplica"),
        showAdd = false,
        currentFilterOptions = {
            consignee: "",
            carrier: "",
            estado: "",
        },
        filter = {
            loadUsersList: false,
            showClient: false,
            showCarrier: false,
            showStatus: true,
        } } = params;
    const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7 };
    const [dates, setDates] = React.useState(localStorage.getItem('dates') || 0);
    const [fechaDesde, setFechaDesde] = React.useState(new Date());
    const [fechaHasta, setFechaHasta] = React.useState(new Date());
    const [modalView, setModalView] = React.useState(null);
    const [objDates, setObjDates] = React.useState();



    const handleOpenFilter = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setModalView(
            <Filter
                parent={{ ...e.target.getBoundingClientRect().toJSON(), width: 220 }}
                currentFilterOptions={currentFilterOptions}
                filter={filter}
                close={() => setModalView(null)}
                call={callFilter} />);
    }

    const asignaPaquete = async (codigo) => {
        setModalView(<Loading />);

        let _data = await api.fetchJson({
            url: "save-assign-package-code",
            data: {
                codigo: codigo,
                mensajero: window.atob(data_user.auth)
            }
        });

        if (_data.response == 1) {
            Confirm(`El paquete ha sido asignado correctamente a tu lista de entregas. \n\n¿Te quieres asignar otro?`, response => {
                if (response) {
                    callScanner();
                } else {
                    params.reload();
                }
            }, "ok");
        } else {
            Alert(_data.msg, "warning");
        }

        setModalView(null);
    }

    const asignaRelecta = async (codigo) => {
        setModalView(<Loading />);

        let _data = await api.fetchJson({
            url: "save-assign-collect-code",
            data: {
                codigo: codigo,
                mensajero: window.atob(data_user.auth)
            }
        });

        if (_data.response == 1) {
            Confirm(`Recolecta asignada correctamente a tu lista. \n\n¿Te quieres asignar otra?`, response => {
                if (response) {
                    callScanner();
                } else {
                    params.reload();
                }
            }, "ok");
        } else {
            Alert(_data.msg, "warning");
        }

        setModalView(null);
    }

    const asignaRecogida = async (codigo) => {
        setModalView(<Loading />);

        let toSend = codigo.split("-");
        let _data = await api.fetchJson({
            url: "save-assign-order-code",
            data: {
                mensajero: window.atob(data_user.auth),
                consignee: toSend[0],
                consecutive: toSend[1]
            }
        });

        if (_data.response == 1) {
            Confirm(`La recogida ha sido asignada correctamente a tu lista. \n\n¿Te quieres asignar otra?`, response => {
                if (response) {
                    callScanner();
                } else {
                    params.reload();
                }
            }, "ok");
        } else {
            Alert(_data.msg, "warning");
        }

        setModalView(null);
    }

    const callScanner = () => {
        if (typeof android !== "undefined") {

            // eslint-disable-next-line no-undef
            scanner.response = (codigo) => {
                if (codigo.substring(0, 3) === "OS-") {
                    log.i("Es una orden de servicio");
                    Alert("¡No se puede asignar una orden de servicio!", "warning");
                } else if (codigo.substring(0, 3) === "OR-") {
                    asignaRecogida(codigo.substring(3));
                } else {
                    if (params.type === "recolectas") {
                        asignaRelecta(codigo);
                    } else if (params.type === "distribucion") {
                        asignaPaquete(codigo);
                    }
                }
            };

            // eslint-disable-next-line no-undef
            scanner.error = (format) => Alert(`Error de lectura: \nEl formato del código es "${format}" y no es compatible con esta función.`, "warning");

            // eslint-disable-next-line no-undef
            android.scannerQR();
        }
    }

    const handleScanner = (e) => {
        e.preventDefault();
        e.stopPropagation();

        callScanner();
    }

    const calcWeek = (_fecha) => {
        let _restantes = 6 - _fecha.getDay();
        let _min_left = (60 - _fecha.getMinutes()) * 60000;
        let _hours_left = ((24 - _fecha.getHours()) * 3600000) - _min_left;
        let _time = _fecha.getTime() + (_restantes * 86400000) + _hours_left;

        let _fecha_d = new Date(_time - ((7 * 86400000) - 2400000));
        let _fecha_h = new Date(_time);

        return {
            desde: _fecha_d.getFullYear() + "-" + format(_fecha_d.getMonth() + 1) + "-" + format(_fecha_d.getDate()),
            hasta: _fecha_h.getFullYear() + "-" + format(_fecha_h.getMonth() + 1) + "-" + format(_fecha_h.getDate()),
        }
    }

    const calcNextWeek = (_fecha = new Date()) => {
        let _restantes = 7 - _fecha.getDay();
        let _time = _fecha.getTime() + (_restantes * 86400000);
        let _fecha_d = new Date(_time);
        let _fecha_h = new Date(_time + (6 * 86400000));

        return {
            desde: _fecha_d.getFullYear() + "-" + format(_fecha_d.getMonth() + 1) + "-" + format(_fecha_d.getDate()),
            hasta: _fecha_h.getFullYear() + "-" + format(_fecha_h.getMonth() + 1) + "-" + format(_fecha_h.getDate()),
        }
    }

    const calcAllDates = (_fecha = new Date()) => {
        let _fecha_d = new Date(0);
        let _fecha_h = new Date((31556926 * 300) * 1000);

        return {
            desde: _fecha_d.getFullYear() + "-" + format(_fecha_d.getMonth() + 1) + "-" + format(_fecha_d.getDate()),
            hasta: _fecha_h.getFullYear() + "-" + format(_fecha_h.getMonth() + 1) + "-" + format(_fecha_h.getDate()),
        }
    }

    const callFilter = (_opt) => {
        let _fecha = new Date();
        let _desde, _hasta, _new_date;
        let _opciones = {}

        switch (dates) {
            case "10":
                _new_date = calcAllDates(_fecha);

                _desde = _new_date.desde;
                _hasta = _new_date.hasta;
                break;
            case "9":
                _desde = `${_fecha.getFullYear()}-${format(_fecha.getMonth() + 2)}-01`;
                _hasta = `${_fecha.getFullYear()}-${format(_fecha.getMonth() + 2)}-30`;
                break;
            case "8":
                _new_date = calcNextWeek(_fecha);

                _desde = _new_date.desde;
                _hasta = _new_date.hasta;
                break;
            case "7":
                _desde = `${_fecha.getFullYear()}-${format(_fecha.getMonth() - 5)}-01`;
                _hasta = `${_fecha.getFullYear()}-${format(_fecha.getMonth())}-30`;
                break;
            case "6":
                _desde = `${_fecha.getFullYear()}-${format(_fecha.getMonth() - 2)}-01`;
                _hasta = `${_fecha.getFullYear()}-${format(_fecha.getMonth())}-30`;
                break;
            case "5":
                _desde = `${_fecha.getFullYear()}-01-01`;
                _hasta = `${_fecha.getFullYear()}-12-30`;
                break;
            case "4":
                _desde = `${_fecha.getFullYear()}-${format(_fecha.getMonth() + 1)}-01`;
                _hasta = `${_fecha.getFullYear()}-${format(_fecha.getMonth() + 1)}-30`;
                break;
            case "3":
                _new_date = calcWeek(_fecha);

                _desde = _new_date.desde;
                _hasta = _new_date.hasta;
                break;
            case "2":
                _desde = _fecha.getFullYear() + "-" + format(_fecha.getMonth() + 1) + "-" + format(_fecha.getDate() - 1);
                _hasta = _fecha.getFullYear() + "-" + format(_fecha.getMonth() + 1) + "-" + format(_fecha.getDate() - 1);
                break;
            case "1":
                _desde = _fecha.getFullYear() + "-" + format(_fecha.getMonth() + 1) + "-" + format(_fecha.getDate());
                _hasta = _fecha.getFullYear() + "-" + format(_fecha.getMonth() + 1) + "-" + format(_fecha.getDate());
                break;
        }

        for (let i in _opt) {
            let _valor = _opt[i].toString();

            if (_valor.length > 0) {
                _opciones[i] = _valor;
            }
        }

        _opciones.desde = _desde;
        _opciones.hasta = _hasta;

        setObjDates(_opciones);
        params.reload(_opciones);
    }

    React.useEffect(() => {
        if (data_user.nivel == 4) {
            setDates("10");
        } else {
            setDates(localStorage.getItem('dates') || "10");
        }
    }, []);

    React.useEffect(() => {
        let _fecha = new Date();
        let _desde, _hasta, _new_date, _obj_dates;

        if (data_user.nivel != 4 && data_user.nivel != 6) {
            localStorage.setItem('dates', dates);
        }

        switch (dates) {
            case "10":
                _new_date = calcAllDates(_fecha);

                _desde = _new_date.desde;
                _hasta = _new_date.hasta;
                break;
            case "9":
                _desde = `${_fecha.getFullYear()}-${format(_fecha.getMonth() + 2)}-01`;
                _hasta = `${_fecha.getFullYear()}-${format(_fecha.getMonth() + 2)}-30`;
                break;
            case "8":
                _new_date = calcNextWeek(_fecha);

                _desde = _new_date.desde;
                _hasta = _new_date.hasta;
                break;
            case "7":
                _desde = `${_fecha.getFullYear()}-${format(_fecha.getMonth() - 5)}-01`;
                _hasta = `${_fecha.getFullYear()}-${format(_fecha.getMonth())}-30`;
                break;
            case "6":
                _desde = `${_fecha.getFullYear()}-${format(_fecha.getMonth() - 2)}-01`;
                _hasta = `${_fecha.getFullYear()}-${format(_fecha.getMonth())}-30`;
                break;
            case "5":
                _desde = `${_fecha.getFullYear()}-01-01`;
                _hasta = `${_fecha.getFullYear()}-12-30`;
                break;
            case "4":
                _desde = `${_fecha.getFullYear()}-${format(_fecha.getMonth() + 1)}-01`;
                _hasta = `${_fecha.getFullYear()}-${format(_fecha.getMonth() + 1)}-30`;
                break;
            case "3":
                _new_date = calcWeek(_fecha);

                _desde = _new_date.desde;
                _hasta = _new_date.hasta;
                break;
            case "2":
                _desde = _fecha.getFullYear() + "-" + format(_fecha.getMonth() + 1) + "-" + format(_fecha.getDate() - 1);
                _hasta = _fecha.getFullYear() + "-" + format(_fecha.getMonth() + 1) + "-" + format(_fecha.getDate() - 1);
                break;
            case "1":
                _desde = _fecha.getFullYear() + "-" + format(_fecha.getMonth() + 1) + "-" + format(_fecha.getDate());
                _hasta = _fecha.getFullYear() + "-" + format(_fecha.getMonth() + 1) + "-" + format(_fecha.getDate());
                break;
        }

        if (![0, 11].includes(parseInt(dates))) {
            _obj_dates = {
                ...currentFilterOptions,
                desde: _desde,
                hasta: _hasta,
            };

            if (_obj_dates.consignee == "" &&
                _obj_dates.carrier == "" &&
                _obj_dates.estado == "") {

                _obj_dates.dates = dates;
            } else {
                _obj_dates.search = "1";
            }

            setObjDates(_obj_dates);
            params.reload(_obj_dates);
        }

    }, [dates]);

    React.useEffect(() => {
        if (dates === "11") {
            let _obj_dates = {
                ...currentFilterOptions,
                dates: dates,
                desde: fechaDesde.getFullYear() + "-" + format(fechaDesde.getMonth() + 1) + "-" + format(fechaDesde.getDate()),
                hasta: fechaHasta.getFullYear() + "-" + format(fechaHasta.getMonth() + 1) + "-" + format(fechaHasta.getDate()),
            };

            setObjDates(_obj_dates);
            params.reload(_obj_dates);
        }
    }, [fechaDesde, fechaHasta]);

    return (
        <div className="tool_bar">
            <ReloadIcon className={params.showLoading ? "btn_refresh on_refresh" : "btn_refresh"} onClick={() => params.reload(objDates)} />
            {((showAdd && funciones.validaPermisos("pack_edicion")) || (showAdd && data_user.nivel == 6)) ? <PlusThickIcon onClick={crear} /> : null}
            <>
                {((currentFilterOptions.consignee === undefined &&
                    currentFilterOptions.carrier === undefined &&
                    currentFilterOptions.estado === undefined) ||
                    (currentFilterOptions.consignee == "" &&
                        currentFilterOptions.carrier == "" &&
                        currentFilterOptions.estado == "")) ?
                    <Iconos.FilterMenu onClick={handleOpenFilter} />
                    :
                    <Iconos.FilterCheck onClick={handleOpenFilter} color="#008000" />}
            </>
            {(data_user.nivel == 4 && typeof android !== "undefined") && <BarcodeScanIcon onClick={handleScanner} />}

            {children}

            {(data_user.nivel != 4 && data_user.nivel != 6) && <div className="_in_row">
                {typeof android === "undefined" && <p>Fechas:</p>}
                <select name="dates"
                    defaultValue={dates}
                    onChange={({ target: { value } }) => setDates(value)}>

                    <option value="1">Hoy</option>
                    <option value="2">Ayer</option>
                    <option value="3">Esta semana</option>
                    <option value="4">Este mes</option>
                    <option value="6">Últimos tres meses</option>
                    <option value="7">Últimos seis meses</option>
                    <option value="5">Este año</option>
                    {/* <option value="8">Siguiente semana</option> */}
                    {/* <option value="9">Siguiente mes</option> */}
                    <option value="10">Todos</option>
                    <option value="11">Personalizado</option>

                </select>
            </div>}

            {dates === "11" &&
                <div className="_in_row">
                    <div className="calendar">
                        <DatePicker dateFormat="yyyy/MM/dd" name="desde" selected={fechaDesde} onChange={(date) => setFechaDesde(date)} />
                        <CalendarMonthIcon />
                    </div>

                    <div className="calendar">
                        <DatePicker dateFormat="yyyy/MM/dd" name="hasta" selected={fechaHasta} onChange={(date) => setFechaHasta(date)} />
                        <CalendarMonthIcon />
                    </div>

                    {/* <AutorenewIcon /> */}
                </div>}

            {modalView}
        </div>
    )
}

export default ToolBar;