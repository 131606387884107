import * as React from "react";
import PackageIcon from "mdi-react/PackageVariantClosedIcon";
import { RequirePermissions } from "shared/AlertMessages";
import { TitleSticky } from "shared/Components";
import withRouter from "services/withRouter";
import funciones from "services/funciones";
import MenuRow from "./components/MenuRow";
import ToolBar from "shared/tool_bar";
import { Alert } from "shared/Alert";
import { Api } from "services/api";
import { useMedia } from "hooks";
import * as Icon from "images";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const meses = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

const Reception = (router) => {
    const { navigate } = router;
    const { media_movil, setTitulo, setDataUser, currentQuery, setCurrentQuery } = useMedia();
    const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7 };
    const [loadResult, setLoadResult] = React.useState("Cargando ordenes...");
    const api = React.useRef(new Api({ navigate, setDataUser }));
    const [showLoading, setShowLoading] = React.useState(false);
    const [selected, setSelected] = React.useState({ id: 0 });
    const [showLazy, setShowLazy] = React.useState(true);
    const [dataList, setDataList] = React.useState([]);


    const Row = ({ data, blue }) => {
        let fecha_recibido = new Date(data.date_received);

        return (
            <MenuRow
                data={data}
                blue={blue}
                navigate={navigate}
                current={selected.id}
                onSel={setSelected}
                reload={() => getList(currentQuery.recepcion)}>

                <td><PackageIcon style={{ marginRight: 8 }} /> {data.consecutivo}</td>
                <td>{format(fecha_recibido.getDate())}/{meses[fecha_recibido.getMonth()]}/{fecha_recibido.getFullYear()}</td>
                {!media_movil && <td>{data.consignee_name}</td>}
                {!media_movil && <td>{data.consignee_address}</td>}

            </MenuRow>
        );
    }

    const AllRow = () => {
        let _data = [];

        dataList.map((item, index) => {
            _data.push(<Row key={item.id} blue={index % 2} data={item} />);
        });

        if (_data.length < 1) {
            return <h4 className="_titulo">{loadResult}</h4>;
        } else {
            return <>
                <table className="table">
                    <tbody>

                        <tr className="table_title">
                            <th>Número</th>
                            <th>Recibido</th>
                            {!media_movil && <th>Cliente bts</th>}
                            {!media_movil && <th>Dirección</th>}
                        </tr>

                        {_data}
                    </tbody>
                </table>

                {showLoading ?
                    <button className="btn_lazy_load"><Icon.Loading /></button>
                    :
                    <>
                        {showLazy ?
                            <button className="btn_lazy_load" onClick={() => getListLazy(currentQuery.recepcion)}>Cargar más</button>
                            :
                            <h4 className="h4_lazy_load">{loadResult}</h4>
                        }
                    </>
                }
            </>;
        }
    }

    const getListLazy = async (_filter) => {
        setLoadResult("Cargando ordenes...");
        setShowLoading(true);

        let _data = await api.current.getReceptionList({ ..._filter, type: 1, offset: dataList.length });

        if (_data.response == 1) {
            let _packs = [];

            dataList.map(item => {
                _packs.push(item);
            });

            _data.data.map(item => {
                _packs.push(item);
            });

            setShowLazy(_data.data.length < 50 ? false : true);
            setDataList(_packs);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        if (_filter?.search == 1) {
            let _msg = "";
            _msg += (_filter.consignee?.length > 0 && _filter.consigneeName) ? "\ncliente \"" + _filter.consigneeName + "\"" : "";
            _msg += (_filter.carrier?.length > 0 && _filter.carrierName) ? "\nmensajero \"" + _filter.carrierName + "\"" : "";
            _msg += (_filter.estado?.length > 0 && _filter.estadoName) ? "\nestado \"" + _filter.estadoName + "\"" : "";

            setLoadResult((!_data?.data || _data?.data.length < 1) ? "No hay ordenes con: " + _msg : "No hay más ordenes con: " + _msg);
        } else {
            setLoadResult((!_data?.data || _data?.data.length < 1) ? "¡No hay ordenes!" : "¡No hay más ordenes!");
        }
        setShowLoading(false);
    }

    const getList = async (_filter) => {
        setLoadResult("Cargando ordenes...");
        setShowLoading(true);

        let _data = await api.current.getReceptionList({ ..._filter, type: 1, offset: 0 });

        if (_data.response == 1) {
            setShowLazy(_data.data.length < 50 ? false : true);
            setDataList(_data.data);
            setCurrentQuery(v => ({ ...v, recepcion: { ..._filter } }));
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        if (_filter?.search == 1) {
            let _msg = "";
            _msg += (_filter.consignee?.length > 0 && _filter.consigneeName) ? "\ncliente \"" + _filter.consigneeName + "\"" : "";
            _msg += (_filter.carrier?.length > 0 && _filter.carrierName) ? "\nmensajero \"" + _filter.carrierName + "\"" : "";
            _msg += (_filter.estado?.length > 0 && _filter.estadoName) ? "\nestado \"" + _filter.estadoName + "\"" : "";

            setLoadResult((!_data?.data || _data?.data.length < 1) ? "No hay ordenes con: " + _msg : "No hay más ordenes con: " + _msg);
        } else {
            setLoadResult((!_data?.data || _data?.data.length < 1) ? "¡No hay ordenes!" : "¡No hay más ordenes!");
        }
        setShowLoading(false);
    }

    // Ordenes de recepción de administración
    React.useEffect(() => {
        setTitulo("Ordenes de recepción");
        document.body.style.overflow = "auto";
    }, []);

    return (
        <RequirePermissions navigate={navigate}
            permission={funciones.validaPermisos("pack_edicion") && ![6, 7].includes(parseInt(data_user.nivel))}>

            <div className="cont_principal">
                <TitleSticky className="_in_filters">Ordenes de recepción</TitleSticky>
                <ToolBar {...router}
                    type="recepcion"
                    showLoading={showLoading}
                    reload={getList}
                    showAdd={true}
                    currentFilterOptions={currentQuery.recepcion}
                    filter={{
                        loadUsersList: true,
                        showClient: true,
                        showCarrier: false,
                        showStatus: false
                    }}
                    crear={() => navigate("/dashboard/nueva-orden-de-servicio")} />

                <AllRow />
            </div>
        </RequirePermissions>
    )
}

export default withRouter(Reception);
