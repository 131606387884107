import { useLocation } from "react-router-dom";
import { Search } from "shared/Alert";
import * as React from "react";
import log from "services/log";

const ContextMedia = React.createContext();

export function MediaProvider({ children }) {
    const [data_user, setDataUser] = React.useState(JSON.parse(localStorage.getItem("usuario") ?? '{ "nivel": 7 }'));
    const [media_movil, setMediaMovil] = React.useState(window.innerWidth > 576 ? false : true);
    const [currentQuery, setCurrentQuery] = React.useState({
        mensajeros: { task: "carrier", criterio: "" },
        clientes: { task: "pay_clients", criterio: "", filtro: "" },
        users_tienda: { task: "users_tienda", criterio: "", rol: 61 },
        users: { task: "users", criterio: "", rol: 7 }
    });
    const location = useLocation();


    /**
     * Configura el titulo de la paguína actual y
     * guarda el estado para cuando caduque la sesión 
     * redirija a esta ultima pagina
     * @param {String} title titulo de la pagina 
     * @param {JSON} state la información de la pagina actual `rol` o `data`
     */
    const setTitulo = (_titulo, _show = true) => {
        document.title = _titulo;
        log.title(_titulo);

        if (typeof android !== "undefined") {
            // eslint-disable-next-line no-undef
            android.setLastPage(window.location.href);
        }
    }

    React.useEffect(() => {
        let _params = {};
        location.search.replace("?", "").split("&").forEach(p => {
            let _p = p.split("=");
            _params[_p[0]] = _p[1];
        });

        if (_params?.ref === "twa") {
            window.android = {
                ..._params,
                setLastPage: (url) => {
                    log.d("setLastPage", url);
                },
                setSwipeRefresh: (val) => {
                    log.d("setSwipeRefresh", val);
                },
                getVersion: () => {
                    log.d("getVersion", _params.version);
                    return _params.version;
                },
                verWebAuxiliar: (url) => {
                    log.d("verWebAuxiliar", url);
                },
                scannerQR: () => {
                    setTimeout(() => {
                        Search((codigo) => {
                            setTimeout(() => {
                                // eslint-disable-next-line no-undef
                                scanner.response(codigo);
                                // scanner.error('janna');
                            }, 400);
                        }, "Esta ventana es para pruebas\nIngresa el código escaneado en el QR");
                    }, 400);
                },
                closeWebAuxiliar: () => {
                    log.d("closeWebAuxiliar");
                },
                getToken: () => {
                    log.d("getToken");
                },
                saveDataLogin: (login) => {
                    log.d("saveDataLogin", login);
                },
                eliminaArchivo: (file) => {
                    log.d("eliminaArchivo", file);
                }
            }
            // log.d("params", _params);
        }

        if (window.android) {
            log.d("android", window.android);
        }

        // ?ref=twa&source=android&version=2.0.0
    }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps 

    React.useEffect(() => {
        let mediaQuery = window.matchMedia('(max-width: 576px)');

        let handleChange = (e) => {
            setMediaMovil(e.matches);
        }

        mediaQuery.addListener(handleChange);

        return () => mediaQuery.removeListener(handleChange);
    }, []);

    return <ContextMedia.Provider
        value={{ media_movil, data_user, setDataUser, setTitulo, currentQuery, setCurrentQuery }}>
        {children}
    </ContextMedia.Provider>
}

export { ContextMedia };