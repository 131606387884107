import * as React from "react";
import CloseIcon from "mdi-react/CloseCircleOutlineIcon";
import { Input, InputNum } from "shared/Input";
import funciones from "services/funciones";
import { useMedia } from "hooks";

/* eslint eqeqeq: 0 */

const EditContainer = ({ item, update, remove }) => {
    const [valorp, setValorp] = React.useState(funciones.formatPrice(item.valorp).format);
    const [destinatario, setDestinatario] = React.useState(item.destinatario);
    const [telefono, setTelefono] = React.useState(item.telefono);
    const [direccion, setDireccion] = React.useState(item.direccion);
    const [localidad, setLocalidad] = React.useState(item.localidad);
    const [ciudad, setCiudad] = React.useState(item.ciudad);
    const [volumen, setVolumen] = React.useState(item.volumen == "" ? false : true);
    const [peso, setPeso] = React.useState(item.peso == "" ? false : true);
    const [tiene_dev, setTiene_dev] = React.useState(item.tiene_dev || false);
    const [devolucion, setDevolucion] = React.useState(item.devolucion || "");
    const [descripcion, setDescripcion] = React.useState(item.descripcion || "");
    const [kodv, setKodv] = React.useState(item.devolucion || "");
    const [devuelve, setDevuelve] = React.useState(false);
    const { media_movil } = useMedia();
    const volumenRef = React.useRef();
    const pesoRef = React.useRef();


    const updatePackage = (param, value) => {

        let _pack = {
            ...item,
            "destinatario": param === "destinatario" ? value : destinatario,
            "telefono": param === "telefono" ? value : telefono,
            "direccion": param === "direccion" ? value : direccion,
            "localidad": param === "localidad" ? value : localidad,
            "ciudad": param === "ciudad" ? value : ciudad,
            "volumen": (param === "volumen" ? value : volumen) ? "volumen" : "",
            "peso": (param === "peso" ? value : peso) ? "peso" : "",
            "valorp": param === "valorp" ? value.replaceAll(".", "") : valorp.replaceAll(".", ""),

            "tiene_dev": param === "tiene_dev" ? value : tiene_dev,
            "devolucion": param === "devolucion" ? value : devolucion,

            "descripcion": param === "descripcion" ? value : descripcion,
        };

        param === "destinatario" && setDestinatario(value);
        param === "telefono" && setTelefono(value);
        param === "direccion" && setDireccion(value);
        param === "localidad" && setLocalidad(value);
        param === "ciudad" && setCiudad(value);
        param === "volumen" && setVolumen(value);
        param === "peso" && setPeso(value);
        param === "valorp" && setValorp(value);
        param === "tiene_dev" && setTiene_dev(value);
        param === "devolucion" && setDevolucion(value);
        param === "descripcion" && setDescripcion(value);

        update(_pack);
    }

    const RadioGoup = () => {

        const handleDevol = (_val) => {
            setDevuelve(_val);
            updatePackage("tiene_dev", _val);
        }

        const handleSelect = (e) => {
            let _val = e.target.value;

            updatePackage("devolucion", _val);
            setKodv(_val);
        }

        return <>
            {devuelve ?
                <fieldset className="_radio_group">
                    <legend>Tipo de devolución</legend>
                    <CloseIcon className="_close" onClick={() => handleDevol(false)} />

                    <label onClick={() => handleSelect({ target: { value: "cambio" } })}>
                        <input type="radio" name={"devolucion_" + item.index} value="cambio"
                            checked={kodv === "cambio"} onChange={handleSelect} />
                        Cambio
                    </label>
                    <div className="input">
                        <input type="hidden" name="tipo_devolucion" />
                    </div>
                    <label onClick={() => handleSelect({ target: { value: "sobrante" } })}>
                        <input type="radio" name={"devolucion_" + item.index} value="sobrante"
                            checked={kodv === "sobrante"} onChange={handleSelect} />
                        Sobrante
                    </label>
                </fieldset>
                :
                <div className="_radio_group">
                    <label>
                        <input type="checkbox"
                            defaultChecked={false}
                            onChange={e => handleDevol(e.target.checked)} />

                        Agregar devolución
                    </label>
                </div>
            }
        </>;
    }

    React.useEffect(() => {
        volumenRef.current.checked = volumen;
        pesoRef.current.checked = peso;
        setDevuelve(item.tiene_dev || false); 
    }, [item]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="new_container">

            <fieldset className="new_features">
                <legend><span style={{ backgroundColor: "#172B44", color: "white" }}>Nuevo Paquete</span></legend>
                <h5>
                    <CloseIcon onClick={() => remove(item.index, item.id)} />
                </h5>

                <div className="f_row" style={{ maxWidth: 600, paddingTop: 0 }}>

                    <div className="f_row" style={{ alignItems: "self-start", width: "100%", marginTop: 0 }}>

                        <fieldset className="_in_row _no_border">
                            <legend>Destinatario *</legend>
                            <Input name="destinatario" placeholder="Ingresa el nombre"
                                value={destinatario} onChange={(value) => updatePackage("destinatario", value)} />
                        </fieldset>

                        <fieldset className="_in_row _no_border">
                            <legend>Teléfono *</legend>
                            <Input name="telefono" placeholder="Ingresa el teléfono"
                                value={telefono} onChange={(value) => updatePackage("telefono", value)} />
                        </fieldset>

                    </div>

                    <div className="f_row" style={{ alignItems: "self-start", width: "100%", marginTop: 0, paddingTop: 0 }}>

                        <fieldset className="_in_row _no_border">
                            <legend>Dirección *</legend>
                            <div className="input">
                                <input type="text"
                                    name="direccion"
                                    value={direccion}
                                    placeholder="Ingresa la dirección"
                                    onChange={e => updatePackage("direccion", e.target.value)} />
                            </div>
                        </fieldset>

                        <fieldset className="_in_row _no_border">
                            <legend>Localidad *</legend>
                            <Input name="localidad" placeholder="Ingresa la localidad"
                                value={localidad} onChange={(value) => updatePackage("localidad", value)} />
                        </fieldset>

                        <fieldset className="_in_row _no_border">
                            <legend>Ciudad *</legend>
                            <Input name="ciudad" placeholder="Ingresa la ciudad"
                                value={ciudad} onChange={(value) => updatePackage("ciudad", value)} />
                        </fieldset>

                    </div>

                    <RadioGoup />

                    <div className="f_row" style={{ alignItems: "self-start", width: "100%", marginTop: 0, paddingTop: 0 }}>

                        <fieldset className="_in_row _no_border">
                            <legend>Valor producto *</legend>
                            <InputNum name="valorp" placeholder="Ingresa el precio"
                                value={valorp} onChange={(value) => updatePackage("valorp", value)} />
                        </fieldset>

                        <fieldset className="_in_row _no_border _static_width">
                            <legend>Descripción</legend>
                            <div className="input">
                                <textarea name="descripcion" rows={media_movil ? "2" : "5"} value={descripcion} placeholder="Agregar descripción"
                                    onChange={e => updatePackage("descripcion", e.target.value)} style={{ paddingTop: media_movil ? "5rem" : "3.4rem", paddingBottom: 16 }} />
                            </div>
                            <div className="_checkbox_group">
                                <label>
                                    <input type="checkbox" ref={volumenRef}
                                        defaultChecked={false}
                                        onChange={e => updatePackage("volumen", e.target.checked)} />
                                    Volumen
                                </label>
                                <label>
                                    <input type="checkbox" ref={pesoRef}
                                        defaultChecked={false}
                                        onChange={e => updatePackage("peso", e.target.checked)} />
                                    Peso
                                </label>
                            </div>
                        </fieldset>

                    </div>

                </div>
            </fieldset>
        </div>
    )
}

export default EditContainer;