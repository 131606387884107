import * as React from 'react';
import { EditAgent, NewAgent } from "../Usuarios/components/Agent";
import { useMedia } from 'hooks';


/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

export const EditUser = ({ children, change, data }) => {
    const [_pos, setPos] = React.useState({ top: "0", left: "0" });
    const [show, sertShow] = React.useState(false);
    const { media_movil } = useMedia();


    const handleAction = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (typeof data === 'object') {
            setPos(e.target.getBoundingClientRect().toJSON());
            sertShow(true);
        }
    }

    return (
        <div className="btn_tool">

            <div className="icon_tool" onClick={handleAction}>
                {children}
            </div>

            {show && <EditAgent
                data={data}
                parent={{ ..._pos, width: media_movil ? (document.getElementById("root").offsetWidth - 16) : 500 }}
                close={() => sertShow(false)}
                onChange={change} />}

        </div>
    )
}

export const NewUser = ({ children, change, rol }) => {
    const [_pos, setPos] = React.useState({ top: "0", left: "0" });
    const [show, sertShow] = React.useState(false);
    const { media_movil } = useMedia();


    const handleAction = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setPos(e.target.getBoundingClientRect().toJSON());
        sertShow(true);
    }

    return (
        <div className="btn_tool">

            <div className="icon_tool" onClick={handleAction}>
                {children}
            </div>

            {show && <NewAgent
                rol={rol}
                parent={{ ..._pos, width: media_movil ? (document.getElementById("root").offsetWidth - 16) : 500 }}
                close={() => sertShow(false)}
                onChange={change} />}

        </div>
    )
}