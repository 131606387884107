import * as React from 'react';
import employee from '../../images/employee_forbidden.svg';
import { NavLink } from 'react-router-dom';

const Forbidden = () => {

    React.useEffect(() => {
        document.title = "Página no permitida";
    }, []);

    return (
        <div className="container_error">
            <p>
                <span>No permitido</span> No tienes permitido ver este contenido.
                <br />
                <br /> Contacta con el administrador para más información <NavLink to="mailto:desarollo@sarguero.com">desarollo@sarguero.com</NavLink>.
            </p>
            <img src={employee} alt="imagen de error" />
        </div>
    )
}

export default Forbidden;