import * as React from "react";
import DeleteIcon from "mdi-react/DeleteOutlineIcon";
import EditIcon from "mdi-react/EditOutlineIcon";
import ChatIcon from "mdi-react/ChatOutlineIcon";
import HistoryIcon from "mdi-react/HistoryIcon";
import OpenIcon from "mdi-react/OpenInNewIcon";
import funciones from "services/funciones";
import CourierList from "./CourierList";
import History from "shared/History";
import * as Iconos from "images";
import { useMedia } from "hooks";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const MenuRow = (params) => {
    const { navigate, data, blue, children, onSel, reload, current, inRow = false } = params;
    const [clases, setClases] = React.useState(blue == 0 ? "table_row bkg_blue" : "table_row");
    const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7 };
    const [_pos, set_pos] = React.useState({ top: "0", left: "0" });
    const [modalView, setModalView] = React.useState(null);
    const [showMenu, setShowMenu] = React.useState(false);
    const [_e, set_e] = React.useState();
    const { media_movil } = useMedia();
    const menuRef = React.useRef();
    const row = React.useRef(null);


    const goChat = (e) => {
        e.preventDefault()
        e.stopPropagation();
        document.body.style.overflow = "auto";
        navigate("/dashboard/chat",
            {
                state: {
                    data: {
                        guest: {
                            id_user: data_user.nivel == 6 ? data.recogida.carrier : data.consignee,
                            nombre: data_user.nivel == 6 ? data.carrier_name : data.consignee_nombre,
                            apellido: data_user.nivel == 6 ? "" : data.consignee_apellido
                        },
                        fromOutside: true
                    }
                }
            });
    }

    const goOpen = (e) => {
        e.preventDefault()
        e.stopPropagation();
        document.body.style.overflow = "auto";
        navigate("/dashboard/orden-de-recogida", { state: { data: data } });
    }

    const onSelect = (e) => {
        e.preventDefault()
        e.stopPropagation();

        onSel(data);
    }

    const stopClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    const hiddenMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();

        document.body.style.overflow = "auto";
        setShowMenu(false);
    }

    const handleContextMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();

        set_e(e);
        document.body.style.overflow = "hidden";
        setShowMenu(true);
    }

    const handleModal = (_option, e) => {
        e.preventDefault();
        e.stopPropagation();
        let _window;

        switch (_option) {
            case "history":
                _window = <History
                    data={{
                        ...data,
                        tipo: 1,
                        parent: { ..._pos, width: media_movil ? (document.getElementById("root").offsetWidth - 16) : 550 }
                    }}
                    close={() => setModalView(null)} />;
                break;
            case "assign_pickup":
                _window = <CourierList
                    data={{
                        ...data,
                        parent: { ..._pos, width: 250 }
                    }}
                    close={() => setModalView(null)}
                    reload={reload} />;
                break;
            default:
                _window = null;
                break;
        }

        setShowMenu(false);
        setModalView(_window);
    }

    const BtnReceive = () => {
        return <li className="unable"><Iconos.PackReceive color="rgb(170, 170, 170)" /> <span style={{ color: "rgb(170, 170, 170)" }}>Recibir en almacén</span></li>;
    }

    const BtnAssign = () => {
        return <li className="unable"><Iconos.BoxMarker color="rgb(170, 170, 170)" /> <span style={{ color: "rgb(170, 170, 170)" }}>Cambiar mensajero</span></li>;
    }

    const BtnDelete = () => {
        return <li className="unable"><DeleteIcon color="rgb(170, 170, 170)" /> <span style={{ color: "rgb(170, 170, 170)" }}>Borrar</span></li>;
    }

    const BtnChat = () => {
        let _btn = <li className="unable"><ChatIcon color="rgb(170, 170, 170)" /> <span style={{ color: "rgb(170, 170, 170)" }}>Abrir chat</span></li>;

        if (data_user.nivel < 4 && data.consignee != null) {
            _btn = <li onClick={goChat}><ChatIcon /> <span>Abrir chat</span></li>;
        }

        if (data_user.nivel == 6 && data.recogida.carrier != null) {
            _btn = <li onClick={goChat}><ChatIcon /> <span>Abrir chat</span></li>;
        }

        return (_btn);
    }

    const BtnEdit = () => {
        return <li className="unable"><EditIcon color="rgb(170, 170, 170)" /> <span style={{ color: "rgb(170, 170, 170)" }}>Editar</span></li>;
    }

    const AllButtons = () => {
        return (
            <>
                {showMenu &&
                    <>
                        <div className="back_form _bkg_black" onClick={hiddenMenu} onContextMenu={hiddenMenu}></div>
                        <nav className="menu" style={{ ..._pos, width: media_movil ? 220 : 180 }} ref={menuRef}>
                            <ul>
                                {inRow && <li onClick={goOpen}><OpenIcon /> <span><strong>Abrir</strong></span></li>}
                                <BtnEdit />
                                <BtnChat />

                                <hr />
                                {data_user.nivel < 4 && <BtnAssign />}
                                <BtnReceive />
                                {data_user.nivel < 5 && <hr />}

                                <BtnDelete />

                                {data_user.nivel < 4 && <li onClick={e => handleModal("history", e)}><HistoryIcon /> <span>Historial</span></li>}
                            </ul>
                        </nav>
                    </>}
            </>)
    }

    React.useEffect(() => {
        if (showMenu) {
            set_pos(funciones.getPositonDisplay(_e, menuRef.current));
        }
    }, [showMenu]);

    React.useEffect(() => {
        if (inRow) {
            current === data.id && setClases(c => c + " bkg_select");
        }
    }, [current]);

    return (
        <>
            {inRow ?
                <tr className={clases} ref={row}
                    onDoubleClick={goOpen}
                    onClick={onSelect}
                    onContextMenu={handleContextMenu}>

                    {children}

                    <td className="rowMenu" onClick={stopClick} onDoubleClick={stopClick} onContextMenu={stopClick}>
                        <AllButtons />
                        {modalView}
                    </td>
                </tr>
                :
                <>
                    <div className="main_container" onContextMenu={handleContextMenu}>

                        {children}

                        <AllButtons />
                    </div>
                    {modalView}
                </>
            }
        </>
    )
}

export default MenuRow;