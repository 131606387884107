import * as React from "react";
import withRouter from "services/withRouter";
import funciones from "services/funciones";
import VisorImg from "shared/VisorImg";
import { useMedia } from "hooks";
import { Alert } from "shared/Alert";
import BarCode from "shared/BarCode";
import Loading from "shared/Loading";
import * as Iconos from "images";
import api from "services/api";
import Menu from "./Menu";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
const mes = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

const DetalleAndroid = ({ navigate, location, params }) => {
    const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7 };
    const [fecha_recibido, setFechaRecibido] = React.useState(new Date());
    const [observaciones, setObservaciones] = React.useState([]);
    const [mensaje, setMensaje] = React.useState("Cargando...");
    const [showLoading, setShowLoading] = React.useState(true);
    const { media_movil, setTitulo } = useMedia();
    const [data, setData] = React.useState();

    const handleClose = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (typeof android !== "undefined") {
            // eslint-disable-next-line no-undef
            android.closeWebAuxiliar();
        }
    }

    const handleZoom = (e, imagen) => {
        e.preventDefault();

        VisorImg([imagen]);
    }

    const Attachments = ({ files }) => {
        let _list = [];
        files = files.toString().split(",");

        files.map((_file, index) => {
            let _data_file = _file.split("|");

            let _new_file = new File([_data_file[1]], _data_file[0], { type: "image/" + _data_file[0].split('.').pop() });

            _new_file = Object.assign(_new_file, { preview: _data_file[1] });

            _list.push(<img src={process.env.REACT_APP_SERVER + _data_file[1]} key={index}
                alt={_data_file[0] + " en el paquete " + data.codigo}
                onClick={e => handleZoom(e, _new_file)} />);
        });

        observaciones.map((_obj, _i) => {
            if (_obj.attachments && _obj.attachments.length > 0) {
                let _fecha = new Date(_obj.date);
                let _obj_files = _obj.attachments.split(",");
                _obj_files.map((f, i) => {
                    let _nombre = f.split("/").pop();
                    let _file_attach = new File([f], _nombre, { type: "image/" + _nombre.split('.').pop() });

                    _file_attach = Object.assign(_file_attach, { preview: f });

                    _list.push(<div className="_evidence" key={"o_" + _i + "_" + i}>
                        <img src={process.env.REACT_APP_SERVER + f}
                            alt={"Imagen de evidencia " + data.codigo}
                            onClick={e => handleZoom(e, _file_attach)} />
                        <i>{_fecha.getDate()}/{mes[_fecha.getMonth()]}/{_fecha.getFullYear()} {format(_fecha.getHours())}:{format(_fecha.getMinutes())}</i>
                        <i className="_note"><b>{_obj.who_received ?? "..."}</b><br />{_obj.note ?? "..."}</i>
                    </div>);
                });
            }
        });

        return <div className="contain_files">{_list}</div>;
    }

    const getData = async (_codebar) => {
        setShowLoading(true);
        setData();
        setObservaciones([]);
        setMensaje(`Buscando código "${_codebar}"...`);

        let _data = await api.fetchJson({
            url: "get-packages",
            data: {
                type: "por_codigo",
                codebar: _codebar
            }
        });

        let _notes = await api.fetchJson({
            url: "get-packages",
            data: {
                type: "notas_pack",
                codebar: _codebar
            }
        });

        if (_data.response == 1) {
            if (_data.data.length > 0) {
                let _datos = _data.data[0];
                _datos.extra = JSON.parse(_datos.extra);
                setData(_datos);
                setObservaciones(_notes.data);
                setFechaRecibido(new Date(_datos.date_received ?? _datos.date_create + " 06:00:00"));
            } else {
                setMensaje(`No se encontró ningún paquete asignado al código "${_codebar}"`);
            }
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const handleReload = (_codebar) => {
        setMensaje("Cargando...");
        setShowLoading(true);
        getData(_codebar);
    }

    React.useEffect(() => {
        document.body.style.overflow = "auto";
        if (params.codebar) {
            setTitulo("Detalle del paquete " + params.codebar);
            getData(params.codebar);
        } else {
            if (location.state) {
                let _data = location.state.data;
                _data.extra = typeof _data.extra === "string" ? JSON.parse(_data.extra) : _data.extra; // Esta validación de tipo es sólo para desarrollo, no es necesaria para producción.
                setTitulo("Detalle del paquete " + _data.codigo);
                getData(_data.codigo);
            } else {
                navigate("/dashboard/todos-los-paquetes");
            }
        }

        let _layout = document.getElementsByClassName("layout")[0];
        let _footer = document.getElementsByClassName("footer")[0];
        _layout.style.display = "none";
        _footer.style.display = "none";
    }, [params.codigo, location.pathname]);

    return (
        <>
            <div className="action_bar">
                <Iconos.CloseCircle onClick={handleClose} />
            </div>

            {data ?
                <Menu data={data} navigate={navigate} reload={() => handleReload(data.codigo)}>
                    <div className="t_header">

                        <div className="_columna" >
                            <h3>Detalle del paquete</h3>
                            {data.carrier ?
                                <h5>Mensajero: <b>{window.atob(data_user.auth) == data.carrier ? "( tú )" : ""} {data.carrier_name}</b></h5>
                                :
                                <h5><b>Sin mensajero asignado</b></h5>
                            }
                            <h5 className="estado">Estado: {funciones.getStateSpan(data)}</h5>
                        </div>

                        <div className="_columna">
                            <BarCode code={data.codigo} />
                        </div>

                    </div>

                    <div className="_tabla">

                        <div className="columna">
                            <h4 className="_title_inside">Información del paquete</h4>

                            <div className="cont_info">
                                <p style={{ marginTop: 6 }}>Cliente: <b>{data.client_name}</b></p>
                                {media_movil ?
                                    <>
                                        {data.consecutivo && <p style={{ marginTop: 6 }}>Consecutivo cliente: <br /><b>{data.consecutivo + "-" + data.consecutive_client}</b></p>}
                                        <p style={{ marginTop: 14, marginBottom: 8 }}>Fecha {data.date_received ? "de recepción" : "creado"}: <br /><b>{fecha_recibido.getDate()} de {meses[fecha_recibido.getMonth()]} del {fecha_recibido.getFullYear()}</b></p>
                                    </>
                                    :
                                    <>
                                        {data.consecutivo && <p>Consecutivo cliente: <b>{data.consecutivo + "-" + data.consecutive_client}</b></p>}
                                        <p style={{ marginTop: 14, marginBottom: 8 }}>Fecha {data.date_received ? "de recepción" : "creado"}: <b>{fecha_recibido.getDate()} de {meses[fecha_recibido.getMonth()]} del {fecha_recibido.getFullYear()}</b></p>
                                    </>
                                }

                                <p>Valor producto:  <b>${funciones.formatPrice(data.price).format}</b></p>
                                {data.pago_al_vendedor && <p style={{ color: "rgb(255,10,10)" }}>Pago al vendedor: <b>${funciones.formatPrice(data.pago_al_vendedor).format}</b></p>}
                                {(data_user.nivel == 6 || data_user.nivel < 4) && <p>Valor domicilio: <b>${funciones.formatPrice(data.v_domicilio).format}</b></p>}
                                {data_user.nivel <= 4 && <p>Valor Mensajero: <b>${funciones.formatPrice(data.v_mensajero).format}</b></p>}

                            </div>
                        </div>

                        <div className="columna">
                            <h4 className="_title_inside">Descripción y observaciones</h4>

                            <div className="cont_info">
                                <h3>Descripción</h3>
                                <p style={{ marginTop: 0, marginBottom: 16 }}>
                                    {data?.extra?.volumen && <span className="marco">{data?.extra?.volumen}</span>} {data?.extra?.peso && <span className="marco">{data?.extra?.peso}</span>}
                                    {(data?.extra?.volumen || data?.extra?.peso) && <br />}
                                    {data.descripcion ?? "..."}
                                </p>

                                {observaciones.length > 0 && <h3>Observaciones</h3>}
                                {observaciones.map((_obj, _i) => {
                                    let _fecha = new Date(_obj.date);
                                    delete _obj.all_states;

                                    return (
                                        <p key={_i} className="observacion">{_obj.tipo_dv ? <>{funciones.getStateSpan(_obj)}<i className="_dv_type">{_obj.tipo_dv}</i></> : funciones.getStateSpan(_obj)}
                                            <b>{_fecha.getDate()}/{mes[_fecha.getMonth()]}/{_fecha.getFullYear()}</b>
                                            {media_movil && <br />}
                                            <samp>{_obj.gestor_name}</samp>
                                            <br />
                                            <i>{_obj.note}</i>
                                        </p>
                                    )
                                })}
                            </div>
                        </div>
                    </div>

                    <h4 style={{ padding: 0 }}>Imagen del paquete</h4>
                    <div className="_tabla">
                        <div className="fila">
                            {data.attachments && <Attachments files={data.attachments} />}
                        </div>
                    </div>
                </Menu>
                :
                <div className="main_container">
                    <h2 style={{ textAlign: "center", paddingTop: 64, paddingBottom: 82 }}>{mensaje}</h2>
                </div>
            }
            {showLoading && <Loading />}
        </>
    );
}

export default withRouter(DetalleAndroid);