
export const company_permissions = [
    { "breack_line": "Permisos del admin. de tienda" },
    { "company_users": "Crear y editar usuarios" },
    { "company_reporte": "Caja y reportes" },
    { "company_gestiones": "Ver gestiones" },
    { "company_recogidas": "Ver recogidas" },
    { "company_recolectas": "Ver recolectas" },
    { "company_entregas": "Ver entregas" },
    { "company_dev_pendientes": "Ver dev. pendientes" },
    { "company_dev_entregadas": "Ver dev. entregadas" },
    { "company_crear_recogidas": "Crear recogidas" },
    { "company_gestion_paquete": "Paquetes agregar/editar" },
];

export const primary_permissions = [
    { "breack_line": "General" },
    { "chat": "Chat" },
    { "genera_codigos": "Generar códigos" },
    { "descargar_informe": "Cierre del día" },
    { "pack_recogidas": "Recogida de paquetes" },
    { "pack_entregas": "Distribución de paquetes" },
    { "pack_edicion": "Recepción (crear y editar paquetes)" },
    { "pack_history": "Historial del cliente" },
    { "validador": "Validador" },

    { "breack_line": "Usuarios (Crear y editar)" },
    // { "user_admin": "Administradores" },
    { "user_coordi": "Coordinadores" },
    { "user_mensajero": "Mensajeros" },
    { "user_recepcionista": "Recepcionistas" },
    { "user_cajero": "Cajeros" },
    { "user_cliente": "Clientes (Tiendas)" },

    { "breack_line": "Tiendas" },
    { "recolectas_gestion": "Recolecta de paquetes" },
    { "recolectas_edita": "Editar recolectas" },
    { "tienda_edita_perfil": "Editar info de rótulo" },
    { "tienda_imprime_rotulo": "Imprimir rótulos" },

    { "breack_line": "Reportes" },
    { "reporte_cli": "Ver reportes de clientes" },
    { "reporte_msj": "Ver reportes de mensajeros" },

    { "breack_line": "Cajas de mensajeros y bodega" },
    { "caja_bodega": "Acceso a caja de bodega" },
    { "caja_msj": "Acceso a caja de mensajeros (principal)" },
    { "caja_msj_aux": "Acceso a caja de mensajeros (auxiliar)" },

    { "breack_line": "Caja de clientes" },
    { "caja_cli": "Acceso a caja de clientes" },
    { "caja_cli_abono": "Abonos en caja de clientes" },
    { "caja_cli_entrada": "Entradas en caja de clientes" },
    { "caja_cli_principal": "Pagar a clientes (principal)" },
    { "caja_cli_aux": "Pagar a clientes (auxiliar)" },

    { "breack_line": "Caja BTS" },
    { "caja_bts": "Acceso a caja BTS" },
    { "caja_bts_entrada": "Entradas en caja BTS" },
    { "caja_bts_retiro": "Retiros en la caja BTS" },
];