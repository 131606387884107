import * as React from "react";
import PlusIcon from "mdi-react/PlusThickIcon";
import { Alert, Confirm } from "shared/Alert";
import DeleteIcon from "mdi-react/DeleteIcon";
import funciones from "services/funciones";
import EditIcon from "mdi-react/EditIcon";
import { useMedia } from "hooks";
import * as Iconos from "images";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const MenuAdmin = (params) => {
    const { navigate, data, clase, children, onSel, reload, current } = params;
    const [_pos, set_pos] = React.useState({ top: "0", left: "0" });
    const [showMenu, setShowMenu] = React.useState(false);
    const [clases, setClases] = React.useState(clase);
    const [_e, set_e] = React.useState();
    const { media_movil } = useMedia();
    const menuRef = React.useRef();
    const row = React.useRef(null);

    const goReport = (e) => {
        e.preventDefault()
        e.stopPropagation();
        document.body.style.overflow = "auto";
        navigate("/dashboard/reporte-clientes", {
            state: { nombre: data.nombre + " " + data.apellido }
        });
    }

    const goNew = (e) => {
        e.preventDefault()
        e.stopPropagation();

        document.body.style.overflow = "auto";
        navigate("/dashboard/tiendas/agregar");
    }

    const goEdit = (e) => {
        e.preventDefault()
        e.stopPropagation();

        document.body.style.overflow = "auto";
        navigate("/dashboard/tiendas/editar", { state: { data: { ...data, rol: 6 } } });
    }

    const goEditPrifile = (e) => {
        e.preventDefault()
        e.stopPropagation();

        document.body.style.overflow = "auto";
        navigate("/dashboard/tienda/editar-perfil-admin", { state: { data: { ...data, rol: 6 } } });
    }

    const goPerfil = (e) => {
        e.preventDefault()
        e.stopPropagation();

        document.body.style.overflow = "auto";
        navigate("/dashboard/tienda/perfil", { state: { data: data } });
    }

    const goDelete = (e) => {
        hiddenMenu(e);

        Confirm(`¿Estás seguro de borrar la cuenta de "${data.nombre} ${data.apellido}"?`, async (response) => {
            if (response) {
                let _data = await api.deleteUser({ id_user: data.id_user });

                if (_data.response == 1) {
                    document.body.style.overflow = "auto";
                    reload();
                } else if (_data.response === -2) {
                    Alert(_data.msg, "warning", () => {
                        document.body.style.overflow = "auto";
                        navigate("/sign-in");
                    });
                } else {
                    Alert(_data.msg, "warning");
                }
            }
        });
    }

    const onSelect = (e) => {
        e.preventDefault()
        e.stopPropagation();

        setTimeout(() => {
            onSel(data);
        }, 400);
    }

    const stopClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    const hiddenMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();
        document.body.style.overflow = "auto";
        setShowMenu(false);
    }

    const handleContextMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();

        set_e(e);
        document.body.style.overflow = "hidden";
        setShowMenu(true);
    }

    const BtnEditPerfil = () => {
        if (funciones.validaPermisos("tienda_edita_perfil")) {
            return <li onClick={goEditPrifile}><Iconos.TagEdit /> <span>Info rótulo</span></li>;
        } else {
            return <li className="unable"><Iconos.TagEdit color="rgb(170, 170, 170)" /> <span style={{ color: "rgb(170, 170, 170)" }}>Info rótulo</span></li>;
        }
    }

    React.useEffect(() => {
        if (showMenu) {
            set_pos(funciones.getPositonDisplay(_e, menuRef.current));
        }
    }, [showMenu]);

    React.useEffect(() => {
        current === data.id_user && setClases(c => c + " bkg_select");
    }, [current]);

    return (
        <tr className={clases} ref={row}
            onDoubleClick={goReport}
            onClick={onSelect}
            onContextMenu={handleContextMenu}>

            {children}
            <td className="rowMenu" onClick={stopClick} onDoubleClick={stopClick} onContextMenu={stopClick}>
                {showMenu &&
                    <>
                        <div className="back_form _bkg_black" onClick={hiddenMenu} onContextMenu={hiddenMenu}></div>
                        <nav className="menu" style={{ ..._pos, width: media_movil ? 170 : 150 }} ref={menuRef}>
                            <ul>
                                <li onClick={goReport}><Iconos.CajaCli /> <span><strong>Ir a reporte</strong></span></li>
                                <li onClick={goPerfil}><Iconos.Profile /> <span> Ver perfil</span></li>
                                <li onClick={goEdit}><EditIcon /> <span>Editar</span></li>
                                <BtnEditPerfil />
                                <li onClick={goNew}><PlusIcon /> <span>Crear nueva tienda</span></li>

                                <hr />
                                <li onClick={goDelete}><DeleteIcon /> <span>Borrar</span></li>
                            </ul>
                        </nav>
                    </>}
            </td>
        </tr>
    )
}

export default MenuAdmin;