import * as React from "react";
import { useReactToPrint } from "react-to-print";
import funciones from "services/funciones";
import { GroupCode } from "shared/BarCode";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const Print = (param) => {
    const { codigos, pos, close } = param;
    const [_style, setStyle] = React.useState(pos);
    const template_print = React.useRef();
    // const pageStyle = `
    //     @page {
    //         size: 816px 1056px;
    //     }

    //     @media all {
    //         .page-break {
    //           display: none;
    //         }
    //     }

    //     @media print {
    //       .page-break {
    //         margin-top: 200px;
    //         margin-bottom: 0;
    //         page-break-before: always;
    //       }
    //     }
    //     `;
    const pageStyle = `
        @page {
            size: 935.43px 1360.63px;
        }
        
        @media all {
            .page-break {
              display: none;
            }
        }

        @media print {
          .page-break {
            margin 0;
            padding: 0;
            page-break-before: always;
          }
        }
        `;


    const printOrder = useReactToPrint({
        content: () => template_print.current,
        pageStyle: pageStyle,
    });

    React.useEffect(() => {
        setStyle({
            // ...pos,

            width: "100%",
            position: "relative",

            borderRadius: 0,
            boxShadow: "none",
            padding: 0
        });
        printOrder();
        close(false);

    }, [codigos]);

    return (
        <div className="content_modal">
            <div className="content_generator" style={_style} ref={template_print}>

                <div className="code_generator">

                    {codigos.map((item, index) => {
                        return (
                            <svg key={index} version="1.2" width="935.43311" height="1360.62988" viewBox="0 0 701.574829 1020.472412" xmlns="http://www.w3.org/2000/svg">
                                {/* <text style={{ fontWeight: "bold", fontSize: "1.3rem", fontFamily: "'Roboto', sans-serif" }} textAnchor="middle" x="350" y="44" fill="rgb(107 107 107)">Códigos desde {item[0].aux != null && item[0].aux + "-"}{item[0].code} hasta el {item[item.length - 1].aux != null && item[item.length - 1].aux + "-"}{item[item.length - 1].code}</text> */}
                                <g>
                                    <path
                                        fill="rgb(0,0,0)" strokeWidth="2.0001" stroke="rgb(10.588235%,9.803922%,9.411765%)"
                                        d="M 85.036458 56.692708 L 85.036458 58.114583 L 28.348958 58.114583 L 28.348958 56.692708 Z M 85.036458 56.692708 "
                                        transform="matrix(0.75,0,0,0.75,0,0)" />
                                    <path
                                        fill="rgb(0,0,0)" strokeWidth="2.0001" stroke="rgb(10.588235%,9.803922%,9.411765%)"
                                        d="M 28.348958 56.692708 L 29.765625 56.692708 L 29.765625 113.385417 L 28.348958 113.385417 Z M 28.348958 56.692708 "
                                        transform="matrix(0.75,0,0,0.75,0,0)" />
                                    <path
                                        fill="rgb(0,0,0)" strokeWidth="2.0001" stroke="rgb(10.588235%,9.803922%,9.411765%)"
                                        d="M 907.088542 56.692708 L 907.088542 58.114583 L 850.395833 58.114583 L 850.395833 56.692708 Z M 907.088542 56.692708 "
                                        transform="matrix(0.75,0,0,0.75,0,0)" />
                                    <path
                                        fill="rgb(0,0,0)" strokeWidth="2.0001" stroke="rgb(10.588235%,9.803922%,9.411765%)"
                                        d="M 905.666667 56.692708 L 907.088542 56.692708 L 907.088542 113.385417 L 905.666667 113.385417 Z M 905.666667 56.692708 "
                                        transform="matrix(0.75,0,0,0.75,0,0)" />
                                    <path
                                        fill="rgb(0,0,0)" strokeWidth="2.0001" stroke="rgb(10.588235%,9.803922%,9.411765%)"
                                        d="M 28.348958 1302.515625 L 85.036458 1302.515625 L 85.036458 1303.9375 L 28.348958 1303.9375 Z M 28.348958 1302.515625 "
                                        transform="matrix(0.75,0,0,0.75,0,0)" />
                                    <path
                                        fill="rgb(0,0,0)" strokeWidth="2.0001" stroke="rgb(10.588235%,9.803922%,9.411765%)"
                                        d="M 28.348958 1247.244792 L 29.765625 1247.244792 L 29.765625 1303.9375 L 28.348958 1303.9375 Z M 28.348958 1247.244792 "
                                        transform="matrix(0.75,0,0,0.75,0,0)" />
                                    <path
                                        fill="rgb(0,0,0)" strokeWidth="2.0001" stroke="rgb(10.588235%,9.803922%,9.411765%)"
                                        d="M 850.395833 1302.515625 L 907.088542 1302.515625 L 907.088542 1303.9375 L 850.395833 1303.9375 Z M 850.395833 1302.515625 "
                                        transform="matrix(0.75,0,0,0.75,0,0)" />
                                    <path
                                        fill="rgb(0,0,0)" strokeWidth="2.0001" stroke="rgb(10.588235%,9.803922%,9.411765%)"
                                        d="M 905.666667 1247.244792 L 907.088542 1247.244792 L 907.088542 1303.9375 L 905.666667 1303.9375 Z M 905.666667 1247.244792 "
                                        transform="matrix(0.75,0,0,0.75,0,0)" />
                                </g>
                                <g>
                                    <GroupCode code={funciones.formatCeros(item[0])}
                                        logo="matrix(0, -0.01, -0.01, 0, 67, 115)"
                                        bars="matrix(0, -0.36, 0.36, 0, 70, 132)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 71.645833 186.3125 L 137.645833 186.3125 L 137.645833 88.0625 L 71.645833 88.0625 Z M 71.645833 186.3125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[1])}
                                        logo="matrix(0, -0.01, -0.01, 0, 117, 115)"
                                        bars="matrix(0, -0.36, 0.36, 0, 120, 132)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 137.645833 186.3125 L 203.645833 186.3125 L 203.645833 88.0625 L 137.645833 88.0625 Z M 137.645833 186.3125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[2])}
                                        logo="matrix(0, -0.01, -0.01, 0, 167, 115)"
                                        bars="matrix(0, -0.36, 0.36, 0, 170, 132)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 203.645833 186.3125 L 269.645833 186.3125 L 269.645833 88.0625 L 203.645833 88.0625 Z M 203.645833 186.3125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[3])}
                                        logo="matrix(0, -0.01, -0.01, 0, 217, 115)"
                                        bars="matrix(0, -0.36, 0.36, 0, 220, 132)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 269.645833 186.3125 L 335.645833 186.3125 L 335.645833 88.0625 L 269.645833 88.0625 Z M 269.645833 186.3125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[4])}
                                        logo="matrix(0, -0.01, -0.01, 0, 267, 115)"
                                        bars="matrix(0, -0.36, 0.36, 0, 270, 132)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 335.645833 186.3125 L 401.645833 186.3125 L 401.645833 88.0625 L 335.645833 88.0625 Z M 335.645833 186.3125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[5])}
                                        logo="matrix(0, -0.01, -0.01, 0, 317, 115)"
                                        bars="matrix(0, -0.36, 0.36, 0, 320, 132)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 401.645833 186.3125 L 467.645833 186.3125 L 467.645833 88.0625 L 401.645833 88.0625 Z M 401.645833 186.3125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[6])}
                                        logo="matrix(0, -0.01, -0.01, 0, 367, 115)"
                                        bars="matrix(0, -0.36, 0.36, 0, 370, 132)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 467.645833 186.3125 L 533.645833 186.3125 L 533.645833 88.0625 L 467.645833 88.0625 Z M 467.645833 186.3125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[7])}
                                        logo="matrix(0, -0.01, -0.01, 0, 417, 115)"
                                        bars="matrix(0, -0.36, 0.36, 0, 420, 132)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 533.645833 186.3125 L 599.645833 186.3125 L 599.645833 88.0625 L 533.645833 88.0625 Z M 533.645833 186.3125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[8])}
                                        logo="matrix(0, -0.01, -0.01, 0, 467, 115)"
                                        bars="matrix(0, -0.36, 0.36, 0, 470, 132)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 599.645833 186.3125 L 665.645833 186.3125 L 665.645833 88.0625 L 599.645833 88.0625 Z M 599.645833 186.3125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[9])}
                                        logo="matrix(0, -0.01, -0.01, 0, 517, 115)"
                                        bars="matrix(0, -0.36, 0.36, 0, 520, 132)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 665.645833 186.3125 L 731.645833 186.3125 L 731.645833 88.0625 L 665.645833 88.0625 Z M 665.645833 186.3125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[10])}
                                        logo="matrix(0, -0.01, -0.01, 0, 567, 115)"
                                        bars="matrix(0, -0.36, 0.36, 0, 570, 132)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 731.645833 186.3125 L 797.645833 186.3125 L 797.645833 88.0625 L 731.645833 88.0625 Z M 731.645833 186.3125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[11])}
                                        logo="matrix(0, -0.01, -0.01, 0, 617, 115)"
                                        bars="matrix(0, -0.36, 0.36, 0, 620, 132)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 797.645833 186.3125 L 863.645833 186.3125 L 863.645833 88.0625 L 797.645833 88.0625 Z M 797.645833 186.3125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[12])}
                                        logo="matrix(0, -0.01, -0.01, 0, 67, 190)"
                                        bars="matrix(0, -0.36, 0.36, 0, 70, 207)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 71.645833 284.5625 L 137.645833 284.5625 L 137.645833 186.3125 L 71.645833 186.3125 Z M 71.645833 284.5625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[13])}
                                        logo="matrix(0, -0.01, -0.01, 0, 117, 190)"
                                        bars="matrix(0, -0.36, 0.36, 0, 120, 207)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 137.645833 284.5625 L 203.645833 284.5625 L 203.645833 186.3125 L 137.645833 186.3125 Z M 137.645833 284.5625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[14])}
                                        logo="matrix(0, -0.01, -0.01, 0, 167, 190)"
                                        bars="matrix(0, -0.36, 0.36, 0, 170, 207)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 203.645833 284.5625 L 269.645833 284.5625 L 269.645833 186.3125 L 203.645833 186.3125 Z M 203.645833 284.5625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[15])}
                                        logo="matrix(0, -0.01, -0.01, 0, 217, 190)"
                                        bars="matrix(0, -0.36, 0.36, 0, 220, 207)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 269.645833 284.5625 L 335.645833 284.5625 L 335.645833 186.3125 L 269.645833 186.3125 Z M 269.645833 284.5625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[16])}
                                        logo="matrix(0, -0.01, -0.01, 0, 267, 190)"
                                        bars="matrix(0, -0.36, 0.36, 0, 270, 207)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 335.645833 284.5625 L 401.645833 284.5625 L 401.645833 186.3125 L 335.645833 186.3125 Z M 335.645833 284.5625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[17])}
                                        logo="matrix(0, -0.01, -0.01, 0, 317, 190)"
                                        bars="matrix(0, -0.36, 0.36, 0, 320, 207)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 401.645833 284.5625 L 467.645833 284.5625 L 467.645833 186.3125 L 401.645833 186.3125 Z M 401.645833 284.5625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[18])}
                                        logo="matrix(0, -0.01, -0.01, 0, 367, 190)"
                                        bars="matrix(0, -0.36, 0.36, 0, 370, 207)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 467.645833 284.5625 L 533.645833 284.5625 L 533.645833 186.3125 L 467.645833 186.3125 Z M 467.645833 284.5625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[19])}
                                        logo="matrix(0, -0.01, -0.01, 0, 417, 190)"
                                        bars="matrix(0, -0.36, 0.36, 0, 420, 207)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 533.645833 284.5625 L 599.645833 284.5625 L 599.645833 186.3125 L 533.645833 186.3125 Z M 533.645833 284.5625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[20])}
                                        logo="matrix(0, -0.01, -0.01, 0, 467, 190)"
                                        bars="matrix(0, -0.36, 0.36, 0, 470, 207)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 599.645833 284.5625 L 665.645833 284.5625 L 665.645833 186.3125 L 599.645833 186.3125 Z M 599.645833 284.5625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[21])}
                                        logo="matrix(0, -0.01, -0.01, 0, 517, 190)"
                                        bars="matrix(0, -0.36, 0.36, 0, 520, 207)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 665.645833 284.5625 L 731.645833 284.5625 L 731.645833 186.3125 L 665.645833 186.3125 Z M 665.645833 284.5625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[22])}
                                        logo="matrix(0, -0.01, -0.01, 0, 567, 190)"
                                        bars="matrix(0, -0.36, 0.36, 0, 570, 207)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 731.645833 284.5625 L 797.645833 284.5625 L 797.645833 186.3125 L 731.645833 186.3125 Z M 731.645833 284.5625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[23])}
                                        logo="matrix(0, -0.01, -0.01, 0, 617, 190)"
                                        bars="matrix(0, -0.36, 0.36, 0, 620, 207)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 797.645833 284.5625 L 863.645833 284.5625 L 863.645833 186.3125 L 797.645833 186.3125 Z M 797.645833 284.5625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[24])}
                                        logo="matrix(0, -0.01, -0.01, 0, 67, 263)"
                                        bars="matrix(0, -0.36, 0.36, 0, 70, 280)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 71.645833 382.8125 L 137.645833 382.8125 L 137.645833 284.5625 L 71.645833 284.5625 Z M 71.645833 382.8125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[25])}
                                        logo="matrix(0, -0.01, -0.01, 0, 117, 263)"
                                        bars="matrix(0, -0.36, 0.36, 0, 120, 280)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 137.645833 382.8125 L 203.645833 382.8125 L 203.645833 284.5625 L 137.645833 284.5625 Z M 137.645833 382.8125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[26])}
                                        logo="matrix(0, -0.01, -0.01, 0, 167, 263)"
                                        bars="matrix(0, -0.36, 0.36, 0, 170, 280)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 203.645833 382.8125 L 269.645833 382.8125 L 269.645833 284.5625 L 203.645833 284.5625 Z M 203.645833 382.8125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[27])}
                                        logo="matrix(0, -0.01, -0.01, 0, 217, 263)"
                                        bars="matrix(0, -0.36, 0.36, 0, 220, 280)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 269.645833 382.8125 L 335.645833 382.8125 L 335.645833 284.5625 L 269.645833 284.5625 Z M 269.645833 382.8125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[28])}
                                        logo="matrix(0, -0.01, -0.01, 0, 267, 263)"
                                        bars="matrix(0, -0.36, 0.36, 0, 270, 280)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 335.645833 382.8125 L 401.645833 382.8125 L 401.645833 284.5625 L 335.645833 284.5625 Z M 335.645833 382.8125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[29])}
                                        logo="matrix(0, -0.01, -0.01, 0, 317, 263)"
                                        bars="matrix(0, -0.36, 0.36, 0, 320, 280)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 401.645833 382.8125 L 467.645833 382.8125 L 467.645833 284.5625 L 401.645833 284.5625 Z M 401.645833 382.8125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[30])}
                                        logo="matrix(0, -0.01, -0.01, 0, 367, 263)"
                                        bars="matrix(0, -0.36, 0.36, 0, 370, 280)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 467.645833 382.8125 L 533.645833 382.8125 L 533.645833 284.5625 L 467.645833 284.5625 Z M 467.645833 382.8125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[31])}
                                        logo="matrix(0, -0.01, -0.01, 0, 417, 263)"
                                        bars="matrix(0, -0.36, 0.36, 0, 420, 280)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 533.645833 382.8125 L 599.645833 382.8125 L 599.645833 284.5625 L 533.645833 284.5625 Z M 533.645833 382.8125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[32])}
                                        logo="matrix(0, -0.01, -0.01, 0, 467, 263)"
                                        bars="matrix(0, -0.36, 0.36, 0, 470, 280)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 599.645833 382.8125 L 665.645833 382.8125 L 665.645833 284.5625 L 599.645833 284.5625 Z M 599.645833 382.8125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[33])}
                                        logo="matrix(0, -0.01, -0.01, 0, 517, 263)"
                                        bars="matrix(0, -0.36, 0.36, 0, 520, 280)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 665.645833 382.8125 L 731.645833 382.8125 L 731.645833 284.5625 L 665.645833 284.5625 Z M 665.645833 382.8125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[34])}
                                        logo="matrix(0, -0.01, -0.01, 0, 567, 263)"
                                        bars="matrix(0, -0.36, 0.36, 0, 570, 280)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 731.645833 382.8125 L 797.645833 382.8125 L 797.645833 284.5625 L 731.645833 284.5625 Z M 731.645833 382.8125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[35])}
                                        logo="matrix(0, -0.01, -0.01, 0, 617, 263)"
                                        bars="matrix(0, -0.36, 0.36, 0, 620, 280)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 797.645833 382.8125 L 863.645833 382.8125 L 863.645833 284.5625 L 797.645833 284.5625 Z M 797.645833 382.8125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[36])}
                                        logo="matrix(0, -0.01, -0.01, 0, 67, 336)"
                                        bars="matrix(0, -0.36, 0.36, 0, 70, 353)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 71.645833 481.0625 L 137.645833 481.0625 L 137.645833 382.8125 L 71.645833 382.8125 Z M 71.645833 481.0625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[37])}
                                        logo="matrix(0, -0.01, -0.01, 0, 117, 336)"
                                        bars="matrix(0, -0.36, 0.36, 0, 120, 353)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 137.645833 481.0625 L 203.645833 481.0625 L 203.645833 382.8125 L 137.645833 382.8125 Z M 137.645833 481.0625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[38])}
                                        logo="matrix(0, -0.01, -0.01, 0, 167, 336)"
                                        bars="matrix(0, -0.36, 0.36, 0, 170, 353)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 203.645833 481.0625 L 269.645833 481.0625 L 269.645833 382.8125 L 203.645833 382.8125 Z M 203.645833 481.0625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[39])}
                                        logo="matrix(0, -0.01, -0.01, 0, 217, 336)"
                                        bars="matrix(0, -0.36, 0.36, 0, 220, 353)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 269.645833 481.0625 L 335.645833 481.0625 L 335.645833 382.8125 L 269.645833 382.8125 Z M 269.645833 481.0625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[40])}
                                        logo="matrix(0, -0.01, -0.01, 0, 267, 336)"
                                        bars="matrix(0, -0.36, 0.36, 0, 270, 353)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 335.645833 481.0625 L 401.645833 481.0625 L 401.645833 382.8125 L 335.645833 382.8125 Z M 335.645833 481.0625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[41])}
                                        logo="matrix(0, -0.01, -0.01, 0, 317, 336)"
                                        bars="matrix(0, -0.36, 0.36, 0, 320, 353)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 401.645833 481.0625 L 467.645833 481.0625 L 467.645833 382.8125 L 401.645833 382.8125 Z M 401.645833 481.0625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[42])}
                                        logo="matrix(0, -0.01, -0.01, 0, 367, 336)"
                                        bars="matrix(0, -0.36, 0.36, 0, 370, 353)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 467.645833 481.0625 L 533.645833 481.0625 L 533.645833 382.8125 L 467.645833 382.8125 Z M 467.645833 481.0625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[43])}
                                        logo="matrix(0, -0.01, -0.01, 0, 417, 336)"
                                        bars="matrix(0, -0.36, 0.36, 0, 420, 353)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 533.645833 481.0625 L 599.645833 481.0625 L 599.645833 382.8125 L 533.645833 382.8125 Z M 533.645833 481.0625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[44])}
                                        logo="matrix(0, -0.01, -0.01, 0, 467, 336)"
                                        bars="matrix(0, -0.36, 0.36, 0, 470, 353)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 599.645833 481.0625 L 665.645833 481.0625 L 665.645833 382.8125 L 599.645833 382.8125 Z M 599.645833 481.0625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[45])}
                                        logo="matrix(0, -0.01, -0.01, 0, 517, 336)"
                                        bars="matrix(0, -0.36, 0.36, 0, 520, 353)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 665.645833 481.0625 L 731.645833 481.0625 L 731.645833 382.8125 L 665.645833 382.8125 Z M 665.645833 481.0625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[46])}
                                        logo="matrix(0, -0.01, -0.01, 0, 567, 336)"
                                        bars="matrix(0, -0.36, 0.36, 0, 570, 353)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 731.645833 481.0625 L 797.645833 481.0625 L 797.645833 382.8125 L 731.645833 382.8125 Z M 731.645833 481.0625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[47])}
                                        logo="matrix(0, -0.01, -0.01, 0, 617, 336)"
                                        bars="matrix(0, -0.36, 0.36, 0, 620, 353)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 797.645833 481.0625 L 863.645833 481.0625 L 863.645833 382.8125 L 797.645833 382.8125 Z M 797.645833 481.0625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[48])}
                                        logo="matrix(0, -0.01, -0.01, 0, 67, 411)"
                                        bars="matrix(0, -0.36, 0.36, 0, 70, 428)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 71.645833 579.3125 L 137.645833 579.3125 L 137.645833 481.0625 L 71.645833 481.0625 Z M 71.645833 579.3125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[49])}
                                        logo="matrix(0, -0.01, -0.01, 0, 117, 411)"
                                        bars="matrix(0, -0.36, 0.36, 0, 120, 428)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 137.645833 579.3125 L 203.645833 579.3125 L 203.645833 481.0625 L 137.645833 481.0625 Z M 137.645833 579.3125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[50])}
                                        logo="matrix(0, -0.01, -0.01, 0, 167, 411)"
                                        bars="matrix(0, -0.36, 0.36, 0, 170, 428)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 203.645833 579.3125 L 269.645833 579.3125 L 269.645833 481.0625 L 203.645833 481.0625 Z M 203.645833 579.3125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[51])}
                                        logo="matrix(0, -0.01, -0.01, 0, 217, 411)"
                                        bars="matrix(0, -0.36, 0.36, 0, 220, 428)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 269.645833 579.3125 L 335.645833 579.3125 L 335.645833 481.0625 L 269.645833 481.0625 Z M 269.645833 579.3125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[52])}
                                        logo="matrix(0, -0.01, -0.01, 0, 267, 411)"
                                        bars="matrix(0, -0.36, 0.36, 0, 270, 428)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 335.645833 579.3125 L 401.645833 579.3125 L 401.645833 481.0625 L 335.645833 481.0625 Z M 335.645833 579.3125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[53])}
                                        logo="matrix(0, -0.01, -0.01, 0, 317, 411)"
                                        bars="matrix(0, -0.36, 0.36, 0, 320, 428)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 401.645833 579.3125 L 467.645833 579.3125 L 467.645833 481.0625 L 401.645833 481.0625 Z M 401.645833 579.3125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[54])}
                                        logo="matrix(0, -0.01, -0.01, 0, 367, 411)"
                                        bars="matrix(0, -0.36, 0.36, 0, 370, 428)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 467.645833 579.3125 L 533.645833 579.3125 L 533.645833 481.0625 L 467.645833 481.0625 Z M 467.645833 579.3125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[55])}
                                        logo="matrix(0, -0.01, -0.01, 0, 417, 411)"
                                        bars="matrix(0, -0.36, 0.36, 0, 420, 428)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 533.645833 579.3125 L 599.645833 579.3125 L 599.645833 481.0625 L 533.645833 481.0625 Z M 533.645833 579.3125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[56])}
                                        logo="matrix(0, -0.01, -0.01, 0, 467, 411)"
                                        bars="matrix(0, -0.36, 0.36, 0, 470, 428)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 599.645833 579.3125 L 665.645833 579.3125 L 665.645833 481.0625 L 599.645833 481.0625 Z M 599.645833 579.3125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[57])}
                                        logo="matrix(0, -0.01, -0.01, 0, 517, 411)"
                                        bars="matrix(0, -0.36, 0.36, 0, 520, 428)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 665.645833 579.3125 L 731.645833 579.3125 L 731.645833 481.0625 L 665.645833 481.0625 Z M 665.645833 579.3125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[58])}
                                        logo="matrix(0, -0.01, -0.01, 0, 567, 411)"
                                        bars="matrix(0, -0.36, 0.36, 0, 570, 428)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 731.645833 579.3125 L 797.645833 579.3125 L 797.645833 481.0625 L 731.645833 481.0625 Z M 731.645833 579.3125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[59])}
                                        logo="matrix(0, -0.01, -0.01, 0, 617, 411)"
                                        bars="matrix(0, -0.36, 0.36, 0, 620, 428)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 797.645833 579.3125 L 863.645833 579.3125 L 863.645833 481.0625 L 797.645833 481.0625 Z M 797.645833 579.3125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[60])}
                                        logo="matrix(0, -0.01, -0.01, 0, 67, 484)"
                                        bars="matrix(0, -0.36, 0.36, 0, 70, 501)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 71.645833 677.5625 L 137.645833 677.5625 L 137.645833 579.3125 L 71.645833 579.3125 Z M 71.645833 677.5625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[61])}
                                        logo="matrix(0, -0.01, -0.01, 0, 117, 484)"
                                        bars="matrix(0, -0.36, 0.36, 0, 120, 501)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 137.645833 677.5625 L 203.645833 677.5625 L 203.645833 579.3125 L 137.645833 579.3125 Z M 137.645833 677.5625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[62])}
                                        logo="matrix(0, -0.01, -0.01, 0, 167, 484)"
                                        bars="matrix(0, -0.36, 0.36, 0, 170, 501)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 203.645833 677.5625 L 269.645833 677.5625 L 269.645833 579.3125 L 203.645833 579.3125 Z M 203.645833 677.5625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[63])}
                                        logo="matrix(0, -0.01, -0.01, 0, 217, 484)"
                                        bars="matrix(0, -0.36, 0.36, 0, 220, 501)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 269.645833 677.5625 L 335.645833 677.5625 L 335.645833 579.3125 L 269.645833 579.3125 Z M 269.645833 677.5625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[64])}
                                        logo="matrix(0, -0.01, -0.01, 0, 267, 484)"
                                        bars="matrix(0, -0.36, 0.36, 0, 270, 501)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 335.645833 677.5625 L 401.645833 677.5625 L 401.645833 579.3125 L 335.645833 579.3125 Z M 335.645833 677.5625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[65])}
                                        logo="matrix(0, -0.01, -0.01, 0, 317, 484)"
                                        bars="matrix(0, -0.36, 0.36, 0, 320, 501)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 401.645833 677.5625 L 467.645833 677.5625 L 467.645833 579.3125 L 401.645833 579.3125 Z M 401.645833 677.5625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[66])}
                                        logo="matrix(0, -0.01, -0.01, 0, 367, 484)"
                                        bars="matrix(0, -0.36, 0.36, 0, 370, 501)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 467.645833 677.5625 L 533.645833 677.5625 L 533.645833 579.3125 L 467.645833 579.3125 Z M 467.645833 677.5625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[67])}
                                        logo="matrix(0, -0.01, -0.01, 0, 417, 484)"
                                        bars="matrix(0, -0.36, 0.36, 0, 420, 501)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 533.645833 677.5625 L 599.645833 677.5625 L 599.645833 579.3125 L 533.645833 579.3125 Z M 533.645833 677.5625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[68])}
                                        logo="matrix(0, -0.01, -0.01, 0, 467, 484)"
                                        bars="matrix(0, -0.36, 0.36, 0, 470, 501)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 599.645833 677.5625 L 665.645833 677.5625 L 665.645833 579.3125 L 599.645833 579.3125 Z M 599.645833 677.5625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[69])}
                                        logo="matrix(0, -0.01, -0.01, 0, 517, 484)"
                                        bars="matrix(0, -0.36, 0.36, 0, 520, 501)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 665.645833 677.5625 L 731.645833 677.5625 L 731.645833 579.3125 L 665.645833 579.3125 Z M 665.645833 677.5625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[70])}
                                        logo="matrix(0, -0.01, -0.01, 0, 567, 484)"
                                        bars="matrix(0, -0.36, 0.36, 0, 570, 501)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 731.645833 677.5625 L 797.645833 677.5625 L 797.645833 579.3125 L 731.645833 579.3125 Z M 731.645833 677.5625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[71])}
                                        logo="matrix(0, -0.01, -0.01, 0, 617, 484)"
                                        bars="matrix(0, -0.36, 0.36, 0, 620, 501)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 797.645833 677.5625 L 863.645833 677.5625 L 863.645833 579.3125 L 797.645833 579.3125 Z M 797.645833 677.5625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[72])}
                                        logo="matrix(0, -0.01, -0.01, 0, 67, 559)"
                                        bars="matrix(0, -0.36, 0.36, 0, 70, 576)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 71.645833 775.8125 L 137.645833 775.8125 L 137.645833 677.5625 L 71.645833 677.5625 Z M 71.645833 775.8125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[73])}
                                        logo="matrix(0, -0.01, -0.01, 0, 117, 559)"
                                        bars="matrix(0, -0.36, 0.36, 0, 120, 576)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 137.645833 775.8125 L 203.645833 775.8125 L 203.645833 677.5625 L 137.645833 677.5625 Z M 137.645833 775.8125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[74])}
                                        logo="matrix(0, -0.01, -0.01, 0, 167, 559)"
                                        bars="matrix(0, -0.36, 0.36, 0, 170, 576)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 203.645833 775.8125 L 269.645833 775.8125 L 269.645833 677.5625 L 203.645833 677.5625 Z M 203.645833 775.8125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[75])}
                                        logo="matrix(0, -0.01, -0.01, 0, 217, 559)"
                                        bars="matrix(0, -0.36, 0.36, 0, 220, 576)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 269.645833 775.8125 L 335.645833 775.8125 L 335.645833 677.5625 L 269.645833 677.5625 Z M 269.645833 775.8125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[76])}
                                        logo="matrix(0, -0.01, -0.01, 0, 267, 559)"
                                        bars="matrix(0, -0.36, 0.36, 0, 270, 576)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 335.645833 775.8125 L 401.645833 775.8125 L 401.645833 677.5625 L 335.645833 677.5625 Z M 335.645833 775.8125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[77])}
                                        logo="matrix(0, -0.01, -0.01, 0, 317, 559)"
                                        bars="matrix(0, -0.36, 0.36, 0, 320, 576)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 401.645833 775.8125 L 467.645833 775.8125 L 467.645833 677.5625 L 401.645833 677.5625 Z M 401.645833 775.8125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[78])}
                                        logo="matrix(0, -0.01, -0.01, 0, 367, 559)"
                                        bars="matrix(0, -0.36, 0.36, 0, 370, 576)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 467.645833 775.8125 L 533.645833 775.8125 L 533.645833 677.5625 L 467.645833 677.5625 Z M 467.645833 775.8125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[79])}
                                        logo="matrix(0, -0.01, -0.01, 0, 417, 559)"
                                        bars="matrix(0, -0.36, 0.36, 0, 420, 576)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 533.645833 775.8125 L 599.645833 775.8125 L 599.645833 677.5625 L 533.645833 677.5625 Z M 533.645833 775.8125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[80])}
                                        logo="matrix(0, -0.01, -0.01, 0, 467, 559)"
                                        bars="matrix(0, -0.36, 0.36, 0, 470, 576)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 599.645833 775.8125 L 665.645833 775.8125 L 665.645833 677.5625 L 599.645833 677.5625 Z M 599.645833 775.8125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[81])}
                                        logo="matrix(0, -0.01, -0.01, 0, 517, 559)"
                                        bars="matrix(0, -0.36, 0.36, 0, 520, 576)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 665.645833 775.8125 L 731.645833 775.8125 L 731.645833 677.5625 L 665.645833 677.5625 Z M 665.645833 775.8125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[82])}
                                        logo="matrix(0, -0.01, -0.01, 0, 567, 559)"
                                        bars="matrix(0, -0.36, 0.36, 0, 570, 576)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 731.645833 775.8125 L 797.645833 775.8125 L 797.645833 677.5625 L 731.645833 677.5625 Z M 731.645833 775.8125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[83])}
                                        logo="matrix(0, -0.01, -0.01, 0, 617, 559)"
                                        bars="matrix(0, -0.36, 0.36, 0, 620, 576)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 797.645833 775.8125 L 863.645833 775.8125 L 863.645833 677.5625 L 797.645833 677.5625 Z M 797.645833 775.8125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[84])}
                                        logo="matrix(0, -0.01, -0.01, 0, 67, 632)"
                                        bars="matrix(0, -0.36, 0.36, 0, 70, 649)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 71.645833 874.0625 L 137.645833 874.0625 L 137.645833 775.8125 L 71.645833 775.8125 Z M 71.645833 874.0625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[85])}
                                        logo="matrix(0, -0.01, -0.01, 0, 117, 632)"
                                        bars="matrix(0, -0.36, 0.36, 0, 120, 649)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 137.645833 874.0625 L 203.645833 874.0625 L 203.645833 775.8125 L 137.645833 775.8125 Z M 137.645833 874.0625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[86])}
                                        logo="matrix(0, -0.01, -0.01, 0, 167, 632)"
                                        bars="matrix(0, -0.36, 0.36, 0, 170, 649)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 203.645833 874.0625 L 269.645833 874.0625 L 269.645833 775.8125 L 203.645833 775.8125 Z M 203.645833 874.0625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[87])}
                                        logo="matrix(0, -0.01, -0.01, 0, 217, 632)"
                                        bars="matrix(0, -0.36, 0.36, 0, 220, 649)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 269.645833 874.0625 L 335.645833 874.0625 L 335.645833 775.8125 L 269.645833 775.8125 Z M 269.645833 874.0625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[88])}
                                        logo="matrix(0, -0.01, -0.01, 0, 267, 632)"
                                        bars="matrix(0, -0.36, 0.36, 0, 270, 649)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 335.645833 874.0625 L 401.645833 874.0625 L 401.645833 775.8125 L 335.645833 775.8125 Z M 335.645833 874.0625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[89])}
                                        logo="matrix(0, -0.01, -0.01, 0, 317, 632)"
                                        bars="matrix(0, -0.36, 0.36, 0, 320, 649)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 401.645833 874.0625 L 467.645833 874.0625 L 467.645833 775.8125 L 401.645833 775.8125 Z M 401.645833 874.0625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[90])}
                                        logo="matrix(0, -0.01, -0.01, 0, 367, 632)"
                                        bars="matrix(0, -0.36, 0.36, 0, 370, 649)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 467.645833 874.0625 L 533.645833 874.0625 L 533.645833 775.8125 L 467.645833 775.8125 Z M 467.645833 874.0625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[91])}
                                        logo="matrix(0, -0.01, -0.01, 0, 417, 632)"
                                        bars="matrix(0, -0.36, 0.36, 0, 420, 649)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 533.645833 874.0625 L 599.645833 874.0625 L 599.645833 775.8125 L 533.645833 775.8125 Z M 533.645833 874.0625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[92])}
                                        logo="matrix(0, -0.01, -0.01, 0, 467, 632)"
                                        bars="matrix(0, -0.36, 0.36, 0, 470, 649)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 599.645833 874.0625 L 665.645833 874.0625 L 665.645833 775.8125 L 599.645833 775.8125 Z M 599.645833 874.0625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[93])}
                                        logo="matrix(0, -0.01, -0.01, 0, 517, 632)"
                                        bars="matrix(0, -0.36, 0.36, 0, 520, 649)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 665.645833 874.0625 L 731.645833 874.0625 L 731.645833 775.8125 L 665.645833 775.8125 Z M 665.645833 874.0625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[94])}
                                        logo="matrix(0, -0.01, -0.01, 0, 567, 632)"
                                        bars="matrix(0, -0.36, 0.36, 0, 570, 649)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 731.645833 874.0625 L 797.645833 874.0625 L 797.645833 775.8125 L 731.645833 775.8125 Z M 731.645833 874.0625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[95])}
                                        logo="matrix(0, -0.01, -0.01, 0, 617, 632)"
                                        bars="matrix(0, -0.36, 0.36, 0, 620, 649)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 797.645833 874.0625 L 863.645833 874.0625 L 863.645833 775.8125 L 797.645833 775.8125 Z M 797.645833 874.0625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[96])}
                                        logo="matrix(0, -0.01, -0.01, 0, 67, 707)"
                                        bars="matrix(0, -0.36, 0.36, 0, 70, 724)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 71.645833 972.3125 L 137.645833 972.3125 L 137.645833 874.0625 L 71.645833 874.0625 Z M 71.645833 972.3125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[97])}
                                        logo="matrix(0, -0.01, -0.01, 0, 117, 707)"
                                        bars="matrix(0, -0.36, 0.36, 0, 120, 724)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 137.645833 972.3125 L 203.645833 972.3125 L 203.645833 874.0625 L 137.645833 874.0625 Z M 137.645833 972.3125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[98])}
                                        logo="matrix(0, -0.01, -0.01, 0, 167, 707)"
                                        bars="matrix(0, -0.36, 0.36, 0, 170, 724)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 203.645833 972.3125 L 269.645833 972.3125 L 269.645833 874.0625 L 203.645833 874.0625 Z M 203.645833 972.3125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[99])}
                                        logo="matrix(0, -0.01, -0.01, 0, 217, 707)"
                                        bars="matrix(0, -0.36, 0.36, 0, 220, 724)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 269.645833 972.3125 L 335.645833 972.3125 L 335.645833 874.0625 L 269.645833 874.0625 Z M 269.645833 972.3125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[100])}
                                        logo="matrix(0, -0.01, -0.01, 0, 267, 707)"
                                        bars="matrix(0, -0.36, 0.36, 0, 270, 724)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 335.645833 972.3125 L 401.645833 972.3125 L 401.645833 874.0625 L 335.645833 874.0625 Z M 335.645833 972.3125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[101])}
                                        logo="matrix(0, -0.01, -0.01, 0, 317, 707)"
                                        bars="matrix(0, -0.36, 0.36, 0, 320, 724)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 401.645833 972.3125 L 467.645833 972.3125 L 467.645833 874.0625 L 401.645833 874.0625 Z M 401.645833 972.3125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[102])}
                                        logo="matrix(0, -0.01, -0.01, 0, 367, 707)"
                                        bars="matrix(0, -0.36, 0.36, 0, 370, 724)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 467.645833 972.3125 L 533.645833 972.3125 L 533.645833 874.0625 L 467.645833 874.0625 Z M 467.645833 972.3125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[103])}
                                        logo="matrix(0, -0.01, -0.01, 0, 417, 707)"
                                        bars="matrix(0, -0.36, 0.36, 0, 420, 724)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 533.645833 972.3125 L 599.645833 972.3125 L 599.645833 874.0625 L 533.645833 874.0625 Z M 533.645833 972.3125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[104])}
                                        logo="matrix(0, -0.01, -0.01, 0, 467, 707)"
                                        bars="matrix(0, -0.36, 0.36, 0, 470, 724)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 599.645833 972.3125 L 665.645833 972.3125 L 665.645833 874.0625 L 599.645833 874.0625 Z M 599.645833 972.3125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[105])}
                                        logo="matrix(0, -0.01, -0.01, 0, 517, 707)"
                                        bars="matrix(0, -0.36, 0.36, 0, 520, 724)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 665.645833 972.3125 L 731.645833 972.3125 L 731.645833 874.0625 L 665.645833 874.0625 Z M 665.645833 972.3125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[106])}
                                        logo="matrix(0, -0.01, -0.01, 0, 567, 707)"
                                        bars="matrix(0, -0.36, 0.36, 0, 570, 724)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 731.645833 972.3125 L 797.645833 972.3125 L 797.645833 874.0625 L 731.645833 874.0625 Z M 731.645833 972.3125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[107])}
                                        logo="matrix(0, -0.01, -0.01, 0, 617, 707)"
                                        bars="matrix(0, -0.36, 0.36, 0, 620, 724)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 797.645833 972.3125 L 863.645833 972.3125 L 863.645833 874.0625 L 797.645833 874.0625 Z M 797.645833 972.3125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[108])}
                                        logo="matrix(0, -0.01, -0.01, 0, 67, 780)"
                                        bars="matrix(0, -0.36, 0.36, 0, 70, 797)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 71.645833 1070.5625 L 137.645833 1070.5625 L 137.645833 972.3125 L 71.645833 972.3125 Z M 71.645833 1070.5625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[109])}
                                        logo="matrix(0, -0.01, -0.01, 0, 117, 780)"
                                        bars="matrix(0, -0.36, 0.36, 0, 120, 797)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 137.645833 1070.5625 L 203.645833 1070.5625 L 203.645833 972.3125 L 137.645833 972.3125 Z M 137.645833 1070.5625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[110])}
                                        logo="matrix(0, -0.01, -0.01, 0, 167, 780)"
                                        bars="matrix(0, -0.36, 0.36, 0, 170, 797)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 203.645833 1070.5625 L 269.645833 1070.5625 L 269.645833 972.3125 L 203.645833 972.3125 Z M 203.645833 1070.5625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[111])}
                                        logo="matrix(0, -0.01, -0.01, 0, 217, 780)"
                                        bars="matrix(0, -0.36, 0.36, 0, 220, 797)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 269.645833 1070.5625 L 335.645833 1070.5625 L 335.645833 972.3125 L 269.645833 972.3125 Z M 269.645833 1070.5625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[112])}
                                        logo="matrix(0, -0.01, -0.01, 0, 267, 780)"
                                        bars="matrix(0, -0.36, 0.36, 0, 270, 797)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 335.645833 1070.5625 L 401.645833 1070.5625 L 401.645833 972.3125 L 335.645833 972.3125 Z M 335.645833 1070.5625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[113])}
                                        logo="matrix(0, -0.01, -0.01, 0, 317, 780)"
                                        bars="matrix(0, -0.36, 0.36, 0, 320, 797)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 401.645833 1070.5625 L 467.645833 1070.5625 L 467.645833 972.3125 L 401.645833 972.3125 Z M 401.645833 1070.5625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[114])}
                                        logo="matrix(0, -0.01, -0.01, 0, 367, 780)"
                                        bars="matrix(0, -0.36, 0.36, 0, 370, 797)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 467.645833 1070.5625 L 533.645833 1070.5625 L 533.645833 972.3125 L 467.645833 972.3125 Z M 467.645833 1070.5625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[115])}
                                        logo="matrix(0, -0.01, -0.01, 0, 417, 780)"
                                        bars="matrix(0, -0.36, 0.36, 0, 420, 797)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 533.645833 1070.5625 L 599.645833 1070.5625 L 599.645833 972.3125 L 533.645833 972.3125 Z M 533.645833 1070.5625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[116])}
                                        logo="matrix(0, -0.01, -0.01, 0, 467, 780)"
                                        bars="matrix(0, -0.36, 0.36, 0, 470, 797)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 599.645833 1070.5625 L 665.645833 1070.5625 L 665.645833 972.3125 L 599.645833 972.3125 Z M 599.645833 1070.5625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[117])}
                                        logo="matrix(0, -0.01, -0.01, 0, 517, 780)"
                                        bars="matrix(0, -0.36, 0.36, 0, 520, 797)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 665.645833 1070.5625 L 731.645833 1070.5625 L 731.645833 972.3125 L 665.645833 972.3125 Z M 665.645833 1070.5625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[118])}
                                        logo="matrix(0, -0.01, -0.01, 0, 567, 780)"
                                        bars="matrix(0, -0.36, 0.36, 0, 570, 797)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 731.645833 1070.5625 L 797.645833 1070.5625 L 797.645833 972.3125 L 731.645833 972.3125 Z M 731.645833 1070.5625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[119])}
                                        logo="matrix(0, -0.01, -0.01, 0, 617, 780)"
                                        bars="matrix(0, -0.36, 0.36, 0, 620, 797)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 797.645833 1070.5625 L 863.645833 1070.5625 L 863.645833 972.3125 L 797.645833 972.3125 Z M 797.645833 1070.5625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[120])}
                                        logo="matrix(0, -0.01, -0.01, 0, 67, 853)"
                                        bars="matrix(0, -0.36, 0.36, 0, 70, 870)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 71.645833 1168.8125 L 137.645833 1168.8125 L 137.645833 1070.5625 L 71.645833 1070.5625 Z M 71.645833 1168.8125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[121])}
                                        logo="matrix(0, -0.01, -0.01, 0, 117, 853)"
                                        bars="matrix(0, -0.36, 0.36, 0, 120, 870)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 137.645833 1168.8125 L 203.645833 1168.8125 L 203.645833 1070.5625 L 137.645833 1070.5625 Z M 137.645833 1168.8125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[122])}
                                        logo="matrix(0, -0.01, -0.01, 0, 167, 853)"
                                        bars="matrix(0, -0.36, 0.36, 0, 170, 870)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 203.645833 1168.8125 L 269.645833 1168.8125 L 269.645833 1070.5625 L 203.645833 1070.5625 Z M 203.645833 1168.8125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[123])}
                                        logo="matrix(0, -0.01, -0.01, 0, 217, 853)"
                                        bars="matrix(0, -0.36, 0.36, 0, 220, 870)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 269.645833 1168.8125 L 335.645833 1168.8125 L 335.645833 1070.5625 L 269.645833 1070.5625 Z M 269.645833 1168.8125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[124])}
                                        logo="matrix(0, -0.01, -0.01, 0, 267, 853)"
                                        bars="matrix(0, -0.36, 0.36, 0, 270, 870)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 335.645833 1168.8125 L 401.645833 1168.8125 L 401.645833 1070.5625 L 335.645833 1070.5625 Z M 335.645833 1168.8125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[125])}
                                        logo="matrix(0, -0.01, -0.01, 0, 317, 853)"
                                        bars="matrix(0, -0.36, 0.36, 0, 320, 870)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 401.645833 1168.8125 L 467.645833 1168.8125 L 467.645833 1070.5625 L 401.645833 1070.5625 Z M 401.645833 1168.8125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[126])}
                                        logo="matrix(0, -0.01, -0.01, 0, 367, 853)"
                                        bars="matrix(0, -0.36, 0.36, 0, 370, 870)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 467.645833 1168.8125 L 533.645833 1168.8125 L 533.645833 1070.5625 L 467.645833 1070.5625 Z M 467.645833 1168.8125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[127])}
                                        logo="matrix(0, -0.01, -0.01, 0, 417, 853)"
                                        bars="matrix(0, -0.36, 0.36, 0, 420, 870)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 533.645833 1168.8125 L 599.645833 1168.8125 L 599.645833 1070.5625 L 533.645833 1070.5625 Z M 533.645833 1168.8125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[128])}
                                        logo="matrix(0, -0.01, -0.01, 0, 467, 853)"
                                        bars="matrix(0, -0.36, 0.36, 0, 470, 870)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 599.645833 1168.8125 L 665.645833 1168.8125 L 665.645833 1070.5625 L 599.645833 1070.5625 Z M 599.645833 1168.8125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[129])}
                                        logo="matrix(0, -0.01, -0.01, 0, 517, 853)"
                                        bars="matrix(0, -0.36, 0.36, 0, 520, 870)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 665.645833 1168.8125 L 731.645833 1168.8125 L 731.645833 1070.5625 L 665.645833 1070.5625 Z M 665.645833 1168.8125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[130])}
                                        logo="matrix(0, -0.01, -0.01, 0, 567, 853)"
                                        bars="matrix(0, -0.36, 0.36, 0, 570, 870)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 731.645833 1168.8125 L 797.645833 1168.8125 L 797.645833 1070.5625 L 731.645833 1070.5625 Z M 731.645833 1168.8125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[131])}
                                        logo="matrix(0, -0.01, -0.01, 0, 617, 853)"
                                        bars="matrix(0, -0.36, 0.36, 0, 620, 870)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 797.645833 1168.8125 L 863.645833 1168.8125 L 863.645833 1070.5625 L 797.645833 1070.5625 Z M 797.645833 1168.8125 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[132])}
                                        logo="matrix(0, -0.01, -0.01, 0, 67, 926)"
                                        bars="matrix(0, -0.36, 0.36, 0, 70, 943)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 71.645833 1267.0625 L 137.645833 1267.0625 L 137.645833 1168.8125 L 71.645833 1168.8125 Z M 71.645833 1267.0625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[133])}
                                        logo="matrix(0, -0.01, -0.01, 0, 117, 926)"
                                        bars="matrix(0, -0.36, 0.36, 0, 120, 943)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 137.645833 1267.0625 L 203.645833 1267.0625 L 203.645833 1168.8125 L 137.645833 1168.8125 Z M 137.645833 1267.0625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[134])}
                                        logo="matrix(0, -0.01, -0.01, 0, 167, 926)"
                                        bars="matrix(0, -0.36, 0.36, 0, 170, 943)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 203.645833 1267.0625 L 269.645833 1267.0625 L 269.645833 1168.8125 L 203.645833 1168.8125 Z M 203.645833 1267.0625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[135])}
                                        logo="matrix(0, -0.01, -0.01, 0, 217, 926)"
                                        bars="matrix(0, -0.36, 0.36, 0, 220, 943)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 269.645833 1267.0625 L 335.645833 1267.0625 L 335.645833 1168.8125 L 269.645833 1168.8125 Z M 269.645833 1267.0625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[136])}
                                        logo="matrix(0, -0.01, -0.01, 0, 267, 926)"
                                        bars="matrix(0, -0.36, 0.36, 0, 270, 943)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 335.645833 1267.0625 L 401.645833 1267.0625 L 401.645833 1168.8125 L 335.645833 1168.8125 Z M 335.645833 1267.0625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[137])}
                                        logo="matrix(0, -0.01, -0.01, 0, 317, 926)"
                                        bars="matrix(0, -0.36, 0.36, 0, 320, 943)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 401.645833 1267.0625 L 467.645833 1267.0625 L 467.645833 1168.8125 L 401.645833 1168.8125 Z M 401.645833 1267.0625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[138])}
                                        logo="matrix(0, -0.01, -0.01, 0, 367, 926)"
                                        bars="matrix(0, -0.36, 0.36, 0, 370, 943)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 467.645833 1267.0625 L 533.645833 1267.0625 L 533.645833 1168.8125 L 467.645833 1168.8125 Z M 467.645833 1267.0625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[139])}
                                        logo="matrix(0, -0.01, -0.01, 0, 417, 926)"
                                        bars="matrix(0, -0.36, 0.36, 0, 420, 943)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 533.645833 1267.0625 L 599.645833 1267.0625 L 599.645833 1168.8125 L 533.645833 1168.8125 Z M 533.645833 1267.0625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[140])}
                                        logo="matrix(0, -0.01, -0.01, 0, 467, 926)"
                                        bars="matrix(0, -0.36, 0.36, 0, 470, 943)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 599.645833 1267.0625 L 665.645833 1267.0625 L 665.645833 1168.8125 L 599.645833 1168.8125 Z M 599.645833 1267.0625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[141])}
                                        logo="matrix(0, -0.01, -0.01, 0, 517, 926)"
                                        bars="matrix(0, -0.36, 0.36, 0, 520, 943)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 665.645833 1267.0625 L 731.645833 1267.0625 L 731.645833 1168.8125 L 665.645833 1168.8125 Z M 665.645833 1267.0625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[142])}
                                        logo="matrix(0, -0.01, -0.01, 0, 567, 926)"
                                        bars="matrix(0, -0.36, 0.36, 0, 570, 943)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 731.645833 1267.0625 L 797.645833 1267.0625 L 797.645833 1168.8125 L 731.645833 1168.8125 Z M 731.645833 1267.0625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                    <GroupCode code={funciones.formatCeros(item[143])}
                                        logo="matrix(0, -0.01, -0.01, 0, 617, 926)"
                                        bars="matrix(0, -0.36, 0.36, 0, 620, 943)">
                                        <path
                                            fill="none" strokeWidth="0.5669" stroke="none"
                                            d="M 797.645833 1267.0625 L 863.645833 1267.0625 L 863.645833 1168.8125 L 797.645833 1168.8125 Z M 797.645833 1267.0625 "
                                            transform="matrix(0.75,0,0,0.75,0,0)" />
                                    </GroupCode>
                                </g>
                            </svg>
                        )
                    })}
                </div>

            </div>
        </div>
    )
}

export default Print;