import * as React from "react";
import { RequirePermissions } from "shared/AlertMessages";
import ListPaquage from "./components/ListPaquage";
import { TitleSticky } from "shared/Components";
import Recogidas from "./components/Recogidas";
import Entregas from "./components/Entregas";
import withRouter from "services/withRouter";
import { Container, Row } from "reactstrap";
import funciones from "services/funciones";
import Resumen from "./components/Resumen";
import Cortes from "./components/Cortes";
import { Alert } from "shared/Alert";
import { Api } from "services/api";
import { useMedia } from "hooks";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint no-extend-native: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

Date.prototype.getWeekNumber = function () {
    var d = new Date(+this);
    d.setHours(0, 0, 0, 0);
    d.setDate(d.getDate() + 4 - (d.getDay() || 7));

    return Math.ceil((((d - new Date(d.getFullYear(), 0, 1)) / 8.64e7) + 1) / 7);
}

const MiCaja = (router) => {
    const { navigate } = router;
    const { setTitulo, setDataUser } = useMedia();
    const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7 };
    const [listCountDelivery, setListCountDelivery] = React.useState([]);
    const api = React.useRef(new Api({ navigate, setDataUser }));
    const [showLoading, setShowLoading] = React.useState(true);
    const [devoluciones, setDevoluciones] = React.useState([]);
    const [listPreCorte, setListPreCorte] = React.useState([]);
    const [listPickups, setListPickups] = React.useState([]);
    const [resumenPago, setResumenPago] = React.useState([]);
    const [paraExcel, setParaExcel] = React.useState([]);
    const [conteo, setConteo] = React.useState(0);


    const armaMensajero = (_resumen) => {
        // 304 378 3429
        let _sales = [
            {
                id: 0,
                title: 'Totales',
                abonos: _resumen.abonos,
                entregas: _resumen.valor_entregado,
                recogidas: _resumen.pagos_recogidas,
                v_mensajero: _resumen.domicilios_entregas,
                v_mensajero_r: _resumen.domicilios_recogidas,
                subtotal: _resumen.total,
                efectivo: _resumen.efectivo,
                transferencia: _resumen.transferencia,
                domicilio_ko: _resumen.ko_dom_pago,
            },
        ];

        setResumenPago(_sales);
    }

    const armaCliente = (_resumen) => {
        // 304 378 3429
        let _sales = [
            {
                id: 0,
                title: 'Totales',
                abonos: _resumen.abonos,
                entregas: _resumen.valor_entregado,
                recogidas: _resumen.pagos_recogidas,
                v_domicilio: _resumen.domicilios_entregas,
                v_domicilio_r: _resumen.domicilios_recogidas,
                subtotal: _resumen.total,
                efectivo: _resumen.efectivo,
                transferencia: _resumen.transferencia,
                domicilio_ko: _resumen.ko_dom_pago,
            },
        ];

        setResumenPago(_sales);
    }

    const getSales = async () => {
        setShowLoading(true);
        let _obj = { nivel: "user" };
        let _url = "";

        switch (parseInt(data_user.nivel)) {
            case 4:
                _url = "cuentas-mensajero";
                _obj["nivel"] = "carrier";
                _obj["pre_corte"] = true;
                _obj["user_close"] = data_user.auth ? window.atob(data_user.auth) : 0;
                _obj["nivel_close"] = data_user.nivel;
                break;
            case 6:
                _url = "cuentas-cliente";
                _obj["nivel"] = "cliente";
                _obj["user_close"] = data_user.auth ? window.atob(data_user.auth) : 0;
                _obj["nivel_close"] = data_user.nivel;
                break;
            case 8:
                _url = "??????";
                _obj["nivel"] = "recepcion";
                _obj["user_close"] = data_user.auth ? window.atob(data_user.auth) : 0;
                _obj["nivel_close"] = data_user.nivel;
                break;
        }

        let _data = await api.current.fetchJson({
            url: _url,
            data: {
                ..._obj,
                resumen: true,
                entregas: true,
                recogidas: true,
                devoluciones: true
            }
        });

        if (_data.response == 1) {
            setDevoluciones(_data.devoluciones);
            setParaExcel(typeof _data.data === "string" ? JSON.parse(_data.data) : _data.data);
            setConteo(parseInt(_data.conteo));

            if (data_user.nivel == 4) {
                armaMensajero(_data.resumen);

                if (_data.pre_corte.length > 0) {
                    let _lista = [];
                    _data.pre_corte.map(item => {
                        _lista.push(item.info);
                    });

                    setListPreCorte(_lista);
                }
            }

            if (data_user.nivel == 6) {
                armaCliente(_data.resumen);
            }

            if (_data.entregas.length > 0) {
                let _delivery = _data.entregas.map(item => {
                    item.counter = parseInt(item.counter) || 0;
                    return item;
                });

                setListCountDelivery(_delivery);
            }

            if (_data.recogidas.length > 0) {
                _data.recogidas.map(item => {
                    item.collect = parseInt(item.collect_product) || 0;
                    return item;
                });

                setListPickups(_data.recogidas);
            }

        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    React.useEffect(() => {
        setTitulo("Mi caja y reportes");
        document.body.style.overflow = "auto";
        if (funciones.validaPermisos("ver_mi_caja")) {
            getSales();
        }
    }, []);

    return (
        <RequirePermissions navigate={navigate}
            permission={funciones.validaPermisos("ver_mi_caja")}>

            <TitleSticky className="_in_page">Mi caja y reportes</TitleSticky>

            <Container className="dashboard">
                <Row>

                    <ListPaquage
                        userToClose={{ ...data_user, id_user: data_user.auth ? window.atob(data_user.auth) : 0 }}
                        sales={paraExcel}
                        conteo={conteo}
                        devoluciones={devoluciones}
                        preCorte={listPreCorte}
                        loading={showLoading}
                        onRefresh={() => getSales()}>

                        <Resumen
                            titulo="Total a entregar"
                            userToClose={{ ...data_user, id_user: data_user.auth ? window.atob(data_user.auth) : 0 }}
                            data={resumenPago}
                            loading={showLoading}
                            onRefresh={() => getSales()} />

                    </ListPaquage>
                </Row>

                <Row className="recalc_left">

                    <Entregas
                        userToClose={{ ...data_user, id_user: data_user.auth ? window.atob(data_user.auth) : 0 }}
                        list={listCountDelivery}
                        loading={showLoading}
                        onRefresh={() => getSales()} />

                    <Recogidas
                        userToClose={{ ...data_user, id_user: data_user.auth ? window.atob(data_user.auth) : 0 }}
                        list={listPickups}
                        loading={showLoading}
                        onRefresh={() => getSales()} />

                </Row>

                <Row>
                    <Cortes data_user={{ ...data_user, id_user: data_user.auth ? window.atob(data_user.auth) : 0 }} />
                </Row>
            </Container>
        </RequirePermissions>
    )
}

export default withRouter(MiCaja);
