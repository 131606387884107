import * as React from "react";
import HistoryIcon from "mdi-react/HistoryIcon";
import PlusIcon from "mdi-react/PlusThickIcon";
import { Alert, Confirm } from "shared/Alert";
import DeleteIcon from "mdi-react/DeleteIcon";
import { NewAgent, EditAgent } from "./Agent";
import funciones from "services/funciones";
import EditIcon from "mdi-react/EditIcon";
import History from "shared/History";
import { useMedia } from "hooks";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint no-unused-vars: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const MenuRow = (params) => {
    const { navigate, data, blue, children, onSel, reload, current } = params;
    const [clases, setClases] = React.useState(blue == 0 ? "table_row bkg_blue" : "table_row");
    const [_pos, set_pos] = React.useState({ top: "0", left: "0" });
    const [modalView, setModalView] = React.useState(null);
    const [showMenu, setShowMenu] = React.useState(false);
    const [_e, set_e] = React.useState();
    const { media_movil } = useMedia();
    const menuRef = React.useRef();
    const row = React.useRef(null);


    const goDelete = (e) => {
        hiddenMenu(e);

        Confirm(`¿Estás seguro de borrar la cuenta de "${data.nombre} ${data.apellido}"?`, async (response) => {
            if (response) {
                let _data = await api.deleteUser({ id_user: data.id_user });

                if (_data.response == 1) {
                    document.body.style.overflow = "auto";
                    reload();
                } else if (_data.response === -2) {
                    Alert(_data.msg, "warning", () => {
                        document.body.style.overflow = "auto";
                        navigate("/sign-in");
                    });
                } else {
                    Alert(_data.msg, "warning");
                }
            }
        });
    }

    const onSelect = (e) => {
        e.preventDefault()
        e.stopPropagation();

        onSel(data);
    }

    const stopClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    const hiddenMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();

        document.body.style.overflow = "auto";
        setShowMenu(false);
    }

    const handleContextMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();

        set_e(e);
        document.body.style.overflow = "hidden";
        setShowMenu(true);
    }

    const handleModal = (_option, e) => {
        e.preventDefault()
        e.stopPropagation();
        let _window;

        switch (_option) {
            case "history":
                _window = <History
                    data={{
                        ...data,
                        tipo: 13,
                        parent: { ..._pos, width: media_movil ? (document.getElementById("root").offsetWidth - 16) : 550 }
                    }}
                    close={() => setModalView(null)} />;
                break;
            case "editar":
                _window = <EditAgent
                    data={data}
                    parent={{ ..._pos, width: media_movil ? (document.getElementById("root").offsetWidth - 16) : 500 }}
                    close={() => setModalView(null)}
                    reload={reload} />;
                break;
            case "nuevo":
                _window = <NewAgent
                    data={data}
                    parent={{ ..._pos, width: media_movil ? (document.getElementById("root").offsetWidth - 16) : 500 }}
                    close={() => setModalView(null)}
                    reload={reload} />;
                break;
            default:
                _window = null;
                break;
        }

        setShowMenu(false);
        setModalView(_window);
    }

    React.useEffect(() => {
        if (showMenu) {
            set_pos(funciones.getPositonDisplay(_e, menuRef.current));
        }
    }, [showMenu]);

    React.useEffect(() => {
        current === data.id_user && setClases(c => c + " bkg_select");
    }, [current]);

    return (
        <tr className={clases} ref={row}
            onClick={onSelect}
            onContextMenu={handleContextMenu}>

            {children}
            <td className="rowMenu" onClick={stopClick} onDoubleClick={stopClick} onContextMenu={stopClick}>
                {showMenu &&
                    <>
                        <div className="back_form _bkg_black" onClick={hiddenMenu} onContextMenu={hiddenMenu}></div>
                        <nav className="menu" style={{ ..._pos, width: media_movil ? 170 : 130 }} ref={menuRef}>
                            <ul>
                                <li onClick={e => handleModal("nuevo", e)}><PlusIcon /> <span>Crear Nuevo</span></li>
                                <li onClick={e => handleModal("editar", e)}><EditIcon /> <span>Editar</span></li>

                                <hr />
                                <li onClick={goDelete}><DeleteIcon /> <span>Borrar</span></li>
                                <li onClick={e => handleModal("history", e)}><HistoryIcon /> <span>Historial</span></li>
                            </ul>
                        </nav>
                    </>}

                {modalView}

            </td>
        </tr>
    )
}

export default MenuRow;