import * as React from "react";
import { RequirePermissions } from "shared/AlertMessages";
import { TitleSticky } from "shared/Components";
import funciones from "services/funciones";
import Menu from "./components/MenuAdmin";
import ToolBar from "shared/tool_bar";
import { Alert } from "shared/Alert";
import { Api } from "services/api";
import { useMedia } from "hooks";
import * as Icon from "images";

/* eslint eqeqeq: 0 */

const RecolectasAdmin = (router) => {
    const { navigate } = router;
    const { media_movil, setTitulo, setDataUser, currentQuery, setCurrentQuery } = useMedia();
    const [loadResult, setLoadResult] = React.useState("Cargando recolectas...");
    const api = React.useRef(new Api({ navigate, setDataUser }));
    const [showLoading, setShowLoading] = React.useState(false);
    const [selected, setSelected] = React.useState({ id: 0 });
    const [showLazy, setShowLazy] = React.useState(true);
    const [dataList, setDataList] = React.useState([]);


    const Row = ({ data, blue }) => {
        data.info = JSON.parse(data.info_tienda);
        if (data.info?.collect) {
            data.carrier_name = data.info.collect.carrier.map(item => item.nombre).join(", ");
        }

        return (
            <Menu
                inRow={true}
                data={data}
                blue={blue}
                navigate={navigate}
                current={selected.id_client}
                onSel={setSelected}
                reload={() => getList(currentQuery.recolectas)}>

                {media_movil ?
                    <td style={{ maxWidth: "calc(100vw - 32px)", paddingBottom: 0 }}>
                        <div className="_states">
                            <strong><i style={{ float: "left" }}>Tienda</i><br />{data.info?.company?.nombre ?? "Sin nombre"}</strong>
                            <strong><i>Mensajero</i><br />{data.carrier_name ?? "Sin asignar"}</strong>
                        </div>

                        <p className="_states">
                            <span><b>Por recolectar:</b> {data.por_recolectar ?? 0}</span>
                            <span><b>En recolecta:</b> {data.en_recolecta ?? 0}</span>
                            <span><b>En error:</b> {data.en_error ?? 0}</span>
                            <span><b>Cancelados:</b> {data.cancelados ?? 0}</span>
                        </p>
                    </td>
                    :
                    <>
                        <td>{data.info?.company?.nombre ?? "Sin nombre"}</td>
                        <td>{data.carrier_name ?? "Sin asignar"}</td>
                        <td>{data.por_recolectar ?? 0}</td>
                        <td>{data.en_recolecta ?? 0}</td>
                        <td>{data.en_error ?? 0}</td>
                        <td>{data.cancelados ?? 0}</td>
                    </>}

            </Menu>
        );
    }

    const AllRow = () => {
        let _data = dataList.map((item, index) => {
            return <Row key={item.id_client} blue={index % 2} data={item} />;
        });

        if (_data.length < 1) {
            return <h4 className="_titulo">{loadResult}</h4>;
        } else {
            return <>
                <table className="table">
                    <tbody>

                        {media_movil ?
                            <tr className="table_title">
                            </tr>
                            :
                            <tr className="table_title">
                                <th>Tienda</th>
                                <th>Mensajero</th>
                                <th>Por recolectar</th>
                                <th>En recolecta</th>
                                <th>En error</th>
                                <th>Cancelados</th>
                            </tr>
                        }

                        {_data}
                    </tbody>
                </table>

                {showLoading ?
                    <button className="btn_lazy_load"><Icon.Loading /></button>
                    :
                    <>
                        {showLazy ?
                            <button className="btn_lazy_load" onClick={() => getListLazy(currentQuery.recolectas)}>Cargar más</button>
                            :
                            <h4 className="h4_lazy_load">{loadResult}</h4>
                        }
                    </>
                }
            </>;
        }
    }

    const getListLazy = async (_filter) => {
        setLoadResult("Cargando recolectas...");
        setShowLoading(true);

        let _data = await api.current.fetchJson({
            url: "count-all-collects",
            data: { ..._filter, corte: 0, offset: dataList.length }
        });

        if (_data.response == 1) {
            let _packs = [];

            dataList.forEach(item => {
                _packs.push(item);
            });

            _data.data.forEach(item => {
                _packs.push(item);
            });

            setShowLazy(_data.data.length < 50 ? false : true);
            setDataList(_packs);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
        } else {
            Alert(_data.msg, "warning");
        }

        if (_filter?.search == 1) {
            let _msg = "";
            _msg += (_filter.consignee?.length > 0 && _filter.consigneeName) ? "\ncliente \"" + _filter.consigneeName + "\"" : "";
            _msg += (_filter.carrier?.length > 0 && _filter.carrierName) ? "\nmensajero \"" + _filter.carrierName + "\"" : "";
            _msg += (_filter.estado?.length > 0 && _filter.estadoName) ? "\nestado \"" + _filter.estadoName + "\"" : "";

            setLoadResult((!_data?.data || _data?.data.length < 1) ? "No hay paquetes con: " + _msg : "No hay más paquetes con: " + _msg);
        } else {
            setLoadResult((!_data?.data || _data?.data.length < 1) ? "¡No hay paquetes!" : "¡No hay más paquetes!");
        }
        setShowLoading(false);
    }

    const getList = async (_filter) => {
        setLoadResult("Cargando recolectas...");
        setShowLoading(true);
        setDataList([]);

        let _data = await api.current.fetchJson({
            url: "count-all-collects",
            data: { ..._filter, corte: 0, offset: 0 }
        });

        if (_data.response == 1) {
            setShowLazy(_data.data.length < 50 ? false : true);
            setDataList(_data.data);
            setCurrentQuery(v => ({ ...v, recolectas: { ..._filter } }));
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
        } else {
            Alert(_data.msg, "warning");
        }

        if (_filter?.search == 1) {
            let _msg = "";
            _msg += (_filter.consignee?.length > 0 && _filter.consigneeName) ? "\ncliente \"" + _filter.consigneeName + "\"" : "";
            _msg += (_filter.carrier?.length > 0 && _filter.carrierName) ? "\nmensajero \"" + _filter.carrierName + "\"" : "";
            _msg += (_filter.estado?.length > 0 && _filter.estadoName) ? "\nestado \"" + _filter.estadoName + "\"" : "";

            setLoadResult((!_data?.data || _data?.data.length < 1) ? "No hay recolectas con: " + _msg : "No hay más recolectas con: " + _msg);
        } else {
            setLoadResult((!_data?.data || _data?.data.length < 1) ? "¡No hay recolectas!" : "¡No hay más recolectas!");
        }
        setShowLoading(false);
    }

    // Lista de recolectas del administrador
    React.useEffect(() => {
        setTitulo("Recolectas");
        document.body.style.overflow = "auto";
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <RequirePermissions navigate={navigate}
            permission={funciones.validaPermisos("recolectas_gestion", [1, 2, 3])}>

            <div className="cont_principal">
                <TitleSticky className="_in_filters">Recolectas</TitleSticky>
                <ToolBar {...router}
                    type="recolectas"
                    showLoading={showLoading}
                    reload={getList}
                    currentFilterOptions={currentQuery.recolectas}
                    filter={{
                        showStatus: true,
                        statusList: [
                            "Todos",
                            "por.recolectar",
                            "en.recolecta",
                            "error.dirección",
                            "recolecta.cancelada",
                        ]
                    }} />

                <AllRow />
            </div>
        </RequirePermissions>
    )
}

export default RecolectasAdmin;