import * as React from "react";
import Account from "mdi-react/AccountCircleOutlineIcon";
import BarcodeScanIcon from "mdi-react/BarcodeScanIcon";
import ThemeIcon from "mdi-react/ThemeLightDarkIcon";
import AutorenewIcon from "mdi-react/AutorenewIcon";
import DownIcon from "mdi-react/ChevronDownIcon";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import TopbarMenuLink from "./TopbarMenuLink";
import { Alert, Search } from "shared/Alert";
import ToolsIcon from "mdi-react/ToolsIcon";
import { Collapse } from "reactstrap";
import * as Iconos from "images";

/* eslint eqeqeq: 0 */
/* eslint jsx-a11y/anchor-is-valid: 0 */
// const Ava = `${process.env.PUBLIC_URL}/static/img/sarguero.png`;

const TopbarProfile = ({ changeTheme }) => {
    const { t } = useTranslation('common');
    const navigate = useNavigate();
    const refresh = localStorage.getItem('refresh');
    const [isCollapsed, setIsCollapsed] = React.useState(false);
    const [nombre, setNombre] = React.useState("");
    const [perfil, setPerfil] = React.useState("");
    const [uRLactual, setURLactual] = React.useState("Cambio");
    const [currentTheme, setCurrentTheme] = React.useState("theme-light");
    const [swipeRefresh, setSwipeRefresh] = React.useState(refresh == null ? false : refresh === "true");
    const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7 };


    const goEditStore = (e) => {
        e.preventDefault()
        e.stopPropagation();

        setIsCollapsed(!isCollapsed);
        navigate("/dashboard/tienda/editar-perfil");
    }

    const setTheme = (theme) => {
        changeTheme(theme);
        setCurrentTheme(theme);
    }

    const handleTheme = (e) => {
        let theme = currentTheme == "theme-light" ? "theme-dark" : "theme-light";

        localStorage.setItem("theme", theme);
        setTheme(theme);
        handleToggleCollapse();
    }

    const handleToggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    }

    const handleToggleRefresh = (e) => {
        e.preventDefault();
        let _var = swipeRefresh ? false : true;
        handleToggleCollapse();

        if (typeof android !== "undefined") {
            localStorage.setItem('refresh', _var);

            // eslint-disable-next-line no-undef
            android.setSwipeRefresh(_var);
            setSwipeRefresh(_var);
        }
    }

    const handleScanner = (e) => {
        e.preventDefault();
        handleToggleCollapse();

        if (typeof android !== "undefined") {

            // eslint-disable-next-line no-undef
            scanner.response = (codigo) => {
                if (codigo.substring(0, 3) === "OS-") {
                    navigate("/dashboard/orden-de-servicio/" + codigo.substring(3));
                } else if (codigo.substring(0, 3) === "OR-") {
                    navigate("/dashboard/orden-de-recogida/" + codigo.substring(3));
                } else {
                    navigate("/dashboard/detalle-paquete/" + codigo);
                }
            };

            // eslint-disable-next-line no-undef
            scanner.error = (format) => Alert(`Error de lectura: \nEl formato del código es "${format}" y no es compatible con esta función.`, "warning");

            // eslint-disable-next-line no-undef
            android.scannerQR();
        }
    }

    const handleSearch = (e) => {
        e.preventDefault();
        handleToggleCollapse();

        Search((codigo) => {
            if (codigo.substring(0, 3) === "OS-") {
                navigate("/dashboard/orden-de-servicio/" + codigo.substring(3));
            } else if (codigo.substring(0, 3) === "OR-") {
                navigate("/dashboard/orden-de-recogida/" + codigo.substring(3));
            } else {
                navigate("/dashboard/detalle-paquete/" + codigo);
            }
        });
    }

    const handleToggleUrl = (e) => {
        e.preventDefault();
        handleToggleCollapse();

        if (uRLactual == "btsmensajeria.com" || uRLactual == "pruebas.btsmensajeria.com") {
            window.location.href = "http://192.168.1.149/dashboard";
        } else {
            window.location.href = "https://btsmensajeria.com/dashboard";
        }
    }

    const BtnRefresh = () => {
        return (<>
            <span className="topbar__link" onClick={handleToggleRefresh}>
                <AutorenewIcon className="topbar__link-icon" />
                <p className="topbar__link-title">{swipeRefresh ? "Desc" : "Act"}. refresh</p>
            </span>
            <div className="topbar__menu-divider" />
        </>);
    }

    const BtnScan = () => {
        return (<>
            <span className="topbar__link" onClick={handleScanner}>
                <BarcodeScanIcon className="topbar__link-icon" />
                <p className="topbar__link-title">Escanear código</p>
            </span>
            <div className="topbar__menu-divider" />
        </>);
    }

    const BtnEditProfile = () => {
        let _btn = <></>;

        if ([6].includes(parseInt(data_user.nivel))) {
            _btn = <>
                <span className="topbar__link" onClick={goEditStore}>
                    <Iconos.StoreEdit className="topbar__link-icon" />
                    <p className="topbar__link-title">Editar tienda</p>
                </span>
                <div className="topbar__menu-divider" />
            </>;
        }

        return _btn;
    }

    const BtnSearch = () => {
        return (<>
            <span className="topbar__link" onClick={handleSearch}>
                <MagnifyIcon className="topbar__link-icon" />
                <p className="topbar__link-title">Buscar código</p>
            </span>
            <div className="topbar__menu-divider" />
        </>);
    }

    const BtnMode = () => {
        let _theme = currentTheme == "theme-light" ? "theme-dark" : "theme-light";
        let _str = "";

        if (uRLactual == "btsmensajeria.com" || uRLactual == "pruebas.btsmensajeria.com") {
            _str = "De " + uRLactual.substring(uRLactual.length - 4) + " a dev";
        } else {
            _str = "De " + uRLactual.substring(uRLactual.length - 4) + " a prod";
        }

        return (<>
            <span className="topbar__link" onClick={handleTheme}>
                <ThemeIcon className="topbar__link-icon" />
                <p className="topbar__link-title">Set {_theme}</p>
            </span>
            <div className="topbar__menu-divider" />

            <span className="topbar__link" onClick={handleToggleUrl}>
                <ToolsIcon className="topbar__link-icon" />
                <p className="topbar__link-title">{_str}</p>
            </span>
            <div className="topbar__menu-divider" />
        </>);
    }

    /**
       * Niveles de los usuarios 
       * 
       * 1 Super Administrador
       * 2 Administrador
       * 3 Coordinador de zona
       * 4 Mensajero
       * 5 Recepcionista
       * 6 Cliente bts
       * 7 Cliente final
       * 
       */
    React.useEffect(() => {
        let _perfil;
        let theme = localStorage.getItem("theme");

        setTheme(theme ? theme : currentTheme);
        setNombre(data_user ? data_user.nombre : "Sign In");

        switch (parseInt(data_user.nivel)) {
            case 1:
                _perfil = "Super Admin";
                break;
            case 2:
                _perfil = "Administrador";
                break;
            case 3:
                _perfil = "Coordinador";
                break;
            case 4:
                _perfil = "Mensajero";
                break;
            case 5:
                _perfil = "Recepcionista";
                break;
            case 6:
                _perfil = `Admin. de tienda: ${data_user.info.company.nombre}`;
                break;
            case 61:
                _perfil = `Usuario de tienda: ${data_user.info.company.nombre}`;
                break;
            case 8:
                _perfil = "Cajero";
                break;
            case 7:
            default:
                _perfil = "";
                break;
        }

        setPerfil(_perfil);


        if (typeof android !== "undefined") {

            // eslint-disable-next-line no-undef
            android.setSwipeRefresh(swipeRefresh);
        }

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        let _url = window.location.href.toString().split('/')[2];

        setURLactual(_url);
    }, [window.location.href]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="topbar__profile">

            <button type="button" className="topbar__avatar" onClick={handleToggleCollapse}>
                <Account className="topbar__avatar-img" />
                <p className="topbar__avatar-name">{nombre}</p>
                <DownIcon className="topbar__icon" />
                <i className="topbar__rol-name">{perfil}</i>
            </button>

            {isCollapsed && (
                <button
                    type="button"
                    aria-label="button collapse"
                    className="topbar__back"
                    onClick={handleToggleCollapse}
                />
            )}

            <Collapse isOpen={isCollapsed} className="topbar__menu-wrap">
                <div className="topbar__menu">
                    {typeof android !== "undefined" && <>
                        <BtnRefresh />
                        <BtnScan />
                    </>}
                    <BtnEditProfile />
                    <BtnSearch />
                    {typeof android !== "undefined" && <>
                        {(data_user.auth && [1, 3].includes(parseInt(window.atob(data_user.auth)))) && <BtnMode />}
                    </>}
                    <TopbarMenuLink title={t("profile.log_out")} icon="exit" path="/sign-in" />
                    {/* <a className="topbar__version">{process.env.REACT_APP_BUILD_VERSION}</a> */}
                </div>
            </Collapse>

        </div>
    );
};

export default TopbarProfile;
