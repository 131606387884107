import * as React from "react";
import SwapVerticalIcon from "mdi-react/SwapVerticalIcon";
import { RequirePermissions } from "shared/AlertMessages";
import { NewAgent } from "../Usuarios/components/Agent";
import PlugIcon from "mdi-react/AccountPlusIcon";
import { TitleSticky } from "shared/Components";
import withRouter from "services/withRouter";
import { SearchUsers } from "shared/Input";
import MenuRow from "./components/MenuRow";
import funciones from "services/funciones";
import { Alert } from "shared/Alert";
import Loading from "shared/Loading";
import { useMedia } from "hooks";
import * as Icon from "images";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const Mensajeros = (params) => {
    const { navigate } = params;
    const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7 };
    const [loadResult, setLoadResult] = React.useState("Cargando mensajeros...");
    const { media_movil, setTitulo, currentQuery, setCurrentQuery } = useMedia();
    const [selected, setSelected] = React.useState({ id_user: 0 });
    const [showLoading, setShowLoading] = React.useState(true);
    const [modalView, setModalView] = React.useState(null);
    const [showLazy, setShowLazy] = React.useState(true);
    const [dataList, setDataList] = React.useState([]);
    const [asc, setAsc] = React.useState(false);


    const Row = ({ data, blue }) => {

        return (
            <MenuRow data={data} blue={blue}
                navigate={navigate}
                current={selected.id_user}
                onSel={setSelected}
                reload={() => getList(currentQuery.mensajeros)}>

                <td>{data.nombre} {data.apellido}</td>
                <td>{data.recogidas}</td>
                <td>{data.entregas}</td>
                <td>{data.devoluciones}</td>
            </MenuRow>
        );
    }

    const AllRow = () => {
        let _data = [];
        let _blue = 0;

        const handleModal = (e) => {
            let _window = <NewAgent
                data={{ rol: 4 }}
                parent={{
                    ...e.target.getBoundingClientRect().toJSON(),
                    width: media_movil ? (document.getElementById("root").offsetWidth - 16) : 500
                }}
                close={() => setModalView(null)}
                reload={() => getList(currentQuery.mensajeros)} />;

            setModalView(_window);
        }

        dataList.map(item => {
            _data.push(<Row key={item.id_user} blue={_blue} data={item} />);
            _blue = _blue >= 1 ? 0 : _blue + 1;
        });

        if (_data.length < 1) {
            return <>
                <h4 className="_titulo">{loadResult}</h4>
                <h4 className="_button" onClick={handleModal}>Crear Nuevo Mensajero<PlugIcon /></h4>

                {modalView}
            </>;
        } else {
            return <>
                <div style={{ overflowX: "scroll" }}>
                    <table className="table">
                        <tbody>

                            <tr className="table_title">
                                <th><SwapVerticalIcon onClick={() => sortBy("nombre")} />Mensajero</th>
                                <th>Recogidas pendientes</th>
                                <th>Entregas pendientes</th>
                                <th>Devoluciones pendientes</th>
                            </tr>

                            {_data}
                        </tbody>
                    </table>
                </div>

                {showLoading ?
                    <button className="btn_lazy_load"><Icon.Loading /></button>
                    :
                    <>
                        {showLazy ?
                            <button className="btn_lazy_load" onClick={() => getListLazy()}>Cargar más</button>
                            :
                            <h4 className="h4_lazy_load">{loadResult}</h4>
                        }

                        <h4 className="_button" onClick={handleModal}>Crear Nuevo Mensajero<PlugIcon /></h4>
                        {modalView}
                    </>
                }
            </>
        }
    }

    const sortBy = (_key) => {

        let mapped = dataList.map((item, i) => {
            return { index: i, value: item[_key].toLowerCase() };
        });

        mapped.sort(function (a, b) {
            if (asc) {
                if (a.value > b.value) {
                    return 1;
                }

                if (a.value < b.value) {
                    return -1;
                }
            } else {
                if (a.value > b.value) {
                    return -1;
                }

                if (a.value < b.value) {
                    return 1;
                }
            }

            return 0;
        });

        let _new = mapped.map(item => {
            return dataList[item.index];
        });

        setAsc(!asc);
        setDataList(_new);
    }

    const getListLazy = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "get-users",
            data: {
                roll: currentQuery.mensajeros.task,
                criterio: currentQuery.mensajeros.criterio,
                limit: 50,
                offset: dataList.length
            }
        });

        if (_data.response == 1) {
            let _packs = [];

            dataList.map(item => {
                _packs.push(item);
            });

            _data.data.map(item => {
                _packs.push(item);
            });

            setDataList(_packs);
            setShowLazy(_data.data.length < 50 ? false : true);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setLoadResult((!_data?.data || _data?.data.length < 1) ? "¡No hay mensajeros!" : "¡No hay más mensajeros!");
        setShowLoading(false);
    }

    const handleSearch = async (_val) => {
        _val = _val.toLowerCase();
        setLoadResult("Buscando mensajeros con '" + _val + "'!");

        setShowLoading(true);
        setCurrentQuery(v => ({ ...v, mensajeros: { task: "search_carrier", criterio: _val } }));
        setDataList([]);
        let _data = await api.fetchJson({
            url: "get-users",
            data: {
                roll: "search_carrier",
                criterio: _val,
                limit: 50,
                offset: 0
            }
        });

        if (_data.response == 1) {
            setDataList(_data.data);
            setShowLazy(_data.data.length < 50 ? false : true);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setLoadResult((!_data?.data || _data?.data.length < 1) ? "¡No hay mensajeros con '" + _val + "'!" : "¡No hay más mensajeros con '" + _val + "'!");
        setShowLoading(false);
    }

    const getList = async (_query) => {
        setShowLoading(true);
        setDataList([]);
        let _data = await api.fetchJson({
            url: "get-users",
            data: {
                roll: _query.task,
                criterio: _query.criterio,
                limit: 50,
                offset: 0
            }
        });

        if (_data.response == 1) {
            setDataList(_data.data);
            setShowLazy(_data.data.length < 50 ? false : true);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setLoadResult((!_data?.data || _data?.data.length < 1) ? "¡No hay mensajeros!" : "¡No hay más mensajeros!");
        setShowLoading(false);
    }

    React.useEffect(() => {
        setTitulo("Lista de mensajeros");
        document.body.style.overflow = "auto";
        if (funciones.validaPermisos("user_mensajero") && [1, 2].includes(parseInt(data_user.nivel))) {
            getList(currentQuery.mensajeros);
        }
    }, []);

    return (
        <RequirePermissions navigate={navigate}
            permission={funciones.validaPermisos("user_mensajero") && [1, 2].includes(parseInt(data_user.nivel))}>

            <div className="cont_principal">
                <TitleSticky className="_in_filters">Lista de mensajeros</TitleSticky>
                <div className="cont_search_bar">
                    <div className="search_bar">
                        <SearchUsers type="text" placeholder="Buscar por nombre"
                            value={currentQuery.mensajeros.criterio}
                            onChange={handleSearch}
                            onClean={() => {
                                setCurrentQuery(v => ({ ...v, mensajeros: { task: "carrier", criterio: "" } }));
                                getList({ task: "carrier", criterio: "" });
                            }}
                        />
                    </div>
                </div>

                <AllRow />

                {showLoading && <Loading />}
            </div>
        </RequirePermissions>
    )
}

export default withRouter(Mensajeros);
